import React, { useEffect, useState } from 'react';
import { useProvideCart } from 'src/hooks/useCart';
import useSnackbar from 'src/hooks/useSnackbar';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import companySvc from 'src/services/company';
import documentSvc from 'src/services/document';
import authSvc from 'src/services/authentication';
import { Cookies, useCookies } from 'react-cookie';
import { useProvideUser } from 'src/hooks/useUsers';
import { useProvideSysInfoUserTable } from 'src/hooks/useSysInfoUser';
import jwt from 'jsonwebtoken';

export const ConfigContext = React.createContext({});

export const ConfigContextProvider = ({ children }) => {
  const location = useLocation();
  const idToken = new Cookies().get('idToken');
  const idTokenData = jwt.decode(idToken);
  const {
    sysInfoUser: APIUser,
    ListSysInfoUser,
  } = useProvideSysInfoUserTable();
  const [_, setCompanyCookies] = useCookies(['company']);

  const clearCart = () => {
    setConfigState((prevState) => {
      return {
        ...prevState,
        cart: {},
        cartList: [],
      };
    });
  };

  const mergeProductAndImage = (cartItems = [], documents = []) => {
    let result = cartItems.map((aCartItem) => {
      if (aCartItem?.product?.product_documents?.length > 0) {
        let newProductDocuments = aCartItem?.product?.product_documents.map(
          (aProductDocument) => {
            let foundDoc = documents.find((aDoc) => {
              return aDoc.document_id == aProductDocument.document_id;
            });
            if (foundDoc) {
              return {
                ...aProductDocument,
                document: foundDoc,
              };
            }
            return aProductDocument;
          },
        );
        return {
          ...aCartItem,
          product: {
            ...aCartItem.product,
            product_documents: newProductDocuments,
          },
        };
      }
      return aCartItem;
    });
    return result;
  };

  const ListCartItemDocuments = async (cartItems = []) => {
    let ids = [];
    cartItems.forEach((aCartItem) => {
      if (aCartItem?.product?.product_documents?.length > 0) {
        aCartItem?.product?.product_documents.forEach((anItem) => {
          ids.push(anItem?.document_id);
        });
      }
    });
    let res;
    // setProductLoading(true);
    if (ids?.length > 0) {
      try {
        res = await documentSvc.getProductImageDocumentsByIds(ids);
        if (res?.data?.documents?.length > 0) {
          let documents =
            res?.data?.documents?.length > 0 ? res?.data?.documents : [];
          let mergedOrderItems = mergeProductAndImage(cartItems, documents);

          return mergedOrderItems;
        }
      } catch (error) {
        // setProductError(error);
      }
      // setProductLoading(false);
    }

    return cartItems;
  };

  const fetchCart = async (user) => {
    if (!cartLoading) {
      (async () => {
        try {
          let res = await ListCart(user.user_id);
          const initState = {};
          if (res.status === 'success') {
            let newCartItems = await ListCartItemDocuments(
              res?.body.cart?.items || [],
            );
            let newCart = { ...res.body.cart, items: newCartItems };
            initState.cartList = newCartItems;
            initState.cart = newCart;

            setConfigState((prevState) => {
              return {
                ...prevState,
                cart: newCart || {},
                cartList: newCartItems || [],
              };
            });
          }
        } catch (error) {
          setConfigState((prevState) => {
            return {
              ...prevState,
            };
          });
        }
      })();
    }
  };

  const setSelectedUser = (selectedUser) => {
    setConfigState((prevState) => {
      return {
        ...prevState,
        selectedUser,
      };
    });
  };

  const setUserLoading = (userLoading) => {
    setConfigState((prevState) => {
      return {
        ...prevState,
        userLoading,
      };
    });
  };

  const setUser = (user) => {
    if (user.user_id) {
      fetchCart(user);
    }
    setConfigState((prevState) => {
      return {
        ...prevState,
        user: user,
      };
    });
  };

  const signOut = async () => {
    let currentState = {};
    setConfigState((prevState) => {
      currentState = prevState;
      return prevState;
    });
    await new Cookies().remove('company');
    await new Cookies().remove('idToken');
    await new Cookies().remove('accessToken');
    window.location.href = `${process.env.REACT_APP_SSO}/session/end?id_token_hint=${currentState.idToken}&post_logout_redirect_uri=${window.origin}`;

    // setConfigState((prevState) => {

    //   return {
    //     ...prevState,
    //     user: {},
    //   };
    // });
  };

  const {
    AddCart,
    UpdateCart,
    DeleteCartItem,
    UpdateCartItem,
    ListCart,
    cartError,
    cartLoading,
    cartSubmitting,
  } = useProvideCart();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  useEffect(() => {
    if (cartError) {
      showErrorSnackbar(cartError);
    }
  }, [cartError]);

  useEffect(() => {
    setConfigState((prevState) => {
      return {
        ...prevState,
        cartSubmitting,
      };
    });
  }, [cartSubmitting]);
  useEffect(() => {
    if (APIUser?.user_id) {
      setConfigState((prevState) => {
        return {
          ...prevState,
          user: APIUser,
        };
      });
    }
  }, [APIUser]);

  useEffect(() => {
    (async () => {
      if (idToken) {
        const idTokenData = jwt.decode(idToken);

        const urlSearchParams = new URLSearchParams(location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (!params.redirect || params.redirect !== 'checkout') {
          // Do not fetch cart if redirected back from payment gateway, due to delay in callback, cart is still not cleared
          fetchCart({ user_id: idTokenData?.sub });
        }
        let res = await ListSysInfoUser(idTokenData?.sub);
        let user = res?.body?.users;

        if (!user?.email) {
          window?.alert('Invalid User. Please login again.');
          await signOut();
        } else {
          setConfigState((prevState) => {
            return {
              ...prevState,
              idToken,
              user: { user_id: idTokenData?.sub },
            };
          });
        }
      }
    })();
  }, [idToken]);

  const addWebInfo = (webInfo) => {
    setConfigState((prevState) => {
      delete prevState.webInfo.webdev_category_name;
      return {
        ...prevState,
        webInfo: { ...prevState.webInfo, ...webInfo },
      };
    });
  };

  const addCart = async (cart, successMsg) => {
    let currentState = {};
    setConfigState((prevState) => {
      currentState = prevState;
      return prevState;
    });
    cart.last_name = currentState?.user?.last_name;
    cart.first_name = currentState?.user?.first_name;
    cart.email = currentState?.user?.email;
    const res = await AddCart(cart);
    if (res.status === 'success') {
      setConfigState((prevState) => ({
        ...prevState,
        cartList: [...prevState.cartList, res.body.cart],
        cart: res.body,
      }));
    }
    return res;
  };

  const updateCart = async (cart, successMsg) => {
    let currentState = {};
    setConfigState((prevState) => {
      console.log('@@@ prevState', prevState);
      currentState = prevState;
      return prevState;
    });
    console.log('@@@ currentState', currentState);
    // cart.last_name = currentState?.user?.last_name;
    // cart.first_name = currentState?.user?.first_name;
    // cart.email = currentState?.user?.email;
    const res = await UpdateCart(cart);
    if (res.status === 'success') {
      setConfigState((prevState) => {
        let newCartList = prevState.cartList.map((anItem) => {
          if (anItem.id == cart.id && res?.body?.cart) {
            return res?.body?.cart;
          }
          return anItem;
        });
        return {
          ...prevState,
          cartList: newCartList,
          cart: res.body,
        };
      });
    }
    return res;
  };

  const removeCart = async (cartList, index) => {
    const itemToRemove = cartList[index];
    const res = await DeleteCartItem(itemToRemove.id);
    if (res.status === 'success') {
      cartList.splice(index, 1);
      setConfigState((prevState) => ({
        ...prevState,
        cartList: cartList,
      }));
      showSuccessSnackbar('Item removed from cart.');
    }
    return res;
  };

  const updateCartItem = async (cartItem, value = {}) => {
    let body = {
      ...value,
      id: cartItem.id,
    };
    const res = await UpdateCartItem(body);
    if (res.status === 'success') {
      setConfigState((prev) => {
        return {
          ...prev,
          cart: res?.body?.cart,
          cartList: res?.body?.cart?.items,
        };
      });
      showSuccessSnackbar('Item quantity updated.');
    }
    return res;
  };
  const setConfirmationDialog = (dialogState = {}) => {
    setConfigState((prevState) => {
      return {
        ...prevState,
        confirmationDialog: Object.assign({}, dialogState),
      };
    });
  };

  const setCompany = async (companyId) => {
    setCompanyCookies('company', companyId);
    setConfigState((prevState) => {
      let newCompany = prevState.companyList.find(
        (item) => item.company_id === companyId,
      );
      companySvc.setCompany({
        companyName: newCompany.company_name,
        companyId: newCompany.company_id,
        uen: newCompany.company_registration_no,
      });
      return {
        ...prevState,
        company: newCompany,
      };
    });
  };

  //   const setCompanyList = async () => {
  //     setConfigState((prevState) => ({
  //       ...prevState,
  //       companyLoading: true,
  //     }));
  //     const res = await companySvc.companiesAndShares();
  //     let newState = {};
  //     if (res.length > 0) {
  //       newState = {
  //         company: res[0],
  //         companyList: res,
  // <<<<<<< HEAD
  //       }));
  //     }

  //     // move out for no company conditions
  //     setConfigState((prevState) => ({
  //       ...prevState,
  // =======
  //       };
  //     }
  //     setConfigState((prevState) => ({
  //       ...prevState,
  //       ...newState,
  // >>>>>>> a7563ce06f9cdfbe07262f02d85e5d2e64ec4ec9
  //       companyLoading: false,
  //     }));
  //     return res;
  //   };

  const setCompanyList = async () => {
    setConfigState((prevState) => ({
      ...prevState,
      companyLoading: true,
    }));
    const companyCookies = new Cookies().get('company');
    const res = await authSvc.getSysinfoUserCompanies(APIUser.user_id);
    // const res = await companySvc.companiesAndShares();
    if (res.length > 0) {
      setConfigState((prevState) => {
        let defaultCompany = res[0];
        if (companyCookies) {
          let selectedCompany = res.find((item) => {
            return item.company_id == companyCookies;
          });
          if (selectedCompany) {
            defaultCompany = selectedCompany;
          }
        }
        companySvc.setCompany({
          companyName: defaultCompany?.company_name,
          companyId: defaultCompany?.company_id,
          uen: defaultCompany?.company_registration_no,
        });
        return {
          ...prevState,
          company: defaultCompany,
          companyList: res,
        };
      });
    }

    // move out for no company conditions
    setConfigState((prevState) => ({
      ...prevState,
      companyLoading: false,
    }));
    return res;
  };

  const [configState, setConfigState] = useState({
    cartList: [],
    cart: {},
    cartSubmitting,
    confirmationDialog: {
      title: '',
      isVisible: false,
      onBackdropPress: () => {},
      confirmButtonAction: () => {},
      cancelButtonAction: () => {},
      confirmButtonLabel: '',
      cancelButtonLabel: '',
      confirmButtonStyles: {},
      cancelButtonStyles: {},
      type: '',
      showIcon: true,
      icon: '',
      maxWidth: 'sm',
    },
    selectedUser: {},
    webInfo: [],
    user: { user_id: idTokenData?.sub },
    addCart,
    updateCart,
    updateCartItem,
    clearCart,
    removeCart,
    addWebInfo,
    setConfirmationDialog,
    setSelectedUser,
    setUser,
    setUserLoading,
    signOut,
    userLoading: true,
    fetchCart,
    company: {},
    companyList: [],
    companyLoading: true,
    setCompany,
    setCompanyList,
    idToken,
  });

  useEffect(() => {
    if (idToken && configState.companyList.length === 0) {
      setCompanyList();
    }
  }, [idToken && configState.companyList.length, APIUser]);

  return (
    <ConfigContext.Provider value={configState}>
      {children}
    </ConfigContext.Provider>
  );
};
