import {
  Box,
  Typography,
  Container,
  Select,
  MenuItem,
  Divider,
  ButtonBase,
  Paper,
} from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import AuthLayout from 'src/components/AuthLayout';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import ROUTES from 'src/constants/routes';
import useBreakpoints from 'src/hooks/useBreakpoints';
import useStyles from './Layout.styles';
import { ConfigContext } from 'src/contexts';
import { EnhancedSkeleton } from 'src/components/Enhanced';
import ExpandMore from '@material-ui/icons/ExpandMore';
import BusinessIcon from '@material-ui/icons/Business';
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader';
import CompanyDropdown from 'src/components/CompanyDropdown';

const Layout = ({ children }) => {
  const current = new Date();
  const { smBelow } = useBreakpoints();
  const classes = useStyles();
  const {
    cartList,
    confirmationDialog,
    company,
    companyList,
    setCompany,
    companyLoading,
  } = useContext(ConfigContext);
  const [openCompany, setOpenCompany] = useState(false);
  const [othersCompanyList, setOthersCompanyList] = useState([]);
  const [collaborationCompanyList, setCollaborationCompanyList] = useState([]);

  useEffect(() => {
    const othersList = companyList.filter(
      (c) => c.company_id !== company.company_id,
    );
    setOthersCompanyList(othersList);
  }, [company]);

  function truncate(str, num) {
    if (str) return str.length > num ? str.substring(0, num) + '...' : str;
  }

  const handleCloseCompany = () => {
    setOpenCompany(false);
  };

  return (
    // <LoadingOverlay
    //   active={companyLoading}
    //   spinner={<ClipLoader color="white" />}
    //   styles={{
    //     wrapper: {
    //       overflow: companyLoading ? 'hidden' : 'initial',
    //     },
    //   }}
    // >
    <>
      {companyLoading ? (
        <div
          style={{
            width: '100%',
          }}
        >
          <EnhancedSkeleton
            layout={[
              {
                loaders: [{ variant: 'rect', height: 100, width: '100%' }],
                repeat: 5,
                mt: 4.25,
              },
            ]}
          />
        </div>
      ) : (
        // <AuthLayout
        // >
        <Box
          px={4}
          py={2}
          display="flex"
          flexDirection="column"
          style={{ minHeight: '85%' }}
        >
          <Paper
            className={classes.dashboardBox}
            style={{
              flexDirection: smBelow ? 'column' : 'row',
            }}
          >
            <Typography variant="h1">
              Secretarial & Compliance Dashboard
            </Typography>
            <div style={{ flexGrow: 1 }} />
            <CompanyDropdown />
          </Paper>
          <Box p={1} />

          {children}
        </Box>
        // </AuthLayout>
      )}
    </>
    // </LoadingOverlay>
  );
};

export default Layout;
