import React, { useState, useContext, useEffect } from 'react';
import { Typography, Box, Button, Paper } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MzButton from 'src/components/MzButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import { useProvideRequest } from 'src/hooks/useRequests';
import useSnackbar from 'src/hooks/useSnackbar';
import { ConfigContext } from 'src/contexts';
import fourSteps from 'src/assets/requests/4steps.png';
import { useProvideProduct } from 'src/hooks/useProduct';
import { useProvideData } from 'src/hooks/useData';
import { useProvideCompany } from 'src/hooks/useCompany';
import AlertBox from 'src/components/AlertBox';
import companySvc from 'src/services/company';
import FormDropdown from 'src/components/FormDropdown/FormDropdown.js';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import FormCheckbox from 'src/components/FormCheckbox/FormCheckbox.js';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';
import useBreakpoints from 'src/hooks/useBreakpoints';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { EnhancedIcon } from 'src/components/Enhanced';
import { useTheme } from '@material-ui/core/styles';
import { FormDatePicker } from 'src/components/Form';

const ChangeBusinessActivities = ({ toggleShowMenu }) => {
  // const classes = useStyles();
  const { smBelow } = useBreakpoints();
  const theme = useTheme();

  const history = useHistory();
  const [step, setStep] = useState(1);

  const {
    CreateBusinessActivityApplication,
    requestError,
    requestSubmitting,
  } = useProvideRequest();
  const {
    GetBusinessActivity,
    businessActivity,
    companyError,
  } = useProvideCompany();

  const { ListSSIC, ssic, dataError } = useProvideData();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const {
    addCart,
    user,
    company,
    setConfirmationDialog,
    cartSubmitting,
  } = useContext(ConfigContext);
  const { ReadProduct, product, productError } = useProvideProduct();

  // https://dev.to/gabrielterriaga/how-to-validate-two-fields-that-depend-on-each-other-with-yup-1ccg
  const validationSchema = Yup.object().shape(
    {
      delete_secondary_activity_flag: Yup.boolean().when(
        ['secondary_activity_id', 'primary_activity_id'],
        {
          is: (secondary_activity_id, primary_activity_id) =>
            !primary_activity_id && !secondary_activity_id,
          then: Yup.boolean().oneOf([true], 'Select Activity to update'),
        },
      ),
      primary_activity_id: Yup.string().when(
        ['secondary_activity_id', 'delete_secondary_activity_flag'],
        {
          is: (secondary_activity_id, delete_secondary_activity_flag) =>
            delete_secondary_activity_flag === false && !secondary_activity_id,
          then: Yup.string().required('Select Activity to update'),
        },
      ),
      secondary_activity_id: Yup.string().when(
        ['primary_activity_id', 'delete_secondary_activity_flag'],
        {
          is: (primary_activity_id, delete_secondary_activity_flag) =>
            delete_secondary_activity_flag === false && !primary_activity_id,
          then: Yup.string().required('Select Activity to update'),
        },
      ),
    },
    [
      ['primary_activity_id', 'secondary_activity_id'],
      ['secondary_activity_id', 'delete_secondary_activity_flag'],
      ['primary_activity_id', 'delete_secondary_activity_flag'],
    ],
  );

  const getStarted = {
    one: {
      content: [
        'Change your company’s primary and/or secondary business activity',
      ],
    },
    two: {
      content: [
        'Your company’s new Primary SICC Code and Description',
        'Your company’s new Secondary SICC Code and Description',
      ],
    },
    three: {
      content: [
        'Create Request',
        'Meyzer360 Review',
        'Sign Resolution',
        'ACRA Filing',
      ],
    },
    four: {
      content: ['SGD' + product.price],
    },
    five: {
      content: [
        'Fill up form: < 2 minutes',
        'Average processing time: 3-5 days',
      ],
    },
  };

  useEffect(() => {
    // get latest product
    ReadProduct(23);
    ListSSIC();
  }, []);

  useEffect(() => {
    if (company.company_id) {
      GetBusinessActivity(company.company_id);
    }
  }, [company.company_id]);

  useEffect(() => {
    if (companyError) {
      showErrorSnackbar(
        `Error loading Company's business activity. ${companyError}`,
      );
    }
  }, [companyError]);

  useEffect(() => {
    if (dataError) {
      showErrorSnackbar(
        `Error loading business activity options. ${dataError}`,
      );
    }
  }, [dataError]);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.company_id;
    values.company_application_status_id = 11;
    values.created_by = user.user_id;
    console.log('change_effective_date', values.change_effective_date);
    let res = await CreateBusinessActivityApplication(values);

    if (res.status === 'success') {
      res = await addCart({
        price: product.price,
        product_id: product.id,
        quantity: 1,
        user_id: user.user_id,
        product_detail_id:
          res.body.applications[0].business_activity_application_id,
        company_id: company.company_id,
        company_name: company.company_name,
        company_registration_no: company.company_registration_no,
        company_application_id: res.body.applications[0].company_application_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setConfirmationDialog({
          isVisible: true,
          title: 'Item added to cart',
          type: 'success',
          cancelButtonLabel: 'Back to Request',
          confirmButtonLabel: 'View your cart',
          confirmButtonAction: () => {
            history.push(ROUTES.PAYMENTCART);
            setConfirmationDialog({
              isVisible: false,
            });
          },
          cancelButtonAction: () => {
            history.push(ROUTES.CORPSEC_REQ);
            setConfirmationDialog({
              isVisible: false,
            });
          },
        });
      }
    }
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          primary_activity_id: '',
          secondary_activity_id: '',
          delete_secondary_activity_flag: false,
          change_effective_date: new Date(),
        }}
        enableReinitialize
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Typography variant="h5">
              <EnhancedIcon
                icon={FiberManualRecordIcon}
                iconStyle={{
                  fill: theme.palette.primary.main,
                  fontSize: 15,
                  paddingRight: 5,
                }}
              />
              Primary Activity
            </Typography>
            <Paper
              elevation={0}
              variant="outlined"
              style={{
                borderRadius: smBelow ? '0px' : '12px',
              }}
            >
              <Box
                p={4}
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
                flexDirection="column"
              >
                <Box
                  width="100%"
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="row"
                  style={{
                    gap: '50px',
                  }}
                >
                  <Box
                    display="flex"
                    flex={1}
                    alignItems="flex-start"
                    flexDirection="column"
                  >
                    <Typography variant="caption" color="textSecondary">
                      Current Primary Activity
                    </Typography>
                    <Typography variant="subtitle1">
                      {businessActivity?.primary_ssic_name}
                    </Typography>
                  </Box>
                  <Box
                    flex={1}
                    display="flex"
                    alignItems="flex-start"
                    flexDirection="column"
                  >
                    <FormDropdown
                      autoCompleteStyle={{ width: '100%' }}
                      name="primary_activity_id"
                      onChange={(event, newValue) => {
                        props.setFieldValue(
                          'primary_activity_id',
                          newValue?.value,
                        );
                      }}
                      labelProps={{
                        variant: 'caption',
                        color: 'textSecondary',
                      }}
                      optionList={ssic}
                      labelText="New Primary Activity *"
                      placeholder="Select One"
                      fullWidth
                      shrink
                      variant="outlined"
                    />
                  </Box>
                </Box>

                <Box
                  width="100%"
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="row"
                  style={{
                    gap: '50px',
                  }}
                >
                  <Box
                    display="flex"
                    flex={1}
                    alignItems="flex-start"
                    flexDirection="column"
                  >
                    <Typography variant="caption" color="textSecondary">
                      Description
                    </Typography>
                    <Typography variant="subtitle1">
                      {businessActivity?.business_description}
                    </Typography>
                  </Box>
                  <Box
                    flex={1}
                    display="flex"
                    alignItems="flex-start"
                    flexDirection="column"
                  >
                    <FormTextField
                      name="primary_activity_description"
                      labelText="Description"
                      placeholder="Description"
                      fullWidth
                      labelProps={{
                        variant: 'caption',
                        color: 'textSecondary',
                      }}
                      shrink
                      multiline
                      rows={3}
                      variant="outlined"
                    />
                  </Box>
                </Box>
              </Box>
            </Paper>

            <Box py={3} />

            <Typography variant="h5">
              <EnhancedIcon
                icon={FiberManualRecordIcon}
                iconStyle={{
                  fill: theme.palette.primary.main,
                  fontSize: 15,
                  paddingRight: 5,
                }}
              />
              Secondary Activity
            </Typography>
            <Paper
              elevation={0}
              variant="outlined"
              style={{
                borderRadius: smBelow ? '0px' : '12px',
              }}
            >
              <Box
                p={4}
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
                flexDirection="column"
              >
                <Box
                  width="100%"
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="row"
                  style={{
                    gap: '50px',
                  }}
                >
                  <Box
                    display="flex"
                    flex={1}
                    alignItems="flex-start"
                    flexDirection="column"
                  >
                    <Typography variant="caption" color="textSecondary">
                      Current Secondary Activity
                    </Typography>
                    <Typography variant="subtitle1">
                      {businessActivity?.secondary_ssic_name}
                    </Typography>
                  </Box>
                  <Box
                    flex={1}
                    display="flex"
                    alignItems="flex-start"
                    flexDirection="column"
                  >
                    <FormDropdown
                      autoCompleteStyle={{ width: '100%' }}
                      name="secondary_activity_id"
                      onChange={(event, newValue) => {
                        props.setFieldValue(
                          'secondary_activity_id',
                          newValue?.value,
                        );
                      }}
                      labelProps={{
                        variant: 'caption',
                        color: 'textSecondary',
                      }}
                      optionList={ssic}
                      labelText="New Secondary Activity *"
                      placeholder="Select One"
                      fullWidth
                      shrink
                      variant="outlined"
                    />
                  </Box>
                </Box>

                <Box
                  width="100%"
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="row"
                  style={{
                    gap: '50px',
                  }}
                >
                  <Box
                    display="flex"
                    flex={1}
                    alignItems="flex-start"
                    flexDirection="column"
                  >
                    <Typography variant="caption" color="textSecondary">
                      Description
                    </Typography>
                    <Typography variant="subtitle1">
                      {businessActivity?.secondary_business_description}
                    </Typography>
                  </Box>
                  <Box
                    flex={1}
                    display="flex"
                    alignItems="flex-start"
                    flexDirection="column"
                  >
                    <FormTextField
                      name="secondary_activity_description"
                      labelText="Description"
                      placeholder="Description"
                      fullWidth
                      labelProps={{
                        variant: 'caption',
                        color: 'textSecondary',
                      }}
                      shrink
                      multiline
                      rows={3}
                      variant="outlined"
                    />
                    <FormCheckbox
                      name="delete_secondary_activity_flag"
                      label={'Delete Secondary Business Activity'}
                    />
                  </Box>
                </Box>
              </Box>
            </Paper>

            <Box py={3} />

            <Paper
              elevation={0}
              variant="outlined"
              style={{
                borderRadius: smBelow ? '0px' : '12px',
              }}
            >
              <Box
                p={4}
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
                flexDirection="column"
              >
                <Box
                  width="100%"
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="row"
                  style={{
                    gap: '50px',
                  }}
                >
                  <Box
                    display="flex"
                    flex={1}
                    alignItems="flex-start"
                    flexDirection="column"
                  >
                    <Typography variant="subtitle1">
                      Effective Date of Change
                    </Typography>
                  </Box>

                  <Box
                    flex={1}
                    display="flex"
                    alignItems="flex-start"
                    flexDirection="column"
                  >
                    <FormDatePicker
                      labelProps={{
                        variant: 'caption',
                        color: 'textSecondary',
                      }}
                      containerWidth="100%"
                      name="change_effective_date"
                      labelText="Date of Change"
                      format="dd/MM/yyyy"
                      variant="outlined"
                      inputVariant="outlined"
                    />
                  </Box>
                </Box>
              </Box>
            </Paper>

            <Box display="flex" pt={3} justifyContent="flex-start">
              <MzButton
                fullWidth={smBelow ? true : false}
                type="submit"
                title={'SUBMIT'}
                onClick={() => {}}
                loading={requestSubmitting || cartSubmitting}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={step}
        setStep={setStep}
        getStarted={getStarted}
        header={
          <>
            <Typography variant="h2">
              {step === 1 && 'Change of Business Activity'}
              {step === 2 &&
                'Fill in Information for Change Company Business Activity'}
            </Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Requests', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Change Company Particulars',
                  route: ROUTES.CORPSEC_REQ_CHANGE_COMPANY_PARTICULARS,
                },
                {
                  path: 'Change Company Business Activity',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        {step === 2 && (
          <>
            <Box px={4} py={4} style={{ borderTop: '0.01rem solid #E4E4E4' }}>
              {renderForm()}
            </Box>
          </>
        )}
        <Box py={5} />
      </GetStartedLayout>
    </>
  );
};

export default ChangeBusinessActivities;
