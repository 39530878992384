import axios from 'axios';
import { Config } from 'src/config';

export const S3UploadAndInsertDocuments = async (
  file,
  docType,
  dataType,
  dataId,
) => {
  if (file !== undefined) {
    const header = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
    };
    // Split the filename to get the name and type
    let fileParts = file.name.split('.');
    let fileType = fileParts[fileParts.length - 1];
    let fileName = [...fileParts].slice(0, -1).join('.');
    // let fileName = fileParts[0];

    let mimeType = 'application';
    if (fileType.indexOf('html') >= 0) {
      mimeType = 'text';
    }

    let contentType = file?.type || `${mimeType}/${fileType}`;

    let queryParams = new URLSearchParams();
    queryParams.set('name', fileName);
    queryParams.set('content-type', contentType);

    const respIdSigned = await axios.get(
      `${Config.apiUrl}/signedurl/?${queryParams.toString()}`,
      { headers: header },
    );
    // const { current } = refInputIdUpload;
    console.log('respIdSigned', respIdSigned);

    // console.log("refInputIdUpload", refInputIdUpload);
    var options = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': contentType,
      },
    };
    const respUploadFile = await axios.put(
      respIdSigned.data.uploadInfo.uploadURL.toString(),
      file,
      options,
      // {
      //   headers: header,
      // }
    );

    console.log('respUploadFile', respUploadFile.status);

    if (respUploadFile.status === 200) {
      const docData = {
        module_id: 1,
        document_container_id: 1,
        document_type_id: docType, //76 NRIC, 109 Proof of Residential, 110 CDD documents
        document_table_type_id: dataType, //table: Users, 5 UserAddresses, 4 CDD
        table_primary_id: dataId, //user_id, user_address_id, customer_due_diligence_id
        document_url: respIdSigned.data.uploadInfo.fileURL,
        file_type: fileType,
        file_name: fileName,
      };
      const respDocument = await axios.post(
        `${Config.apiUrl}/documents/`,
        docData,
        { headers: header },
      );

      console.log('respDocument', respDocument);
      return respDocument.data.document[0];
    }
  }
};

export const S3Upload = async (file) => {
  if (file !== undefined) {
    const header = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
    };
    // Split the filename to get the name and type
    let fileParts = file.name.split('.');
    let fileType = fileParts[fileParts.length - 1];
    let fileName = [...fileParts].slice(0, -1).join('.');
    // let fileName = fileParts[0];
    // let fileType = fileParts[1];

    let mimeType = 'application';
    if (fileType.indexOf('html') >= 0) {
      mimeType = 'text';
    }

    let contentType = file?.type || `${mimeType}/${fileType}`;
    let queryParams = new URLSearchParams();
    queryParams.set('name', fileName);
    queryParams.set('content-type', contentType);

    const respIdSigned = await axios.get(
      `${Config.apiUrl}/signedurl/?${queryParams.toString()}`,
      { headers: header },
    );

    var options = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': contentType,
      },
    };
    const respUploadFile = await axios.put(
      respIdSigned.data.uploadInfo.uploadURL.toString(),
      file,
      options,
      // {
      //   headers: header,
      // }
    );
    console.log('respUploadFile', respUploadFile);
    return respUploadFile;
  }
};
