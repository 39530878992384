import React, { useContext } from 'react';
import { Typography, Box, Container, Button } from '@material-ui/core';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { makeStyles } from '@material-ui/core/styles';
import img from 'src/assets/errorpage/comingsoon.png';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import AuthLayout from 'src/components/AuthLayout';
import Greeting from 'src/components/Greeting/Greeting';
import { ConfigContext } from 'src/contexts';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  fontWeight: {
    fontWeight: 600,
  },
  containerRoot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const ComingSoonPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(ConfigContext);

  return (
    // <AuthLayout>
    <Box padding={4}>
      <Container
        maxWidth="xl"
        classes={{
          root: classes.containerRoot,
        }}
      >
        <Greeting
          title={`${
            user.first_name
              ? user.first_name + ' ' + user.last_name
              : user.email
          }`}
        />

        <Box p={2} className={classes.root}>
          <Typography
            variant="h1"
            className={classes.fontWeight}
            style={{ fontSize: 72 }}
          >
            Coming Soon
          </Typography>
          <img src={img} height="60%" width="60%" />
          <Box p={2} />

          <Typography variant="h2" className={classes.fontWeight}>
            We are under scheduled maintenance.
          </Typography>
          <Box p={3} />

          <Button
            variant="contained"
            size="large"
            color="primary"
            style={{
              textTransform: 'initial',
            }}
            onClick={() => history.push(ROUTES.DASHBOARD)}
          >
            Go to dashboard
          </Button>
        </Box>
      </Container>
    </Box>
    // </AuthLayout>
  );
};

export default ComingSoonPage;
