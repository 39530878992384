import React, { useState, useEffect, useContext } from 'react';
import {
  Paper,
  Box,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  Backdrop,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import ProductCard from './components/ProductCard';
import { useProvideProduct } from 'src/hooks/useProduct';
import { SelectField } from 'src/components/CustomFormFields/CustomSelectField';
import { ConfigContext } from 'src/contexts';

const subscriptionTypeOptions = [
  // {
  //   label: 'One Off',
  //   value: 1,
  // },
  // {
  //   label: 'Annual Recurring',
  //   value: 2,
  // },
];

const productCategoryId = 2;

const OfficeSupplies = (props) => {
  //   const [products, setProducts] = useState([]);
  // const [moreProducts, setMoreProducts] = useState(true);
  const [selectedItemCategory, setSelectedItemCategory] = useState('');
  const {
    cartList,
    cart,
    removeCart,
    addCart,
    user,
    company,
    cartSubmitting,
    setConfirmationDialog,
  } = useContext(ConfigContext);

  const {
    ReadProduct,
    ListProducts,
    ListProductItemCategories,
    products: defaultProducts = [],
    product,
    productItemCategories,
    productError,
    productLoading = false,
  } = useProvideProduct();

  let products = [];
  if (defaultProducts?.length > 0) {
    defaultProducts.forEach((anItem) => {
      let passed = true;
      if (anItem.product_category_id != productCategoryId) {
        passed = false;
      }
      if (selectedItemCategory != undefined && selectedItemCategory != null) {
        if (anItem?.item_category_id != selectedItemCategory) {
          passed = false;
        }
      }
      if (passed) {
        products.push({
          ...anItem,
          href: `/office-supplies/${anItem.id}`,
        });
      }
    });
  }

  useEffect(() => {
    (async () => {
      try {
        await ListProducts();
        await ListProductItemCategories();
        // .then((result) => {
        //   console.log('ListProductItemCategories', result);
        // })
        // .catch((error) => {
        //   console.log('ListProductItemCategories error', error);
        // });
      } catch (error) {}
    })();
  }, []);

  const handleAddToCart = async (value) => {
    await addCart({
      price: value.price,
      product_id: value.id,
      quantity: 1,
      user_id: user.user_id,
      // product_detail_id: res.body.applications.company_name_application_id,
      company_id: company.company_id,
      company_name: company.company_name,
      company_registration_no: company.company_registration_no,
      // company_application_id: res.body.applications.company_application_id,
    });
  };

  let itemCategoryOptions = [
    {
      label: 'All',
      value: null,
    },
  ];
  if (productItemCategories?.length > 0) {
    productItemCategories?.forEach((anItem) => {
      if (anItem?.product_category_id == productCategoryId) {
        itemCategoryOptions.push({
          label: anItem.name,
          value: anItem.id,
        });
      }
    });
  }
  return (
    <Paper component={Box} p={2}>
      {productLoading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight="300px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid container item xs={12}>
            <Grid item xs={12} sm={4}>
              <SelectField
                options={itemCategoryOptions}
                value={selectedItemCategory}
                onChange={(e, value) => {
                  setSelectedItemCategory(value?.value);
                }}
                placeholder="Category"
              />
            </Grid>
            {/* <FormControl variant="outlined">
                  <InputLabel htmlFor="filled-age-native-simple">
                    Subscription Type
                  </InputLabel>
                  <Select
                    native
                    //   value={state.age}
                    //   onChange={handleChange}
                    label="Subscription Type"
                    inputProps={{
                      name: 'subscription_type_id',
                      id: 'filled-age-native-simple',
                    }}
                  >
                    <option aria-label="None" value="" />
                    {subscriptionTypeOptions.map((anItem, index) => {
                      return (
                        <option key={index} value={anItem.value}>
                          {anItem.label}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl> */}
          </Grid>
          {products?.length > 0 ? (
            <>
              {products.map((anItem, index) => {
                return (
                  <Grid item key={index} xs={6} sm={4} md={3}>
                    <ProductCard
                      data={{
                        ...anItem,
                        imageSrc:
                          anItem?.product_documents?.[0]?.document
                            ?.document_url,
                      }}
                      onClick={() => {
                        handleAddToCart(anItem);
                      }}
                    />
                  </Grid>
                );
              })}
              {/* {moreProducts && ( */}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <Button color="primary">
                    <Box>
                      <Box>Load More</Box>
                      <Box>
                        <KeyboardArrowDownIcon />
                      </Box>
                    </Box>
                  </Button>
                </Box>
              </Grid>
              {/* )} */}
            </>
          ) : (
            'No Product(s) found'
          )}
        </Grid>
      )}
      <Backdrop open={cartSubmitting} style={{ zIndex: 5 }}>
        <CircularProgress />
      </Backdrop>
    </Paper>
  );
};
export default OfficeSupplies;
