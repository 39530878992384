import React, { useContext, useEffect, useState } from 'react';
import { Typography, Box, Badge, Paper, Divider } from '@material-ui/core';
import MzButton from 'src/components/MzButton';
import { useTheme, withStyles } from '@material-ui/core/styles';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { ConfigContext } from 'src/contexts';

const StyledBadge = withStyles((theme) => ({
  badge: {
    left: 5,
    bottom: 12,
    // border: `2px solid ${theme.palette.background.paper}`,
    // padding: '0 4px',
    // paddingLeft: '30px',
  },
}))(Badge);

const GetStartedManual = ({
  one = {
    content: [],
  },
  two = {
    content: [],
  },
  three = {
    content: [],
  },
  four = {
    content: [],
  },
  five = {
    content: [],
  },
  onClick,
  noneDisable,
}) => {
  const { company } = useContext(ConfigContext);
  const [disableRequest, setDisableRequest] = useState(false);

  const theme = useTheme();
  const { smBelow } = useBreakpoints();
  const requestHiddenCompany = [0, 1];
  useEffect(() => {
    if (company.company_id) {
      const companyExist = requestHiddenCompany.find(
        (element) => element === company.company_id,
      );
      setDisableRequest(!!companyExist);
    }
  }, [company.company_id]);

  const generateSteps = () => {
    return three.content.map((content, index) => {
      return {
        component: (
          <StyledBadge
            color="primary"
            badgeContent={index + 1}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            key={index}
          >
            <Box pl={2} pr={1} />
            <Typography variant="body1">{content}</Typography>
          </StyledBadge>
        ),
      };
    });
  };

  const renderContent = () => (
    <>
      {smBelow && <Divider flexItem style={{ height: '1px' }} />}
      <Box p={3}>
        <Typography variant="h3">What does this request do?</Typography>
        <ul
          style={{
            color: theme.palette.primary.main,
            paddingInlineStart: '18px',
          }}
        >
          {one.content.map((content) => (
            <li key={content}>
              <Typography align="justify" variant="body1" color="textSecondary">
                {content}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
      <Divider flexItem style={{ height: '1px' }} />
      <Box p={3}>
        <Typography variant="h3">
          What information/document(s) do I need?
        </Typography>
        <ul
          style={{
            color: theme.palette.primary.main,
            paddingInlineStart: '18px',
          }}
        >
          {two.content.map((content) => (
            <li key={content}>
              <Typography align="justify" variant="body1" color="textSecondary">
                {content}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
      <Divider flexItem style={{ height: '1px' }} />

      <Box p={3}>
        <Typography variant="h3">What steps do I take?</Typography>
        <Box pl={1} pt={3}>
          <BreadcrumbsNav pathList={generateSteps()} arrowSeparator />
        </Box>
      </Box>
      <Divider flexItem style={{ height: '1px' }} />
      <Box p={3}>
        <Typography variant="h3">How much will it cost?</Typography>
        <ul
          style={{
            color: theme.palette.primary.main,
            paddingInlineStart: '18px',
          }}
        >
          {four.content.map((content) => (
            <li key={content}>
              <Typography align="justify" variant="body1" color="textSecondary">
                {content === 'SGD0' ? 'Free' : content}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
      <Divider flexItem style={{ height: '1px' }} />

      {/* {!(disableRequest && !noneDisable) && (
        <>
          <Box p={3}>
            <Typography variant="h3">How much will it cost?</Typography>
            <ul
              style={{
                color: theme.palette.primary.main,
                paddingInlineStart: '18px',
              }}
            >
              {four.content.map((content) => (
                <li key={content}>
                  <Typography
                    align="justify"
                    variant="body1"
                    color="textSecondary"
                  >
                    {content}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
          <Divider flexItem style={{ height: '1px' }} />
        </>
      )} */}
      <Box p={3}>
        <Typography variant="h3">How long will it take?</Typography>
        <ul
          style={{
            color: theme.palette.primary.main,
            paddingInlineStart: '18px',
          }}
        >
          {five.content.map((content) => (
            <li key={content}>
              <Typography align="justify" variant="body1" color="textSecondary">
                {content}
              </Typography>
            </li>
          ))}
        </ul>
        <Box p={3} />
        <MzButton
          fullWidth={smBelow ? true : false}
          title={'Get Started'}
          disabled={disableRequest && !noneDisable}
          onClick={() => {
            if (onClick) onClick();
          }}
        />
      </Box>
    </>
  );
  return smBelow ? (
    renderContent()
  ) : (
    <Paper
      elevation={0}
      variant="outlined"
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        borderRadius: smBelow ? '0px' : '12px',
      }}
    >
      {renderContent()}
    </Paper>
  );
};

export default GetStartedManual;
