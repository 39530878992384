import React from 'react';
import {
  Typography,
  Box,
  Container,
  Button,
  Paper,
  Avatar,
} from '@material-ui/core';
import AuthLayout from 'src/components/AuthLayout';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import img from 'src/assets/errorpage/loginfailed.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fontWeight: {
    fontWeight: 600,
  },
  containerRoot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const LoginFailed = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  return (
    // <AuthLayout>
    <Box padding={4}>
      <Container
        maxWidth="xl"
        classes={{
          root: classes.containerRoot,
        }}
      >
        <Paper elevation={0} variant="outlined" style={{ height: '85vh' }}>
          <Box className={classes.root} textAlign="center" pl={2} pr={2}>
            <Box p={10} />

            <Box
              flexGrow="1"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Avatar
                style={{
                  width: '130px',
                  height: '130px',
                  padding: theme.spacing(3),
                  backgroundColor: '#F0F3F6',
                }}
              >
                <img src={img} height="80%" width="80%" />
              </Avatar>
            </Box>

            <Box p={2} />
            <Typography variant="h2" className={classes.fontWeight}>
              Login Failed
            </Typography>
            <Box p={1} />
            <Typography variant="body1">
              Thank you for your interest in Digital Banking.
            </Typography>
            <Typography variant="body1">
              Please click the button below to onboard you to our Digital
              Banking via onboarding processing.
            </Typography>
            <Box p={3} />

            <Button
              variant="contained"
              size="large"
              color="primary"
              style={{
                textTransform: 'initial',
              }}
              onClick={() => history.push(ROUTES.CONTACT_SUPPORT)}
            >
              Onboard to Digital Banking
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
    // </AuthLayout>
  );
};

export default LoginFailed;
