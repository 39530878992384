import {
  Paper,
  Box,
  Divider,
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Badge,
  Backdrop,
  CircularProgress,
  Button,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState, useContext } from 'react';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { ConfigContext } from 'src/contexts';
import { useParams, useHistory } from 'react-router-dom';
import { useProvideRequest } from 'src/hooks/useRequests';
import DetailList from 'src/components/DetailList';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import ROUTES from 'src/constants/routes';
import companySvc from 'src/services/company';
import documentSvc from 'src/services/document';
import FileViewerButton from 'src/components/FileViewerButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const fieldsDisplay = [
  {
    label: 'Business Structure',
    fields: {
      account_type_id: 'Account Type ID',
      company_name: 'Business Legal Name',
      company_country_name: 'Business Registered Country',
      entity_category: 'Entity Category',
      entity_type: 'Entity Type',
      bus_registration_no: 'Business Reg. number (UEN)',
      industry_nature: 'Nature of Industry',
      sub_industry_nature: 'Nature of Sub-Industry',
      bus_description: 'Product/Service Description',
      company_website: 'Company website',
      number_employees: 'Number of Employee',
      annual_turnover: 'Annual Turnover',
    },
  },
  {
    label: 'Business Detail',
    fields: {
      floor_unit_no: 'Floor/Unit No',
      address_line_1: 'Address Line 1',
      address_line_2: 'Address Line 2',
      state_name: 'City / State',
      postal_code: 'Postcode',
      country_name: 'Country',
    },
  },
];

const useStyles = makeStyles((theme) => ({
  dashboardBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: '10px',
    padding: 15,
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: '#FFFFFF',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
    marginBottom: '15px',
  },
  modalPaper: {
    boxShadow: '#191919',
    display: 'flex',
    flexDirection: 'column',
    // width: '60%',
    borderRadius: 20,
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    left: 10,
    backgroundColor: '#3DD598',
    color: 'white',
  },
}))(Badge);

const CompanyAccountDetail = () => {
  const history = useHistory();
  const classes = useStyles();
  const params = useParams();
  const { smBelow, mdBelow } = useBreakpoints();
  const { company, user } = useContext(ConfigContext);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const { requestDetails, ListRequestDetails } = useProvideRequest();
  const [data, setData] = useState();
  const [onboardProfileDocuments, setOnboardProfileDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (params.id) {
      (async () => {
        setLoading(true);
        try {
          let onboardProfileRes = await companySvc.getOnBoardProfileById(
            params.id,
          );
          if (onboardProfileRes?.data?.is_success) {
            let data = onboardProfileRes?.data?.onboard_profile;
            setData(data);
            if (data?.onboard_profile_id) {
              let documentsRes = await documentSvc.getDocumentsSTS(
                135,
                11,
                data?.onboard_profile_id,
                // user?.username,
                // company?.company_id,
              );
              // if (documentsRes?.data?.document?.length > 0) {
              setOnboardProfileDocuments(
                documentsRes?.data?.document?.length > 0
                  ? documentsRes?.data?.document
                  : [],
              );
              // }
            }
          } else {
            setData();
            setOnboardProfileDocuments([]);
          }
        } catch (error) {
          setData();
          setOnboardProfileDocuments([]);
        }
        setLoading(false);
      })();
    }
  }, [params?.id]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  // const DetailsSelection = ['Overview', 'Files', 'Invoice', 'Activity log'];
  const DetailsSelection = ['Overview'];
  const RequestOverview = ({ label, description, bold }) => {
    return (
      <ListItem>
        <ListItemText
          primaryTypographyProps={{
            style: {
              whiteSpace: 'normal',
              fontWeight: bold ? 'bold' : null,
            },
            variant: 'body1',
          }}
          primary={label}
        />

        <ListItemSecondaryAction>
          <Typography
            style={{ fontWeight: bold ? 'bold' : null }}
            variant="body1"
          >
            {!!description && description !== 'Invalid date'
              ? description
              : '-'}
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const BackButton = () => {
    return (
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => {
          history.push(history.goBack());
        }}
      >
        Back
      </Button>
    );
  };

  return (
    <Container
      maxWidth="xl"
      classes={{
        root: classes.containerRoot,
      }}
    >
      <Paper>
        {smBelow && <BackButton />}
        <Box
          className={classes.dashboardBox}
          // flexDirection={smBelow ? 'column' : 'row'}
        >
          <Box>
            {!smBelow && <BackButton />}
            <Typography variant="h1">Company Account Details</Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Home', route: ROUTES.DASHBOARD },
                {
                  path: 'Company Accounts',
                  route: ROUTES.CORPSEC_COMPANY_ACCOUNT_SETTINGS,
                },
                {
                  path: 'Company Account Details',
                  color: 'primary',
                },
              ]}
            />
          </Box>
        </Box>

        <Divider flexItem style={{ height: '1px' }} />

        <Box display="flex">
          <Box p={2} style={{ width: '100%' }}>
            <Paper
              elevation={0}
              variant={smBelow ? 'elevation' : 'outlined'}
              style={{
                borderRadius: smBelow ? '0px' : '4px',
                // flex: '1 1 0%',
              }}
            >
              <List subheader={<li />} style={{ paddingBottom: 0 }}>
                <ListItem
                  style={{
                    backgroundColor: '#F5F5F5',
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        whiteSpace: 'normal',
                        fontWeight: 'bold',
                      },
                      variant: 'body2',
                    }}
                    primary={'Company Account ID: #' + params.id}
                  />
                </ListItem>
                <Divider />
                <DetailList
                  label="Created At"
                  description={
                    data?.created_date
                      ? moment(data?.created_date)
                          .utc('+0800')
                          .format('YYYY-MM-DD HH:mm')
                      : '-'
                  }
                />
                <Divider />
                {/* <RequestOverview
                    label="Total Amount"
                    description="S$100.00"
                    bold
                  />
                  <Divider /> */}
                <DetailList
                  label="Status"
                  description={data?.status_name || '-'}
                />
                <Divider />
                {fieldsDisplay.map((aFieldGroup, index) => {
                  let groupFields = aFieldGroup.fields;
                  let groupItemKeys = Object.keys(groupFields);
                  return (
                    <React.Fragment key={index}>
                      {index != 0 ? <Divider /> : null}
                      <DetailList
                        label={<b>{aFieldGroup.label}</b>}
                        description={' '}
                      />
                      {groupItemKeys.map((aKey, index2) => {
                        return (
                          <React.Fragment key={index2}>
                            {/* {index2 != 0 ? <Divider /> : null} */}
                            <DetailList
                              label={groupFields[aKey]}
                              description={data?.[aKey] || '-'}
                            />
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
                <Divider />

                <DetailList label={<b>Documents</b>} description=" " />
                <DetailList
                  label={
                    onboardProfileDocuments &&
                    onboardProfileDocuments.length > 0 ? (
                      <Box display="flex" flexDirection="column">
                        {onboardProfileDocuments.map((aDoc, index) => {
                          return (
                            <Box key={index} mb={2}>
                              <FileViewerButton
                                srcType="url"
                                src={aDoc?.signed_document_url}
                                size="small"
                              >
                                View{' '}
                                {aDoc?.file_name || aDoc?.document_type_name}{' '}
                                (ID:
                                {aDoc?.document_id})
                              </FileViewerButton>
                            </Box>
                          );
                        })}
                      </Box>
                    ) : (
                      'No Document found'
                    )
                  }
                  description=" "
                />
              </List>
            </Paper>

            {/* <FileViewerButton srcType="url" src={signed_document_url} size="small">
              View
            </FileViewerButton> */}
          </Box>
        </Box>
      </Paper>
      <Backdrop open={loading} style={{ zIndex: 5 }}>
        <CircularProgress />
      </Backdrop>
    </Container>
  );
};

export default CompanyAccountDetail;
