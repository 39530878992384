import {
  useContext,
  useState,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import { APIRequest } from 'src/utils/api';
import documentSvc from 'src/services/document';
// import { Config } from 'src/config';
import ENDPOINTS from 'src/constants/endpoints';

const productContext = createContext({
  products: [],
  product: {},
  productItemCategories: [],
  productError: '',
  productLoading: false,
  productSubmitting: false,
  ListProducts: () => Promise.resolve({}),
  ReadProduct: () => Promise.resolve({}),
  ListProductItemCategories: () => Promise.resolve({}),
});

export function useProvideProduct() {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [productItemCategories, setProductItemCategories] = useState([]);

  const [productError, setProductError] = useState();
  const [productLoading, setProductLoading] = useState(false);
  const [productSubmitting, setProductSubmitting] = useState(false);

  const mergeProductAndImage = (products = [], documents = []) => {
    let result = products.map((aProduct) => {
      if (aProduct?.product_documents?.length > 0) {
        let newProductDocuments = aProduct?.product_documents.map(
          (aProductDocument) => {
            let foundDoc = documents.find((aDoc) => {
              return aDoc.document_id == aProductDocument.document_id;
            });
            if (foundDoc) {
              return {
                ...aProductDocument,
                document: foundDoc,
              };
            }
            return aProductDocument;
          },
        );
        return {
          ...aProduct,
          product_documents: newProductDocuments,
        };
      }
      return aProduct;
    });
    return result;
  };

  const ListProductDocuments = async (products = []) => {
    let ids = [];
    products.forEach((aProduct) => {
      if (aProduct?.product_documents?.length > 0) {
        aProduct?.product_documents.forEach((anItem) => {
          ids.push(anItem?.document_id);
        });
      }
    });
    let res;
    if (ids?.length > 0) {
      setProductLoading(true);
      try {
        res = await documentSvc.getProductImageDocumentsByIds(ids);
        if (res?.data?.documents?.length > 0) {
          let documents =
            res?.data?.documents?.length > 0 ? res?.data?.documents : [];
          let mergedProducts = mergeProductAndImage(products, documents);

          return mergedProducts;
        }
      } catch (error) {
        setProductError(error);
      }
      setProductLoading(false);
    }

    return products;
  };
  const ListProducts = async () => {
    const res = await APIRequest({
      setError: setProductError,
      setLoading: setProductLoading,
      path: `${ENDPOINTS.ORDER}/products`,
      method: 'get',
    });
    if (res.status === 'success') {
      let products = res?.body?.products || [];
      let productsWithImage = await ListProductDocuments(products);
      setProducts(productsWithImage);
    }
    setProductLoading(false);
    return res;
  };

  const ReadProduct = async (productID) => {
    const res = await APIRequest({
      setError: setProductError,
      setLoading: setProductLoading,
      path: `${ENDPOINTS.ORDER}/product/${productID}`,
      method: 'get',
    });
    if (res.status === 'success') {
      let productsWithImage = await ListProductDocuments([res.body.product]);

      setProduct(productsWithImage?.[0]);
    }
    return res;
  };

  const ListProductItemCategories = async () => {
    const res = await APIRequest({
      setError: setProductError,
      setLoading: setProductLoading,
      path: `${ENDPOINTS.ORDER}/item-categories`,
      method: 'get',
    });
    if (res.status === 'success') {
      setProductItemCategories(res?.body?.item_categories || []);
    }
    return res;
  };

  const ListProductsPublic = async () => {
    const res = await APIRequest({
      setError: setProductError,
      setLoading: setProductLoading,
      path: `${process.env.REACT_APP_ORDER_URL}/products`,
      method: 'get',
    });
    if (res.status === 'success') {
      let products = res?.body?.products || [];
      // let productsWithImage = await ListProductDocuments(products);
      setProducts(products);
    }
    return res;
  };

  return {
    products,
    product,
    productItemCategories,
    productError,
    productLoading,
    productSubmitting,
    ListProducts,
    ReadProduct,
    ListProductItemCategories,
    ListProductsPublic,
  };
}

export const useProductContext = () => useContext(productContext);

export function ProvideProduct({ children }) {
  const product = useProvideProduct();
  return (
    <productContext.Provider value={product}>
      {children}
    </productContext.Provider>
  );
}
