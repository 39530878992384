import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Typography,
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  Paper,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader';

import MzButton from '../MzButton';
import CustomTextField from '../CustomFormFields/CustomTextField';
// import CustomSelectField from '../CustomFormFields/CustomSelectField';
import FormFieldContainer from '../CustomFormFields/FormFieldContainer';
import utilsSvc from '../../services/utilsApi';
import authApi from '../../services/authentication';
import { useProvideContactSupportTable } from 'src/hooks/useContactSupport';
import useSnackbar from 'src/hooks/useSnackbar';
import { ConfigContext } from 'src/contexts';
import useQueryParams from 'src/hooks/useQueryParams';

const useStyles = makeStyles((theme) => ({
  fontWeight: {
    fontWeight: 600,
  },
  background: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: -2,
    backgroundPosition: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  bgroot: {
    color: 'white',
    position: 'relative',
    display: 'flex',
    zIndex: 0,
    height: '20vh',
  },
  tab: {
    textTransform: 'initial',
    fontSize: 16,
  },
  disabledText: {
    paddingLeft: '5px',
  },
  changeDetails: {
    color: '#888B94',
  },
  gridItem: {
    marginBottom: theme.spacing(2),
  },
  paperContainer: {
    padding: theme.spacing(4),
  },
}));

const fields = {
  fieldName_name: {
    label: 'Name',
    name: 'username',
  },
  fieldName_email: {
    label: 'Email Address',
    name: 'email',
  },
  fieldName_subject: {
    label: 'Subject',
    name: 'subject',
  },
  fieldName_message: {
    label: 'Message',
    name: 'message',
  },
  fieldName_notify: {
    label: 'Keep me updated.',
    name: 'remarks',
  },
};
const {
  fieldName_name,
  fieldName_email,
  fieldName_subject,
  fieldName_message,
  fieldName_notify,
} = fields;

const validationSchema = Yup.object({
  [fieldName_name.name]: Yup.string().required('Name is required').default(''),
  [fieldName_email.name]: Yup.string()
    .required('Email is required')
    .default(''),
  [fieldName_message.name]: Yup.string().default(''),
});

const ContactSupport = (props) => {
  const classes = useStyles();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { addCart, user, setConfirmationDialog, company } = useContext(
    ConfigContext,
  );

  const queryParams = useQueryParams();
  let serviceLabel = queryParams.get('label');
  const [remarkChecked, setRemarkChecked] = useState(true);

  const {
    error: createContactSupportError,
    loading: createContactSupportLoading,
    SubmitContactSupportRequest,
  } = useProvideContactSupportTable();

  useEffect(() => {
    if (createContactSupportError) {
      showErrorSnackbar(
        `Error requesting contact support. ${createContactSupportError}`,
      );
    }
  }, [createContactSupportError]);

  let defaultValues = validationSchema.cast();
  defaultValues = {
    ...defaultValues,
    [fieldName_name.name]:
      user?.first_name || user.last_name
        ? `${user.first_name} ${user.last_name}`
        : '',
    [fieldName_email.name]: user?.email || '',
  };

  const handleOnSubmit = (values) => {
    let userData = authApi.getLocalUser();
    if (userData?.sysInfo_user_id) {
      const body = {
        ...values,
        sysinfo_user_id: userData.sysInfo_user_id,
        created_by: userData.sysInfo_user_id,
        status: 'pending',
      };
      utilsSvc
        .createContactSupport(body)
        .then((result) => {
          showSuccessSnackbar('Your request has been submitted successfully.');
        })
        .catch((error) => {
          showErrorSnackbar(
            'Failed to submit your request, please try again later.',
          );
        });
    }
  };

  const servicesContent = [
    {
      label: 'Book Keeping / Accounting',
      imageSrc: '/Vector-service-accounting.png',
      description: `Our Chartered Accountants prevent audit risks, optimise tax bills and manage your company’s financial health.`,
    },
    {
      label: 'Engage Auditor',
      imageSrc: '/Vector-service-engage-auditor.png',
      description: `Avoid compliance issues. We spot potential problems before they grow into compliance deal-breakers.`,
    },
    {
      label: 'Payroll',
      imageSrc: '/Vector-service-payroll.png',
      description: `Streamline and automate your company's payroll. Ensure your staff are paid on time and eliminate expensive tax penalties.`,
    },
    {
      label: 'Data Protection',
      imageSrc: '/Vector-service-data-protection.png',
      description: `Safeguard your data, apps and systems against malware attacks. Our cloud-based subscription service keeps you safe and secure.`,
    },
    {
      label: 'Tax Filing',
      imageSrc: '/Vector-service-tax-filing.png',
      description: `Minimise your company's tax liabilities with our tax-efficient solutions and services.`,
    },
    {
      label: 'Training',
      imageSrc: '/Vector-service-training.png',
      description: `Upskill your staff to take on senior roles and responsibilities.`,
    },
    {
      label: 'Office Supplies',
      imageSrc: '/Vector-service-office-supplies.png',
      description: `Upskill your staff to take on senior roles and responsibilities.`,
    },
    {
      label: 'Virtual Chief Financial Officer',
      imageSrc: '/Vector-service-training.png',
      description: `Upskill your staff to take on senior roles and responsibilities.`,
    },
    {
      label: 'Software Licenses',
      imageSrc: '/Vector-service-training.png',
      description: `Upskill your staff to take on senior roles and responsibilities.`,
    },
    {
      label: 'Website Development',
      imageSrc: '/Vector-service-web-development.png',
      description: `Designed to engage. Build a custom website that's scalable
      to your business model.`,
    },
    {
      label: 'Digital Marketing',
      imageSrc: '/Vector-service-digital-marketing.png',
      description: `Find your audience. Promote brand awareness and increase sales through multi-channel marketing.`,
    },
    {
      label: 'KYC / CDD',
      imageSrc: '/Vector-service-kyc.png',
      description: `Customer due diligence. Verify and stay updated on client identities, suitability and risks.`,
    },
  ];

  let foundService = servicesContent.find(
    (anItem) => anItem.label == serviceLabel,
  );
  return (
    <LoadingOverlay
      active={createContactSupportLoading}
      spinner={<ClipLoader color="white" />}
      styles={{
        wrapper: {
          overflow: createContactSupportLoading ? 'hidden' : 'initial',
        },
      }}
    >
      <Paper
        elevation={0}
        variant="outlined"
        style={{
          borderRadius: '10px',
        }}
        className={classes.paperContainer}
      >
        <Box>
          <Box pb={4}>
            <Box>
              <Typography variant="h3" className={classes.fontWeight}>
                Thank you for your interest in {serviceLabel}.
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography variant="caption">
                Please submit the form below to be notified when this service is
                available.
              </Typography>
            </Box>
          </Box>
          <Grid container wrap="wrap-reverse">
            <Grid item xs={12} md={4}>
              <Box display="flex" width="100%">
                {foundService && (
                  <Box display="flex" flexDirection="column" width="100%">
                    <Box width="100%" display="flex" justifyContent="center">
                      <img
                        src={foundService.imageSrc}
                        style={{ width: '50%', marginBottom: '15px' }}
                      />
                    </Box>
                    <Box>{foundService.description}</Box>
                  </Box>
                )}
                {/* {serviceLabel == 'Book Keeping / Accounting' && (
                  <Box display="flex" flexDirection="column">
                    <img
                      src="/Vector-service-accounting.png"
                      style={{ width: '90%', marginBottom: '15px' }}
                    />
                    <Box>
                      {`Our Chartered Accountants prevent audit risks, optimise tax bills and manage your company’s financial health.`}
                    </Box>
                  </Box>
                )}
                {serviceLabel == 'Engage Auditor' && (
                  <Box display="flex" flexDirection="column">
                    <img
                      src="/Vector-service-engage-auditor.png"
                      style={{ width: '90%', marginBottom: '15px' }}
                    />
                    <Box>
                      {`Avoid compliance issues. We spot potential problems before they grow into compliance deal-breakers.`}
                    </Box>
                  </Box>
                )}

                {serviceLabel == 'Payroll' && (
                  <Box display="flex" flexDirection="column">
                    <img
                      src="/Vector-service-payroll.png"
                      style={{ width: '90%', marginBottom: '15px' }}
                    />
                    <Box>
                      {`Streamline and automate your company's payroll. Ensure your staff are paid on time and eliminate expensive tax penalties.`}
                    </Box>
                  </Box>
                )}
                {serviceLabel == 'Data Protection' && (
                  <Box display="flex" flexDirection="column">
                    <img
                      src="/Vector-service-data-protection.png"
                      style={{ width: '90%', marginBottom: '15px' }}
                    />
                    <Box>
                      {`Safeguard your data, apps and systems against malware attacks. Our cloud-based subscription service keeps you safe and secure.`}
                    </Box>
                  </Box>
                )}
                {serviceLabel == 'Tax Filing' && (
                  <Box display="flex" flexDirection="column">
                    <img
                      src="/Vector-service-tax-filing.png"
                      style={{ width: '90%', marginBottom: '15px' }}
                    />
                    <Box>
                      {`Minimise your company's tax liabilities with our tax-efficient solutions and services.`}
                    </Box>
                  </Box>
                )}
                {serviceLabel == 'Training' && (
                  <Box display="flex" flexDirection="column">
                    <img
                      src="/Vector-service-training.png"
                      style={{ width: '90%', marginBottom: '15px' }}
                    />
                    <Box>
                      {`Upskill your staff to take on senior roles and responsibilities.`}
                    </Box>
                  </Box>
                )}
                {serviceLabel == 'Office Supplies' && (
                  <Box display="flex" flexDirection="column">
                    <img
                      src="/Vector-service-office-supplies.png"
                      style={{ width: '90%', marginBottom: '15px' }}
                    />
                    <Box>
                      {`Upskill your staff to take on senior roles and responsibilities.`}
                    </Box>
                  </Box>
                )}
                {serviceLabel == 'Virtual Chief Financial Officer' && (
                  <Box display="flex" flexDirection="column">
                    <img
                      src="/Vector-service-training.png"
                      style={{ width: '90%', marginBottom: '15px' }}
                    />
                    <Box>
                      {`Upskill your staff to take on senior roles and responsibilities.`}
                    </Box>
                  </Box>
                )}
                {serviceLabel == 'Software Licenses' && (
                  <Box display="flex" flexDirection="column">
                    <img
                      src="/Vector-service-training.png"
                      style={{ width: '90%', marginBottom: '15px' }}
                    />
                    <Box>
                      {`Upskill your staff to take on senior roles and responsibilities.`}
                    </Box>
                  </Box>
                )}
                {serviceLabel == 'Website Development' && (
                  <Box display="flex" flexDirection="column">
                    <img
                      src="/Vector-service-web-development.png"
                      style={{ width: '90%', marginBottom: '15px' }}
                    />
                    <Box>
                      {`Designed to engage. Build a custom website that's scalable
                      to your business model.`}
                    </Box>
                  </Box>
                )}
                {serviceLabel == 'Digital Marketing' && (
                  <Box display="flex" flexDirection="column">
                    <img
                      src="/Vector-service-digital-marketing.png"
                      style={{ width: '90%', marginBottom: '15px' }}
                    />
                    <Box>
                      {`Find your audience. Promote brand awareness and increase sales through multi-channel marketing.`}
                    </Box>
                  </Box>
                )}
                {serviceLabel == 'KYC / CDD' && (
                  <Box display="flex" flexDirection="column">
                    <img
                      src="/Vector-service-kyc.png"
                      style={{ width: '90%', marginBottom: '15px' }}
                    />
                    <Box>
                      {`Customer due diligence. Verify and stay updated on client identities, suitability and risks.`}
                    </Box>
                  </Box>
                )} */}
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Formik
                initialValues={defaultValues}
                validationSchema={validationSchema}
                onSubmit={() => {}}
              >
                {(props) => {
                  const {
                    validateForm,
                    values,
                    setTouched,
                    touched,
                    handleSubmit,
                    setValues,
                  } = props;

                  return (
                    <Form style={{ height: '100%' }}>
                      <Grid container>
                        <Grid container item xs={12}>
                          <Grid item xs={12} className={classes.gridItem}>
                            <CustomTextField
                              label={`${fieldName_name.label} *`}
                              name={fieldName_name.name}
                            />
                          </Grid>
                          <Grid item xs={12} className={classes.gridItem}>
                            <CustomTextField
                              label={`${fieldName_email.label} *`}
                              name={fieldName_email.name}
                            />
                          </Grid>
                          <Grid item xs={12} className={classes.gridItem}>
                            <CustomTextField
                              label={`${fieldName_message.label}`}
                              name={fieldName_message.name}
                              multiline
                              rows={4}
                            />
                          </Grid>
                          <Grid item xs={12} className={classes.gridItem}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={remarkChecked}
                                  onChange={(e) => {
                                    setRemarkChecked(e.target.checked);
                                  }}
                                  // name={fieldName_notify.name}
                                  color="primary"
                                />
                              }
                              label={fieldName_notify.label}
                            />
                            <Box mt={1}>
                              <Typography variant="caption">
                                Thank you for your patience and have a wonderful
                                day.
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Button
                              // type="submit"
                              variant="contained"
                              color="primary"
                              style={{
                                minWidth: '180px',
                                minHeight: '60px',
                                textTransform: 'unset',
                              }}
                              onClick={() => {
                                validateForm()
                                  .then((result) => {
                                    console.log('validateForm result', result);
                                    setTouched({
                                      ...touched,
                                      ...result,
                                    });
                                    let canSubmit = false;
                                    if (
                                      result &&
                                      Object.keys(result).length == 0
                                    ) {
                                      canSubmit = true;
                                    }
                                    if (canSubmit) {
                                      // handleOnSubmit(values, canSubmit);
                                      let userData = user;

                                      if (userData?.user_id) {
                                        let body = {
                                          ...values,
                                          [fieldName_subject.name]: `Upcoming Service - ${serviceLabel}`,
                                          sysinfo_user_id: userData.user_id,
                                          created_by: userData.user_id,
                                          status: 'pending',
                                        };

                                        if (remarkChecked) {
                                          body[fieldName_message.name] = `[${
                                            fieldName_notify.label
                                          }] ${
                                            body?.[fieldName_message.name] || ''
                                          }`;
                                        }
                                        SubmitContactSupportRequest(body)
                                          .then((result) => {
                                            showSuccessSnackbar(
                                              'Your request has been submitted successfully.',
                                            );
                                            setValues(defaultValues);
                                            setTouched({});
                                          })
                                          .catch((error) => {
                                            showErrorSnackbar(
                                              'Failed to submit your request, please try again later.',
                                            );
                                          });
                                      } else {
                                        showErrorSnackbar(
                                          'Failed to submit your request, please try again later.',
                                        );
                                      }
                                    }
                                  })
                                  .catch((errors) => {
                                    console.log('validateForm error', errors);
                                    setTouched({ ...touched, ...errors });
                                  });
                              }}
                            >
                              Submit
                            </Button>
                            {/* <MzButton
                        type="submit"
                        title={'Submit'}
                        onClick={() => {
                          validateForm()
                            .then((result) => {
                              console.log('validateForm result', result);
                              setTouched({
                                ...touched,
                                ...result,
                              });
                              let canSubmit = false;
                              if (result && Object.keys(result).length == 0) {
                                canSubmit = true;
                              }
                              if (canSubmit) {
                                handleOnSubmit(values, canSubmit);
                              }
                            })
                            .catch((errors) => {
                              console.log('validateForm error', errors);
                              setTouched({ ...touched, ...errors });
                            });
                        }}
                      /> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </LoadingOverlay>
  );
};

export default ContactSupport;
