import React, { useState } from 'react';
import {
  Box,
  Grid,
  Button,
  Typography,
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import CustomList from '../cart/CustomList';
import CustomModal from '../../components/CustomModal';
import MembershipPlanCard from './MembershipPlanCard';
import MembershipForm from './MembershipForm';
import useQueryParams from 'src/hooks/useQueryParams';

const samplePlans = [
  {
    image: '/membership-plan-starter.png',
    name: 'Starter',
    description: 'If you are a small business man, please select this plan',
    price: 99,
    note: '1 user, Billed Yearly',
    features: ['f1', 'f2'],
  },
  {
    image: '/membership-plan-standard.png',
    name: 'Standard',
    description: 'If you are a small business man, please select this plan',
    price: 999,
    note: '1 user, Billed Yearly',
    features: ['f1', 'f2', 'f3'],
  },
  {
    image: '/membership-plan-premium.png',
    name: 'Premium',
    description: 'If you are a small business man, please select this plan',
    price: 9999,
    note: '1 user, Billed Yearly',
    features: ['f1', 'f2', 'f3', 'f4', 'f5'],
  },
];

const sampleFeatures = [
  {
    name: 'Feature 1',
    id: 'f1',
  },
  {
    name: 'Feature 2',
    id: 'f2',
  },
  {
    name: 'Feature 3',
    id: 'f3',
  },
  {
    name: 'Feature 4',
    id: 'f4',
  },
  {
    name: 'Feature 5',
    id: 'f5',
  },
];

const defaultPlanForm = {
  show: false,
  data: null,
};
const MembershipPlans = (props) => {
  const {} = props;
  const theme = useTheme();

  const queryParams = useQueryParams();
  const routeHistory = useHistory();

  const [planForm, setPlanForm] = useState(defaultPlanForm);
  let featureTableColumns = [
    {
      dataIndex: 'name',
      label: (
        <Typography
          variant="h4"
          style={{
            textTransform: 'uppercase',
            fontWeight: 'normal',
          }}
        >
          Features
        </Typography>
      ),
      flexGrow: 6,
    },
  ];
  samplePlans.forEach((anItem) => {
    featureTableColumns.push({
      dataIndex: 'id',
      label: (
        <Box width="100%" display="flex" justifyContent="center">
          <Typography
            variant="h4"
            style={{
              textTransform: 'uppercase',
              fontWeight: 'normal',
            }}
          >
            {anItem.name}
          </Typography>
        </Box>
      ),
      render: (text, data) => {
        let foundFeature = anItem?.features?.indexOf(text) >= 0;
        return (
          <Box width="100%" display="flex" justifyContent="center">
            {foundFeature ? (
              <CheckIcon style={{ color: theme.palette.success.main }} />
            ) : (
              <CloseIcon />
            )}
          </Box>
        );
      },
      flexGrow: 2,
    });
  });

  const handlePlanFormClose = () => {
    setPlanForm(defaultPlanForm);
  };
  const handleOnClickPlan = (data = null) => {
    setPlanForm({
      data: data,
      show: true,
    });
  };

  const handleOnSubmitCheckout = (values) => {
    queryParams.set('checkout', 'success');
    routeHistory.push({
      pathname: '/settings',
      search: '?' + queryParams.toString(),
    });
    handlePlanFormClose();
  };

  return (
    <>
      <Grid container spacing={3}>
        {samplePlans.map((anItem, index) => {
          return (
            <Grid item key={index} xs={12} sm={4}>
              <MembershipPlanCard
                data={anItem}
                onClick={() => {
                  handleOnClickPlan(anItem);
                }}
              />
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Box>
            <CustomList
              data={sampleFeatures}
              columns={featureTableColumns}
              bordered={false}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            border={1}
            borderColor={theme.palette.grey[300]}
            display="flex"
            alignItems="center"
            px={4}
            py={2}
          >
            <Box>
              <img
                src="/membership-plan-support.png"
                style={{
                  width: '100px',
                  height: '100px',
                }}
              />
            </Box>
            <Box flexGrow={1} px={3}>
              <Box mb={1}>
                <Typography variant="h3">We’re here to help you!</Typography>
              </Box>
              <Box color={theme.palette.grey[600]}>
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 'normal',
                  }}
                >
                  As a question or request an issue. Our team support will get
                  back to you by email soon.
                </Typography>
              </Box>
            </Box>
            <Box minWidth="170px">
              <Button
                variant="outlined"
                color="primary"
                disableElevation={true}
                fullWidth={true}
                size="large"
                style={{ textTransform: 'none' }}
              >
                <b>Get Support Now</b>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <CustomModal
        title="Paying to Meyzer360"
        show={planForm.show}
        onHide={handlePlanFormClose}
      >
        <MembershipForm
          plan={planForm.data}
          onSubmit={handleOnSubmitCheckout}
        />
      </CustomModal>
    </>
  );
};

export default MembershipPlans;
