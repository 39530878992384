import React, { useEffect, forwardRef, useContext } from 'react';
import { Typography, Box, Paper } from '@material-ui/core';
import useStyles from './CapTable.styles';
import useBreakpoints from 'src/hooks/useBreakpoints';
import MaterialTable from 'material-table';
import FilterList from '@material-ui/icons/FilterList';
import { useProvideCapTable } from 'src/hooks/useCapTable';
import TitleLayout from 'src/components/TitleLayout';
import ROUTES from 'src/constants/routes';
import DisplayBox from 'src/components/DisplayBox';
import DetailsIcon from '@material-ui/icons/Details';
import { ConfigContext } from 'src/contexts';

const tempAllocationTables = [
  {
    Name: 'Comp LImited',
    identifications: [],
    share_capital_id: 8,
    share_type_id: 1,
    share_type_name: 'Ordinary',
    shareholder_id: 29,
    shareholder_type_id: 1,
    shareholder_type_name: 'Corporate',
    user_allotment: 10000,
    user_id: 67,
  },
  {
    Name: 'Corp Capital',
    identifications: [],
    share_capital_id: 8,
    share_type_id: 1,
    share_type_name: 'Ordinary',
    shareholder_id: 29,
    shareholder_type_id: 1,
    shareholder_type_name: 'Corporate',
    user_allotment: 5000,
    user_id: 67,
  },
  {
    Name: 'General LImited',
    identifications: [],
    share_capital_id: 8,
    share_type_id: 1,
    share_type_name: 'Ordinary',
    shareholder_id: 29,
    shareholder_type_id: 1,
    shareholder_type_name: 'Corporate',
    user_allotment: 4800,
    user_id: 67,
  },
];

const CapTable = () => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();
  const { company } = useContext(ConfigContext);
  const {
    ListCapTables,
    ListCapTableShareholders,
    capTables,
    capTableShareholders,
  } = useProvideCapTable();

  useEffect(() => {
    if (company?.company_id) {
      ListCapTables(company.company_id);
      ListCapTableShareholders(company.company_id);
    }
  }, [company.company_id]);

  const mapCapTable = () => {
    return capTables.map((cap, index) => ({
      name: cap.Name,
      type: cap.shareholder_type_name,
      security: cap.share_type_name,
      count: cap.user_allotment.toLocaleString(),
    }));
  };

  const mapAllocationTable = () => {
    return tempAllocationTables.map((cap, index) => ({
      name: cap.Name,
      type: cap.shareholder_type_name,
      security: cap.share_type_name,
      count: cap.user_allotment,
    }));
  };

  const renderDisplayBox = () => {
    return (
      <>
        {!!capTableShareholders && capTableShareholders.length > 0 && (
          <DisplayBox
            mobileVertical
            width="20%"
            itemList={[
              {
                number:
                  '$ ' +
                  capTableShareholders[0].number_of_shares.toLocaleString(),
                label: 'Total Share Capital',
              },
              {
                number: capTableShareholders[0].issued_share_capital.toLocaleString(),
                label: 'Issued Share Capital',
              },
              {
                number:
                  '$ ' +
                  capTableShareholders[0].paid_up_capital.toLocaleString(),
                label: 'Total Paid Up Capital',
              },
              {
                number: capTables.length.toLocaleString(),
                label: 'Number of Shareholders',
              },
            ]}
          />
        )}
      </>
    );
  };

  return (
    <TitleLayout
      title="Cap Table"
      pathList={[
        { path: 'Home', route: ROUTES.DASHBOARD },
        {
          path: 'Corporate Secretary',
          route: ROUTES.CORPSEC,
        },
        {
          path: 'Cap Table',
          color: 'primary',
        },
      ]}
    >
      {smBelow && (
        <>
          {renderDisplayBox()}
          <Box p={1} />
        </>
      )}
      <Paper
        style={{
          width: '100%',
          borderRadius: 10,
          padding: smBelow ? 20 : 40,
        }}
      >
        {!smBelow && (
          <>
            {renderDisplayBox()}
            <Box p={3} />
          </>
        )}
        <Typography variant="h5" style={{ color: '#979797' }}>
          Current Cap Table
        </Typography>
        <Box p={1} />
        <MaterialTable
          options={{
            filtering: false,
            paging: false,
            toolbar: false,
            draggable: false,
            headerStyle: {
              backgroundColor: '#F9F9F9',
            },
            rowStyle: (x) => {
              if (x.tableData.id % 2) {
                return { backgroundColor: '#FFFFFF' };
              }
              return { backgroundColor: '#F9F9F9' };
            },
          }}
          style={{ width: '100%' }}
          icons={{
            SortArrow: forwardRef((props, ref) => (
              <DetailsIcon {...props} ref={ref} fontSize="small" />
            )),
            Filter: forwardRef((props, ref) => (
              <FilterList {...props} ref={ref} />
            )),
          }}
          columns={[
            { title: 'SHAREHOLDER NAME', field: 'name' },
            { title: 'SHAREHOLDER TYPE', field: 'type' },
            { title: 'SECURITY TYPE', field: 'security' },
            { title: 'SHARE COUNT', field: 'count' },
          ]}
          data={mapCapTable()}
        />
        {/* <Box p={3} />
        <Typography variant="h5" style={{ color: '#979797' }}>
          Pending Allocation Table
        </Typography>
        <Box p={1} />
        <MaterialTable
          options={{
            filtering: true,
            paging: false,
            toolbar: false,
            draggable: false,
            headerStyle: {
              backgroundColor: '#F9F9F9',
            },
            rowStyle: (x) => {
              if (x.tableData.id % 2) {
                return { backgroundColor: '#FFFFFF' };
              }
              return { backgroundColor: '#F9F9F9' };
            },
          }}
          style={{ width: '100%' }}
          icons={{
            SortArrow: forwardRef((props, ref) => (
              <DetailsIcon {...props} ref={ref} fontSize="small" />
            )),
            Filter: forwardRef((props, ref) => (
              <FilterList {...props} ref={ref} />
            )),
          }}
          columns={[
            { title: 'SHAREHOLDER NAME', field: 'name' },
            { title: 'SHAREHOLDER TYPE', field: 'type' },
            { title: 'SECURITY TYPE', field: 'security' },
            { title: 'SHARE COUNT', field: 'count' },
          ]}
          data={mapAllocationTable()}
        /> */}
        <Box p={smBelow ? 1 : 5} />
      </Paper>
    </TitleLayout>
  );
};

export default CapTable;
