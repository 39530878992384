import {
  useContext,
  useState,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { APIRequest } from 'src/utils/api';
import { Config } from 'src/config';
import ENDPOINTS from 'src/constants/endpoints';

const companyContext = createContext({
  agm: {},
  businessActivity: {},
  company: {},
  companyAddressess: [],
  companyOfficers: [],
  financialYearEnd: {},

  companyError: '',
  companyLoading: false,
  companySubmitting: false,
  GetAGM: () => Promise.resolve({}),
  GetBusinessActivity: () => Promise.resolve({}),
  GetCompanyAuditors: () => Promise.resolve({}),
  GetCompanyByID: () => Promise.resolve({}),
  GetCompanyOfficers: () => Promise.resolve({}),
  GetFinancialYearEnd: () => Promise.resolve({}),
  ListCompanys: () => Promise.resolve({}),
  ReadCompany: () => Promise.resolve({}),
  ReadCompanyAddressess: () => Promise.resolve({}),
});

export function useProvideCompany() {
  const [agm, setAgm] = useState({});
  const [businessActivity, setBusinessActivity] = useState({});
  const [company, setCompany] = useState({});
  const [companyAddressess, setCompanyAddressess] = useState([]);
  const [companyAuditors, setCompanyAuditors] = useState([]);
  const [companyOfficers, setCompanyOfficers] = useState([]);
  const [financialYearEnd, setFinancialYearEnd] = useState({});

  const [companyError, setCompanyError] = useState();
  const [companyLoading, setCompanyLoading] = useState(false);
  const [companySubmitting, setCompanySubmitting] = useState(false);

  const GetAGM = async (companyID) => {
    const res = await APIRequest({
      setError: setCompanyError,
      setLoading: setCompanyLoading,
      // path: `${Config.apiURL}/company/timeline/company/${companyID}/event/1/latest`,
      path: `${ENDPOINTS.COMPANY}/companies/${companyID}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setAgm(res.body.company);
    }
    return res;
  };

  const GetBusinessActivity = async (companyID) => {
    const res = await APIRequest({
      setError: setCompanyError,
      setLoading: setCompanyLoading,
      path: `${ENDPOINTS.BIZACTIVITY}/companies/${companyID}/businessactivities`,
      method: 'get',
    });
    if (res.status === 'success') {
      setBusinessActivity(res.body.business_activities);
    }
    return res;
  };

  const GetCompanyAuditors = async (companyID) => {
    const res = await APIRequest({
      setError: setCompanyError,
      setLoading: setCompanyLoading,
      path: `${ENDPOINTS.COMPANY}/companyauditors/company/${companyID}?type=active`,
      method: 'get',
    });
    if (res.status === 'success') {
      setCompanyAuditors(res.body.company_auditors);
    }
    return res;
  };

  const GetCompanyByID = async (companyID) => {
    const res = await APIRequest({
      setError: setCompanyError,
      setLoading: setCompanyLoading,
      path: `${ENDPOINTS.COMPANY}/companies/${companyID}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setCompany(res.body.company);
    }
    return res;
  };

  const GetCompanyOfficers = async (companyID) => {
    const res = await APIRequest({
      setError: setCompanyError,
      setLoading: setCompanyLoading,
      path: `${ENDPOINTS.COMPANY}/companies/${companyID}/officers`,
      method: 'get',
    });
    if (res.status === 'success') {
      setCompanyOfficers(res.body.userCompanyRoles);
    }
    return res;
  };

  const GetFinancialYearEnd = async (companyID) => {
    const res = await APIRequest({
      setError: setCompanyError,
      setLoading: setCompanyLoading,
      path: `${Config.applicationURL}/financialyearendapplication/${companyID}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setFinancialYearEnd(res.body.financial_year_ends);
    }
    return res;
  };

  const ReadCompanyAddresses = async (companyID) => {
    const res = await APIRequest({
      setError: setCompanyError,
      setLoading: setCompanyLoading,
      path: `${ENDPOINTS.ADDRESS}/companies/${companyID}/addresses`,
      method: 'get',
    });
    if (res.status === 'success') {
      setCompanyAddressess(res.body.companyAddresses);
    }
    return res;
  };

  return {
    agm,
    businessActivity,
    company,
    companyAddressess,
    companyAuditors,
    companyOfficers,
    financialYearEnd,
    companyError,
    companyLoading,
    companySubmitting,
    GetAGM,
    GetBusinessActivity,
    GetCompanyByID,
    GetCompanyAuditors,
    GetCompanyOfficers,
    GetFinancialYearEnd,
    ReadCompanyAddresses,
  };
}

export const useCompanyContext = () => useContext(companyContext);

export function ProvideCompany({ children }) {
  const company = useProvideCompany();
  return (
    <companyContext.Provider value={company}>
      {children}
    </companyContext.Provider>
  );
}
