require('dotenv').config();

export const Config = {
  checkoutAuthToken: process.env.REACT_APP_CHECKOUT_AUTH_TOKEN,
  checkoutPaymentAuthToken: process.env.REACT_APP_CHECKOUT_PAYMENT_AUTH_TOKEN,
  addressURL: process.env.REACT_APP_ADDRESS_URL,
  apiURL: process.env.REACT_APP_API_URL,
  apiUrl: process.env.REACT_APP_DOCUMENT_URL,
  cartURL: process.env.REACT_APP_CART_URL,
  checkoutURL: process.env.REACT_APP_CHECKOUT_URL,
  companyURL: process.env.REACT_APP_COMPANY_URL,
  dataURL: process.env.REACT_APP_COMMON_URL,
  oAuthURL: process.env.REACT_APP_OAUTH_URL,
  requestURL: process.env.REACT_APP_REQUEST_URL,
  shareholderURL: process.env.REACT_APP_SHAREHOLDER_URL,
  sharesURL: process.env.REACT_APP_SHARES,
  userURL: process.env.REACT_APP_USERCONTACT_URL,
  utilsURL: process.env.REACT_APP_UTILS_URL,
  applicationURL: process.env.REACT_APP_APPLICATION_URL,
  bizURL: process.env.REACT_APP_BIZACTIVITY_URL,
  companyapplicationURL: process.env.REACT_APP_COMPANYAPPLICATIONS_URL,
  coAppDocURL: process.env.REACT_APP_CO_APP_DOC_URL,
  orderURL: process.env.REACT_APP_ORDER_URL,
};
