import React from 'react';
import { Typography, Box, Button, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import Layout from './Layout';

const useStyles = makeStyles((theme) => ({
  fontWeight: {
    fontWeight: 900,
  },
}));

const UnsuccessfulPayment = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Layout>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h1" className={classes.fontWeight}>
          Unsuccessful Payment
        </Typography>
        <Box p={5} />
        <Button
          variant="contained"
          size="large"
          color="primary"
          style={{
            textTransform: 'initial',
          }}
          onClick={() => history.push(ROUTES.DASHBOARD)}
        >
          Back to Home Page
        </Button>

        <Box p={3} />
        <Link
          to="#"
          onClick={(e) => {
            window.location = 'mailto:';
            e.preventDefault();
          }}
        >
          <Typography
            variant="body1"
            style={{
              color: '#000000',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            Need help?
          </Typography>
        </Link>
      </Box>
    </Layout>
  );
};

export default UnsuccessfulPayment;
