import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import useStyles from './FileDropzone.styles';
import { Button, Typography, Box } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const FileDropzone = ({
  multiple = false,
  files = [],
  setFiles,
  error,
  disabled,
  ...props
}) => {
  const classes = useStyles();

  const uploadRef = useRef();
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    multiple,
  });

  useEffect(() => {
    if (setFiles) {
      setFiles(acceptedFiles);
    }
  }, [acceptedFiles]);

  function bytesToSize(bytes) {
    const units = ['byte', 'kilobyte', 'megabyte', 'terabyte', 'petabyte'];
    const unit = Math.floor(Math.log(bytes) / Math.log(1024));
    return new Intl.NumberFormat('en', {
      style: 'unit',
      unit: units[unit],
    }).format(bytes / 1024 ** unit);
  }

  const handleOnChange = (e) => {
    if (
      uploadRef.current.value !== undefined ||
      uploadRef.current.value !== null ||
      uploadRef.current.value !== ''
    ) {
      let newFiles = Array.from(uploadRef?.current?.files);

      setFiles([...files, ...newFiles]);
      uploadRef.current.value = '';

      //   console.log('imageInputRef.current.value', imageInputRef.current.files);
      if (!uploadRef?.current?.files?.[0]?.type) {
      } else {
      }
    }
  };

  return (
    <>
      <section className={error ? classes.errorContainer : classes.container}>
        {files.length === 0 && (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} disabled={disabled} />
            <Box
              px={2}
              display="flex"
              flexDirection="row"
              alignItems="center"
              p={3}
            >
              <CloudUploadIcon fontSize={'large'} />
              <Box display="flex" flexDirection="column" px={2}>
                <div>
                  <Typography color="textSecondary">
                    Drag and drop here / Click to upload
                  </Typography>
                </div>
                <Box mt={2}>
                  <Typography color="textSecondary">
                    Accepted formats: .jpg / .pdf / .png
                  </Typography>
                </Box>
                <Typography color="textSecondary">Click to Upload</Typography>
              </Box>
            </Box>
          </div>
        )}
        {files.length > 0 && (
          <>
            {multiple ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                // alignItems="center"
                minHeight={'50px'}
                flexShrink={1}
              >
                {files.map((aFile, index) => {
                  return (
                    <Box key={index} px={3} mb={2}>
                      <Box>
                        <Typography variant="overline">{aFile.name}</Typography>
                        <Typography variant="overline">
                          &nbsp;{bytesToSize(aFile.size)}
                        </Typography>
                      </Box>
                      <Button
                        variant="outlined"
                        size="small"
                        style={{
                          color: '#900E1A',
                          border: '1px solid #900E1A',
                        }}
                        onClick={() => {
                          if (setFiles) {
                            let newValues = files || [];
                            if (files.length > 0 && index > -1) {
                              newValues.splice(index, 1);
                            }
                            setFiles(newValues, true);
                          }
                        }}
                      >
                        Remove
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight={'50px'}
                flexShrink={1}
              >
                <Typography variant="overline">{files[0].name}</Typography>
                <Typography variant="overline">
                  {bytesToSize(files[0].size)}
                </Typography>
                <Button
                  variant="outlined"
                  style={{
                    color: '#900E1A',
                    border: '1px solid #900E1A',
                  }}
                  onClick={() => {
                    if (setFiles) {
                      setFiles([], true);
                    }
                  }}
                >
                  Remove
                </Button>
              </Box>
            )}
            <Box px={3}>
              <Button
                variant={'contained'}
                component="label"
                color="primary"
                onChange={handleOnChange}
                // disabled={disabled}
                // size={size}
                // {...restProps}
              >
                Choose File(s)
                <input type="file" hidden ref={uploadRef} />
              </Button>
            </Box>
          </>
        )}
      </section>
      {error && (
        <Typography variant="caption" className={classes.errorText}>
          File is required
        </Typography>
      )}
    </>
  );
};

export default FileDropzone;
