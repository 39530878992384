import React from 'react';
import { Typography, Box, AppBar, Toolbar } from '@material-ui/core';
import AuthLayout from 'src/components/AuthLayout';
import useStyles from './ServiceLayout.styles';

const ServiceLayout = ({ title, child, showAppbar = true }) => {
  const classes = useStyles();
  return (
    // <AuthLayout>
    <Box display="flex" height={'calc(100% - 64px)'} flexDirection={'column'}>
      {showAppbar && (
        <AppBar
          position="static"
          classes={{
            root: classes.appbar,
          }}
        >
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.title} variant="h5" noWrap>
              {title.toUpperCase()}
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      {child}
    </Box>
    // </AuthLayout>
  );
};

export default ServiceLayout;
