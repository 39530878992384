import React, { useState, useEffect, useRef } from 'react';
import { Typography } from '@material-ui/core';

import ContentLayout from 'src/components/ContentLayout';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import OfficeSuppliesDetail from 'src/components/OfficeSuppliesDetail';
import useQueryParams from 'src/hooks/useQueryParams';

const OfficeSuppliesDetailPage = () => {
  return (
    <ContentLayout
      header={
        <>
          <Typography variant="h2">Office Supplies</Typography>
          <BreadcrumbsNav pathList={[]} />
        </>
      }
    >
      <OfficeSuppliesDetail />
    </ContentLayout>
  );
};

export default OfficeSuppliesDetailPage;
