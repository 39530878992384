import { createStyles, makeStyles } from '@material-ui/core/styles';

const drawerWidth = 256;

export default makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& .MuiListItemIcon-root': {
        minWidth: '40px',
      },
    },

    appBar: {
      boxShadow: 'none',
      backgroundColor: '#FAFAFA',
      color: 'black',
      zIndex: theme.zIndex.drawer + 0,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      zIndex: 1,
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      position: 'relative',
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      zIndex: 1,
    },
    drawerOpen: {
      backgroundColor: '#FFFFFF',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflow: 'visible',
      '@global': {
        '*::-webkit-scrollbar': {
          width: '0.4em',
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: '1px solid slategrey',
        },
      },
    },
    drawerClose: {
      backgroundColor: '#FFFFFF',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
      overflow: 'visible',
    },
    drawerLogo: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 20,
      paddingLeft: 20,
      paddingBottom: 10,
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1),
    },
    toolbar: {
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    textlabel: {
      color: '#000000',
    },
    textlabelclose: {
      color: '#161616',
    },

    subtextlabel: {
      color: '#FFFFFF',
      fontSize: 15,
    },
    nested: {
      paddingLeft: theme.spacing(2),
    },

    settingLogo: {
      color: 'black',
      width: 20,
      height: 20,
      [theme.breakpoints.down('sm')]: {
        width: 35,
        height: 35,
      },
    },
    settingDrawer: {
      backgroundColor: '#161616',
    },
    settingsDropDown: {
      left: 0,
      right: 0,
      top: 50,
    },
    shape: {
      backgroundColor: '#D8D8D8',
      width: 35,
      height: 35,
    },
    shapeCircle: {
      borderRadius: '50%',
    },
    iconButton: {
      color: 'black',
    },
    toolbarToggle: {
      position: 'absolute',
      right: '-15px',
      backgroundColor: '#fff',
      // border: "1px solid black",
      borderRadius: '5px',
      boxShadow: theme.shadows[1],
      zIndex: 100000,
      '&:hover': {
        backgroundColor: '#fff',
      },
      padding: theme.spacing(1),
      paddingLeft: '3px',
      paddingRight: '3px',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    drawerButton: {
      border: '1px solid #ccc',
      borderRadius: '4px',
      padding: 5,
      height: 48,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
    },
    companyselect: {
      '& .MuiSelect-select': {
        backgroundColor: '#FAFAFA',
      },
    },
    companyTitle: {
      color: '#B4B6CE',
      fontWeight: 600,
      padding: 15,
    },
    companyItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    addNewCompany: {
      color: '#172B4D',
      fontWeight: 600,
      padding: 15,
    },
  }),
);
