import React, { useState, useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import useQueryParams from '../../hooks/useQueryParams';
import { sgdFormat } from 'src/utils/currency';
import CustomTable from 'src/components/CustomTable';

import MembershipUpgradePlans from './MembershipUpgradePlans';
import MembershipPlans from './MembershipPlans';
import MembershipCheckoutResult from './MembershipCheckoutResult';

const samplePlanData = {
  name: 'Standard (Annual)',
  description: 'Lorem Ipsum',
  credits: 122.0,
  expireAt: 'Dec 09, 2023',
};

const SettingsMembership = (props) => {
  const {} = props;
  const theme = useTheme();

  const routeHistory = useHistory();
  const queryParams = useQueryParams();
  let isCheckoutSuccess = queryParams.get('checkout') == 'success';
  let isSubscribed = queryParams.get('subscribed') == 'true';
  let isViewingUpgradePlans = queryParams.get('upgrade') == 'true';

  const [viewUpgradePlans, setViewUpgradePlans] = useState(false);

  useEffect(() => {
    if (viewUpgradePlans) {
      setViewUpgradePlans(false);
    }
  }, []);

  const handleOnClickViewUpgradePlans = () => {
    queryParams.set('upgrade', true);
    routeHistory.push({
      pathname: '/settings',
      search: '?' + queryParams.toString(),
    });
  };
  if (isCheckoutSuccess) {
    return (
      <MembershipCheckoutResult
        onClick={() => {
          queryParams.set('subscribed', true);
          queryParams.delete('checkout');
          queryParams.delete('upgrade');

          routeHistory.replace({
            pathname: '/settings',
            search: '?' + queryParams.toString(),
          });
        }}
      />
    );
  } else if (isViewingUpgradePlans) {
    return <MembershipUpgradePlans />;
  } else if (isSubscribed) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            style={{ backgroundColor: theme.palette.grey[100] }}
            borderRadius={'1rem'}
            mb={5}
          >
            <Box
              p={3}
              borderRadius={'1rem'}
              style={{ backgroundColor: theme.palette.common.black }}
              display="flex"
              alignItems="center"
            >
              <Box
                py={2}
                px={7}
                borderRadius={'1rem'}
                style={{ backgroundColor: theme.palette.common.white }}
                mr={2}
              >
                <Typography variant="h1">
                  <b>{sgdFormat(samplePlanData.credits)}</b>
                </Typography>
              </Box>
              <Box>
                <Box mb={1}>
                  <Typography
                    variant="h3"
                    style={{
                      fontWeight: 'normal',
                      color: theme.palette.warning.main,
                    }}
                  >
                    {samplePlanData.name}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: 'normal',
                      color: 'white',
                    }}
                  >
                    {samplePlanData.description}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" p={3}>
              <Box>
                <Box mb={1}>
                  <Typography
                    variant="h3"
                    style={{
                      fontWeight: 'normal',
                    }}
                  >
                    <b>Active until {samplePlanData.expireAt}</b>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: 'normal',
                    }}
                  >
                    We will send you a notification upon Subscription expiration
                  </Typography>
                </Box>
              </Box>
              <Box ml="auto">
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation={true}
                  fullWidth={true}
                  size="large"
                  style={{ textTransform: 'none' }}
                  onClick={handleOnClickViewUpgradePlans}
                >
                  <b>Upgrade Plan</b>
                </Button>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box mb={1}>
              <Typography variant="h2">Transaction</Typography>
            </Box>
            <Box>
              <CustomTable
                paperProps={{
                  //   variant: 'outlined',
                  elevation: 0,
                }}
                striped={true}
                pagination={false}
                columns={[
                  {
                    label: 'Order ID',
                    id: 'id',
                  },
                  {
                    label: 'Description',
                    id: 'description',
                  },
                  {
                    label: 'Date',
                    id: 'date',
                  },
                  {
                    label: 'Type',
                    id: 'type',
                  },
                  {
                    label: 'Amount',
                    id: 'amount',
                    numeric: true,
                    render: (text) => {
                      let result = text;
                      if (text > 0) {
                        result = (
                          <span style={{ color: theme.palette.success.main }}>
                            +{sgdFormat(text)}
                          </span>
                        );
                      } else if (text < 0) {
                        result = (
                          <span style={{ color: theme.palette.error.main }}>
                            {sgdFormat(text)}
                          </span>
                        );
                      }
                      return <Box>{result}</Box>;
                    },
                  },
                ]}
                data={[
                  {
                    id: '123456',
                    description: 'Renewal',
                    date: '13 Feb 2022',
                    type: 'Credit',
                    amount: 99,
                  },
                  {
                    id: '234567',
                    description: 'Debit',
                    date: '13 Feb 2022',
                    type: 'Credit',
                    amount: -66,
                  },
                  {
                    id: '345678',
                    description: 'Debit',
                    date: '13 Feb 2022',
                    type: 'Credit',
                    amount: -99,
                  },
                  {
                    id: '456789',
                    description: 'Credit',
                    date: '13 Feb 2022',
                    type: 'Credit',
                    amount: 99,
                  },
                  {
                    id: '456789',
                    description: 'Credit',
                    date: '13 Feb 2022',
                    type: 'Credit',
                    amount: 99,
                  },
                ]}
                rowKey="id"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return <MembershipPlans />;
};

export default SettingsMembership;
