import React, { useState, useEffect, useContext } from 'react';
import {
  Paper,
  Box,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Tabs,
  Tab,
  Button,
  ButtonGroup,
  TextField,
  InputBase,
  Divider,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useTheme, withStyles } from '@material-ui/core/styles';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import { useProvideProduct } from 'src/hooks/useProduct';
import ImageContainer, { RatioContainer } from 'src/components/ImageContainer';
import { ConfigContext } from 'src/contexts';
import './styles.css';
import CustomSlider from '../CustomSlider';

function TabPanel(props) {
  const { children, value, index, style = {}, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      style={{
        minHeight: '300px',
        ...style,
      }}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CustomStyledTabs = withStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.grey[400]}`,
  },
  indicator: {
    top: 0,
  },
}))(Tabs);

const OfficeSuppliesDetail = (props) => {
  // const [product, setProduct] = useState({});
  const routeParams = useParams();
  const theme = useTheme();
  const {
    cartList,
    cart,
    removeCart,
    addCart,
    user,
    company,
    setConfirmationDialog,
  } = useContext(ConfigContext);

  const [quantity, setQuantity] = useState(1);
  const [informationTabIndex, setInformationTabIndex] = useState(0);

  let productId = routeParams?.id;
  const {
    ReadProduct,
    ListProducts,
    products: defaultProducts = [],
    product,
    productError,
  } = useProvideProduct();

  const { id, name, price, description, quantity: stock } = product;

  useEffect(() => {
    // setProduct(sampleProduct);
    if (productId) {
      ReadProduct(productId);
    }
  }, [productId]);

  const handleAddQuantity = () => {
    setQuantity((prev) => {
      let newValue = prev + 1;
      return newValue;
    });
  };
  const handleMinusQuantity = () => {
    setQuantity((prev) => {
      let newValue = prev - 1;
      if (newValue >= 1) {
        return newValue;
      }
      return 1;
    });
  };

  const handleQuantityOnChange = (e) => {
    let newValue = e.target.value;
    if (newValue >= 1) {
      let isDecimal = newValue % 1 != 0;
      if (isDecimal) {
        newValue = Math.round(newValue);
      }
      setQuantity(newValue);
    } else {
      if (newValue == '') {
        setQuantity(newValue);
      }
    }
  };

  const handleQuantityOnBlur = (e) => {
    let newValue = e.target.value;
    if (!newValue) {
      setQuantity(1);
    }
  };
  console.log('product', product?.product_documents);
  const handleAddToCart = async () => {
    await addCart({
      price: product.price,
      product_id: product.id,
      quantity: quantity,
      user_id: user.user_id,
      // product_detail_id: res.body.applications.company_name_application_id,
      company_id: company.company_id,
      company_name: company.company_name,
      company_registration_no: company.company_registration_no,
      // company_application_id: res.body.applications.company_application_id,
    });
  };

  let productImageSrc = product?.product_documents?.[0]?.document?.document_url;

  return (
    <Paper component={Box} p={4}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box height="100%" className="custom-slider">
            {/* {product?.product_documents?.length > 0 ? (
              <CustomSlider
                data={product?.product_documents?.map((anItem) => {
                  return {
                    src: anItem?.document?.document_url,
                  };
                })}
                showPagination={false}
                space={'3px'}
                slider={{
                  autoplay: false,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite:
                    product?.product_documents?.length > 2 ? true : false,
                  centerMode: true,
                  pauseOnHover: true,
                }}
              />
            ) : (
              <RatioContainer>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                >
                  Image Not Available
                </Box>
              </RatioContainer>
            )} */}
            {productImageSrc ? (
              <ImageContainer ratio="100%" src={productImageSrc} />
            ) : (
              <RatioContainer>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                >
                  Image Not Available
                </Box>
              </RatioContainer>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={2}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/office-supplies">
                Office Supplies
              </Link>
              <Typography color="textPrimary">{name}</Typography>
            </Breadcrumbs>
          </Box>
          <Box mb={2}>
            <Typography variant="h2">{name}</Typography>
          </Box>
          {price != undefined && (
            <Box color={'#F4A51C'} mb={2}>
              <Typography variant="h3">${price.toFixed(2)}</Typography>
            </Box>
          )}
          {/* {description != undefined && (
            <Box>
              <Typography>{description}</Typography>
            </Box>
          )} */}
          <Box mb={3} color={theme.palette.grey[600]}>
            <Typography>
              Condimentum fermentum vestibulum vestibulum mi adipiscing dapibus
              parturient placerat elit a mus facilisi feugiat parturient
              senectus urna adipiscing
            </Typography>
          </Box>

          <Box display="flex" flexWrap="wrap">
            <Box mr={2} className="add-to-cart-quantity" mb={1}>
              <ButtonGroup>
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: 0,
                    paddingLeft: '5px',
                    paddingRight: '5px',
                  }}
                  onClick={handleMinusQuantity}
                >
                  <RemoveIcon />
                </Button>
                <InputBase
                  variant="outlined"
                  type="number"
                  inputProps={{
                    step: 1,
                    min: 1,
                    style: {
                      textAlign: 'center',
                    },
                  }}
                  style={{
                    borderRadius: 0,
                    border: `1px solid ${theme.palette.grey[400]}`,
                    borderRight: 0,
                    padding: theme.spacing(1),
                    width: '50px',
                  }}
                  value={quantity}
                  onChange={handleQuantityOnChange}
                  onBlur={handleQuantityOnBlur}
                />
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: 0,
                    paddingLeft: '5px',
                    paddingRight: '5px',
                  }}
                  onClick={handleAddQuantity}
                >
                  <AddIcon />
                </Button>
              </ButtonGroup>
            </Box>
            <Button
              variant="contained"
              color="primary"
              disableElevation={true}
              style={{
                borderRadius: 0,
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
                marginBottom: theme.spacing(1),
              }}
              disabled={!(stock && stock >= 0)}
              onClick={handleAddToCart}
            >
              Add to Cart
            </Button>
          </Box>
          <Box py={4}>
            <Divider />
          </Box>
          <Box>
            <Box py={1}>
              <b>Category</b>:{' '}
              <span style={{ color: theme.palette.grey[500] }}>{'-'}</span>
            </Box>
            <Box py={1}>
              <b>Item ID</b>:{' '}
              <span style={{ color: theme.palette.grey[500] }}>{id}</span>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box py={3} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomStyledTabs
            value={informationTabIndex}
            onChange={(e, value) => {
              setInformationTabIndex(value);
            }}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Additional Info" />
            <Tab label="Review" />
            <Tab label="Shipping & Delivery" />
          </CustomStyledTabs>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={informationTabIndex} index={0}>
            Additional Information
            <Grid container>
              <Grid item xs={12} md={5}>
                <Box color={theme.palette.grey[600]}>
                  <p>
                    <Typography>
                      Condimentum fermentum vestibulum vestibulum mi adipiscing
                      dapibus parturient placerat elit a mus facilisi feugiat
                      parturient senectus urna adipiscing
                    </Typography>
                  </p>
                  <p>
                    <Typography>
                      Condimentum fermentum vestibulum vestibulum mi adipiscing
                      dapibus parturient placerat elit a mus facilisi feugiat
                      parturient senectus urna adipiscing
                    </Typography>
                  </p>
                </Box>
              </Grid>
              <Grid item xs={0} md={1}>
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  textAlign="center"
                  width="100%"
                  height="100%"
                >
                  <Divider orientation="vertical" />
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box mb={1}>
                  <b>Height</b>: <span>200cm</span>
                </Box>
                <Box mb={1}>
                  <b>Weight</b>: <span>12KG</span>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={informationTabIndex} index={1}>
            Reviews
          </TabPanel>
          <TabPanel value={informationTabIndex} index={2}>
            Shipping & Delivery
          </TabPanel>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default OfficeSuppliesDetail;
