import React, { useState, useEffect, useContext } from 'react';
import { Typography, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import ActionList from 'src/components/ActionList';
import { useProvideUser } from 'src/hooks/useUsers';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';
import { ConfigContext } from 'src/contexts';
import { useProvideProduct } from 'src/hooks/useProduct';

const ResgnAdmin = ({ toggleShowMenu }) => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const { ListUserByCompanyRole, users } = useProvideUser();
  const { company, setSelectedUser } = useContext(ConfigContext);
  const { ReadProduct, product, productError } = useProvideProduct();

  useEffect(() => {
    // get latest product
    ReadProduct(1);
  }, []);

  const getStarted = {
    one: {
      content: [
        'Remove an active administrator or authorized agent from your business',
      ],
    },
    two: {
      content: ['The name of the administrator or authorized agent resigning'],
    },
    three: {
      content: [
        'Create Request',
        'Meyzer360 Review',
        'Sign Resolution',
        'ACRA Filing',
      ],
    },
    four: {
      content: ['SGD' + product.price],
    },
    five: {
      content: [
        'Fill up form: < 2 minutes',
        'Average processing time: 1-2 days',
      ],
    },
  };

  useEffect(() => {
    if (company.company_id) {
      ListUserByCompanyRole(company.company_id, 15);
    }
  }, [company.company_id]);

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={step}
        setStep={setStep}
        getStarted={getStarted}
        header={
          <>
            <Typography variant="h2">
              {step === 1 && 'Resignation of Administrator'}
              {step === 2 && 'Select Administrator to Resign'}
            </Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Request', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Change of Officers',
                  route: ROUTES.CORPSEC_REQ_CHANGE_OFFICER,
                },
                {
                  path: 'Resignation of Administrator',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        {step === 2 && (
          <ActionList
            itemList={users}
            buttonLabel="Resign"
            onClick={(userID, selectedUser) => {
              setSelectedUser(selectedUser);
              history.push(ROUTES.CORPSEC_REQ_RESGN_ADMIN_INFO(userID));
            }}
            onClickBackButton={() => {
              history.push(ROUTES.CORPSEC_REQ_CHANGE_OFFICER);
            }}
          />
        )}
        <Box py={5} />
      </GetStartedLayout>
    </>
  );
};

export default ResgnAdmin;
