import React, { useState, useEffect, useContext } from 'react';
import { Button, Box, IconButton, CircularProgress } from '@material-ui/core';
// import FileViewer from 'react-file-viewer';
import { useSnackbar } from 'notistack';
import GetAppIcon from '@material-ui/icons/GetApp';

import CustomModal from '../CustomModal';
import FilePreview from './Preview';
import documentSvc from 'src/services/document';
import { ConfigContext } from 'src/contexts';

const FileViewer = React.forwardRef((props, ref) => {
  const {
    src,
    srcType = 'file', // "file" or "url"

    disabled = false,
    size = 'medium',
    modalProps = {},
    filePreviewProps: defaultFilePreviewProps = {},
    loading = false,
    documentId,
    onChange,
    className = '',
    style = {},
    children,
    ...restProps
  } = props;

  const { user, company } = useContext(ConfigContext);

  const [showPreview, setShowPreview] = useState(false);
  const [reloadedSrc, setReloadedSrc] = useState();
  const [reloading, setReloading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handlePreviewOpen = () => {
    setShowPreview(true);
  };
  const handlePreviewClose = () => {
    setShowPreview(false);
    // setReloadedSrc();
  };

  const handleOnReload = async () => {
    await (async () => {
      setReloading(true);
      try {
        let res = await documentSvc.getDocumentsByIdSTS(
          documentId,
          user?.username,
          company?.company_id,
        );
        setReloadedSrc(res?.data?.document?.[0]?.signed_document_url);
        // setSrc('');
      } catch (error) {
        setReloadedSrc();

        // setSrc('');
      }
      setReloading(false);
    })();
  };

  const handlePreviewOnError = (e) => {
    console.log('handlePreviewOnError', e);
  };

  let finalSrc = reloadedSrc || src;
  console.log('aaaaaaa', {
    reloadedSrc,
    isReloadUrl: reloadedSrc == finalSrc,
    isDefaultUrl: src == finalSrc,
  });
  let filePreviewProps = { ...defaultFilePreviewProps };
  if (srcType == 'file') {
    filePreviewProps['file'] = finalSrc;
  } else if (srcType == 'url') {
    filePreviewProps['url'] = finalSrc;
  }

  return (
    <>
      {children &&
        (typeof children == 'function' ? (
          <>
            {children({
              handlePreviewOpen: handlePreviewOpen,
            })}
          </>
        ) : (
          <Box display="flex">
            <Box mr={2}>
              <Button
                variant="contained"
                size={size}
                onClick={handlePreviewOpen}
                className={`${className}`}
                style={style}
                disabled={disabled}
                {...restProps}
              >
                {children}
              </Button>
            </Box>
          </Box>
        ))}
      <CustomModal
        show={showPreview}
        onHide={handlePreviewClose}
        // scrollable={false}
        maxWidth={'lg'}
        title={
          <Box>
            Preview{' '}
            {/* {srcType == 'url' ? (
              <IconButton
                aria-label="downloadfile"
                color="primary"
                as="a"
                href={src}
                size="small"
              >
                <GetAppIcon />
              </IconButton>
            ) : null} */}
          </Box>
        }
        //   style={{ maxHeight: '100%' }}
      >
        <div style={{ minHeight: '80vh', width: '100%' }}>
          {reloading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              minHeight="80vh"
              height="100%"
              width="100%"
            >
              <CircularProgress />
            </Box>
          ) : src ? (
            <FilePreview
              type={srcType}
              onError={handlePreviewOnError}
              {...filePreviewProps}
              onReload={handleOnReload}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                minHeight: '80vh',
              }}
            >
              <div>Source Not Found</div>
            </div>
          )}
        </div>
      </CustomModal>
    </>
  );
  return (
    <Box display="flex">
      <Box mr={2}>
        <Button
          variant="contained"
          size={size}
          onClick={handlePreviewOpen}
          className={`${className}`}
          style={style}
          disabled={disabled}
          {...restProps}
        >
          {children}
        </Button>
        <CustomModal
          show={showPreview}
          onHide={handlePreviewClose}
          // scrollable={false}
          title={
            <Box>
              Preview{' '}
              {srcType == 'url' ? (
                <IconButton
                  aria-label="downloadfile"
                  color="primary"
                  as="a"
                  href={src}
                  size="small"
                >
                  <GetAppIcon />
                </IconButton>
              ) : null}
            </Box>
          }
          //   style={{ maxHeight: '100%' }}
        >
          <FilePreview
            type={srcType}
            onError={handlePreviewOnError}
            {...filePreviewProps}
          />
        </CustomModal>
      </Box>
    </Box>
  );
});

export default FileViewer;
