import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  AppBar,
  Box,
  Collapse,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListSubheader,
  Toolbar,
  ListItem,
  Typography,
  Badge,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Link,
  Grid,
  Menu,
  MenuItem,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader';

// material icons
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SettingIcon from '@material-ui/icons/ChevronRight';
import LogoutIcon from '@material-ui/icons/ChevronRight';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccount';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import PersonPinOutlinedIcon from '@material-ui/icons/PersonPinOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import AddIcon from '@material-ui/icons/Add';
import FolderSpecial from '@material-ui/icons/FolderSpecial';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import Web from '@material-ui/icons/Web';
import FindInPage from '@material-ui/icons/FindInPage';
import Attachment from '@material-ui/icons/Attachment';
import VpnKey from '@material-ui/icons/VpnKey';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import TimelineOutlinedIcon from '@material-ui/icons/TimelineOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import FolderSpecialOutlinedIcon from '@material-ui/icons/FolderSpecialOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import DevicesOutlinedIcon from '@material-ui/icons/DevicesOutlined';
import FolderSharedOutlinedIcon from '@material-ui/icons/FolderSharedOutlined';
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import VpnLockOutlinedIcon from '@material-ui/icons/VpnLockOutlined';
import LiveHelp from '@material-ui/icons/LiveHelp';

// constants/services
import ROUTES from 'src/constants/routes';
import { navGroups } from './nav';
import companySvc from 'src/services/company';
import { ConfigContext } from 'src/contexts';

// hooks
import { useProvideOAuth } from 'src/hooks/useOAuth';
import { useProvideCapTable } from 'src/hooks/useCapTable';
import useBreakpoints from 'src/hooks/useBreakpoints';

// components
import NavItem from 'src/components/NavItem';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { EnhancedSkeleton } from 'src/components/Enhanced';
import ButtonModal from 'src/components/ButtonModal';
import EnhancedTextField from 'src/components/EnhancedTextField/EnhancedTextField';

// assets
import mlogo from 'src/assets/m360.png';
import wavingHandImage from 'src/assets/notifications/waving-hand.png';
import { ReactComponent as taxIcon } from '../../assets/nav/tax.svg';
import { ReactComponent as digitalmarketingIcon } from '../../assets/nav/digitalmarketing.svg';
import { ReactComponent as kycIcon } from '../../assets/nav/kyc.svg';
import { ReactComponent as bookkeepingIcon } from '../../assets/nav/bookkeeping.svg';
import { ReactComponent as payrollIcon } from '../../assets/nav/payroll.svg';
import { ReactComponent as teachingIcon } from '../../assets/nav/teaching.svg';

import useStyles from './AuthLayout.styles';

const isStg = process.env.REACT_APP_API_URL == 'https://apistg.meyzer360.com';
const logo = isStg ? process.env.PUBLIC_URL + '/STG-logo.png' : mlogo;
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const tempCompanyList = [
  {
    company_id: 10,
    company_name: 'ABC Company',
    company_registration_no: '16888888A',
    company_role_id: 15,
    company_status_id: 2,
    company_type_id: 5,
    former_name: null,
    incorporation_date: '2016-08-08T00:00:00.000Z',
    name_effective_from: null,
    role_name: 'Administrator',
    shares: 111,
    status_date: '2016-08-08T00:00:00.000Z',
    user_id: 68,
    type: 'others',
  },
  {
    company_id: 28,
    company_name: 'MTGS Singapore Advisory Pte',
    company_registration_no: '0123456789',
    company_role_id: 15,
    company_status_id: 2,
    company_type_id: 5,
    former_name: null,
    incorporation_date: '2021-12-01T00:00:00.000Z',
    name_effective_from: null,
    role_name: 'Administrator',
    shares: 100,
    status_date: '2021-12-01T00:00:00.000Z',
    user_id: 68,
    type: 'others',
  },
  {
    company_id: 26,
    company_name: 'Tech Link Company',
    company_registration_no: '264A357B',
    company_role_id: 15,
    company_status_id: 4,
    company_type_id: 1,
    former_name: 'A Really Really Old Company Pte Ltd',
    incorporation_date: '2021-12-08T00:00:00.000Z',
    name_effective_from: '2019-03-31T00:00:00.000Z',
    role_name: 'Administrator',
    shares: '0',
    status_date: '2019-04-07T00:00:00.000Z',
    user_id: 68,
    type: 'collaboration',
  },
  {
    company_id: 15,
    company_name: 'Bitpay Company',
    company_registration_no: '246a46F',
    company_role_id: 5,
    company_status_id: 1,
    company_type_id: 1,
    former_name: 'A Really Old Company Pte Ltd',
    incorporation_date: '2021-03-31T00:00:00.000Z',
    name_effective_from: '2019-03-31T00:00:00.000Z',
    role_name: 'Director',
    shares: '0',
    status_date: '2019-03-31T00:00:00.000Z',
    user_id: 68,
    type: 'collaboration',
  },
];

const AuthLayout = ({ children, contentStyle = {} }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { smBelow } = useBreakpoints();
  const { Authorize } = useProvideOAuth();
  const {
    cartList,
    confirmationDialog,
    company,
    companyLoading,
    userLoading,
    user,
    signOut,
  } = useContext(ConfigContext);
  const {
    ListCapTableShareholders,
    capTableShareholders,
  } = useProvideCapTable();

  const notificationBtnRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(true);
  const [openList, setOpenList] = useState({
    ['LEGAL']: location.pathname.includes(ROUTES.CORPSEC),
  });
  const [createCompanyAccModal, setCreateCompanyAccModal] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([
    {
      imageSrc: '',
      description: 'Request the service of change company particulars.',
      createdAt: '10 mins ago',
    },
    {
      imageSrc: '',
      description: 'Import by creating a new post, photo or video.',
      createdAt: '12 mins ago',
    },
    {
      imageSrc: '',
      description: 'Request the service of change company particulars. ',
      createdAt: '18 mins ago',
    },
    {
      imageSrc: '',
      description: 'Request the service of change company particulars. ',
      createdAt: '18 mins ago',
    },
  ]);

  const noShareCompany = capTableShareholders?.length === 0;

  let loading = userLoading || companyLoading;

  let tempDisableLink = true;
  let tempAllowUsers = [
    'edison.tan@meyzergroup.com',
    'corpsec@meyzerbusiness.com',
  ];
  if (tempAllowUsers.indexOf(user?.email) >= 0) {
    tempDisableLink = false;
  }

  const NavItems = {
    overview: {
      selected: location.pathname === ROUTES.DASHBOARD,
      label: 'Overview',
      icon: DashboardOutlinedIcon,
      route: ROUTES.DASHBOARD,
      textVariant: 'h4',
    },
    legal: {
      selected: false,
      label: 'Secretarial',
      icon: FolderSpecialOutlinedIcon,
      onClick: () => {
        handleListClick('LEGAL');
      },
      isOpen: openList['LEGAL'],
      children: openList['LEGAL'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
      textVariant: 'h4',
    },
    data: {
      disabled: true,
      selected: false,
      label: 'Data Protection',
      icon: VerifiedUserOutlinedIcon,
      route: `${ROUTES.UPCOMING_SERVICE}?label=Data Protection`,
      isOpen: openList['DATA'],
      children: openList['DATA'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
      textVariant: 'h4',
      disabled: tempDisableLink,
    },
    kyc: {
      disabled: true,
      selected: false,
      label: 'KYC / CDD',
      icon: PersonOutlinedIcon,
      route: `${ROUTES.UPCOMING_SERVICE}?label=KYC / CDD`,
      isOpen: openList['KYC'],
      children: openList['KYC'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
      textVariant: 'h4',
      disabled: tempDisableLink,
    },
    legalDashboard: {
      selected: location.pathname === ROUTES.CORPSEC,
      label: 'Dashboard',
      route: ROUTES.CORPSEC,
    },
    legalRequest: {
      disabled: true,
      selected: location.pathname === ROUTES.CORPSEC_DASHBOARD,
      label: 'Requests',
      route: ROUTES.CORPSEC_DASHBOARD,
      disabled: tempDisableLink,
    },
    legalDocument: {
      selected: location.pathname === ROUTES.CORPSEC_DOC,
      label: 'Documents',
      route: ROUTES.CORPSEC_DOC,
    },
    legalProfile: {
      selected: location.pathname === ROUTES.CORPSEC_PRO,
      label: 'Products',
      route: ROUTES.CORPSEC,
    },
    legalTasklist: {
      selected: location.pathname === ROUTES.CORPSEC_TASK,
      label: 'Tasklist',
      route: ROUTES.CORPSEC,
    },
    legalBoardroom: {
      selected: location.pathname === ROUTES.CORPSEC_BOARD,
      label: 'Boardroom',
      route: ROUTES.CORPSEC_BOARD,
    },
    legalCapTable: {
      selected: location.pathname === ROUTES.CORPSEC_CAP,
      label: 'Cap Table',
      route: ROUTES.CORPSEC_CAP,
    },
    legalManageUserTable: {
      disabled: true,
      selected: location.pathname === ROUTES.CORPSEC_MANAGEUSER,
      label: 'Manage Users',
      route: ROUTES.CORPSEC_MANAGEUSER,
      disabled: tempDisableLink,
    },
    legalCompanyAccountSettingsTable: {
      selected: location.pathname === ROUTES.CORPSEC_COMPANY_ACCOUNT_SETTINGS,
      label: 'Company Account Settings',
      route: ROUTES.CORPSEC_COMPANY_ACCOUNT_SETTINGS,
    },
    bankAccount: {
      textVariant: 'h4',
      selected: false,
      label: 'Digital Banking',
      icon: AccountBalanceOutlinedIcon,
      onClick: () => {
        // if (
        //   user.email === 'max.goh@meyzer.com' ||
        //   user.email === 'julian.peh@meyzergroup.com' ||
        //   user.email === 'edison.tan@meyzergroup.com' ||
        //   user.email === 'meyzeraccount@meyzer360.com' ||
        //   user.email === 'meyzertest2@gmail.com'
        // ) {
        handleListClick('BANK_ACCOUNT');
        window.open(process.env.REACT_APP_BANK_URL, '_blank');
        // window.location.href = process.env.REACT_APP_BANK_URL;
        // } else {
        //   history.push(`${ROUTES.UPCOMING_SERVICE}?label=Digital Banking`);
        // }
      },
      children: openList['BANK_ACCOUNT'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
    },
    marketingWeb: {
      disabled: true,
      textVariant: 'h4',
      selected: false,
      label: 'Website Development',
      icon: DevicesOutlinedIcon,
      route: `${ROUTES.UPCOMING_SERVICE}?label=Website Development`,
      isOpen: openList['WebsiteDevelopment'],
      children: openList['WebsiteDevelopment'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
      disabled: tempDisableLink,
    },
    marketingDigital: {
      disabled: true,
      textVariant: 'h4',
      selected: false,
      label: 'Digital Marketing',
      icon: TimelineOutlinedIcon,
      route: `${ROUTES.UPCOMING_SERVICE}?label=Digital Marketing`,
      isOpen: openList['DigitalMarketing'],
      children: openList['DigitalMarketing'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
      disabled: tempDisableLink,
    },
    hcTraining: {
      disabled: true,
      textVariant: 'h4',
      selected: false,
      label: 'Training',
      icon: SchoolOutlinedIcon,
      route: `${ROUTES.UPCOMING_SERVICE}?label=Training`,
      isOpen: openList['TRAINING'],
      children: openList['TRAINING'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
      disabled: tempDisableLink,
    },
    hcPayroll: {
      disabled: true,
      textVariant: 'h4',
      selected: false,
      label: 'Payroll',
      icon: MonetizationOnOutlinedIcon,
      route: `${ROUTES.UPCOMING_SERVICE}?label=Payroll`,
      isOpen: openList['PAYROLL'],
      children: openList['PAYROLL'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
      disabled: tempDisableLink,
    },
    accountsBookkeeping: {
      textVariant: 'h4',
      selected: false,
      label: 'Book Keeping / Accounting',
      icon: bookkeepingIcon,
      route: `${ROUTES.UPCOMING_SERVICE}?label=Book Keeping / Accounting`,
      isOpen: openList['BookKeeping'],
      children: openList['BookKeeping'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
      disabled: tempDisableLink,
    },
    accountsTax: {
      textVariant: 'h4',
      selected: false,
      label: 'Tax Filing',
      icon: taxIcon,
      route: `${ROUTES.UPCOMING_SERVICE}?label=Tax Filing`,
      isOpen: openList['TaxFiling'],
      children: openList['TaxFiling'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
      disabled: tempDisableLink,
    },

    auditsEngage: {
      textVariant: 'h4',
      selected: false,
      label: 'Engage Auditor',
      icon: FolderSharedOutlinedIcon,
      route: `${ROUTES.UPCOMING_SERVICE}?label=Engage Auditor`,
      isOpen: openList['EngageAuditor'],
      children: openList['EngageAuditor'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
      disabled: tempDisableLink,
    },
    procurementOfficesupplies: {
      textVariant: 'h4',
      selected: false,
      label: 'Office Supplies',
      icon: ShoppingBasketOutlinedIcon,
      route: `${ROUTES.OFFICE_SUPPLIES}`,
      isOpen: openList['OfficeSupplies'],
      children: openList['OfficeSupplies'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
      disabled: tempDisableLink,
    },
    procurementSoftwarelicenses: {
      disabled: true,
      textVariant: 'h4',
      selected: false,
      label: 'Software Licenses',
      icon: VpnLockOutlinedIcon,
      route: `${ROUTES.UPCOMING_SERVICE}?label=Software Licenses`,
      isOpen: openList['SoftwareLicenses'],
      children: openList['SoftwareLicenses'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
      disabled: tempDisableLink,
    },
    vcfo: {
      disabled: true,
      textVariant: 'h4',
      selected: false,
      label: 'Virtual Chief Financial Officer',
      icon: PersonPinOutlinedIcon,
      onClick: () => {
        if (user.email === 'max.goh@meyzer.com') {
          handleListClick('VCFO');
          window.location.href = process.env.REACT_APP_VCFO_URL;
        } else {
          history.push(
            `${ROUTES.UPCOMING_SERVICE}?label=Virtual Chief Financial Officer`,
          );
        }
      },
      isOpen: openList['VCFO'],
      children: openList['VCFO'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
      disabled: tempDisableLink,
    },
    settings: {
      textVariant: 'h5',
      selected: false,
      label: 'Settings',
      icon: SettingIcon,
      onClick: () => {
        handleListClick('SETTINGS');
      },
      isOpen: openList['SETTINGS'],
      children: openList['SETTINGS'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
    },
    logout: {
      textVariant: 'h5',
      selected: false,
      label: 'Logout',
      icon: LogoutIcon,
      onClick: () => {
        handleListClick('LOGOUT');
      },
    },
  };

  const LegalListDefault = [NavItems.legalDashboard];

  const LegalListRole1 = [
    NavItems.legalDashboard,
    NavItems.legalRequest,
    NavItems.legalDocument,
    NavItems.legalBoardroom,
    NavItems.legalCapTable,
    NavItems.legalManageUserTable,
  ];

  const LegalListRole2 = [
    NavItems.legalDashboard,
    NavItems.legalRequest,
    NavItems.legalDocument,
    NavItems.legalBoardroom,
    NavItems.legalCapTable,
  ];

  const LegalListRole3 = [NavItems.legalDashboard, NavItems.legalRequest];

  const LegalListRole4 = [
    NavItems.legalDashboard,
    NavItems.legalDocument,
    NavItems.legalBoardroom,
    NavItems.legalCapTable,
  ];

  const handleNotificationToggle = () => {
    setShowNotifications((prevOpen) => !prevOpen);
  };

  const handleNotificationClose = (event) => {
    if (
      notificationBtnRef.current &&
      notificationBtnRef.current.contains(event.target)
    ) {
      return;
    }

    setShowNotifications(false);
  };

  // const handleListClick = (id) => {
  //   setOpenList((prevState) => ({ ...prevState, [id]: !prevState[id] }));

  const handleListClick = (id, open) => {
    setOpenList((prevState) => ({
      ...prevState,
      [id]: open ? true : !prevState[id],
    }));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = async () => {
    companySvc.removeSelectedCompany();
    // authSvc.signOut();
    await signOut();
    // history.push(`/`);
    // window.location.reload();
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (company?.company_id) {
      ListCapTableShareholders(company.company_id);
    }
  }, [company.company_id]);

  useEffect(() => {
    if (smBelow && open === true) {
      setOpen(false);
    } else if (!smBelow && open === false) {
      setOpen(true);
    }
  }, [smBelow]); // detect resizing only

  const getNavListGroupsMobile = (nav = []) => {
    let result = [];
    if (nav && nav.length > 0) {
      result = nav.map((aNavGroup, index) => {
        const { title, children = [], disabled = false } = aNavGroup;
        let navGroupItems = [];
        if (children && children.length > 0) {
          children.forEach((anItem, index2) => {
            const {
              title: itemTitle,
              icon: itemIcon,
              children: itemChildren = [],
            } = anItem;

            let DynamicIcon = itemIcon;

            navGroupItems.push(
              <ListItem
                button
                key={index2}
                // className={classes.navGroupItemContainer}
                disabled={disabled}
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {DynamicIcon ? <DynamicIcon /> : null}
              </ListItem>,
            );
          });
        }

        return navGroupItems;
      });
    }
    return <List>{result}</List>;
  };

  const renderSettingList = () => {
    return (
      <>
        {/* {!smBelow && (
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              history.push(ROUTES.ORDERHISTORY);
            }}
          >
            <FeaturedPlayListOutlinedIcon />
          </IconButton>
        )} */}

        <Button
          variant="contained"
          size="small"
          style={{
            textTransform: 'initial',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#FFFFFF',
          }}
          onClick={() => {
            history.push(ROUTES.CORPSEC_COMPANY_ACCOUNT_SETTINGS);
          }}
        >
          <AddIcon fontSize="small" /> New Account
        </Button>

        <IconButton
          className={classes.iconButton}
          onClick={() => {
            history.push(ROUTES.PAYMENTCART);
          }}
        >
          <Badge color="error" badgeContent={cartList.length}>
            <ShoppingCartOutlinedIcon />
          </Badge>
        </IconButton>

        {/* <IconButton
          ref={notificationBtnRef}
          className={classes.iconButton}
          onClick={handleNotificationToggle}
        >
          <Badge
            color="error"
            overlap="circular"
            badgeContent=" "
            variant="dot"
          >
            <NotificationsNoneRoundedIcon />
          </Badge>
        </IconButton> */}
        <Popper
          open={showNotifications}
          anchorEl={notificationBtnRef.current}
          role={undefined}
          transition
          style={{ zIndex: 100 }}
          // disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleNotificationClose}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    flexWrap="no-wrap"
                    style={{
                      // height: '330px',
                      maxHeight: '330px',
                      // width: '350px',
                      maxWidth: smBelow ? '100%' : '330px',
                      // maxWidth: '330px',
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      m={2}
                    >
                      <Box>
                        <Typography variant="body2">Notifications</Typography>
                      </Box>
                      {notifications && notifications.length > 0 && (
                        <Box fontSize="10px" color={theme.palette.primary.main}>
                          <Link
                            onClick={() => {
                              setNotifications([]);
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            Clear all
                          </Link>
                        </Box>
                      )}
                    </Box>
                    <Box flexGrow={1} overflow="auto" position="relative">
                      <NotificationsList data={notifications} />
                    </Box>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        <Box
          display="flex"
          alignItems="center"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <IconButton className={classes.iconButton}>
            <div className={clsx(classes.shape, classes.shapeCircle)} />
            {!smBelow && (
              <Box p={1} display="flex" flexDirection="column">
                <Typography
                  variant="body2"
                  align="left"
                  style={{ fontWeight: 600 }}
                >
                  {`${user?.first_name ? user?.first_name + ' ' : ''}${
                    user?.last_name ? user?.last_name : ''
                  }`}
                </Typography>
                <Typography
                  variant="caption"
                  align="left"
                  style={{ opacity: '50%' }}
                >
                  {user.email}
                </Typography>
              </Box>
            )}
          </IconButton>
        </Box>
        {/* <IconButton
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <Icon icon="ci:settings-filled" className={classes.settingLogo} />
        </IconButton> */}
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={handleClose}> */}
          <MenuItem
            onClick={() => {
              history.push(ROUTES.ORDERHISTORY);
            }}
          >
            <FeaturedPlayListOutlinedIcon />
            <Box pr={1} />
            My Orders
          </MenuItem>
          <MenuItem onClick={() => history.push(ROUTES.SETTINGS)}>
            <SettingsOutlinedIcon />
            <Box pr={1} />
            Settings
          </MenuItem>
          <MenuItem onClick={() => history.push(ROUTES.CONTACT_SUPPORT)}>
            <ContactSupportOutlinedIcon />
            <Box pr={1} />
            Contact Support
          </MenuItem>
          <MenuItem onClick={handleSignout}>
            <PowerSettingsNewOutlinedIcon />
            <Box pr={1} />
            Log out
          </MenuItem>
        </StyledMenu>
      </>
    );
  };

  const windowDrawer = (noShareCompany) => {
    let LegalList = [];
    switch (company.role_id) {
      case 1:
        LegalList = LegalListRole1;
        break;
      case 2:
        LegalList = LegalListRole2;
        break;
      case 3:
        LegalList = LegalListRole3;
        break;
      case 4:
        LegalList = LegalListRole4;
        break;
      default:
        LegalList = LegalListDefault;
        break;
    }

    if (noShareCompany) {
      LegalList = LegalList.filter((list) => list.label !== 'Cap Table');
    }

    return (
      <>
        <div className={classes.drawerLogo}>
          <Grid container spacing={0} style={{ justifyContent: 'center' }}>
            <Grid item xs={6} md={12}>
              <img
                src={logo}
                width="90%"
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(ROUTES.DASHBOARD)}
              />
            </Grid>
          </Grid>

          {!smBelow && (
            <div className={classes.toolbar}>
              <IconButton
                className={classes.toolbarToggle}
                onClick={handleDrawerClose}
              >
                {theme.direction === 'rtl' ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon className={classes.textlabel} />
                )}
              </IconButton>
            </div>
          )}
        </div>
        <div style={{ overflow: 'auto' }}>
          <Box px={2} height="100%">
            <List
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
              }}
              subheader={<li />}
            >
              <>
                <ListSubheader
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    lineHeight: '20px',
                    marginTop: '28px',
                  }}
                  disableSticky
                >
                  <Typography variant="h6">MAIN</Typography>
                </ListSubheader>
                <NavItem
                  onHideMenu={handleDrawerClose}
                  {...NavItems.overview}
                />

                <ListSubheader
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    lineHeight: '20px',
                    marginTop: '28px',
                  }}
                  disableSticky
                >
                  <Typography variant="h6">COMPLIANCE & CORPORATE</Typography>
                </ListSubheader>
                <List
                  style={{
                    marginTop: '-8px',
                  }}
                >
                  <NavItem {...NavItems.legal} />

                  <Collapse in={openList['LEGAL']}>
                    <List
                      component="div"
                      disablePadding
                      style={{ width: '100%' }}
                    >
                      {LegalList.map((item) => (
                        <NavItem
                          onHideMenu={handleDrawerClose}
                          style={{
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                          key={item.label}
                          {...item}
                        />
                      ))}
                    </List>
                  </Collapse>
                  <NavItem onHideMenu={handleDrawerClose} {...NavItems.data} />
                  <NavItem onHideMenu={handleDrawerClose} {...NavItems.kyc} />
                </List>

                <ListSubheader
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    lineHeight: '20px',
                    marginTop: '28px',
                  }}
                  disableSticky
                >
                  <Typography variant="h6">BUSINESS BANKING</Typography>
                </ListSubheader>
                <NavItem
                  onHideMenu={handleDrawerClose}
                  {...NavItems.bankAccount}
                />
                <ListSubheader
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    lineHeight: '20px',
                    marginTop: '28px',
                  }}
                  disableSticky
                >
                  <Typography variant="h6">MARKETING</Typography>
                </ListSubheader>
                <NavItem
                  onHideMenu={handleDrawerClose}
                  {...NavItems.marketingWeb}
                />
                <NavItem
                  onHideMenu={handleDrawerClose}
                  {...NavItems.marketingDigital}
                />
                <ListSubheader
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    lineHeight: '20px',
                    marginTop: '28px',
                  }}
                  disableSticky
                >
                  <Typography variant="h6">HUMAN CAPITAL</Typography>
                </ListSubheader>
                <NavItem
                  onHideMenu={handleDrawerClose}
                  {...NavItems.hcTraining}
                />
                <NavItem
                  onHideMenu={handleDrawerClose}
                  {...NavItems.hcPayroll}
                />
                <ListSubheader
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    lineHeight: '20px',
                    marginTop: '28px',
                  }}
                  disableSticky
                >
                  <Typography variant="h6">ACCOUNTS</Typography>
                </ListSubheader>
                <NavItem
                  onHideMenu={handleDrawerClose}
                  {...NavItems.accountsBookkeeping}
                />
                <NavItem
                  onHideMenu={handleDrawerClose}
                  {...NavItems.accountsTax}
                />
                <ListSubheader
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    lineHeight: '20px',
                    marginTop: '28px',
                  }}
                  disableSticky
                >
                  <Typography variant="h6">AUDITS</Typography>
                </ListSubheader>
                <NavItem
                  onHideMenu={handleDrawerClose}
                  {...NavItems.auditsEngage}
                />
                <ListSubheader
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    lineHeight: '20px',
                    marginTop: '28px',
                  }}
                  disableSticky
                >
                  <Typography variant="h6">PROCUREMENT</Typography>
                </ListSubheader>
                <NavItem
                  onHideMenu={handleDrawerClose}
                  {...NavItems.procurementOfficesupplies}
                />
                <NavItem
                  onHideMenu={handleDrawerClose}
                  {...NavItems.procurementSoftwarelicenses}
                />

                <ListSubheader
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    lineHeight: '20px',
                    marginTop: '28px',
                  }}
                  disableSticky
                >
                  <Typography variant="h6">VIRTUAL C-SUITE</Typography>
                </ListSubheader>
                <NavItem onHideMenu={handleDrawerClose} {...NavItems.vcfo} />

                <Box p={1} />
                <Divider variant="middle" light />
              </>
              <div style={{ flexGrow: 1 }} />
              <Box p={1} />
            </List>
          </Box>
        </div>
      </>
    );
  };

  const miniDrawer = () => {
    return (
      <>
        <div className={classes.drawerLogo}>
          <Box height={119} />
          <div className={classes.toolbar}>
            <IconButton
              className={classes.toolbarToggle}
              onClick={handleDrawerClose}
            >
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon className={classes.textlabel} />
              )}
            </IconButton>
          </div>
        </div>
        <div style={{ overflow: 'auto' }}>
          {getNavListGroupsMobile(navGroups)}
        </div>
      </>
    );
  };

  const CreateNewCompanyAcc = () => {
    return (
      <>
        <Divider />
        <Box p={4} pb={0}>
          <EnhancedTextField
            fullWidth
            labelText="Company Account Name"
            placeholder="Company Name"
            shrink
            variant="outlined"
          />
        </Box>
      </>
    );
  };

  if (!(user?.user_id && user?.email)) {
    return (
      <Backdrop open={true} invisible={true}>
        <CircularProgress />
      </Backdrop>
    );
  }
  return (
    // <LoadingOverlay
    //   active={loading}
    //   spinner={<ClipLoader color="white" />}
    //   styles={{
    //     wrapper: {
    //       overflow: loading ? 'hidden' : 'initial',
    //     },
    //   }}
    // >
    <>
      <ButtonModal
        isModalOpen={createCompanyAccModal}
        onCloseModal={() => setCreateCompanyAccModal(false)}
        label="Create New Company Account"
        onPress1={() => setCreateCompanyAccModal(false)}
        onPress2={() => setCreateCompanyAccModal(false)}
      >
        <CreateNewCompanyAcc />
      </ButtonModal>

      <CssBaseline />
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
          // colorPrimary="#000000"
        >
          <Toolbar style={{ justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {smBelow && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                >
                  <MenuIcon />
                </IconButton>
              )}
              {!smBelow && !open && <Box mr={'72px'} />}
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              {renderSettingList()}
            </div>
          </Toolbar>
        </AppBar>
        {!smBelow && (
          <Drawer
            variant="permanent"
            onClick={!open ? handleDrawerOpen : null}
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            {open && windowDrawer(noShareCompany)}
            {!open && miniDrawer()}
          </Drawer>
        )}

        {smBelow && (
          <Drawer
            open={open}
            onClose={!open}
            onBackdropClick={() => setOpen(false)}
          >
            {windowDrawer(noShareCompany)}
          </Drawer>
        )}
        <main
          className={classes.content}
          style={{ minWidth: 0, minHeight: 0, ...contentStyle }}
        >
          <div className={classes.toolbar} />
          <ConfirmationDialog {...confirmationDialog} />
          {children}
        </main>
      </div>
    </>
    // </LoadingOverlay>
  );
};

export default AuthLayout;

const NotificationItem = (props) => {
  const { data = {} } = props;
  const { description, createdAt } = data;
  const theme = useTheme();

  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar>{/* <ImageIcon /> */}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={<Typography variant="body2">{description}</Typography>}
          secondary={
            <span
              style={{
                fontSize: '10px',
                color: theme.palette.primary.main,
              }}
            >
              {createdAt}
            </span>
          }
        />
      </ListItem>
      <Box px={2}>
        <Divider />
      </Box>
    </>
  );
};

const NotificationsList = (props) => {
  const { data = [] } = props;
  const theme = useTheme();

  if (data && data.length > 0) {
    return (
      <List disablePadding={true}>
        {data.map((aNotification, index) => {
          return <NotificationItem key={index} data={aNotification} />;
        })}
      </List>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      p={3}
      textAlign="center"
    >
      <Box
        flexGrow="1"
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={2}
      >
        <Avatar
          style={{
            width: '100px',
            height: '100px',
            padding: theme.spacing(3),
            backgroundColor: theme.palette.secondary.main,
          }}
        >
          <img
            src={wavingHandImage}
            style={{ width: '100%', height: '100%' }}
          />
        </Avatar>
      </Box>
      <Box>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          You have no notifications!
        </Typography>
      </Box>
      <Box maxWidth="300px">
        <Typography variant="body2">
          If you will get any notifications we will tell you about it.
        </Typography>
      </Box>
    </Box>
  );
};
