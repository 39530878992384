import React from 'react';
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
  Box,
  Divider,
} from '@material-ui/core';
import { Field } from 'formik';
import useBreakpoints from 'src/hooks/useBreakpoints';

function FormRadioGroupDetails({ name, label, options = [], ...props }) {
  const { smBelow } = useBreakpoints();

  return (
    <Field name={name}>
      {({ field, meta }) => {
        return (
          <FormControl
            error={meta.touched && !!meta.error}
            style={{ width: '100%' }}
          >
            <RadioGroup {...props}>
              {options.map((opt, index) => (
                <>
                  <Box p={2} pl={5} display="flex" alignItems="center">
                    <FormControlLabel
                      key={opt.value}
                      value={opt.value}
                      control={<Radio color="primary" />}
                      label={opt.label}
                      style={{ width: smBelow ? '30%' : '20%' }}
                    />
                    <Typography
                      variant="body1"
                      style={{ width: smBelow ? '70%' : '80%' }}
                    >
                      {opt.desc}
                    </Typography>
                  </Box>
                  {options.length - 1 !== index && <Divider />}
                </>
              ))}
            </RadioGroup>
            {meta.touched && !!meta.error && (
              <Box pb={2} pl={6}>
                <FormHelperText>{meta.error}</FormHelperText>
              </Box>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
}

export default FormRadioGroupDetails;
