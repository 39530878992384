import React, { useEffect, useState, useContext } from 'react';
import ImageTitleList from '../components/ImageTitleList/ImageTitleList';
import { AllServices, Promotions, QuickLinks } from '../constants/list';
import Greeting from '../components/Greeting/Greeting';
import IconListBox from '../components/IconListBox/IconListBox';
import CardSlider from '../components/CardSlider/CardSlider';
import AuthLayout from 'src/components/AuthLayout';
import {
  makeStyles,
  Button,
  Box,
  Avatar,
  Paper,
  Typography,
  Divider,
  ListItem,
  Container,
  ButtonBase,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import InfoBox from 'src/components/InfoBox';
import useBreakpoints from 'src/hooks/useBreakpoints';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import webBuilderImg from 'src/assets/overview/overview-banner-laptop.png';
import webBuilderImgMobile from 'src/assets/overview/overview-banner-mobile1.png';
import DescriptionBox from 'src/components/DescriptionBox';
import ROUTES from 'src/constants/routes';
import { ConfigContext } from 'src/contexts';
import supportLogo from 'src/assets/supportLogo.png';
import moment from 'moment';
import { useProvideOrder } from 'src/hooks/useOrder';
import { EnhancedSkeleton } from 'src/components/Enhanced';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  paper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 20,
    borderRadius: 10,
    backgroundColor: '#E3EDFD',
  },
  avatar1: {
    backgroundColor: '#FFFFFF',
    color: '#A21D23',
    height: 70,
    width: 70,
  },
  startButton: {
    backgroundColor: 'white',
    color: '#A21D23',
    textTransform: 'initial',
    width: 150,
    padding: 10,
  },
  recentTransactionsPaper: {
    borderRadius: 10,
    width: '100%',
  },
  reminderPaper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    height: '100%',
    width: '49%',
  },
  reminderAvatar: {
    backgroundColor: '#ACD4FC',
    color: '#143350',
    height: 50,
    width: 50,
  },
  noTransactionAvatar: {
    backgroundColor: '#E4ECF7',
    color: '#000000',
    height: 70,
    width: 70,
  },
  bgroot: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    height: '80vh',
    minHeight: 500,
    maxHeight: 1300,
  },
  bgbox: {
    marginTop: theme.spacing(3),
    display: 'flex',
  },
  containerRoot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const TempRecentTransactions = [
  {
    id: '75',
    name: 'Register of Directors and CEO',
    date: '23/10/2021',
    price: 100,
  },
  {
    id: '76',
    name: 'Register of Directors and CEO Shareholdings...',
    date: '23/07/2021',
    price: 100,
  },
  {
    id: '77',
    name: 'Register of Managers, Secretaries and Auditors',
    date: '23/05/2021',
    price: 100,
  },
  {
    id: '78',
    name: 'Register of Mortgages and Charges',
    date: '23/05/2021',
    price: 50,
  },
];

const renderItemNames = (items = []) => {
  const names = items.map((item) => item?.product.name) || [];
  return names.join(', ');
};

const Dashboard = () => {
  const history = useHistory();
  const { smBelow, mdBelow } = useBreakpoints();
  const classes = useStyles();
  const { ListOrders, orders, orderLoading } = useProvideOrder();

  const { companyList, user } = useContext(ConfigContext);
  const sliceOrders = orders.slice(0, 3);

  useEffect(() => {
    if (user && user.user_id) {
      ListOrders(user.user_id);
    }
  }, [user]);

  const renderRecentTransactions = () => {
    return (
      <Paper
        elevation={0}
        variant="outlined"
        className={classes.recentTransactionsPaper}
      >
        <Box p={4}>
          <Typography variant="h2">Recent Transactions</Typography>
        </Box>
        <Box p={3} px={2} style={{ backgroundColor: '#F7F7F7' }}>
          <ListItem>
            <Box display="flex" style={{ width: '100%' }}>
              <Typography style={{ flex: '2 2 0%' }} variant="h4">
                ORDER ID
              </Typography>
              <Box p={1} />
              <Typography style={{ flex: '6 6 0%' }} variant="h4">
                ITEM/SERVICE
              </Typography>
              <Box p={1} />
              <Typography style={{ flex: '2 2 0%' }} variant="h4">
                DATE
              </Typography>
              {!mdBelow && (
                <Typography style={{ flex: '1 1 0%' }} variant="h4">
                  TOTAL
                </Typography>
              )}
            </Box>
          </ListItem>
        </Box>

        {orderLoading ? (
          <div
            style={{
              width: '100%',
            }}
          >
            <EnhancedSkeleton
              layout={[
                {
                  loaders: [{ variant: 'rect', height: 40, width: '100%' }],
                  repeat: 3,
                  mt: 4.25,
                },
              ]}
            />
          </div>
        ) : (
          <>
            {sliceOrders.length > 0 ? (
              <>
                {sliceOrders.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <Box p={3} px={2}>
                      <ListItem>
                        <Box display="flex" style={{ width: '100%' }}>
                          <Typography style={{ flex: '2 2 0%' }} variant="h4">
                            {item.id}
                          </Typography>
                          <Box p={1} />
                          <Typography style={{ flex: '6 6  0%' }} variant="h4">
                            {renderItemNames(item.items)}
                          </Typography>
                          <Box p={1} />
                          <Typography style={{ flex: '2 2 0%' }} variant="h4">
                            {moment(item.created_at).format(`DD/MM/yyyy HH:mm`)}
                          </Typography>
                          {!mdBelow && (
                            <Typography style={{ flex: '1 1 0%' }} variant="h4">
                              S$ {item.totalPrice}
                            </Typography>
                          )}
                        </Box>
                      </ListItem>
                    </Box>
                    <Divider />
                  </React.Fragment>
                ))}
              </>
            ) : (
              <Box
                display="flex"
                width="100%"
                flexDirection="column"
                alignItems="center"
                p={10}
              >
                <Avatar className={classes.noTransactionAvatar}>
                  <InboxOutlinedIcon fontSize="large" />
                </Avatar>
                <Box p={1} />
                <Typography align="center" variant="body1">
                  No data
                </Typography>
              </Box>
            )}
          </>
        )}

        <ButtonBase
          style={{
            width: '100%',
            backgroundColor: '#FAFBFC',
          }}
          onClick={() => {
            history.push(ROUTES.ORDERHISTORY);
          }}
        >
          <Box
            p={4}
            color="#A21D23"
            style={{
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <Typography align="center" variant="h2">
              View All Transactions
            </Typography>
          </Box>
        </ButtonBase>
      </Paper>
    );
  };
  return (
    // <AuthLayout>
    <Box padding={4}>
      <Container
        maxWidth="xl"
        classes={{
          root: classes.containerRoot,
        }}
      >
        <Greeting
          title={`${
            user.first_name
              ? user.first_name + ' ' + user.last_name
              : user.email
          }`}
        />
        <Paper className={classes.paper}>
          <>
            <Box pb={mdBelow ? 7 : 0} pl={mdBelow ? 0 : 3}>
              <Avatar className={classes.avatar1}>
                <AssignmentIcon fontSize="large" />
              </Avatar>
            </Box>
            <Box pl={2} display="flex" flexDirection="column">
              <Typography variant="h5">Welcome to Meyzer360</Typography>
              <Typography variant="h6" style={{ color: '#84818A' }}>
                Let’s start by setting up your Corporate Secretarial requests.
              </Typography>
              {mdBelow && (
                <>
                  <Box p={1} />
                  <Paper style={{ width: 150 }}>
                    <Button
                      className={classes.startButton}
                      onClick={() => {
                        history.push(ROUTES.CORPSEC);
                      }}
                    >
                      Get Started
                    </Button>
                  </Paper>
                </>
              )}
            </Box>
          </>
          {!mdBelow && (
            <>
              <div style={{ flexGrow: 1 }} />
              <Paper>
                <Button
                  className={classes.startButton}
                  onClick={() => history.push(ROUTES.CORPSEC)}
                >
                  Get Started
                </Button>
              </Paper>
              <Box pr={5} />
            </>
          )}
        </Paper>

        <Box p={2} />

        {/* <InfoBox vertical={mdBelow ? true : false} />
          <Box p={2} /> */}

        <Box display="flex" width="100%">
          {renderRecentTransactions()}

          {!mdBelow && (
            <>
              <Box p={1} />

              <Paper className={classes.reminderPaper}>
                <Box p={4}>
                  <Typography variant="h2" align="left">
                    {companyList.length > 0 ? 'Reminders' : 'Support'}
                  </Typography>
                </Box>
                <Box
                  p={3}
                  style={{ backgroundColor: '#E3EDFD', color: '#2E2C34' }}
                >
                  <Box display="flex" width="100%">
                    {companyList.length > 0 ? (
                      <Avatar className={classes.reminderAvatar}>
                        <PersonOutlineIcon fontSize="large" />
                      </Avatar>
                    ) : (
                      <img src={supportLogo} height={50} width={50} />
                    )}
                    <div style={{ flexGrow: 1 }} />
                    <ButtonBase
                      onClick={() => {}}
                      style={{ paddingBottom: 30 }}
                    >
                      <CloseIcon style={{ color: '#84818A' }} />
                    </ButtonBase>
                  </Box>
                  <Box pr={5} />
                  <Box p={1} display="flex" flexDirection="column">
                    {companyList.length > 0 ? (
                      <>
                        <Typography variant="h5">
                          Update your profile
                        </Typography>
                        <Typography variant="body1">
                          Secure your account with 2-step verification
                        </Typography>
                        <Box p={1} />
                        <ButtonBase
                          onClick={() =>
                            history.push(ROUTES.SETTINGS, {
                              tab: 1,
                            })
                          }
                          style={{
                            justifyContent: 'flex-start',
                            color: '#143350',
                          }}
                        >
                          <Typography variant="h5">
                            Enable 2-step verification
                          </Typography>
                          <ChevronRightIcon />
                        </ButtonBase>
                      </>
                    ) : (
                      <>
                        <Typography variant="h5">
                          Have any questions?
                        </Typography>
                        <Typography variant="h5">
                          We are here to help.
                        </Typography>
                        <Box p={1} />
                        <Box display="flex" style={{ color: '#143350' }}>
                          <ButtonBase
                            onClick={() => history.push(ROUTES.CONTACT_SUPPORT)}
                          >
                            <Typography variant="h5">Contact Us</Typography>
                            <ChevronRightIcon />
                          </ButtonBase>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Paper>
            </>
          )}
        </Box>

        <Box p={2} />
        <DescriptionBox img={mdBelow ? webBuilderImgMobile : webBuilderImg} />

        {/* <CardSlider itemData={Promotions} overlay />
        <IconListBox
          title="Quick Links"
          itemData={QuickLinks}
          styles={{ paddingTop: 50 }}
        />
        <ImageTitleList
          title="All Services"
          itemData={AllServices}
          overlay
          styles={{ paddingTop: 50, paddingBottom: 30 }}
          onClick={(item) => {
            if (item.route) {
              history.push(item.route);
            }
          }}
        /> */}
      </Container>
    </Box>
    // </AuthLayout>
  );
};

export default Dashboard;
