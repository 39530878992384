import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
    marginBottom: '15px',
  },
  modalPaper: {
    boxShadow: '#191919',
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
  },
  fontWeight: {
    fontWeight: 600,
  },
}));
