import React, { useState, useEffect, useContext } from 'react';
import { Typography, Box, Grid, Button, Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import PaymentIcon from '@material-ui/icons/Payment';

import { ConfigContext } from 'src/contexts';
import { sgdFormat } from 'src/utils/currency';
import ImageContainer from 'src/components/ImageContainer';

const CartSummary = (props) => {
  const {
    onSubmit,
    label,
    buttonProps = {},
    isCheckout = false,
    footer,
  } = props;
  const theme = useTheme();
  const { cartList, cart, removeCart, webInfo, user, fetchCart } = useContext(
    ConfigContext,
  );

  let isEmptyCart = cartList?.length > 0 ? false : true;
  let subtotal = 0;
  cartList?.forEach((anItem) => {
    subtotal += anItem.price || 0;
  });

  let summary = {
    total: cart?.totalPrice,
    details: [
      {
        label: 'Subtotal',
        value: subtotal,
      },
      //   {
      //     label: 'Discount 10%',
      //     value: null,
      //   },
      {
        label: 'Shipping Fee',
        value: null,
      },
      {
        label: 'Tax 6%',
        value: null,
      },
    ],
  };

  const handleOnSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <Box p={2} border={1} borderColor={theme.palette.grey[300]}>
      <Box mb={2}>
        <Typography variant="h3">Order Summary</Typography>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Box pb={2}>
            <Divider />
          </Box>
        </Grid>
        {isCheckout && cartList?.length > 0 ? (
          <>
            {cartList.map((anItem, index) => {
              return (
                <Grid container item key={index} xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    pt={index == 0 ? 0 : 1}
                  >
                    <Box width="50px" minWidth="50px" maxWidth="50px" mr={2}>
                      <ImageContainer
                        ratio="100%"
                        src={'/sample-office-chair.png'}
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexGrow={1}
                      flexWrap="wrap"
                    >
                      <Box flexGrow={1} pr={1}>
                        {anItem?.product?.name}{' '}
                        {anItem?.product?.product_category_id != 1
                          ? `x${anItem?.quantity}`
                          : null}
                      </Box>
                      <Box textAlign="right">{sgdFormat(anItem?.price)}</Box>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Box py={2}>
                <Divider />
              </Box>
            </Grid>
          </>
        ) : null}
        {summary.details.map((anItem, index) => {
          return (
            <React.Fragment key={index}>
              <Grid
                container
                item
                xs={12}
                style={{ paddingBottom: theme.spacing(1) }}
              >
                <Grid item xs={7}>
                  <Typography
                    variant="h4"
                    style={{
                      color: theme.palette.grey[500],
                      fontWeight: 'normal',
                    }}
                  >
                    {anItem.label}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Box textAlign="right">
                    <Typography
                      variant="h4"
                      style={{
                        color: anItem.value < 0 ? 'red' : 'black',
                        fontWeight: 'normal',
                      }}
                    >
                      {anItem?.value
                        ? `${sgdFormat(anItem.value)}`
                        : sgdFormat(0)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
      <Box>
        <Divider />
      </Box>
      <Box py={2} display="flex" justifyContent="space-between">
        <Typography variant="h2">
          <span>Total</span>
        </Typography>
        <Box>
          <Typography variant="h2">
            {isEmptyCart
              ? '-'
              : summary?.total
              ? `${sgdFormat(summary.total)}`
              : sgdFormat(0)}
          </Typography>
        </Box>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      {footer}
      <Button
        variant="contained"
        color="primary"
        fullWidth
        disableElevation={true}
        style={{ borderRadius: 0, textTransform: 'none' }}
        // startIcon={<PaymentIcon />}
        onClick={handleOnSubmit}
        disabled={isEmptyCart}
        {...buttonProps}
      >
        {label || 'Proceed to Checkout'}
      </Button>
    </Box>
  );
};

export default CartSummary;
