import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import CustomModal from '../../components/CustomModal';
import MembershipPlanCard from './MembershipPlanCard';
import MembershipForm from './MembershipForm';
import useQueryParams from 'src/hooks/useQueryParams';

const samplePlans = [
  {
    image: '/membership-plan-starter.png',
    name: 'Starter',
    description: 'If you are a small business man, please select this plan',
    price: 99,
    note: '1 user, Billed Yearly',
    features: ['f1', 'f2'],
  },
  {
    image: '/membership-plan-standard.png',
    name: 'Standard',
    description: 'If you are a small business man, please select this plan',
    price: 999,
    note: '1 user, Billed Yearly',
    features: ['f1', 'f2', 'f3'],
  },
  {
    image: '/membership-plan-premium.png',
    name: 'Premium',
    description: 'If you are a small business man, please select this plan',
    price: 9999,
    note: '1 user, Billed Yearly',
    features: ['f1', 'f2', 'f3', 'f4', 'f5'],
  },
];

const defaultPlanForm = {
  show: false,
  data: null,
};
const MembershipUpgrade = (props) => {
  const {} = props;
  const theme = useTheme();

  const queryParams = useQueryParams();
  const routeHistory = useHistory();

  const [planForm, setPlanForm] = useState(defaultPlanForm);

  const handlePlanFormClose = () => {
    setPlanForm(defaultPlanForm);
  };
  const handleOnClickPlan = (data = null) => {
    setPlanForm({
      show: true,
      data: data,
    });
  };

  const handleOnSubmitCheckout = (values) => {
    queryParams.set('checkout', 'success');
    routeHistory.push({
      pathname: '/settings',
      search: '?' + queryParams.toString(),
    });
    handlePlanFormClose();
  };

  return (
    <>
      <Grid container spacing={3}>
        {samplePlans.map((anItem, index) => {
          return (
            <Grid item key={index} xs={12} sm={4}>
              <MembershipPlanCard
                data={anItem}
                onClick={() => {
                  handleOnClickPlan(anItem);
                }}
                active={index == 0}
              />
            </Grid>
          );
        })}
      </Grid>
      <CustomModal
        title="Upgrade Plan to"
        show={planForm.show}
        onHide={handlePlanFormClose}
      >
        <MembershipForm
          plan={planForm.data}
          onSubmit={handleOnSubmitCheckout}
        />
      </CustomModal>
    </>
  );
};

export default MembershipUpgrade;
