import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Divider,
  Button,
  Container,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Avatar,
  Link,
  Card,
  CardContent,
} from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import { Formik, Form, Field } from 'formik';
import yellow from '@material-ui/core/colors/yellow';
import cyan from '@material-ui/core/colors/cyan';
import clsx from 'clsx';
import * as Yup from 'yup';

import useBreakpoints from 'src/hooks/useBreakpoints';
import commonSvc from '../../services/common';
import ProgressLoader from '../Progress';
import StepsDisplay from './StepsDisplay';
import Layout from './Layout';
import {
  numberOfEmployees,
  annualRevenues,
  totalMonthlyCardSpends,
  entityCategories,
  industries,
} from '../../constants/onboarding';

// fields
import FileDropzone from 'src/components/FileDropzone';
import FaceDetectContainer from './FaceAPI/FaceDetectContainer';

import FormRadioGroupField2 from './Fields/RadioGroupField2';
import FieldContainer from './Fields/FieldContainer';

import CustomTextField from '../CustomFormFields/CustomTextField';
import CustomSelectField from '../CustomFormFields/CustomSelectField';
import CustomRadioGroupField from '../CustomFormFields/CustomRadioGroupField';
import CustomPinField from '../CustomFormFields/CustomPinField';

// media
import image_director from '../../assets/onboarding/director.png';
import image_employee from '../../assets/onboarding/employee.png';
import image_freelancer from '../../assets/onboarding/freelancer.png';
import kycIcon from '../../assets/onboarding/kycIcon.png';
import image_DocumentFront from '../../assets/onboarding/sampleDocumentFront.png';
import image_DocumentBack from '../../assets/onboarding/sampleDocumentBack.png';
import image_docIllustration from '../../assets/onboarding/docIllustration.png';
import image_avatar from '../../assets/onboarding/avatar.png';
import image_scanFace from '../../assets/onboarding/scan-face.png';

const fieldNames = {
  fieldName_position: 'position',
  fieldName_checkbox1: 'checkbox1',

  fieldName_registrationCountry: 'registrationCountry',
  fieldName_solution: 'solution',

  fieldName_email: 'email',
  fieldName_gender: 'gender',
  fieldName_dateOfBirth: 'dateOfBirth',
  fieldName_countryOfBirth: 'countryOfBirth',
  fieldName_nationality: 'nationality',

  fieldName_businessLegalName: 'businessLegalName',
  fieldName_entityCategory: 'entityCategory',
  fieldName_entityType: 'entityType',
  fieldName_businessNumber: 'businessNumber',
  fieldName_natureOfIndustry: 'natureOfIndustry',
  fieldName_natureOfSubIndustry: 'natureOfSubIndustry',

  fieldName_businessActivity: 'businessActivity',
  fieldName_productDescription: 'productDescription',
  fieldName_companyWebsite: 'companyWebsite',
  fieldName_numberOfEmployee: 'numberOfEmployee',
  fieldName_annualTurnover: 'annualTurnover',

  fieldName_addressUnit: 'addressUnit',
  fieldName_addressLine1: 'addressLine1',
  fieldName_addressLine2: 'addressLine2',
  fieldName_addressLine3: 'addressLine3',
  fieldName_postcode: 'postcode',
  fieldName_addressCountry: 'addressCountry',

  fieldName_businessFiles: 'businessFiles',

  fieldName_fileCategory: 'fileCategory',
  fieldName_documentFront: 'documentFront',
  fieldName_documentBack: 'documentBack',
  fieldName_checkbox2: 'checkbox2',
  fieldName_checkbox3: 'checkbox3',

  // fieldName_: '',
};
const {
  fieldName_position,
  fieldName_checkbox1,
  fieldName_registrationCountry,
  fieldName_solution,
  fieldName_email,
  fieldName_gender,
  fieldName_dateOfBirth,
  fieldName_countryOfBirth,
  fieldName_nationality,
  fieldName_businessLegalName,
  fieldName_entityCategory,
  fieldName_entityType,
  fieldName_businessNumber,
  fieldName_natureOfIndustry,
  fieldName_natureOfSubIndustry,
  fieldName_businessActivity,
  fieldName_productDescription,
  fieldName_companyWebsite,
  fieldName_numberOfEmployee,
  fieldName_annualTurnover,
  fieldName_addressUnit,
  fieldName_addressLine1,
  fieldName_addressLine2,
  fieldName_addressLine3,
  fieldName_postcode,
  fieldName_addressCountry,
  fieldName_businessFiles,
  fieldName_fileCategory,
  fieldName_documentFront,
  fieldName_documentBack,
  fieldName_checkbox2,
  fieldName_checkbox3,
} = fieldNames;

const positionOptions = [
  {
    label: 'I am a registered director of the company',
    value: 1,
    image: image_director,
  },
  {
    label: 'I am not a registered director or an employee',
    value: 2,
    image: image_employee,
  },
  {
    label: 'I am a freelancer',
    value: 3,
    image: image_freelancer,
  },
];

const setFormValueLocalStorage = (values) => {
  localStorage.setItem('onboarding', JSON.stringify(values));
};
const getFormValueLocalStorage = () => {
  return JSON.parse(localStorage.getItem('onboarding')) || {};
};

const useCustomCommonAPI = (api, props) => {
  const { respondKey, labelKey, valueKey } = props;

  const [value, setValue] = useState({
    options: [],
    rawData: [],
  });

  useEffect(() => {
    (async () => {
      const apiRes = await api();
      let options = [];
      if (apiRes && apiRes?.[respondKey]) {
        apiRes[respondKey].map((aData) => {
          options.push({
            label: aData[labelKey],
            value: aData[valueKey],
          });
        });
        setValue((prev) => {
          return {
            ...prev,
            options: options,
            rawData: apiRes[respondKey],
          };
        });
      }
    })();
  }, []);

  return {
    ...value,
  };
};

const Content = ({ stepTitle, contentTitle, hideHeader = false, children }) => {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      {!hideHeader && (
        <>
          {/* Header */}
          <Box textAlign="center" py={3} px={2}>
            <Box>
              <Typography variant="caption">{stepTitle}</Typography>
            </Box>
            <Box>
              <Typography variant="h4" style={{ fontWeight: '700' }}>
                {contentTitle}
              </Typography>
            </Box>
          </Box>
          {stepTitle || contentTitle ? <Divider /> : null}
        </>
      )}
      {/* Content */}
      <Box flexGrow={1} py={2}>
        {children}
      </Box>
    </Box>
  );
};

const Form1 = (props) => {
  const theme = useTheme();
  return (
    <>
      <Field name={fieldName_position}>
        {({
          field, // { name, value, onChange, onBlur }
          form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => {
          const { name, value, onChange } = field;
          const { touched, errors, setFieldValue } = form;

          return (
            <Box>
              {positionOptions.map((aPosition, index) => {
                let isActive = aPosition.value == value;
                let activeProps = isActive
                  ? {
                      borderColor: theme.palette.primary.main,
                    }
                  : {
                      borderColor: theme.palette.grey['300'],
                    };
                return (
                  <Box
                    key={index}
                    mb={2}
                    border={1}
                    p={2}
                    display="flex"
                    alignItems="center"
                    {...activeProps}
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      setFieldValue(name, aPosition.value);
                    }}
                  >
                    <Box pr={3}>
                      <img src={aPosition.image} height="60" width="60" />
                    </Box>
                    <Box flexGrow={1} fontWeight="600">
                      {aPosition.label}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          );
        }}
      </Field>
      <Field name={fieldName_checkbox1}>
        {({ field, form, meta }) => {
          const { value } = field;
          return (
            <FormControlLabel
              control={<Checkbox {...field} checked={value} color="primary" />}
              label={
                <Typography variant="body2">
                  I confirm that I will be the{' '}
                  <b>Administrator of the Business Account</b> and I am
                  authorised to fill out this form on behalf of the business.
                </Typography>
              }
            />
          );
        }}
      </Field>
    </>
  );
};

const Form2 = (props) => {
  const { commonData = {} } = props;
  const { countries = [], applicationTypes = [] } = commonData;
  const { smBelow } = useBreakpoints();

  return (
    <Box maxWidth="100%" width={smBelow ? '100%' : '65%'}>
      <FieldContainer label="Where is your company registered?" mb={2}>
        <CustomSelectField
          name={fieldName_registrationCountry}
          options={countries}
          placeholder="Select a country"
        />
      </FieldContainer>
      <FieldContainer label="What solution are you looking for?" mb={2}>
        <CustomSelectField
          name={fieldName_solution}
          options={applicationTypes}
          placeholder="Select application options"
        />
      </FieldContainer>
    </Box>
  );
};

const Form3 = (props) => {
  const { commonData = {} } = props;
  const { countries = [], genders = [] } = commonData;
  const { smBelow } = useBreakpoints();

  return (
    <Box maxWidth="100%" width={smBelow ? '100%' : '65%'}>
      <FieldContainer label="Email Address" mb={2}>
        <CustomTextField name={fieldName_email} type="email" />
      </FieldContainer>

      <FieldContainer label="Gender" mb={2}>
        <CustomRadioGroupField name={fieldName_gender} options={genders} />
      </FieldContainer>

      <FieldContainer label="Date of birth" mb={2}>
        <CustomTextField name={fieldName_dateOfBirth} type="date" />
      </FieldContainer>

      <FieldContainer label="Country of birth" mb={2}>
        <CustomSelectField
          name={fieldName_countryOfBirth}
          options={countries}
          placeholder="Select a country"
        />
      </FieldContainer>

      <FieldContainer label="Nationality" mb={2}>
        <CustomTextField name={fieldName_nationality} />
      </FieldContainer>
    </Box>
  );
};

const Form4 = (props) => {
  const { values } = props;
  const { smBelow } = useBreakpoints();

  const [pinValue, setPinValue] = useState();

  const handleVerifyPin = () => {
    console.log('verify pin');
  };
  const handleResendPin = () => {
    console.log('resend pin');
  };

  return (
    <Card
      variant="outlined"
      style={{
        borderRadius: 16,
        border: smBelow ? 'none' : '',
        width: smBelow ? '100%' : '400px',
        maxWidth: '100%',
      }}
    >
      <CardContent>
        <Box fontWeight="700" fontSize="30px" textAlign="center">
          2-step verification
        </Box>
        <Box
          mb={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          // width={smBelow ? 'auto' : '275px'}
          width="100%"
        >
          <Box maxWidth="275px" width={'100%'}>
            <Typography color="textSecondary" variant="body2">
              Please enter the 6-digit verification code we sent to your email
              address <span style={{ color: '#000' }}>{values?.email}</span>
            </Typography>
          </Box>
        </Box>
        <Box mb={2}>
          <CustomPinField value={pinValue} onChange={setPinValue} />
        </Box>

        <Button
          variant="outlined"
          color="primary"
          // disabled={!(props.isValid && props.dirty)}
          // variant="contained"
          onClick={handleVerifyPin}
          style={{
            textTransform: 'initial',
            fontWeight: 700,
          }}
          size="large"
          fullWidth={true}
          disableElevation
        >
          Verify
        </Button>
        {/* <Box display="flex" justifyContent="center" mt={loading ? 2 : 0}>
            <ProgressLoader loading={loading} />
          </Box> */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={3}
        >
          <Box display="flex">
            <Typography color="textSecondary" variant="body2">
              {`Didn\’t receive a code? `}
              <span style={{ cursor: 'pointer' }} onClick={handleResendPin}>
                Resend
              </span>
            </Typography>
            &nbsp;
            <Typography
              color="initial"
              variant="body2"
              style={{ fontWeight: 'bold' }}
            >
              (01:23s)
            </Typography>
          </Box>
          <Box>
            <Typography color="textSecondary" variant="body2">
              Need more help?{' '}
              <Link href="mailto:support@meyzer360.com" color="primary">
                Contact us
              </Link>
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const Form5 = (props) => {
  const { commonData = {} } = props;
  const {
    entityCategories = [],
    entityTypes = [],
    industries = [],
    subIndustries = [],
  } = commonData;
  const { smBelow } = useBreakpoints();

  return (
    <Box maxWidth="100%" width={smBelow ? '100%' : '65%'}>
      <FieldContainer label="Business Legal Name" mb={2}>
        <CustomTextField
          name={fieldName_businessLegalName}
          placeholder="Enter company name"
        />
      </FieldContainer>

      <FieldContainer label="Entity Category" mb={2}>
        <CustomSelectField
          name={fieldName_entityCategory}
          options={entityCategories}
          placeholder="Select business registration type"
        />
      </FieldContainer>
      <FieldContainer label="Entity Type" mb={2}>
        <CustomSelectField
          name={fieldName_entityType}
          options={entityTypes}
          placeholder="Select an entity type"
        />
      </FieldContainer>
      <FieldContainer label="Business Reg. number (UEN)" mb={2}>
        <CustomTextField
          name={fieldName_businessNumber}
          placeholder="Enter business Reg. number"
        />
      </FieldContainer>

      <FieldContainer label="Nature of Industry" mb={2}>
        <CustomSelectField
          name={fieldName_natureOfIndustry}
          options={industries}
          placeholder="Select any of following"
        />
      </FieldContainer>
      <FieldContainer label="Nature of Sub-Industry" mb={2}>
        <CustomSelectField
          name={fieldName_natureOfSubIndustry}
          options={subIndustries}
          placeholder="Select any of following"
        />
      </FieldContainer>
    </Box>
  );
};

const Form6 = (props) => {
  const { commonData = {} } = props;
  const { numberOfEmployees = [], annualRevenues = [] } = commonData;
  const { smBelow } = useBreakpoints();

  return (
    <Box maxWidth="100%" width={smBelow ? '100%' : '65%'}>
      <FieldContainer label="Business Activity" mb={2}>
        <CustomTextField name={fieldName_businessActivity} />
      </FieldContainer>
      <FieldContainer label="Product/Service Description" mb={2}>
        <CustomTextField
          name={fieldName_productDescription}
          placeholder="e.g. We provide IT for companies."
          multiline
          rows={4}
        />
      </FieldContainer>
      <FieldContainer label="Company website (optional)" mb={2}>
        <CustomTextField
          name={fieldName_companyWebsite}
          placeholder="Enter website url"
        />
      </FieldContainer>

      <FieldContainer label="Number of Employee" mb={2}>
        <CustomSelectField
          name={fieldName_numberOfEmployee}
          options={numberOfEmployees}
          placeholder="Select..."
        />
      </FieldContainer>
      <FieldContainer label="Annual Turnover" mb={2}>
        <CustomSelectField
          name={fieldName_annualTurnover}
          options={annualRevenues}
          placeholder="Select..."
        />
      </FieldContainer>
    </Box>
  );
};

const Form7 = (props) => {
  const { commonData = {} } = props;
  const { countries = [] } = commonData;
  const { smBelow } = useBreakpoints();

  return (
    <Box maxWidth="100%" width={smBelow ? '100%' : '65%'}>
      <FieldContainer label="Floor/Unit No" mb={2}>
        <CustomTextField name={fieldName_addressUnit} />
      </FieldContainer>
      <FieldContainer label="Address Line 1" mb={2}>
        <CustomTextField name={fieldName_addressLine1} />
      </FieldContainer>
      <FieldContainer label="Address Line 2" mb={2}>
        <CustomTextField name={fieldName_addressLine2} />
      </FieldContainer>
      <FieldContainer label="Address Line 3 (Optional)" mb={2}>
        <CustomTextField name={fieldName_addressLine3} />
      </FieldContainer>
      <FieldContainer label="Postcode" mb={2}>
        <CustomTextField name={fieldName_postcode} />
      </FieldContainer>
      <FieldContainer label="Country" mb={2}>
        <CustomSelectField
          name={fieldName_addressCountry}
          options={countries}
          placeholder="Select a country"
        />
      </FieldContainer>
    </Box>
  );
};

const Form8 = (props) => {
  return (
    <>
      <Box
        border={2}
        borderRadius={5}
        style={{ backgroundColor: yellow['100'], borderColor: yellow['500'] }}
        mb={3}
        p={3}
      >
        <Box fontWeight="bold">
          Please provide all the following documents for verification
        </Box>
        <Box>
          <ul>
            <li>
              Passport or National ID of other directors/partners listed in ACRA
              Bizfile
            </li>
            <li>
              Passport or National ID of shareholders with at least 10% shares
            </li>
            <li>Constitution, or Articles of Association</li>
            <li>
              Signed board resolution letter authorizing you to open a bank
              ccount for your company.
            </li>
          </ul>
        </Box>
      </Box>
      <Box>
        <Box>Upload applicable documents</Box>
        <Box>
          <Typography variant="caption">
            You can upload multiple files in one go. If you miss anything, we
            might ask for it later.
          </Typography>
        </Box>
        <Field name={fieldName_businessFiles}>
          {({ field, form, meta }) => {
            const { name, value } = field;
            const { error } = meta;
            const { setFieldValue } = form;
            // console.log('aaaaaa', value);
            return (
              <FieldContainer error={error} mb={2}>
                <FileDropzone
                  {...field}
                  {...props}
                  multiple={true}
                  files={value || []}
                  setFiles={(files = []) => {
                    setFieldValue(name, files);
                  }}
                />
              </FieldContainer>
            );
          }}
        </Field>
      </Box>
    </>
  );
};

const Form9 = (props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Box py={5}>
        <img src={kycIcon} />
      </Box>
      <Box textAlign="center">
        To comply with regulation each participant will have to go through
        indentity verification (KYC/AML) to prevent fraud causes.
      </Box>
    </Box>
  );
};

const Form10 = (props) => {
  const theme = useTheme();

  return (
    <Box>
      <Box mb={1}>
        In order to complete, please upload any of the following personal
        document.
      </Box>
      <Box mb={1}>
        <Field name={fieldName_fileCategory}>
          {({ field, meta }) => {
            const { error } = meta;
            return (
              <FieldContainer error={error} mb={2}>
                <FormRadioGroupField2
                  {...field}
                  required
                  options={[
                    { label: 'International Passport', value: '1' },
                    { label: 'National ID', value: '2' },
                    { label: 'Singapore Work Permit', value: '3' },
                  ]}
                />
              </FieldContainer>
            );
          }}
        </Field>
      </Box>
      <Box mb={1}>
        <Typography variant="subtitle2">
          {' '}
          To avoid delays when verifying account, Please make sure bellow:
        </Typography>
      </Box>
      <Box mb={3}>
        <Box display="flex" mb={1}>
          <Avatar
            style={{
              marginRight: theme.spacing(1),
              height: '20px',
              width: '20px',
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <CheckIcon style={{ height: '15px', width: '15px' }} />
          </Avatar>
          Chosen credential must not be expaired.
        </Box>
        <Box display="flex" mb={1}>
          <Avatar
            style={{
              marginRight: theme.spacing(1),
              height: '20px',
              width: '20px',
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <CheckIcon style={{ height: '15px', width: '15px' }} />
          </Avatar>{' '}
          Document should be good condition and clearly visible.
        </Box>
        <Box display="flex">
          <Avatar
            style={{
              marginRight: theme.spacing(1),
              height: '20px',
              width: '20px',
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <CheckIcon style={{ height: '15px', width: '15px' }} />
          </Avatar>{' '}
          Make sure that there is no light glare on the card.
        </Box>
      </Box>
      <Box mb={1}>
        <Box>
          <Field name={fieldName_documentFront}>
            {({ field, form, meta }) => {
              const { name, value } = field;
              const { error } = meta;
              const { setFieldValue } = form;
              return (
                <FieldContainer
                  label="Upload card’s front copy"
                  error={error}
                  mb={2}
                >
                  <Box display="flex" alignItems="center">
                    <Box flexGrow={3}>
                      <FileDropzone
                        {...field}
                        {...props}
                        files={value || []}
                        setFiles={(files = []) => {
                          setFieldValue(name, files);
                        }}
                      />
                    </Box>
                    <Box flexGrow={1}>
                      <img
                        src={image_DocumentFront}
                        style={{ width: '100%', maxWidth: '150px' }}
                      />
                    </Box>
                  </Box>
                </FieldContainer>
              );
            }}
          </Field>
        </Box>
        <Box>
          <Field name={fieldName_documentBack}>
            {({ field, form, meta }) => {
              const { name, value } = field;
              const { error } = meta;
              const { setFieldValue } = form;
              return (
                <FieldContainer
                  label="Upload card’s back copy"
                  error={error}
                  mb={2}
                >
                  <Box display="flex" alignItems="center">
                    <Box flexGrow={3}>
                      <FileDropzone
                        {...field}
                        {...props}
                        files={value || []}
                        setFiles={(files = []) => {
                          setFieldValue(name, files);
                        }}
                      />
                    </Box>
                    <Box flexGrow={1}>
                      <img
                        src={image_DocumentBack}
                        style={{ width: '100%', maxWidth: '150px' }}
                      />
                    </Box>
                  </Box>
                </FieldContainer>
              );
            }}
          </Field>
        </Box>
      </Box>
      <Box>
        <Field name={fieldName_checkbox2}>
          {({ field, form, meta }) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    // onChange={handleChange}
                    // name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <Typography variant="caption">
                    I Have Read The Terms Of Condition And Privacy Policy
                  </Typography>
                }
              />
            );
          }}
        </Field>
        <Field name={fieldName_checkbox3}>
          {({ field, form, meta }) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    // onChange={handleChange}
                    // name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <Typography variant="caption">
                    All The Personal Information I Have Entered Is Correct.
                  </Typography>
                }
              />
            );
          }}
        </Field>
      </Box>
    </Box>
  );
};

const Form11 = (props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Box>
        <img src={image_avatar} width="100" />
      </Box>
      <Box textAlign="center">
        <Box>
          <Button
            startIcon={<img src={image_scanFace} />}
            style={{ color: cyan['500'] }}
          >
            Take a selfie
          </Button>
        </Box>
        <Typography variant="caption">
          Put your face inside the frame and wait until it turns blue
        </Typography>
      </Box>
    </Box>
  );
};

const Form12 = (props) => {
  return (
    <Box
      display="flex"
      // flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <FaceDetectContainer />
    </Box>
  );
};

const Form13 = (props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Box p={5} mb={3}>
        <img src={image_docIllustration} width="100" />
      </Box>
      <Box textAlign="center">
        <Box mb={1}>
          <Typography variant="h4">
            Thanks! We’ll review your documents within 24 hours
          </Typography>
        </Box>
        <Typography variant="caption">
          Get ready to start using Meyzer360 for your daily business app.
        </Typography>
      </Box>
    </Box>
  );
};

const steps = [
  {
    id: 1,
    label: 'Personal details',
    title: 'I want to open new Meyzer360 account',
    children: [
      {
        id: 1,
        label: 'Personal information',
        totalPages: 3,
        maxPageNum: 3,
      },
      {
        id: 2,
        label: 'Email verification',
        maxPageNum: 4,
      },
    ],
  },
  {
    id: 2,
    label: 'Business details',
    children: [
      {
        id: 1,
        label: 'Business information',
        maxPageNum: 5,
      },
      {
        id: 2,
        label: 'Business structure',
        maxPageNum: 6,
      },
      {
        id: 3,
        label: 'Business address',
        maxPageNum: 7,
      },
      {
        id: 4,
        label: 'Business documents',
        maxPageNum: 8,
      },
    ],
  },

  {
    id: 3,
    label: 'Identity details',
    children: [
      {
        id: 5,
        label: 'Scan document',
        maxPageNum: 9,
      },
      {
        id: 6,
        label: 'Take selfie',
        maxPageNum: 11,
      },
    ],
  },
  {
    id: 4,
    label: 'Confirmation',
  },
];

const forms = [
  {
    id: 1,
    stepTitle: 'Personal details',
    title: 'I want to open new Meyzer360 account',
    component: Form1,
  },
  {
    id: 1,
    stepTitle: 'Personal details',
    title: 'I want to open new Meyzer360 account',
    component: Form2,
  },
  {
    id: 1,
    stepTitle: 'Personal details',
    title: 'Fill in your basic information',
    component: Form3,
  },
  {
    id: 1,
    stepTitle: '',
    title: '',
    component: Form4,
  },
  {
    id: 2,
    stepTitle: 'Business Details',
    title: 'Fill in your business information',
    component: Form5,
  },
  {
    id: 2,
    stepTitle: 'Business Details',
    title: 'Tell us about your business structure',
    component: Form6,
  },
  {
    id: 2,
    stepTitle: 'Business Details',
    title: 'Fill in your business address',
    component: Form7,
  },
  {
    id: 2,
    stepTitle: 'Business Details',
    title: 'Submit your business documents',
    component: Form8,
  },
  {
    id: 3,
    stepTitle: 'Identify Details',
    title: 'KYC Verification',
    component: Form9,
  },
  {
    id: 3,
    stepTitle: 'Identify Details',
    title: 'Document Verification',
    component: Form10,
  },
  {
    id: 3,
    stepTitle: 'Identify Details',
    title: 'KYC Verification',
    component: Form11,
  },
  {
    id: 3,
    stepTitle: 'Identify Details',
    title: 'KYC Verification',
    component: Form12,
  },
  {
    id: 3,
    stepTitle: 'Identify Details',
    title: 'KYC Verification',
    component: Form13,
  },
];

const Onboarding = () => {
  const defaultValues = getFormValueLocalStorage();
  const { formPage: defaultFormPage, ...restDefaultValues } = defaultValues;
  const { smBelow } = useBreakpoints();

  // const [activeStep, setActiveStep] = useState(1);
  const [formPage, setFormPage] = useState(
    defaultFormPage ? defaultFormPage : 1,
  );
  let activeStep = 1;
  if (formPage <= 4) {
    activeStep = 1;
  } else if (formPage <= 8) {
    activeStep = 2;
  } else if (formPage <= 13) {
    activeStep = 3;
  }

  const foundForm = forms[formPage - 1];

  const { options: countryOptions = [] } = useCustomCommonAPI(
    commonSvc.countries,
    {
      labelKey: 'country_name',
      valueKey: 'country_id',
      respondKey: 'countries',
    },
  );

  const { options: genderOptions = [] } = useCustomCommonAPI(
    commonSvc.genders,
    {
      labelKey: 'gender_name',
      valueKey: 'gender_code',
      respondKey: 'genders',
    },
  );
  const { options: applicationTypeOptions = [] } = useCustomCommonAPI(
    commonSvc.companyApplicationTypes,
    {
      labelKey: 'application_type_table_name',
      valueKey: 'company_application_type_id',
      respondKey: 'applicationtype',
    },
  );
  const { options: companyTypeOptions = [] } = useCustomCommonAPI(
    commonSvc.companyTypes,
    {
      labelKey: 'company_type_name',
      valueKey: 'company_type_id',
      respondKey: 'company_types',
    },
  );

  const entityCategoriesOptions = entityCategories.map((anItem) => {
    return {
      label: anItem.label,
      value: anItem.label,
    };
  });

  const industriesOptions = industries.map((anItem) => {
    return {
      label: anItem.label,
      value: anItem.label,
    };
  });

  const numberOfEmployeeOptions = numberOfEmployees.map((anItem) => {
    return {
      label: anItem.label,
      value: anItem.label,
    };
  });
  const annualRevenueOptions = annualRevenues.map((anItem) => {
    return {
      label: anItem.label,
      value: anItem.label,
    };
  });

  return (
    <Layout
      subHeader={
        smBelow ? (
          <Box display="flex" justifyContent="center">
            <StepsDisplay
              activeStep={activeStep}
              steps={steps}
              formPage={formPage}
              horizontal={true}
            />
          </Box>
        ) : null
      }
    >
      <Container maxWidth="lg" style={{ display: 'flex' }}>
        <Grid container spacing={2}>
          {smBelow ? null : (
            <Grid item xs={3} sm={3} md={3}>
              <Box height="100%">
                <StepsDisplay
                  activeStep={activeStep}
                  steps={steps}
                  formPage={formPage}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={6}>
            <Formik
              initialValues={restDefaultValues}
              onSubmit={(values, actions) => {
                console.log('Formik values: ', values);
              }}
            >
              {(props) => {
                const { values } = props;
                const selectedEntityCategory = values['entityCategory'];
                let foundEntityCategory = entityCategories.find(
                  (aCategory) => aCategory.label == selectedEntityCategory,
                );
                let entityTypeOptions = [];
                if (
                  foundEntityCategory &&
                  foundEntityCategory.children &&
                  foundEntityCategory.children.length > 0
                ) {
                  entityTypeOptions = foundEntityCategory.children.map(
                    (anItem) => {
                      return {
                        label: anItem.label,
                        value: anItem.label,
                      };
                    },
                  );
                }

                const selectedIndustry = values['natureOfIndustry'];
                let foundIndustry = industries.find(
                  (aCategory) => aCategory.label == selectedIndustry,
                );
                // console.log('foundIndustry', foundIndustry);
                let subIndustriesOptions = [];
                if (
                  foundIndustry &&
                  foundIndustry.children &&
                  foundIndustry.children.length > 0
                ) {
                  subIndustriesOptions = foundIndustry.children.map(
                    (anItem) => {
                      return {
                        label: anItem.label,
                        value: anItem.label,
                      };
                    },
                  );
                }

                const CurrentForm = forms?.[formPage - 1]?.component;
                return (
                  <Form style={{ height: '100%' }}>
                    <Paper display="flex" style={{ height: '100%' }}>
                      <Box display="flex" flexDirection="column" height="100%">
                        <Box flexGrow={1}>
                          <Container maxWidth="xs" style={{ height: '100%' }}>
                            <Content
                              stepTitle={foundForm?.stepTitle}
                              contentTitle={foundForm?.title}
                            >
                              {CurrentForm && (
                                <CurrentForm
                                  commonData={{
                                    countries: countryOptions,
                                    genders: genderOptions,
                                    applicationTypes: applicationTypeOptions,
                                    companyTypes: companyTypeOptions,
                                    entityCategories: entityCategoriesOptions,
                                    entityTypes: entityTypeOptions,
                                    industries: industriesOptions,
                                    subIndustries: subIndustriesOptions,
                                    numberOfEmployees: numberOfEmployeeOptions,
                                    annualRevenues: annualRevenueOptions,
                                  }}
                                  values={values}
                                />
                              )}
                            </Content>
                          </Container>
                        </Box>
                        <Divider />
                        {/* Footer */}
                        <Box
                          py={3}
                          px={2}
                          display="flex"
                          justifyContent="space-between"
                        >
                          {formPage > 1 && (
                            <Button
                              variant="contained"
                              disableElevation={true}
                              style={{ width: '150px' }}
                              onClick={() => {
                                let newPageIndex = formPage - 1;
                                if (newPageIndex >= 1) {
                                  setFormPage((prev) => newPageIndex);
                                  setFormValueLocalStorage({
                                    ...defaultValues,
                                    formPage: newPageIndex,
                                  });
                                }
                              }}
                            >
                              Back
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            disableElevation={true}
                            color="primary"
                            style={{ width: '150px', marginLeft: 'auto' }}
                            onClick={() => {
                              const {
                                [fieldName_businessFiles]: businessFiles,
                                [fieldName_documentBack]: documentBack,
                                [fieldName_documentFront]: documentFront,
                                ...restValues
                              } = values;

                              let newPageIndex = formPage + 1;
                              if (newPageIndex <= forms.length) {
                                setFormValueLocalStorage({
                                  ...restValues,
                                  formPage: newPageIndex,
                                });
                                setFormPage((prev) => newPageIndex);
                              }
                            }}
                          >
                            Next
                          </Button>
                        </Box>
                      </Box>
                    </Paper>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
          <Grid item sm={3} md={3} />
        </Grid>
      </Container>
    </Layout>
  );
};

export default Onboarding;
