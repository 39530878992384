import React from 'react';
import { Typography, Box, Container, Button } from '@material-ui/core';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { makeStyles } from '@material-ui/core/styles';
import img from 'src/assets/errorpage/kyc.jpg';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fontWeight: {
    fontWeight: 600,
  },
}));

const KycDone = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container className={classes.root}>
      <Box p={5} />
      <img src={img} height="70%" width="70%" />
      <Box p={2} />
      <Typography variant="h2" className={classes.fontWeight}>
        Thank you for your verification.
      </Typography>
      <Box p={1} />
      <Typography variant="body1"> </Typography>
      <Box p={3} />
      <Button
        variant="contained"
        size="large"
        color="primary"
        style={{
          textTransform: 'initial',
        }}
        onClick={() => history.push(ROUTES.DASHBOARD)}
      >
        Go to dashboad
      </Button>
    </Container>
  );
};

export default KycDone;
