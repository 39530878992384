import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Divider,
  Grid,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  Container,
  Item,
} from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Formik, Form, Field, useFormikContext } from 'formik';
import yellow from '@material-ui/core/colors/yellow';
import * as Yup from 'yup';
import kycSvc from '../../../services/kyc';

import useBreakpoints from 'src/hooks/useBreakpoints';
import { sgdFormat } from '../../../utils/currency';

// fields
import { FormFileDropzone } from 'src/components/Form';
import FieldContainer from '../Fields/FieldContainer';

import CustomTextField from '../../CustomFormFields/CustomTextField';
import CustomSelectField from '../../CustomFormFields/CustomSelectField';
import FormFieldContainer from '../../CustomFormFields/FormFieldContainer';
import CustomPhoneNumberField from '../../CustomFormFields/CustomPhoneNumberField';

// media
import image_director from '../../../assets/onboarding/director.png';
import image_employee from '../../../assets/onboarding/employee.png';
import image_freelancer from '../../../assets/onboarding/freelancer.png';
import image_docIllustration from '../../../assets/onboarding/docIllustration.png';
import CustomModal from '../../CustomModal';

export const fieldNames_0 = {
  fieldName_productId: 'product_id',
  fieldName_checkbox0: 'checkbox0',
};
export const fieldNames_1 = {
  fieldName_position: 'account_type_id',
  fieldName_checkbox1: 'checkbox1',
  fieldName_name: 'full_name',
  fieldName_email: 'email',
  fieldName_contactNumber: 'contact_number',
};

export const fieldNames_2 = {
  fieldName_businessLegalName: 'company_name',
  fieldName_registrationCountry: 'company_country_id',
  fieldName_directorName: 'director_name',
  fieldName_directorPassportIC: 'director_passport_ic',
  fieldName_directorEmail: 'director_email',
  fieldName_directorContactNumber: 'director_contact_number',
  fieldName_entityCategory: 'entity_category',
  fieldName_entityType: 'entity_type',
  fieldName_businessNumber: 'bus_registration_no',
  fieldName_natureOfIndustry: 'industry_nature',
  fieldName_natureOfSubIndustry: 'sub_industry_nature',
  fieldName_businessActivity: 'businessActivity',
  fieldName_productDescription: 'bus_description',
  fieldName_companyWebsite: 'company_website',
  fieldName_numberOfEmployee: 'number_employees',
  fieldName_annualTurnover: 'annual_turnover',
};
export const fieldNames_3 = {
  fieldName_addressUnit: 'floor_unit_no',
  fieldName_addressLine1: 'address_line_1',
  fieldName_addressLine2: 'address_line_2',
  fieldName_addressLine3: 'address_line_3',
  fieldName_postcode: 'postal_code',
  fieldName_addressCountry: 'country_id',
  fieldName_state_id: 'state_id',
};
export const fieldNames_4 = {
  fieldName_businessFiles: 'bus_document_attachment_id',
  fieldName_checkbox2: 'checkbox2',
};
const fieldNames_ = {};
export const fieldNames = {
  ...fieldNames_0,
  ...fieldNames_1,
  ...fieldNames_2,
  ...fieldNames_3,
  ...fieldNames_4,
  fieldName_created_by: 'created_by',
  fieldName_onboard_status_id: 'onboard_status_id',

  // fieldName_: '',
};

const {
  fieldName_productId,
  fieldName_checkbox0,
  fieldName_position,
  fieldName_checkbox1,
  fieldName_registrationCountry,
  fieldName_businessLegalName,
  fieldName_entityCategory,
  fieldName_entityType,
  fieldName_businessNumber,
  fieldName_natureOfIndustry,
  fieldName_natureOfSubIndustry,
  fieldName_businessActivity,
  fieldName_productDescription,
  fieldName_companyWebsite,
  fieldName_numberOfEmployee,
  fieldName_annualTurnover,
  fieldName_addressUnit,
  fieldName_addressLine1,
  fieldName_addressLine2,
  fieldName_addressLine3,
  fieldName_postcode,
  fieldName_state_id,
  fieldName_addressCountry,
  fieldName_businessFiles,
  fieldName_checkbox2,
  fieldName_created_by,
  fieldName_onboard_status_id,

  fieldName_name,
  fieldName_email,
  fieldName_contactNumber,

  fieldName_directorName,
  fieldName_directorPassportIC,
  fieldName_directorEmail,
  fieldName_directorContactNumber,
} = fieldNames;

export const address_fields = {
  first_name: {
    name: 'first_name',
    label: 'First Name',
  },
  last_name: {
    name: 'last_name',
    label: 'Last Name',
  },
  email: {
    name: 'email',
    label: 'Your Email',
  },
  phoneNumber: {
    name: 'phone',
    label: 'Phone Number',
    custom: Yup.string()
      .required('Please fill up this field')
      .min(3, 'Please fill up this field'),
  },
  phoneNumberCountry: {
    name: 'phoneNumberCountry',
    label: 'Phone Number Country',
    custom: Yup.string().default('').nullable(),
  },
  country_id: {
    name: 'country_id',
    label: 'Country',
  },
  state_id: {
    name: 'state_id',
    label: 'State',
  },
  // country: {
  //   name: 'country',
  //   label: 'Country',
  // },
  // city: {
  //   name: 'state',
  //   label: 'City',
  // },
  postal_code: {
    name: 'postal_code',
    label: 'ZIP Code',
  },
  floor_unit_no: {
    name: 'floor_unit_no',
    label: 'Floor/Unit No',
  },
  address_line_1: {
    name: 'address_line_1',
    label: 'Address Line 1',
  },
  address_line_2: {
    name: 'address_line_2',
    label: 'Address Line 2',
  },
  address_line_3: {
    name: 'address_line_3',
    label: 'Address Line 3 (Optional)',
    required: false,
  },
};

export const order_fields = {
  remark: {
    name: 'remark',
    label: 'Write a Note',
  },
  payment_method: {
    name: 'payment_method',
    label: 'Payment Method',
  },
  tnc: {
    name: 'tnc',
    label: 'Terms and Conditions',
  },
};

const useOptionStyles = makeStyles((theme) => ({
  option: {
    '&:hover, &:focus': {
      // backgroundColor: theme.palette.primary.light,
      backgroundColor: `rgba(162, 29, 35, 0.1)`,
    },
  },
}));

export let staticProductOptions = {
  28: {
    label: 'Entity Incorporation + Standard Corporate Secretarial Services',
    descriptionItems: [
      'Application for approval and reservation of one company name with ACRA',
      'ACRA Incorporation Fees',
      'Appointment of up to 3 directors (includes resident director)',
      'First allotment of up to 3 subscriber shares',
      "First Directors' resolutions (2pages)",
      'Constitution - 1 set',
      'Register of Registrable Controllers',
      'Register of Nominee Director',
      'Compliance service on due diligence checks',
    ],
  },
  37: {
    label: 'Standard Corporate Secretarial Services',
    descriptionItems: [
      'Acting as Company Secretary + AGM documentation + Annual Return Filing',
    ],
  },
};

export const Form0 = (props) => {
  const { commonData = {}, showPrice = true } = props;
  const { products = [] } = commonData;

  const theme = useTheme();
  let optionstyles = useOptionStyles();
  let productOptions = [];
  products.forEach((anItem) => {
    if (anItem.id == 28 || anItem.id == 37) {
      productOptions.push({
        label: staticProductOptions?.[anItem.id]?.label || anItem.name,
        value: anItem.id,
        price: anItem.price,
        description: () => {
          return (
            <Grid container spacing={1}>
              {staticProductOptions?.[anItem.id]?.descriptionItems.map(
                (anItem, index) => {
                  return (
                    <Grid item key={index} xs={12}>
                      <Box fontWeight="400">
                        {index + 1}. {anItem}
                      </Box>
                    </Grid>
                  );
                },
              )}
            </Grid>
          );
        },
      });
    }
  });
  return (
    <>
      <Box mb={1}>Please select one of the package :</Box>
      <Box mb={3}>
        <Field name={fieldName_productId}>
          {({
            field, // { name, value, onChange, onBlur }
            form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => {
            const { name, value, onChange } = field;
            const { touched, errors, setFieldValue } = form;
            let error = meta.touched && !!meta.error;

            return (
              <FormFieldContainer
                error={error}
                helperText={meta.touched && meta.error ? meta.error : ''}
              >
                <Grid container spacing={3}>
                  {productOptions.map((anItem, index) => {
                    let isActive = anItem.value == value;
                    let activeProps = isActive
                      ? {
                          borderColor: theme.palette.primary.main,
                        }
                      : {
                          borderColor: theme.palette.grey['300'],
                        };

                    return (
                      <Grid item key={index} xs={12}>
                        <Box
                          style={{
                            cursor: 'pointer',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            overflow: 'hidden',
                            ...activeProps,
                          }}
                          className={optionstyles.option}
                          onClick={(e) => {
                            setFieldValue(name, anItem.value);
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={5}
                              style={{
                                borderRight: '1px solid',
                                borderColor: theme.palette.grey['300'],
                              }}
                            >
                              <Box py={2} px={1}>
                                <Box fontWeight="600" mb={3}>
                                  <Typography variant="h4">
                                    <b>{anItem.label}</b>
                                  </Typography>
                                </Box>
                                {/* {showPrice ? (
                                  <Box color={theme.palette.primary.main}>
                                    <b>{sgdFormat(anItem.price)}</b>
                                  </Box>
                                ) : null} */}
                              </Box>
                            </Grid>
                            <Grid item xs={7}>
                              {anItem?.description ? (
                                <Box py={2} px={1}>
                                  {anItem.description()}
                                </Box>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    );

                    return (
                      <Grid item key={index} xs={12} sm={6}>
                        <Box
                          height="100%"
                          mb={2}
                          border={1}
                          p={2}
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          {...activeProps}
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => {
                            setFieldValue(name, anItem.value);
                          }}
                        >
                          {/* <Box pr={3}>
                        <img src={aPosition.image} height="60" width="60" />
                      </Box> */}
                          <Box fontWeight="600" mb={3} textAlign="center">
                            <Typography variant="h4">
                              <b>{anItem.label}</b>
                            </Typography>
                          </Box>
                          {anItem?.description ? (
                            <Box mb={3}>{anItem.description()}</Box>
                          ) : null}
                          <Box mt={'auto'}>
                            <b>{sgdFormat(anItem.price)}</b>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </FormFieldContainer>
            );
          }}
        </Field>
      </Box>

      {/* <Field name={fieldName_checkbox0}>
        {({ field, form, meta }) => {
          const { value } = field;
          let error = meta.touched && !!meta.error;

          return (
            <FormFieldContainer
              error={error}
              helperText={meta.touched && meta.error ? meta.error : ''}
            >
              <FormControlLabel
                control={
                  <Checkbox {...field} checked={value} color="primary" />
                }
                label={
                  <Typography variant="body2">
                    I confirm that I will be the{' '}
                    <b>Administrator of the Business Account</b> and I am
                    authorised to fill out this form on behalf of the business.
                  </Typography>
                }
              />
            </FormFieldContainer>
          );
        }}
      </Field> */}
    </>
  );
};

export const Form1 = (props) => {
  const { isPublic } = props;
  const theme = useTheme();
  let optionstyles = useOptionStyles();

  const positionOptions = [
    {
      label: 'I am a registered director of the company',
      value: 1,
      image: image_director,
    },
    {
      label: 'I am an employee',
      value: 2,
      image: image_employee,
    },
    {
      label: 'I am a service provider',
      value: 3,
      image: image_freelancer,
    },
  ];
  return (
    <>
      <Box mb={1}>Please select one of the</Box>
      <Field name={fieldName_position}>
        {({
          field, // { name, value, onChange, onBlur }
          form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => {
          const { name, value, onChange } = field;
          const { touched, errors, setFieldValue } = form;
          let error = meta.touched && !!meta.error;

          return (
            <Box mb={2}>
              <FormFieldContainer
                error={error}
                helperText={meta.touched && meta.error ? meta.error : ''}
              >
                {positionOptions.map((aPosition, index) => {
                  let isActive = aPosition.value == value;
                  let activeProps = isActive
                    ? {
                        borderColor: theme.palette.primary.main,
                      }
                    : {
                        borderColor: theme.palette.grey['300'],
                      };
                  return (
                    <Box
                      key={aPosition.value}
                      mb={2}
                      border={1}
                      p={2}
                      display="flex"
                      alignItems="center"
                      {...activeProps}
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        setFieldValue(name, aPosition.value);
                      }}
                      className={optionstyles.option}
                    >
                      <Box pr={3}>
                        <img src={aPosition.image} height="60" width="60" />
                      </Box>
                      <Box flexGrow={1} fontWeight="600">
                        {aPosition.label}
                      </Box>
                    </Box>
                  );
                })}
              </FormFieldContainer>
            </Box>
          );
        }}
      </Field>
      {isPublic ? (
        <>
          <FieldContainer label="Name" mb={2}>
            <CustomTextField name={fieldName_name} placeholder="Enter name" />
          </FieldContainer>
          <FieldContainer label="Email" mb={2}>
            <CustomTextField
              name={fieldName_email}
              type="email"
              placeholder="Enter email"
            />
          </FieldContainer>
          <FieldContainer label="Contact Number" mb={2}>
            <CustomTextField
              name={fieldName_contactNumber}
              placeholder="Enter contact number"
            />
          </FieldContainer>
        </>
      ) : null}
      <Field name={fieldName_checkbox1}>
        {({ field, form, meta }) => {
          const { value } = field;
          let error = meta.touched && !!meta.error;

          return (
            <FormFieldContainer
              error={error}
              helperText={meta.touched && meta.error ? meta.error : ''}
            >
              <FormControlLabel
                control={
                  <Checkbox {...field} checked={value} color="primary" />
                }
                label={
                  <Typography variant="body2">
                    I confirm that I will be the{' '}
                    <b>Administrator of the Business Account</b> and I am
                    authorised to fill out this form on behalf of the business.
                  </Typography>
                }
              />
            </FormFieldContainer>
          );
        }}
      </Field>
    </>
  );
};

export const Form5 = (props) => {
  const { commonData = {} } = props;
  const {
    countries = [],
    entityCategories = [],
    entityTypes = [],
    industries = [],
    subIndustries = [],
    numberOfEmployees = [],
    annualRevenues = [],
  } = commonData;
  const { smBelow } = useBreakpoints();

  const formikProps = useFormikContext();
  const { values, setFieldValue } = formikProps;

  let selectedProductId = values?.[fieldName_productId];
  let isCorporate = selectedProductId != 39;
  // console.log('isCorporate', {
  //   isCorporate,
  //   selectedProductId,
  // });

  useEffect(() => {
    if (isCorporate) {
      setFieldValue(fieldName_businessNumber, '-');
      setFieldValue(fieldName_numberOfEmployee, '-');
      setFieldValue(fieldName_annualTurnover, '-');
    }
  }, [isCorporate]);

  // let limitedCountries2 = ['Singapore', 'Hong kong', 'BVI', 'Cayman', 'USA'];
  let limitedCountries = [
    'Singapore',
    'Hong Kong',
    'British Virgin Islands',
    'Cayman Islands',
    'US',
  ];

  let filteredCountryOptions = countries.filter((anItem) => {
    let found = limitedCountries.find((anItem2) => {
      return anItem.label.toLocaleLowerCase() == anItem2.toLocaleLowerCase();
      return (
        anItem.label.toLocaleLowerCase().indexOf(anItem2.toLocaleLowerCase()) >=
        0
      );
    });
    return Boolean(found);
  });

  return (
    <Box maxWidth="100%" width={smBelow ? '100%' : '65%'}>
      <FieldContainer label="Business Legal Name" mb={2}>
        <CustomTextField
          name={fieldName_businessLegalName}
          placeholder="Enter company name"
        />
      </FieldContainer>
      <FieldContainer label="Business Registered Country" mb={2}>
        <CustomSelectField
          name={fieldName_registrationCountry}
          options={filteredCountryOptions}
          placeholder="Select a country"
        />
      </FieldContainer>
      {isCorporate ? (
        <>
          <FieldContainer label="Director Name" mb={2}>
            <CustomTextField
              name={fieldName_directorName}
              placeholder="Enter Director Name"
            />
          </FieldContainer>
          <FieldContainer label="Director Passport/IC" mb={2}>
            <CustomTextField
              name={fieldName_directorPassportIC}
              placeholder="Enter Director Passport/IC"
            />
          </FieldContainer>
          <FieldContainer label="Director Email" mb={2}>
            <CustomTextField
              name={fieldName_directorEmail}
              placeholder="Enter Director Email"
            />
          </FieldContainer>
          <FieldContainer label="Director Contact number" mb={2}>
            <CustomTextField
              name={fieldName_directorContactNumber}
              placeholder="Enter Director Contact number"
            />
          </FieldContainer>
        </>
      ) : null}

      <FieldContainer label="Entity Category" mb={2}>
        <CustomSelectField
          name={fieldName_entityCategory}
          options={entityCategories}
          placeholder="Select business registration type"
        />
      </FieldContainer>
      <FieldContainer label="Entity Type" mb={2}>
        <CustomSelectField
          name={fieldName_entityType}
          options={entityTypes}
          placeholder="Select an entity type"
        />
      </FieldContainer>
      {isCorporate ? null : (
        <FieldContainer label="Business Reg. number (UEN)" mb={2}>
          <CustomTextField
            name={fieldName_businessNumber}
            placeholder="Enter business Reg. number"
          />
        </FieldContainer>
      )}

      <FieldContainer label="Nature of Industry" mb={2}>
        <CustomSelectField
          name={fieldName_natureOfIndustry}
          options={industries}
          placeholder="Select any of following"
          // multiple={true}
        />
      </FieldContainer>
      <FieldContainer label="Nature of Sub-Industry" mb={2}>
        <CustomSelectField
          name={fieldName_natureOfSubIndustry}
          options={subIndustries}
          placeholder="Select any of following"
          // multiple={true}
        />
      </FieldContainer>
      {/* <FieldContainer label="Business Activity" mb={2}>
        <CustomTextField name={fieldName_businessActivity} />
      </FieldContainer> */}
      <FieldContainer label="Product/Service Description" mb={2}>
        <CustomTextField
          name={fieldName_productDescription}
          placeholder="e.g. We provide IT for companies."
          multiline
          rows={4}
        />
      </FieldContainer>
      {isCorporate ? null : (
        <>
          <FieldContainer label="Company website (optional)" mb={2}>
            <CustomTextField
              name={fieldName_companyWebsite}
              placeholder="Enter website url"
            />
          </FieldContainer>

          <FieldContainer label="Number of Employee" mb={2}>
            <CustomSelectField
              name={fieldName_numberOfEmployee}
              options={numberOfEmployees}
              placeholder="Select..."
            />
          </FieldContainer>
          <FieldContainer label="Annual Turnover" mb={2}>
            <CustomSelectField
              name={fieldName_annualTurnover}
              options={annualRevenues}
              placeholder="Select..."
            />
          </FieldContainer>
        </>
      )}
    </Box>
  );
};

export const Form7 = (props) => {
  const { commonData = {} } = props;
  const {
    countries = [],
    states: defaultStateOptions = [],
    statesRawData = [],
  } = commonData;
  const { smBelow } = useBreakpoints();

  const formikProps = useFormikContext();
  const { values } = formikProps;
  // const selectedAddressCountry = values[fieldName_addressCountry];

  let stateOptions = [];

  let countryValue = values?.[fieldName_addressCountry];
  if (countryValue) {
    statesRawData.forEach((anItem) => {
      if (anItem?.country_id == countryValue) {
        stateOptions.push({
          label: anItem?.state_name,
          value: anItem?.state_id,
        });
      }
    });
  }

  return (
    <Box maxWidth="100%" width={smBelow ? '100%' : '65%'}>
      <FieldContainer label="Floor/Unit No" mb={2}>
        <CustomTextField name={fieldName_addressUnit} />
      </FieldContainer>
      <FieldContainer label="Address Line 1" mb={2}>
        <CustomTextField name={fieldName_addressLine1} />
      </FieldContainer>
      <FieldContainer label="Address Line 2" mb={2}>
        <CustomTextField name={fieldName_addressLine2} />
      </FieldContainer>
      <FieldContainer label="Address Line 3 (Optional)" mb={2}>
        <CustomTextField name={fieldName_addressLine3} />
      </FieldContainer>
      <FieldContainer label="Postcode" mb={2}>
        <CustomTextField name={fieldName_postcode} />
      </FieldContainer>
      <FieldContainer label="Country" mb={2}>
        <CustomSelectField
          name={fieldName_addressCountry}
          options={countries}
          placeholder="Select a country"
        />
      </FieldContainer>
      <FieldContainer label="State" mb={2}>
        <CustomSelectField
          name={fieldName_state_id}
          options={stateOptions}
          placeholder="Select a state"
        />
      </FieldContainer>
    </Box>
  );
};

export const Form8 = (props) => {
  const { commonData = {} } = props;
  const {
    countries = [],
    states: defaultStateOptions = [],
    statesRawData = [],
  } = commonData;
  const { smBelow } = useBreakpoints();

  const formikProps = useFormikContext();
  const { values } = formikProps;
  const [showAgreement, setShowAgreement] = useState(false);
  return (
    <>
      <Box
        border={2}
        borderRadius={5}
        style={{ backgroundColor: yellow['100'], borderColor: yellow['500'] }}
        mb={3}
        p={3}
      >
        <Box fontWeight="bold">
          Please provide all the following documents for verification
        </Box>
        <Box>
          <ul>
            <li>
              Passport or National ID of other directors/partners listed in ACRA
              Bizfile
            </li>
            <li>
              Passport or National ID of shareholders with at least 10% shares
            </li>
            <li>Constitution, or Articles of Association</li>
            <li>
              Signed board resolution letter authorizing you to open a bank
              ccount for your company.
            </li>
          </ul>
        </Box>
      </Box>
      <Box>
        <Box>Upload applicable documents</Box>
        <Box mb={1}>
          <Typography variant="caption">
            You can upload multiple files in one go. If you miss anything, we
            might ask for it later.
          </Typography>
        </Box>
        <FormFileDropzone name={fieldName_businessFiles} multiple />
      </Box>
      <Box>
        <Field name={fieldName_checkbox2}>
          {({ field, form, meta }) => {
            const { value } = field;
            let error = meta.touched && !!meta.error;

            return (
              <FormFieldContainer
                error={error}
                helperText={meta.touched && meta.error ? meta.error : ''}
              >
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox {...field} checked={value} color="primary" />
                    }
                    style={{ marginRight: '0px' }}
                    // label={<Typography variant="body2">Consent</Typography>}
                  />
                  {/* <Checkbox {...field} checked={value} color="primary" /> */}
                  I&nbsp;
                  <a
                    onClick={() => {
                      setShowAgreement(true);
                    }}
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                  >
                    {/* <Typography variant="body2">Consent</Typography> */}
                    consent
                  </a>
                  &nbsp; to provide my information as above.
                </Box>
              </FormFieldContainer>
            );
          }}
        </Field>
      </Box>
      <CustomModal
        title="PERSONAL DATA PROTECTION ACT – CONSENT FORM"
        show={showAgreement}
        onHide={() => {
          setShowAgreement(false);
        }}
      >
        {/* <div>PERSONAL DATA PROTECTION ACT – CONSENT FORM</div> */}
        <Box mb={1}>
          1. The Personal Data Protection Act (“PDPA”) establishes a data
          protection law that recognizes both the rights of the individuals to
          protect their personal data and the needs of organisations to collect,
          use or disclose personal data for legitimate and reasonable purpose.
        </Box>
        <Box mb={1}>
          2. In compliance with the Personal Data Protection Act (“PDPA”), we
          seek your consent to collect and use your personal data (i.e. Name,
          NRIC, Contact Numbers, Mailing and Email addresses) for the purpose of
          maintaining your particulars in our databases, provisions of updates
          to you and to disclose such personal data to Meyzer Management
          Advisory Pte. Ltd. and related organisations, where necessary.
        </Box>
        <Box mb={1}>
          3. We respect your privacy and assure you that your personal data will
          be kept securely according to the Personal Data Protection Act
          (“PDPA”).
        </Box>
        <Box mb={1}>
          4. Please note that we presume you agree and consent that your
          personal data may be used as explained above when you are in our
          employ.
        </Box>
        <Box mb={1}>
          5. You are entitled at any time, to request access to information held
          by us about you and to update and correct such information. You may
          also opt out of all or specific forms of communication from us. Please
          make such request in writing to our Compliance Officer , at 391B
          Orchard Road, #21-06 Ngee Ann City Tower B, Singapore 238874 or email
          it to biz@meyzergroup.com.
        </Box>
        <Box mb={1}>
          I, hereby, acknowledge and give my consent to Meyzer Management
          Advisory Pte. Ltd.to use my personal data for the aforesaid purposes
          and services. In the event that I wish to withhold or withdraw my
          consent to Meyzer Management Advisory Pte. Ltd. In respect of
          receiving telephone calls, e-mails and/or other electronic means of
          communications, I will provide sufficient written notice to Meyzer
          Management Advisory Pte. Ltd. as soon as reasonably practicable. I
          further agree to indemnify Meyzer Management Advisory Pte. Ltd.
          against any financial penalties imposed by the Personal Data
          Protection Commission or any court law in Singapore as a direct or
          indirect result of my failure to inform Meyzer Management Advisory
          Pte. Ltd.
        </Box>
      </CustomModal>
    </>
  );
};

export const Form13 = (props) => {
  const [kyclink, setKyclink] = useState();

  const formikProps = useFormikContext();
  const { values } = formikProps;
  console.log('final', props);
  const data = {
    companyName: values.company_name,
    directorName: values.director_name,
    directorEmail: values.director_email,
    country: null,
  };
  useEffect(() => {
    axios
      .post('https://kyc.meyzer360.com/shuftipro/kyclink', data)
      .then((response) => {
        setKyclink(response.data.verification_url);
      });
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Box textAlign="center">
        <Box mb={1}>
          <Typography variant="h4">
            To ensure the security and compliance of our platform, we kindly
            request you to complete your KYC (Know Your Customer) verification.
          </Typography>
        </Box>
      </Box>
      <Box
        py={3}
        px={2}
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex" alignItems="center">
          {values?.director_email}
        </Box>
        <Box>
          <Button
            mb={1}
            target="_blank"
            rel="noreferrer"
            as="a"
            href={kyclink}
            variant="contained"
            disableElevation={true}
            color="primary"
            style={{
              minWidth: '50px',
              marginLeft: 'auto',
            }}
            type="button"
          >
            {'Verify Identity'}
          </Button>
        </Box>
      </Box>
      <Box p={5} mb={3}>
        <img src={image_docIllustration} width="100" />
      </Box>
      <Box textAlign="center">
        <Box mb={1}>
          <Typography variant="h4">
            Thanks! We’ll review your documents within 24 hours
          </Typography>
        </Box>
        <Typography variant="caption">
          We’ll send you the email notification.
        </Typography>
      </Box>
    </Box>
  );
};

export const FormCart = (props) => {
  const { commonData } = props;
  const { products = [] } = commonData;

  const formikProps = useFormikContext();
  const { values } = formikProps;

  let selectedProductId = values?.[fieldName_productId];
  let foundProduct = products.find((anItem) => {
    return anItem.id == selectedProductId;
  });
  let selectedProduct = staticProductOptions?.[selectedProductId];

  console.log('selectedProductId', {
    selectedProductId,
    foundProduct,
    selectedProduct,
  });
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <b>{selectedProduct?.label || '-'}</b>
      </Grid>
      <Grid item xs={4}>
        <Box textAlign="right">{sgdFormat(foundProduct?.price)}</Box>
      </Grid>
    </Grid>
  );
};

const AddressFields = (props) => {
  const {
    nestedKey,
    prefix = '',
    countryOptions = [],
    stateOptions: defaultStateOptions = [],
    statesData,
  } = props;

  const { values } = useFormikContext();
  const {
    first_name,
    last_name,
    email,
    phoneNumber,
    phoneNumberCountry,
    country_id,
    state_id,
    // city,
    postal_code,
    floor_unit_no,
    address_line_1,
    address_line_2,
    address_line_3,
  } = address_fields;

  let stateOptions = [];

  let countryFieldName = prefix + country_id.name;
  let countryValue = values?.[countryFieldName];
  if (nestedKey) {
    countryValue = values?.[nestedKey]?.[country_id.name];
  }

  if (countryValue) {
    statesData.forEach((anItem) => {
      if (anItem?.country_id == countryValue) {
        stateOptions.push({
          label: anItem?.state_name,
          value: anItem?.state_id,
        });
      }
    });
  }
  return (
    <>
      <Grid item xs={12} md={6}>
        <FormFieldContainer label={first_name.label} mb={2}>
          <CustomTextField name={prefix + first_name.name} />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormFieldContainer label={last_name.label} mb={2}>
          <CustomTextField name={prefix + last_name.name} />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormFieldContainer label={email.label} mb={2}>
          <CustomTextField name={prefix + email.name} type="email" />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormFieldContainer label={phoneNumber.label} mb={2}>
          <CustomPhoneNumberField
            name={prefix + phoneNumber.name}
            phoneCodeName={prefix + phoneNumberCountry.name}
          />
        </FormFieldContainer>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormFieldContainer label={floor_unit_no.label} mb={2}>
          <CustomTextField name={prefix + floor_unit_no.name} />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormFieldContainer label={address_line_1.label} mb={2}>
          <CustomTextField name={prefix + address_line_1.name} />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormFieldContainer label={address_line_2.label} mb={2}>
          <CustomTextField name={prefix + address_line_2.name} />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormFieldContainer label={address_line_3.label} mb={2}>
          <CustomTextField name={prefix + address_line_3.name} />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormFieldContainer label={postal_code.label} mb={2}>
          <CustomTextField name={prefix + postal_code.name} />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormFieldContainer label={country_id.label} mb={2}>
          <CustomSelectField
            name={prefix + country_id.name}
            options={countryOptions}
            placeholder="Select a country"
          />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormFieldContainer label={state_id.label} mb={2}>
          <CustomSelectField
            name={prefix + state_id.name}
            options={stateOptions}
            placeholder="Select a state"
          />
        </FormFieldContainer>
      </Grid>
    </>
  );
};
export const FormCheckout = (props) => {
  const { commonData = {} } = props;
  const {
    countries = [],
    states: defaultStates = [],
    statesRawData = [],
  } = commonData;
  const { smBelow } = useBreakpoints();

  const formikProps = useFormikContext();
  const { values } = formikProps;
  const selectedAddressCountry = values[fieldName_addressCountry];

  let states = defaultStates;
  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      <AddressFields
        nestedKey="billing"
        prefix="billing."
        countryOptions={countries}
        stateOptions={states}
        statesData={statesRawData}
      />
      <Grid item xs={12}>
        <FormFieldContainer label={<b>{order_fields.remark.label}</b>} mb={2}>
          <CustomTextField name={order_fields.remark.name} multiline rows={4} />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12}>
        <Box mb={2}>
          <Box mb={1}>
            <Typography variant="h3">Select Payment Method</Typography>
          </Box>
          <Box>
            <Field name={order_fields.payment_method.name}>
              {({ field, meta }) => {
                let error = meta.touched && !!meta.error;

                return (
                  <FormFieldContainer
                    error={error}
                    helperText={meta.touched && meta.error ? meta.error : ''}
                  >
                    <RadioGroup
                      aria-label="payment-method"
                      name="payment_method"
                      {...field}
                    >
                      {/* <FormControlLabel
                        value="payment1"
                        control={<Radio color="primary" />}
                        label="PayNow"
                      /> */}
                      <FormControlLabel
                        value="payment2"
                        control={
                          <Radio color="primary" style={{ paddingTop: 0 }} />
                        }
                        label={
                          <Box>
                            <Box>Pay via Checkout.com</Box>
                            <Box color={theme.palette.grey[500]}>
                              <small>
                                Make a payment using your credit/debit card
                              </small>
                            </Box>
                          </Box>
                        }
                        style={{ alignItems: 'flex-start' }}
                      />
                    </RadioGroup>
                  </FormFieldContainer>
                );
              }}
            </Field>
          </Box>
          <Box py={2}>
            <Divider />
          </Box>
          <Box color={theme.palette.grey[500]} mb={1}>
            <Typography variant="h6" style={{ fontWeight: 'normal' }}>
              Your personal data will be used to process your order, support
              your experience throughout this website, and for other purposes
              described in our{' '}
              <span style={{ color: 'black' }}>privacy policy</span>.
            </Typography>
          </Box>
          <Box>
            <Field name={order_fields.tnc.name}>
              {({ field, meta }) => {
                let error = meta.touched && !!meta.error;

                return (
                  <FormFieldContainer
                    error={error}
                    helperText={meta.touched && meta.error ? meta.error : ''}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={isDifferentAddress}
                          // onChange={(e) => {
                          //   setIsDifferentAddress(e.target.checked);
                          // }}
                          {...field}
                          // required={true}
                          color="primary"
                        />
                      }
                      label={
                        <Box color={theme.palette.grey[500]}>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: 'normal' }}
                          >
                            I have read and agree to the website{' '}
                            <a style={{ color: 'black' }}>terms & conditions</a>
                            .
                          </Typography>
                        </Box>
                      }
                    />
                  </FormFieldContainer>
                );
              }}
            </Field>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
