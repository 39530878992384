import React from 'react';
import { Paper, Typography, Box } from '@material-ui/core';
import useBreakpoints from 'src/hooks/useBreakpoints';

const DisplayBox = ({ itemList, mobileVertical, width }) => {
  const { xsBelow, mdBelow } = useBreakpoints();
  return (
    <Box
      display="flex"
      flexDirection={mdBelow && mobileVertical ? 'column' : 'row'}
      justifyContent="space-between"
      width="100%"
    >
      {itemList.map((item, index) => (
        <>
          <Paper
            key={`${item.label}${index}`}
            variant="outlined"
            style={{
              width: mdBelow && mobileVertical ? '100%' : width ? width : '30%',
              paddingTop: 30,
              paddingBottom: 30,
              borderRadius: 10,
              display: 'flex',
              flexDirection: 'column',
              color: '#635D5D',
            }}
          >
            <Typography
              variant="h1"
              align="center"
              style={{
                fontWeight: 600,
              }}
            >
              {item.number}
            </Typography>
            <Box p={1} />
            <Typography variant={xsBelow ? 'body2' : 'h5'} align="center">
              {item.label}
            </Typography>
          </Paper>
          {mdBelow && mobileVertical && <Box p={1} />}
        </>
      ))}
    </Box>
  );
};

export default DisplayBox;
