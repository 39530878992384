import React, { useState, useContext, useEffect } from 'react';
import { Typography, Box } from '@material-ui/core';
import { FormRadioGroup } from 'src/components/Form';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory, useParams } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import MzButton from 'src/components/MzButton';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import fourSteps from 'src/assets/requests/4steps.png';
import { useProvideRequest } from 'src/hooks/useRequests';
import { ConfigContext } from 'src/contexts';
import useSnackbar from 'src/hooks/useSnackbar';
import { useProvideProduct } from 'src/hooks/useProduct';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';

const CloseBankAccount = ({ toggleShowMenu }) => {
  const history = useHistory();
  const params = useParams();
  const [bankName, setBankName] = React.useState();
  const [step, setStep] = useState(1);
  const { smBelow } = useBreakpoints();
  const {
    CreateCloseBankAccApplication,
    requestError,
    requestSubmitting,
  } = useProvideRequest();
  const { ReadProduct, product, productError } = useProvideProduct();
  const {
    addCart,
    user,
    cartSubmitting,
    company,
    setConfirmationDialog,
  } = useContext(ConfigContext);
  const { showErrorSnackbar } = useSnackbar();

  const getStarted = {
    one: {
      content: [
        'Obtain approval/authorization documents to close a SGD and/or USD bank account',
      ],
    },
    two: {
      content: ['Name of intended bank', 'Bank account number to be closed'],
    },
    three: {
      content: [
        'Create Request',
        'Meyzer360 Review',
        'Sign Resolution',
        'ACRA Filing',
      ],
    },
    four: {
      content: ['SGD' + product.price],
    },
    five: {
      content: [
        'Fill up form: < 2 minutes',
        'Average processing time: 3-5 days',
      ],
    },
  };

  useEffect(() => {
    // get latest product
    ReadProduct(18);
  }, []);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const validationSchema = Yup.object({
    bankName: Yup.string().required('Bank is required'),
    otherbankname: Yup.string().when('bankName', {
      is: () => bankName === 'others',
      then: Yup.string().required('Bank Name is required'),
    }),
    accountnum: Yup.string().required('Account Number is required'),
  });

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.company_id;
    values.company_application_status_id = 11;
    values.created_by = user.user_id;
    values.bank_name = bankName === 'others' ? values.otherbankname : bankName;
    values.bank_account_number = values?.accountnum;
    let res = await CreateCloseBankAccApplication(values);
    if (res.status === 'success') {
      res = await addCart({
        price: product.price,
        product_id: product.id,
        quantity: 1,
        user_id: user.user_id,
        product_detail_id:
          res.body.applications[0].open_bank_account_application_id,
        company_id: company.company_id,
        company_name: company.company_name,
        company_registration_no: company.company_registration_no,
        company_application_id: res.body.applications[0].company_application_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setConfirmationDialog({
          isVisible: true,
          title: 'Item added to cart',
          type: 'success',
          cancelButtonLabel: 'Back to Request',
          confirmButtonLabel: 'View your cart',
          confirmButtonAction: () => {
            history.push(ROUTES.PAYMENTCART);
            setConfirmationDialog({
              isVisible: false,
            });
          },
          cancelButtonAction: () => {
            history.push(ROUTES.CORPSEC_REQ);
            setConfirmationDialog({
              isVisible: false,
            });
          },
        });
      }
    }
  };

  const stepTwo = () => {
    return (
      <Formik
        initialValues={{
          bankName: '',
          otherbankname: '',
          accountnum: '',
        }}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <FormRadioGroup
              name="bankName"
              options={[
                { value: 'dbs', label: 'DBS Bank' },
                { value: 'ocbc', label: 'OCBC Bank' },
                { value: 'uob', label: 'UOB Bank' },
                { value: 'others', label: 'Others' },
              ]}
              label={'Select Bank (Must tick ONE box)'}
              onChange={(event) => {
                setBankName(event.target.value);
                props.setFieldValue('bankName', event.target.value);
              }}
            />
            {bankName === 'others' && (
              <FormTextField
                name="otherbankname"
                labelText="Enter Bank Name"
                fullWidth={smBelow ? true : false}
                shrink
                variant="outlined"
              />
            )}
            <Box p={1} />
            <FormTextField
              name="accountnum"
              labelText="Bank Account Number"
              fullWidth={smBelow ? true : false}
              shrink
              variant="outlined"
            />
            <Box display="flex" pt={3} justifyContent="flex-start">
              <MzButton
                fullWidth={smBelow ? true : false}
                type="submit"
                title={'SUBMIT'}
                onClick={() => {
                  setStep(2);
                }}
                loading={cartSubmitting || requestSubmitting}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={step}
        setStep={setStep}
        getStarted={getStarted}
        header={
          <>
            <Typography variant="h2">
              {step === 1 && 'Closing of Bank Account'}
              {step === 2 && 'Fill in Information for Close Bank Account'}
            </Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Requests', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Filing of Other Resolutions',
                  route: ROUTES.CORPSEC_REQ_FILLING_OTHER_RESOLUTIONS,
                },
                {
                  path: 'Close Bank Account ',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        {step === 2 && (
          <>
            <Box px={4} py={4} style={{ borderTop: '0.01rem solid #E4E4E4' }}>
              {stepTwo()}
            </Box>
          </>
        )}
        <Box py={5} />
      </GetStartedLayout>

      {/* <Formik
        initialValues={{
          bankName: '',
          otherbankname: '',
          accountnum: '',
        }}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Box
              flexDirection="column"
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: '100%' }}
            >
              {step === 0 && stepOne()}
              {step === 1 && stepTwo()}
              <Box p={2} />
              {step === 1 && (
                <MzButton
                  disabled={
                    bankName === 'others'
                      ? !(props.isValid && props.dirty)
                      : !(bankName && props.isValid && props.dirty)
                  }
                  type="submit"
                  onClick={() => {}}
                  title={'Submit'}
                  loading={cartSubmitting || requestSubmitting}
                />
              )}
            </Box>
          </Form>
        )}
      </Formik>
      <Box p={4} /> */}
    </>
  );
};

export default CloseBankAccount;
