import axios from './customAxios';

const products = async () => {
  const { data } = await axios.get(
    `https://9qta428pn8.execute-api.ap-southeast-1.amazonaws.com/Prod/products`,
  );

  if (data && !data.is_success) throw Error(data?.message);

  return data?.products;
};

const productSvc = { products };

export default productSvc;
