import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Typography,
  Box,
  InputAdornment,
  Tabs,
  Tab,
  Divider,
  Paper,
  ButtonBase,
} from '@material-ui/core';
import { StringMasking } from 'src/utils/string';
import { useTheme } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import Stepper from 'src/components/Stepper';
import MzButton from 'src/components/MzButton';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import { FormFileDropzone, FormDatePicker } from 'src/components/Form';
import FormDropdownTextField from 'src/components/FormDropdownTextField/FormDropdownTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import { useProvideCompany } from 'src/hooks/useCompany';
import { useProvideUser } from 'src/hooks/useUsers';
import { useProvideUserAddresses } from 'src/hooks/useUserAddresses';
import { useProvideData } from 'src/hooks/useData';
import { useProvideRequest } from 'src/hooks/useRequests';
import FormDropdown from 'src/components/FormDropdown/FormDropdown.js';
import { S3UploadAndInsertDocuments } from 'src/utils/aws';
import useSnackbar from 'src/hooks/useSnackbar';
import { useProvideProduct } from 'src/hooks/useProduct';
import { ConfigContext } from 'src/contexts';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { EnhancedIcon } from 'src/components/Enhanced';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined';
import CheckboxField from 'src/components/CheckboxField';
import OtpAlert from 'src/components/OtpAlert';

const tempSteps = [
  {
    id: 1,
    label: 'Change Address',
  },
  {
    id: 2,
    label: 'Change Company Name',
  },
  {
    id: 3,
    label: 'Change Registration No',
  },
  {
    id: 4,
    label: 'Change Country of Incorporation',
  },
];

const ChangeShareholderParticulars = ({ toggleShowMenu }) => {
  const history = useHistory();
  const params = useParams();
  const theme = useTheme();
  const {
    company,
    GetCompanyByID,
    companyError,
    ReadCompanyAddresses,
    companyAddressess,
  } = useProvideCompany();
  const { countries, idTypes, ListIDType, ListCountries } = useProvideData();
  const { user, userContacts, GetUserContact, GetUserByID } = useProvideUser();
  const {
    CreateUpdateParticularsApplication,
    requestError,
  } = useProvideRequest();
  // const { userAddressess, ListUserAddressess } = useProvideUserAddresses();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const {
    addCart,
    user: loggedInUser,
    company: currentCompany,
    setConfirmationDialog,
  } = useContext(ConfigContext);
  const { ReadProduct, product, productError } = useProvideProduct();
  const [submitting, setSubmitting] = useState(false);
  const buttonClasses = useButtonStyle();
  const [tabValue, setTabValue] = useState(0);
  const [step, setStep] = useState(0);
  const { smBelow } = useBreakpoints();
  const [checkedAddress, setCheckedAddress] = useState(false);
  const [checkedName, setCheckedName] = useState(false);
  const [checkedRegisNo, setCheckedRegisNo] = useState(false);
  const [checkedCountryIncorp, setCheckedCountryIncorp] = useState(false);

  const [otpVerification, setOtpVerification] = useState(false);
  const stepformRef = useRef(null);

  const [address, setAddress] = useState({});
  const [companyRegisNo, setCompanyRegisNo] = useState('');
  const [name, setName] = useState('');
  const [countryIncorp, setCountryIncorp] = useState('');

  useEffect(() => {
    GetCompanyByID(params.id);
    ReadCompanyAddresses(params.id);
    ListIDType();
    ListCountries();
    ReadProduct(9);
  }, []);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (companyError) {
      showErrorSnackbar(`Error loading company. ${companyError}`);
    }
  }, [companyError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const step1validationSchema = Yup.object({
    address_line_1: Yup.string().when('checkedAddress', {
      is: () => checkedAddress,
      then: Yup.string().required('Address Line 1 is required'),
    }),
    postal_code: Yup.string().when('checkedAddress', {
      is: () => checkedAddress,
      then: Yup.string().required('Postal Code is required'),
    }),
    country_id: Yup.string().when('checkedAddress', {
      is: () => checkedAddress,
      then: Yup.string().required('Country is required'),
    }),
    proofOfResidency: Yup.array().when('checkedAddress', {
      is: () => checkedAddress,
      then: Yup.array().min(1, 'Proof of Residency is required'),
    }),
  });

  const step2validationSchema = Yup.object({
    company_name: Yup.string().when('checkedName', {
      is: () => checkedName,
      then: Yup.string().required('Company Name is required'),
    }),
  });

  const step3validationSchema = Yup.object({
    company_registration_no: Yup.string().when('checkedRegisNo', {
      is: () => checkedName,
      then: Yup.string().required('Company Registration No is required'),
    }),
  });

  const step4validationSchema = Yup.object({
    country_id: Yup.string().when('checkedCountryIncorp', {
      is: () => checkedCountryIncorp,
      then: Yup.string().required('Country is required'),
    }),
  });

  const getBusinessAddress = () => {
    return userAddressess.find(
      (address) => address.address_type_name === 'Business',
    );
  };

  const getContactEmail = () => {
    return userContacts.find(
      (contact) => contact.contact_type_name === 'email',
    );
  };

  const getContactMobile1 = () => {
    return userContacts.find(
      (contact) => contact.contact_type_name === 'mobile1',
    );
  };

  const getDefaultCountry = (countryID) => {
    return countries.find((country) => country.country_id === countryID);
  };

  const getDefaultIdType = (typeId) => {
    return idTypes.find((type) => type.value === typeId);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        style={{ width: '100%' }}
        hidden={value !== index}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  };

  const stepOne = () => {
    return (
      <Formik
        initialValues={{
          postal_code: address.postal_code,
          floor_unit_no: address.floor_unit_no,
          address_line_1: address.address_line_1,
          address_line_2: address.address_line_2,
          address_line_3: address.address_line_3,
          country_id: address.country_id,
          proofOfResidency: address.proofOfResidency,
        }}
        enableReinitialize
        onSubmit={step1formikOnSubmit}
        validationSchema={step1validationSchema}
        innerRef={stepformRef}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Box p={3}>
              <>
                <CheckboxField
                  checkboxLabel="Change Address"
                  checked={checkedAddress}
                  onCheckedChange={(event) =>
                    setCheckedAddress(event.target.checked)
                  }
                  checkboxList={[
                    {
                      currentLabel: 'CURRENT FLOOR / UNIT NO',
                      currentValue: companyAddressess[0]?.floor_unit_no,
                      child: (
                        <FormTextField
                          name="floor_unit_no"
                          labelText="NEW FLOOR / UNIT NO"
                          placeholder="#90-9"
                          containerWidth="50%"
                          shrink
                          variant="outlined"
                          disabled={!checkedAddress}
                        />
                      ),
                    },
                    {
                      currentLabel: 'CURRENT ADDRESS LINE 1',
                      currentValue: companyAddressess[0]?.address_line_1,
                      child: (
                        <FormTextField
                          name="address_line_1"
                          labelText="NEW ADDRESS LINE 1"
                          placeholder="Orchard Road"
                          containerWidth="50%"
                          shrink
                          variant="outlined"
                          disabled={!checkedAddress}
                        />
                      ),
                    },
                    {
                      currentLabel: 'CURRENT ADDRESS LINE 2',
                      currentValue: companyAddressess[0]?.address_line_2,
                      child: (
                        <FormTextField
                          name="address_line_2"
                          labelText="NEW ADDRESS LINE 2"
                          placeholder="Ngee Ann City Tower A"
                          containerWidth="50%"
                          shrink
                          variant="outlined"
                          disabled={!checkedAddress}
                        />
                      ),
                    },
                    {
                      currentLabel: 'CURRENT ADDRESS LINE 3',
                      currentValue: companyAddressess[0]?.address_line_3,
                      child: (
                        <FormTextField
                          name="address_line_3"
                          labelText="NEW ADDRESS LINE 3"
                          containerWidth="50%"
                          shrink
                          variant="outlined"
                          disabled={!checkedAddress}
                        />
                      ),
                    },
                    {
                      currentLabel: 'CURRENT POSTCODE',
                      currentValue: companyAddressess[0]?.postal_code,
                      child: (
                        <FormTextField
                          name="postal_code"
                          labelText="NEW POSTCODE"
                          placeholder="542902"
                          containerWidth="50%"
                          shrink
                          variant="outlined"
                          disabled={!checkedAddress}
                        />
                      ),
                    },
                    {
                      currentLabel: 'CURRENT COUNTRY',
                      currentValue: companyAddressess[0]?.country_name,
                      child: (
                        <Box
                          display="flex"
                          alignItems="flex-start"
                          flexDirection="column"
                          width={'50%'}
                        >
                          <FormDropdown
                            autoCompleteStyle={{ width: '100%' }}
                            name="country_id"
                            onChange={(event, newValue) => {
                              props.setFieldValue(
                                'country_id',
                                newValue?.value,
                              );
                            }}
                            optionList={countries}
                            labelText="NEW COUNTRY"
                            placeholder="AUSTRALIA"
                            shrink
                            variant="outlined"
                            defaultValue={getDefaultCountry(
                              props.values.country_id,
                            )}
                            disabled={!checkedAddress}
                          />
                        </Box>
                      ),
                    },
                    {
                      currentLabel: 'PROOF OF RESIDENCY (MUST BE IN ENGLISH)',
                      currentValue: ' ',
                      vertical: true,
                      child: (
                        <FormFileDropzone
                          containerWidth="50%"
                          name="proofOfResidency"
                          disabled={!checkedAddress}
                        />
                      ),
                    },
                  ]}
                />
              </>
            </Box>

            <Box display="flex" justifyContent="flex-start" pl={3}>
              <MzButton onClick={() => {}} type="submit" title={'Continue'} />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const stepTwo = () => {
    return (
      <Formik
        initialValues={{
          company_name: company.company_name,
        }}
        enableReinitialize
        onSubmit={step2formikOnSubmit}
        validationSchema={step2validationSchema}
        innerRef={stepformRef}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Divider />
            <Box p={3}>
              {!!company.company_name && (
                <>
                  <CheckboxField
                    checkboxLabel="Change Company Name"
                    checked={checkedName}
                    onCheckedChange={(event) =>
                      setCheckedName(event.target.checked)
                    }
                    checkboxList={[
                      {
                        currentLabel: 'CURRENT',
                        currentValue: company.company_name,
                        child: (
                          <FormTextField
                            name="company_name"
                            labelText="NEW"
                            placeholder="XYZ Company"
                            containerWidth="50%"
                            shrink
                            variant="outlined"
                            disabled={!checkedName}
                          />
                        ),
                      },
                    ]}
                  />
                </>
              )}
            </Box>

            <Box display="flex" justifyContent="flex-start" pl={3}>
              <MzButton onClick={() => {}} type="submit" title={'Continue'} />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const stepThree = (props) => {
    return (
      <Formik
        initialValues={{
          company_registration_no: company.company_registration_no,
        }}
        enableReinitialize
        onSubmit={step3formikOnSubmit}
        validationSchema={step3validationSchema}
        innerRef={stepformRef}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Divider />
            <Box p={3}>
              {!!user && (
                <>
                  <CheckboxField
                    checkboxLabel="Change Registration No"
                    checked={checkedRegisNo}
                    onCheckedChange={(event) =>
                      setCheckedRegisNo(event.target.checked)
                    }
                    checkboxList={[
                      {
                        currentLabel: 'CURRENT',
                        currentValue: company.company_registration_no,
                        child: (
                          <FormTextField
                            name="company_registration_no"
                            labelText="NEW"
                            placeholder="SG 123456"
                            containerWidth="50%"
                            shrink
                            variant="outlined"
                            disabled={!checkedRegisNo}
                          />
                        ),
                      },
                    ]}
                  />
                </>
              )}
            </Box>

            <Box display="flex" justifyContent="flex-start" pl={3}>
              <MzButton onClick={() => {}} type="submit" title={'Continue'} />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const stepFour = () => {
    return (
      <Formik
        initialValues={{
          country_id: companyAddressess[0]?.country_id,
        }}
        enableReinitialize
        onSubmit={formikOnSubmit}
        validationSchema={step4validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Divider />
            <Box p={3}>
              <CheckboxField
                checkboxLabel="Change Country of Incorporation"
                checked={checkedCountryIncorp}
                onCheckedChange={(event) =>
                  setCheckedCountryIncorp(event.target.checked)
                }
                checkboxList={[
                  {
                    currentLabel: 'CURRENT COUNTRY',
                    currentValue: companyAddressess[0]?.country_name,
                    child: (
                      <Box
                        display="flex"
                        alignItems="flex-start"
                        flexDirection="column"
                        width={'50%'}
                      >
                        <FormDropdown
                          autoCompleteStyle={{ width: '100%' }}
                          name="country_id"
                          onChange={(event, newValue) => {
                            props.setFieldValue('country_id', newValue?.value);
                          }}
                          optionList={countries}
                          labelText="NEW COUNTRY"
                          placeholder="AUSTRALIA"
                          shrink
                          variant="outlined"
                          defaultValue={getDefaultCountry(
                            props.values.country_id,
                          )}
                          disabled={!checkedCountryIncorp}
                        />
                      </Box>
                    ),
                  },
                ]}
              />
            </Box>

            <Box display="flex" justifyContent="flex-start" pl={3}>
              <MzButton
                loading={submitting}
                onClick={() => {}}
                type="submit"
                title={'Submit'}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const step1formikOnSubmit = async (values, actions) => {
    if (checkedAddress) setAddress(values);
    setStep((prevState) => {
      return prevState + 1;
    });
  };

  const step2formikOnSubmit = async (values, actions) => {
    if (checkedName) setName(values.company_name);
    setStep((prevState) => {
      return prevState + 1;
    });
  };

  const step3formikOnSubmit = async (values, actions) => {
    if (checkedRegisNo) setCompanyRegisNo(values.company_registration_no);
    setStep((prevState) => {
      return prevState + 1;
    });
  };

  const formikOnSubmit = async (values, actions) => {
    if (checkedCountryIncorp) setCountryIncorp(values);
    // TODO: pending API
    return;
  };

  return (
    <>
      <OtpAlert
        isVisible={otpVerification}
        onBackdropPress={() => setOtpVerification(false)}
        onClick={() => setOtpVerification(false)}
        onPressResend={() => {}}
        onPressContactUs={() => {}}
      />
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={2}
        setStep={() => {
          history.push(ROUTES.CORPSEC_REQ_SHAREHOLDER_LIST);
        }}
        header={
          <>
            <Typography variant="h2">Update Shareholder Particulars</Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Requests', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Change Personal Particulars',
                  route: ROUTES.CORPSEC_REQ_SHAREHOLDER_LIST,
                },
                {
                  path: 'Change Shareholder Particulars',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        <Stepper
          steps={tempSteps}
          providedStep={step}
          setProvidedStep={async (newStep) => {
            if (stepformRef.current) {
              await stepformRef.current.validateForm();

              if (!stepformRef.current.isValid) {
                stepformRef.current.handleSubmit();
                return;
              }
              setStep(newStep);
            } else {
              setStep(newStep);
            }
          }}
        />
        {step === 0 && stepOne()}
        {step === 1 && stepTwo()}
        {step === 2 && stepThree()}
        {step === 3 && stepFour()}
        <Box p={2} />
      </GetStartedLayout>
    </>
  );
};

export default ChangeShareholderParticulars;
