import React, { useRef, useState, useEffect } from 'react';
import './styles.css';

export const Image = React.forwardRef((props, ref) => {
  const {
    src = '',
    alt = '',
    className = '',
    style = {},
    ...restProps
  } = props;

  const placeholderImageSrc = '/assets/';
  const [imageRef, { error, retry }] = useImageError(ref);
  const [finalImageSrc, setFinalImageSrc] = useState();

  // useEffect(() => {
  //   if (retry) {
  //     retry();
  //   }
  // }, [src]);
  useEffect(() => {
    let withImageSrc = src != undefined && src != null && src != '';
    // let imageSrc = error || !src ? placeholderImageSrc : src;
    if (withImageSrc) {
      setFinalImageSrc(src);

      if (retry && error) {
        retry();
      }
    } else {
      setFinalImageSrc(placeholderImageSrc);
    }
  }, [src]);

  return (
    <img
      ref={imageRef}
      // src={imageSrc}
      src={error ? placeholderImageSrc : finalImageSrc}
      alt={alt}
      className={`${className}`}
      style={{ ...style }}
      {...restProps}
    />
  );
});

const useImageError = (ref) => {
  const innerRef = useRef();
  const imageRef = ref || innerRef;
  const [error, setError] = useState(false);
  // console.log('useImageErroruseImageError', {
  //   ref,
  //   innerRef,
  // });
  const _handleError = () => {
    setError(true);
  }; // set error
  const retry = () => {
    setError(false);
  }; // set error false to img can re-render

  useEffect(() => {
    imageRef?.current?.addEventListener('error', _handleError);
    return () => {
      imageRef?.current?.removeEventListener('error', _handleError);
    };
  }, [imageRef]);

  return [imageRef, { error, retry }];
};

export const RatioContainer = (props) => {
  const {
    ratio = '100%',
    className = '',
    style = {},
    children,
    ...restProps
  } = props;
  return (
    <div
      className={`${className}`}
      style={{ '--aspect-ratio': ratio, ...style }}
      {...restProps}
    >
      {children}
    </div>
  );
};

const ImageContainer = React.forwardRef((props, ref) => {
  const {
    src = `${process.env.PUBLIC_URL}/SecretarialServiceImageHD.jpg`,
    alt,
    ratio,
    fill = true,
    className = '',
    ...restProps
  } = props;
  if (ratio) {
    return (
      <RatioContainer ratio={ratio} className={`${className}`} {...restProps}>
        <Image
          ref={ref}
          src={src}
          alt={alt}
          className={`${fill ? 'image-container-fill' : ''}`}
        />
      </RatioContainer>
    );
  }
  // console.log('ImageContainer ref', ref);
  return (
    <Image
      ref={ref}
      src={src}
      alt={alt}
      className={`${fill ? 'image-container-fill' : ''} ${className}`}
      {...restProps}
    />
  );
});

export default ImageContainer;
