import { GetErrorMessage } from 'src/utils/error';
import axios from 'axios';
import { Cookies } from 'react-cookie';

export const APIRequest = async ({
  setError,
  setLoading,
  path,
  method,
  body,
  headers = {},
}) => {
  const accessToken = new Cookies().get('accessToken');

  setLoading(true);
  setError(undefined);
  let res;
  try {
    res = await axios({
      method,
      url: path,
      data: body,
      headers: {
        Authorization: accessToken,
        ...headers,
      },
    });
    setLoading(false);
  } catch (err) {
    const errMsg = GetErrorMessage(err);
    setError(errMsg);
    setLoading(false);
    return {
      message: errMsg,
      status: 'rejected',
    };
  }
  // finally {
  //   setLoading(false);
  // }
  return {
    status: 'success',
    body: res.data,
  };
};
