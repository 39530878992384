import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const StepFormContentWrapper = ({
  stepTitle,
  contentTitle,
  hideHeader = false,
  children,
}) => {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      {!hideHeader && (
        <>
          {/* Header */}
          <Box textAlign="center" py={3} px={2}>
            <Box>
              <Typography variant="caption">{stepTitle}</Typography>
            </Box>
            <Box>
              <Typography variant="h4" style={{ fontWeight: '700' }}>
                {contentTitle}
              </Typography>
            </Box>
          </Box>
          {stepTitle || contentTitle ? <Divider /> : null}
        </>
      )}
      {/* Content */}
      <Box flexGrow={1} py={2}>
        {children}
      </Box>
    </Box>
  );
};

export default StepFormContentWrapper;
