import React, { useEffect, useRef } from 'react';
import { TextField } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

import FormFieldContainer from '../FormFieldContainer';

const StyledTextField = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      // '& fieldset': {
      borderRadius: `4px`,
      // },
      backgroundColor: '#fff',
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0',
    },
  },
}))(TextField);

const useStyles = makeStyles(() => {
  return {
    fieldBorder: {
      borderStyle: 'solid',
      borderRadius: '4px',
      borderWidth: '1px',
    },
  };
});
const CustomPhoneNumberField = (props) => {
  const {
    label,
    name,
    phoneCodeName = 'contact_phone_code',
    containerProps: defaultContainerProps = {},
    helperText,
    children,
    ...restProps
  } = props;

  const classes = useStyles();

  let fieldProps = {
    name: name,
    variant: 'outlined',
    fullWidth: true,
    size: 'small',
    ...restProps,
  };

  function formatPhoneNumberOutput(
    isValid,
    newNumber,
    countryData,
    fullNumber,
    isExtension,
  ) {
    if (isValid && fullNumber) {
      return fullNumber.replace(/(\s|-)/g, '');
    }
    return 'invalid_phone_number'; // caught by validator
  }

  return (
    <Field name={name}>
      {({ field, meta, form }) => {
        const { setFieldValue, setFieldTouched } = form;
        const { value, onChange } = field;
        let error = meta.touched && !!meta.error;

        return (
          <FormFieldContainer
            label={label}
            error={error}
            helperText={meta.touched && meta.error ? meta.error : ''}
            {...defaultContainerProps}
          >
            {/* <input {...field} hidden /> */}
            <IntlTelInput
              // style={error ? { borderColor: 'red' } : {}}
              // value={value}
              // containerClassName={`intl-tel-input MuiInputBase-root MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-marginDense MuiOutlinedInput-marginDense MuiOutlinedInput-notchedOutline ${classes.fieldBorder}`}
              // inputClassName="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense"
              // preferredCountries={['sg', 'my']}
              // onPhoneNumberFocus={(e) => {
              //   console.log('aaaa', e);
              // }}
              // onSelectFlag={(
              //   currentNumber,
              //   selectedCountryData,
              //   fullNumber,
              //   isValid,
              // ) => {
              //   setFieldValue(phoneCodeName, selectedCountryData.dialCode);
              //   setFieldValue(name, selectedCountryData.dialCode);
              // }}
              // onPhoneNumberChange={(
              //   isValid,
              //   rawValue,
              //   countryData,
              //   formattedValue,
              //   extension,
              // ) => {
              //   setFieldValue(name, rawValue);
              // }}

              containerClassName={`intl-tel-input MuiInputBase-root MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-marginDense MuiOutlinedInput-marginDense MuiOutlinedInput-notchedOutline ${classes.fieldBorder}`}
              inputClassName="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense"
              style={error ? { borderColor: 'red' } : {}}
              defaultCountry="sg"
              defaultValue={value}
              value={value}
              fieldId={name}
              fieldName={name}
              onSelectFlag={(
                currentNumber,
                selectedCountryData,
                fullNumber,
                isValid,
              ) => {
                setFieldValue(phoneCodeName, selectedCountryData.dialCode);
                setFieldValue(name, selectedCountryData.dialCode);
              }}
              onPhoneNumberChange={(
                isValid,
                rawValue,
                countryData,
                formattedValue,
                extension,
              ) => {
                console.log('phoneValues', {
                  isValid,
                  rawValue,
                  countryData,
                  formattedValue,
                  extension,
                });
                setFieldValue(name, rawValue);
              }}
              // onPhoneNumberChange={(...args) => {
              //   setFieldValue(name, formatPhoneNumberOutput(...args));
              // }}
              preferredCountries={['sg', 'my']}
            />
            {children}
          </FormFieldContainer>
        );
      }}
    </Field>
  );
};

export default CustomPhoneNumberField;
