import React, { useState, useEffect, useContext } from 'react';
import {
  Typography,
  Paper,
  Box,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { withStyles, useTheme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { ConfigContext } from 'src/contexts';
import { sgdFormat } from 'src/utils/currency';
import { useProvideOrder } from 'src/hooks/useOrder';
import useQueryParams from 'src/hooks/useQueryParams';
import ROUTES from 'src/constants/routes';

const StyledTableHeadCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[200],
    // color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  //   body: {
  //     fontSize: 14,
  //   },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontWeight: 'normal',
  },
}))(TableCell);

//   const StyledTableRow = withStyles((theme) => ({
//     root: {
//       '&:nth-of-type(odd)': {
//         backgroundColor: theme.palette.action.hover,
//       },
//     },
//   }))(TableRow);

const OrderComplete = (props) => {
  const { onSubmit } = props;
  const theme = useTheme();
  const history = useHistory();
  const { cartList, cart, removeCart, webInfo, user, fetchCart } = useContext(
    ConfigContext,
  );
  const {
    ReadOrder,
    GetOrderAddressById,
    order,
    orderLoading,
  } = useProvideOrder();
  // const params = useParams();
  const queryParams = useQueryParams();

  let isPaymentRedirect = queryParams.get('redirect');

  let subtotal = 0;
  order?.items?.forEach((anItem) => {
    subtotal += anItem?.price || 0;
  });
  useEffect(() => {
    if (isPaymentRedirect) {
      ReadOrder(isPaymentRedirect);
    }
  }, [isPaymentRedirect]);

  //   billing_address_id: null
  // cart_id: 679
  // company_name: "Pet's Shop "
  // company_registration_no: "55666Singapore "
  // created_at: "2022-06-20 15:43:39"
  // created_by: 115
  // discount: null
  // email: "emilylai057@gmail.com"
  // first_name: "Emily "
  // id: 708

  // items: Array(1)
  // 0:
  // company_application_id: 2990
  // created_at: "2022-06-20 15:43:39"
  // created_by: 115
  // id: 1170
  // misc_details: null
  // order_id: 708
  // price: 100
  // product: {id: 21, name: 'Transfer of Shares', description: 'Price', price: 100, created_by: 0, …}
  // product_id: 21
  // quantity: 1
  // status: null
  // updated_at: null
  // updated_by: null
  // [[Prototype]]: Object
  // length: 1
  // [[Prototype]]: Array(0)

  // last_name: "Lai"
  // payment_method: null
  // phone: null
  // product_detail_id: 169
  // remark: ""
  // shipping_address_id: null
  // shipping_fee: null
  // status: "PAID"
  // tax: null
  // totalPrice: 100
  // updated_at: "2022-06-20 15:44:16"
  // updated_by: null
  // user_id: 115

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={7} md={8}>
        <Box p={2}>
          <Box mb={2}>
            <Typography variant="h1">Order Completed!</Typography>
            <Box color={theme.palette.grey[400]} mt={1}>
              <Typography variant="h4">
                Thank you. Your order has been received.
              </Typography>
            </Box>
          </Box>
          <Box mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box fontWeight="normal">ORDER NUMBER:</Box>
                <Box>
                  <b>{order?.id || '-'}</b>
                </Box>
              </Grid>
              <Grid item xs={4} component={Box} pl={0}>
                <Box style={{ borderLeft: '1px dotted black' }} pl={1}>
                  <Box fontWeight="normal">DATE:</Box>
                  <Box>
                    <b>
                      {order?.created_at
                        ? moment(order?.created_at).format('MMM DD, YYYY')
                        : '-'}
                    </b>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4} component={Box} pl={0}>
                <Box style={{ borderLeft: '1px dotted black' }} pl={1}>
                  <Box fontWeight="normal">PAYMENT METHOD:</Box>
                  <Box>
                    <b>{order?.payment_method || '-'}</b>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mb={4}>
            <Box mb={1}>
              <Typography variant="h2">Order Details</Typography>
            </Box>
            <TableContainer component={Paper} elevation={1}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableHeadCell colSpan={2}>
                      <Typography variant="h4">Product / Service</Typography>
                    </StyledTableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order?.items?.map((anItem, index) => (
                    <TableRow key={index}>
                      <StyledTableCell>{anItem?.product?.name}</StyledTableCell>
                      <StyledTableCell align="right">
                        {sgdFormat(anItem?.price)}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <StyledTableCell>Subtotal</StyledTableCell>
                    <StyledTableCell align="right">
                      {subtotal != undefined ? sgdFormat(subtotal) : '-'}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>Shipping Fee</StyledTableCell>
                    <StyledTableCell align="right">
                      {order?.shipping_fee != undefined
                        ? sgdFormat(order?.shipping_fee)
                        : '-'}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>
                      <b>Total Amount</b>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <b>{sgdFormat(order?.totalPrice)}</b>
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {/* <Paper
              elevation={0}
              variant={smBelow ? 'elevation' : 'outlined'}
              style={{
                borderRadius: smBelow ? '0px' : '4px',
                flex: '1 1 0%',
              }}
            >
              <List subheader={<li />}>
                <ListItem
                  style={{
                    backgroundColor: '#F5F5F5',
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        whiteSpace: 'normal',
                        fontWeight: 'bold',
                      },
                      variant: 'body2',
                    }}
                    primary={'Order Summary'}
                  />
                </ListItem>
                <Divider />
                {cartList.length !== 0 &&
                  cartList.map((item, index) => (
                    <>
                      <ListItem key={item.id}>
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              whiteSpace: 'normal',
                            },
                            variant: 'body2',
                          }}
                          primary={`${item?.product?.name}`}
                        />

                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            S$ {item.price}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </>
                  ))}
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        whiteSpace: 'normal',
                        fontWeight: 'bold',
                      },
                      variant: 'body2',
                    }}
                    primary={'Total'}
                  />
                  <ListItemSecondaryAction>
                    <Typography style={{ fontWeight: 'bold' }} variant="body2">
                      S$ {totalPrice}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Paper> */}
          </Box>
          <Box display="flex">
            <Box mr={2}>
              <Button
                variant="contained"
                //   color="initial"
                disableElevation={true}
                style={{ textTransform: 'none' }}
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                  history.push(ROUTES.OFFICE_SUPPLIES);
                }}
              >
                Continue Shopping
              </Button>
            </Box>
            <Button
              variant="contained"
              color="primary"
              disableElevation={true}
              style={{ textTransform: 'none' }}
              onClick={() => {
                history.push(
                  `${ROUTES.ORDERHISTORYDETAILS(isPaymentRedirect)}`,
                );
              }}
              disabled={!isPaymentRedirect}
            >
              Track Your Order
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={5} md={4}>
        {' '}
      </Grid>
    </Grid>
  );
};

export default OrderComplete;
