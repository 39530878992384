import React, { useState } from 'react';
import { Box, Grid, Button, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const MembershipCheckoutResult = (props) => {
  const { title, description, onClick } = props;
  const theme = useTheme();

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else {
    }
  };
  return (
    <Grid
      container
      style={{
        justifyContent: 'center',
      }}
    >
      <Grid item xs={12} sm={6}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box
            border={1}
            borderColor={theme.palette.grey[300]}
            display="flex"
            alignItems="center"
            flexDirection="column"
            textAlign="center"
            p={3}
          >
            <Box p={5}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box
                  borderRadius={'50%'}
                  border={1}
                  width="70px"
                  height="70px"
                  mb={2}
                  p={2}
                >
                  <CheckIcon color="primary" fontSize="large" />
                </Box>
              </Box>
              <Box mb={1}>Thank you!</Box>
              <Box mb={1}>
                <Typography variant="h2">
                  <b>{title || 'Subscription Successful'}</b>
                </Typography>
              </Box>
              <Box mb={2} color={theme.palette.grey[600]}>
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 'normal',
                  }}
                >
                  {description ||
                    'Get the process lorem ipsum dolor sit amet set started in less than 10 minutes. Let us handle the rest.'}
                </Typography>
              </Box>
            </Box>
            <Button
              variant="contained"
              color="primary"
              disableElevation={true}
              fullWidth={true}
              size="large"
              style={{ textTransform: 'none' }}
              onClick={handleOnClick}
            >
              View Your Plan
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MembershipCheckoutResult;
