import React, { useState, useContext, useEffect } from 'react';
import { Typography, Box, Button, Paper } from '@material-ui/core';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import FormDropdownTextField from 'src/components/FormDropdownTextField/FormDropdownTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import MzButton from 'src/components/MzButton';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import { useProvideRequest } from 'src/hooks/useRequests';
import useSnackbar from 'src/hooks/useSnackbar';
import { ConfigContext } from 'src/contexts';
import { useProvideProduct } from 'src/hooks/useProduct';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';

const ApptAdmin = ({ toggleShowMenu }) => {
  // const classes = useStyles();
  const buttonClasses = useButtonStyle();

  const history = useHistory();
  const [step, setStep] = useState(1);
  const [tabValue, setTabValue] = useState(0);
  const { smBelow } = useBreakpoints();
  const [showAlert, setShowAlert] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState({
    phone_number: '',
    country_code_number: '+65',
    country_code: 'SG',
  });

  const {
    CreateOfficerApplication,
    requestError,
    requestSubmitting,
  } = useProvideRequest();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { addCart, user, setConfirmationDialog, company } = useContext(
    ConfigContext,
  );
  const { ReadProduct, product, productError } = useProvideProduct();

  const getStarted = {
    one: {
      content: [
        'Appoint an administrator or authorized agent to your business',
        'An administrator is a designated individual who acts on behalf of company directors. Directors are still responsible for signing resolutions',
      ],
    },
    two: {
      content: [
        'Personal particulars of the new administrator',
        'Their local mobile number and email address',
      ],
    },
    three: {
      content: [
        'Create Request',
        'Meyzer360 Review',
        'Sign Resolution',
        'ACRA Filing',
      ],
    },
    four: {
      content: ['SGD' + product.price],
    },
    five: {
      content: [
        'Fill up form: < 2 minutes',
        'Average processing time: 1-2 days',
      ],
    },
  };

  const validationSchema = Yup.object({
    // name: Yup.string().required('Name is required'),
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    nric_passport_no: Yup.string().required('ID is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    mobile_number: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Mobile Number is required'),
  });

  useEffect(() => {
    // get latest product
    ReadProduct(4);
  }, []);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.company_id;
    values.company_application_status_id = 11; // in cart status
    // values.user_id = user.company_user_id;
    values.user_id = 0;
    values.created_by = user.user_id;
    values.mobile_number =
      phoneNumber.country_code_number + phoneNumber.phone_number;
    let res = await CreateOfficerApplication(values);

    if (res.status === 'success') {
      res = await addCart({
        type: 'Appointment of Administrator',
        price: product.price,
        product_id: product.id,
        quantity: 1,
        user_id: user.user_id,
        product_detail_id: res.body.applications.officer_application_id,
        company_id: company.company_id,
        company_name: company.company_name,
        company_registration_no: company.company_registration_no,
        company_application_status_id: values.company_application_status_id,
        company_application_id: res.body.applications.company_application_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setConfirmationDialog({
          isVisible: true,
          title: 'Item added to cart',
          type: 'success',
          cancelButtonLabel: 'Back to Request',
          confirmButtonLabel: 'View your cart',
          confirmButtonAction: () => {
            history.push(ROUTES.PAYMENTCART);
            setConfirmationDialog({
              isVisible: false,
            });
          },
          cancelButtonAction: () => {
            history.push(ROUTES.CORPSEC_REQ);
            setConfirmationDialog({
              isVisible: false,
            });
          },
        });
      }
    }
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          company_role_id: 15,
          name: '',
          nric_passport_no: '',
          email: '',
          mobile_number: '',
        }}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <FormTextField
              name="first_name"
              labelText="FIRST NAME (as in NRIC/FIN/PASSPORT) *"
              placeholder="First Name"
              shrink
              variant="outlined"
              fullWidth={smBelow ? true : false}
            />
            <FormTextField
              name="last_name"
              labelText="LAST NAME (as in NRIC/FIN/PASSPORT) *"
              placeholder="Last Name"
              shrink
              variant="outlined"
              fullWidth={smBelow ? true : false}
            />
            <FormTextField
              name="nric_passport_no"
              labelText="ID (NRIC/FIN/PASSPORT NO) *"
              placeholder="ID"
              shrink
              variant="outlined"
              fullWidth={smBelow ? true : false}
            />
            <FormDropdownTextField
              name="mobile_number"
              labelText="Mobile Number *"
              placeholder="91234567"
              shrink
              variant="outlined"
              showFlag={false}
              color="secondary"
              containerWidth={smBelow ? '100%' : '50%'}
              fullWidth
              onValueChange={(event, newValue) => {
                setPhoneNumber({
                  ...phoneNumber,
                  country_code_number: newValue.phone,
                  country_code: newValue.code,
                });
              }}
              countryCode={phoneNumber.country_code}
              onChange={(event) => {
                setPhoneNumber({
                  ...phoneNumber,
                  phone_number: event.target.value,
                });
                props.setFieldValue('mobile_number', event.target.value);
              }}
              inputProps={{
                form: {
                  autocomplete: 'off',
                },
              }}
            />
            <FormTextField
              name="email"
              labelText="Email Address *"
              placeholder="Email Address"
              shrink
              variant="outlined"
              fullWidth={smBelow ? true : false}
            />

            <Box display="flex" pt={3} justifyContent="flex-start">
              <MzButton
                fullWidth={smBelow ? true : false}
                type="submit"
                title={'SUBMIT'}
                onClick={() => {
                  setStep(2);
                }}
                loading={requestSubmitting}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={step}
        setStep={setStep}
        getStarted={getStarted}
        header={
          <>
            <Typography variant="h2">
              {step === 1 && 'Appointment of Administrator'}
              {step === 2 && 'Fill in Information for New Administrator'}
            </Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Request', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Change of Officers',
                  route: ROUTES.CORPSEC_REQ_CHANGE_OFFICER,
                },
                {
                  path: 'Appointment of Administrator',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        {step === 2 && (
          <>
            <Box px={4} py={4} style={{ borderTop: '0.01rem solid #E4E4E4' }}>
              {renderForm()}
            </Box>
          </>
        )}
        <Box py={5} />
      </GetStartedLayout>
    </>
  );
};

export default ApptAdmin;
