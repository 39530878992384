import React, { useState, useEffect, useRef } from 'react';
import { Typography } from '@material-ui/core';

import ContentLayout from 'src/components/ContentLayout';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import UpcomingService from 'src/components/UpcomingServiceForm';
import useQueryParams from 'src/hooks/useQueryParams';

const UpcomingServiceForm = () => {
  const queryParams = useQueryParams();
  let serviceLabel = queryParams.get('label');

  return (
    <ContentLayout
      header={
        <>
          <Typography variant="h2">{serviceLabel} Service</Typography>
          <BreadcrumbsNav pathList={[]} />
        </>
      }
    >
      <UpcomingService />
    </ContentLayout>
  );
};

export default UpcomingServiceForm;
