import { Box, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ConfigContext } from 'src/contexts';

import ActionList from 'src/components/ActionList';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import ROUTES from 'src/constants/routes';
import { useProvideUser } from 'src/hooks/useUsers';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';
import { useProvideProduct } from 'src/hooks/useProduct';

const DirectorList = ({ toggleShowMenu }) => {
  // const classes = useStyles();
  const { company } = useContext(ConfigContext);

  const history = useHistory();
  const [step, setStep] = useState(1);
  const [multipleProduct, setMultipleProduct] = useState([]);

  const { ListUserByCompanyMultipleRole, users } = useProvideUser();
  const { ReadProduct, product, productError } = useProvideProduct();

  useEffect(() => {
    let multiProd = [];
    // get latest local & foreign director
    ReadProduct(5) //local
      .then((res) => {
        multiProd.push(res.body.product);

        ReadProduct(78) //foreign
          .then((res) => {
            multiProd.push(res.body.product);
            setMultipleProduct(multiProd);
          })
          .catch((error) => {
            showErrorSnackbar('Failed load product, please try again later.');
          });
      })
      .catch((error) => {
        showErrorSnackbar('Failed load product, please try again later.');
      });
  }, []);

  const getStarted = {
    one: {
      content: [
        'Change the particulars of one of your company’s active directors',
      ],
    },
    two: {
      content: [
        'The updated particulars of the director',
        'For change of address, a copy of their proof of residency (in English) ',
        'For change of residency status, a copy of their NRIC/passport',
        'For change of name, a copy of their deed poll',
      ],
    },
    three: {
      content: [
        'Create Request',
        'Meyzer360 Review',
        'Sign Resolution',
        'ACRA Filing',
      ],
    },
    four: {
      content: [
        'Local: SGD' + multipleProduct[0]?.price,
        'Foreign: SGD' + multipleProduct[1]?.price,
      ],
    },
    five: {
      content: [
        'Fill up form: < 5 minutes',
        'Average processing time: 3-5 days',
      ],
    },
  };

  useEffect(() => {
    if (company.company_id && users.length === 0)
      ListUserByCompanyMultipleRole(company.company_id, 5, 2, 1, 14);
  }, [company]);

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={step}
        setStep={setStep}
        getStarted={getStarted}
        header={
          <>
            <Typography variant="h2">
              {step === 1 && 'Change in Director Particulars'}
              {step === 2 && 'Select Director'}
            </Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Requests', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Change Personal Particulars',
                  route: ROUTES.CORPSEC_REQ_CHANGE_PERSONAL_PARTICULARS,
                },
                {
                  path: 'Change Director Particulars',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        {step === 2 && (
          <ActionList
            itemList={users}
            buttonLabel="Edit"
            onClick={(directorId) => {
              history.push(
                ROUTES.CORPSEC_REQ_CHANGE_DIRECTOR_PARTICULARS(directorId),
              );
            }}
          />
        )}
      </GetStartedLayout>
      <Box p={3} />
    </>
  );
};

export default DirectorList;
