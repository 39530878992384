import SvgIcon from '@material-ui/core/SvgIcon';
import PersonIcon from '@material-ui/icons/Person';

// nav list
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

// top nav
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

// dashboard cards
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

// content
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';

import FolderSpecial from '@material-ui/icons/FolderSpecial';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import FindInPage from '@material-ui/icons/FindInPage';
import Attachment from '@material-ui/icons/Attachment';
import Web from '@material-ui/icons/Web';
import { ReactComponent as teachingIcon } from '../../assets/nav/teaching.svg';
import { ReactComponent as bookkeepingIcon } from '../../assets/nav/bookkeeping.svg';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import { ReactComponent as kycIcon } from '../../assets/nav/kyc.svg';
import { ReactComponent as digitalmarketingIcon } from '../../assets/nav/digitalmarketing.svg';
import { ReactComponent as payrollIcon } from '../../assets/nav/payroll.svg';
import { ReactComponent as taxIcon } from '../../assets/nav/tax.svg';
import VpnKey from '@material-ui/icons/VpnKey';

import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import PersonPinOutlinedIcon from '@material-ui/icons/PersonPinOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import TimelineOutlinedIcon from '@material-ui/icons/TimelineOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import FolderSpecialOutlinedIcon from '@material-ui/icons/FolderSpecialOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import DevicesOutlinedIcon from '@material-ui/icons/DevicesOutlined';
import FolderSharedOutlinedIcon from '@material-ui/icons/FolderSharedOutlined';
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import VpnLockOutlinedIcon from '@material-ui/icons/VpnLockOutlined';

const PersonSettingIcon = (props) => {
  return (
    <SvgIcon>
      <PersonIcon />
    </SvgIcon>
  );
};
export const navGroups = [
  {
    title: 'Main',
    children: [
      {
        title: 'Overview',
        icon: DashboardOutlinedIcon,
        path: '/overview',
      },
    ],
  },
  {
    title: 'Complicane & Corporate',
    children: [
      {
        title: 'Secretarial',
        icon: FolderSpecialOutlinedIcon,
        children: [
          {
            title: 'Dashboard',
            path: '/',
          },
          {
            title: 'Request',
            path: '/request',
          },
          {
            title: 'Document',
            path: '/document',
          },
          {
            title: 'Product',
            path: '/product',
          },
          {
            title: 'Tasklist',
            path: '/tasklist',
          },
          {
            title: 'Boardroom',
            path: '/boardroom',
          },
          {
            title: 'Cap Table',
            path: '/cap-table',
          },
          {
            title: 'Manage User',
            path: '/manage_user',
          },
          {
            title: 'Company Account Settings',
            path: '/company-account-settings',
          },
        ],
      },
    ],
  },
  {
    disabled: true,
    title: 'Data Protection',
    children: [
      {
        title: 'Data Protection',
        icon: VerifiedUserOutlinedIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    disabled: true,
    title: 'KYC / CDD',
    children: [
      {
        title: 'KYC / CDD',
        icon: PersonOutlinedIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    title: 'Business Banking',
    children: [
      {
        title: 'Bank Account',
        icon: AccountBalanceOutlinedIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    disabled: true,
    title: 'Marketing',
    children: [
      {
        title: 'Website Development',
        icon: DevicesOutlinedIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    disabled: true,
    title: 'Digital Marketing',
    children: [
      {
        title: 'Digital Marketing',
        icon: TimelineOutlinedIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    disabled: true,
    title: 'Human Capital',
    children: [
      {
        title: 'Human Resource Management',
        icon: SchoolOutlinedIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    disabled: true,
    title: 'Payroll',
    children: [
      {
        title: 'Payroll',
        icon: MonetizationOnOutlinedIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    disabled: true,
    title: 'Accounts',
    children: [
      {
        title: 'Book Keeping / Accounting',
        icon: StorageOutlinedIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    disabled: true,
    title: 'Tax Filing',
    children: [
      {
        title: 'Tax Filing',
        icon: ReceiptOutlinedIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    disabled: true,
    title: 'Audits',
    children: [
      {
        title: 'Engage Auditor',
        icon: FolderSharedOutlinedIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    title: 'Procurement',
    children: [
      {
        title: 'Office Supplies',
        icon: ShoppingBasketOutlinedIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    disabled: true,
    title: 'Software Licenses',
    children: [
      {
        title: 'Software Licenses',
        icon: VpnLockOutlinedIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    disabled: true,
    title: 'Virtual C-Suite',
    children: [
      {
        title: 'Virtual Chief Financial Officer',
        icon: PersonPinOutlinedIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
];
