import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  Divider,
  Button,
  Container,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Avatar,
  Link,
  Card,
  CardContent,
  Backdrop,
  CircularProgress,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { ConfigContext } from 'src/contexts';
import CheckIcon from '@material-ui/icons/Check';

import { Formik, Form, Field } from 'formik';
import yellow from '@material-ui/core/colors/yellow';
import cyan from '@material-ui/core/colors/cyan';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import useBreakpoints from 'src/hooks/useBreakpoints';
import commonSvc from '../../services/common';
import companySvc from '../../services/company';
import { S3UploadAndInsertDocuments } from '../../utils/aws';
import ROUTES from '../../constants/routes';
import useSnackbar from '../../hooks/useSnackbar';
import { useProvideProduct } from 'src/hooks/useProduct';
import { useProvideOrder } from 'src/hooks/useOrder';
import { useProvideCheckout } from 'src/hooks/useCheckoutIntegration';

import ProgressLoader from '../Progress';
import StepsDisplay from './StepsDisplay';
import Layout from './Layout';
import {
  numberOfEmployees,
  annualRevenues,
  totalMonthlyCardSpends,
  entityCategories,
  industries,
} from '../../constants/onboarding';

import { sgdFormat } from '../../utils/currency';
import useQueryParams from '../../hooks/useQueryParams';

// fields
import { FormFileDropzone } from 'src/components/Form';
import FieldContainer from './Fields/FieldContainer';

import CustomTextField from '../CustomFormFields/CustomTextField';
import CustomSelectField from '../CustomFormFields/CustomSelectField';
import FormFieldContainer from '../CustomFormFields/FormFieldContainer';
import CustomPhoneNumberField from '../CustomFormFields/CustomPhoneNumberField';

// media
import image_director from '../../assets/onboarding/director.png';
import image_employee from '../../assets/onboarding/employee.png';
import image_freelancer from '../../assets/onboarding/freelancer.png';
import image_docIllustration from '../../assets/onboarding/docIllustration.png';
import CustomModal from '../CustomModal';
import StepFormContentWrapper from './StepForms/StepFormContentWrapper';

import {
  fieldNames,
  address_fields,
  order_fields,
  staticProductOptions,
  Form0,
  Form1,
  Form13,
  Form5,
  Form7,
  Form8,
  FormCart,
  FormCheckout,
  fieldNames_0,
  fieldNames_1,
  fieldNames_2,
  fieldNames_3,
  fieldNames_4,
} from './StepForms';

const {
  fieldName_productId,
  fieldName_checkbox0,
  fieldName_position,
  fieldName_checkbox1,
  fieldName_registrationCountry,
  fieldName_solution,
  // fieldName_email,
  fieldName_gender,
  fieldName_dateOfBirth,
  fieldName_countryOfBirth,
  fieldName_nationality,
  fieldName_businessLegalName,
  fieldName_entityCategory,
  fieldName_entityType,
  fieldName_businessNumber,
  fieldName_natureOfIndustry,
  fieldName_natureOfSubIndustry,
  fieldName_businessActivity,
  fieldName_productDescription,
  fieldName_companyWebsite,
  fieldName_numberOfEmployee,
  fieldName_annualTurnover,
  fieldName_addressUnit,
  fieldName_addressLine1,
  fieldName_addressLine2,
  fieldName_addressLine3,
  fieldName_postcode,
  fieldName_state_id,
  fieldName_addressCountry,
  fieldName_businessFiles,
  fieldName_fileCategory,
  fieldName_checkbox2,
  fieldName_checkbox3,
  fieldName_created_by,
  fieldName_onboard_status_id,

  fieldName_name,
  fieldName_email,
  fieldName_contactNumber,

  fieldName_directorName,
  fieldName_directorPassportIC,
  fieldName_directorEmail,
  fieldName_directorContactNumber,
} = fieldNames;

const useCustomCommonAPI = (api, props) => {
  const { respondKey, labelKey, valueKey } = props;

  const [value, setValue] = useState({
    options: [],
    rawData: [],
  });

  useEffect(() => {
    (async () => {
      const apiRes = await api();
      let options = [];
      if (apiRes && apiRes?.[respondKey]) {
        apiRes[respondKey].map((aData) => {
          options.push({
            label: aData[labelKey],
            value: aData[valueKey],
          });
        });
        setValue((prev) => {
          return {
            ...prev,
            options: options,
            rawData: apiRes[respondKey],
          };
        });
      }
    })();
  }, []);

  return {
    ...value,
  };
};

const getValidationSchemaObj = (
  fieldsData = {},
  prefix = '',
  required = true,
) => {
  let result = {};
  let fieldsDataKeys = Object.keys(fieldsData);
  fieldsDataKeys.forEach((aKey) => {
    let fieldName = prefix + fieldsData[aKey]['name'];
    result[fieldName] = Yup.string().nullable();

    let isRequired = false;
    if (fieldsData[aKey].required != undefined) {
      isRequired = fieldsData[aKey].required;
    }
    if (isRequired || required) {
      result[fieldName] = Yup.string()
        .required('Please fill up this field')
        .nullable();
    }

    if (fieldsData[aKey]?.custom) {
      result[fieldName] = fieldsData[aKey]?.custom;
    }
  });
  return result;
};

const validationSchemas = [
  Yup.object().shape({
    [fieldName_productId]: Yup.string()
      .required('Please select a type')
      .default('28'),
    // [fieldName_checkbox0]: Yup.string().required('Please check'),
  }),
  Yup.object().shape({
    [fieldName_position]: Yup.string()
      .required('Please select a type')
      .default('1'),
    [fieldName_checkbox1]: Yup.string().required('Please check'),
    [fieldName_name]: Yup.string().default(''),
    [fieldName_email]: Yup.string().default(''),
    [fieldName_contactNumber]: Yup.string().default(''),
  }),
  Yup.object().shape({
    [fieldName_productId]: Yup.string()
      .required('Please select a type')
      .default('28'),
    // [fieldName_checkbox0]: Yup.string().required('Please check'),
    [fieldName_directorName]: Yup.string().when(fieldName_productId, {
      is: (isCorporate) => isCorporate == '28',
      then: Yup.string().required('Please fill up this field'),
      otherwise: Yup.string().default(''),
    }),
    [fieldName_directorPassportIC]: Yup.string().when(fieldName_productId, {
      is: (isCorporate) => isCorporate == '28',
      then: Yup.string().required('Please fill up this field'),
      otherwise: Yup.string().default(''),
    }),
    [fieldName_directorEmail]: Yup.string().when(fieldName_productId, {
      is: (isCorporate) => isCorporate == '28',
      then: Yup.string()
        .email('Please enter a valid email')
        .required('Please fill up this field'),
      otherwise: Yup.string().default(''),
    }),
    [fieldName_directorContactNumber]: Yup.string().when(fieldName_productId, {
      is: (isCorporate) => isCorporate == '28',
      then: Yup.string().required('Please fill up this field'),
      otherwise: Yup.string().default(''),
    }),
    [fieldName_businessLegalName]: Yup.string().required(
      'Please fill up this field',
    ),
    [fieldName_registrationCountry]: Yup.string().required(
      'Please fill up this field',
    ),
    [fieldName_entityCategory]: Yup.string().required(
      'Please fill up this field',
    ),
    [fieldName_entityType]: Yup.string().required('Please fill up this field'),
    [fieldName_businessNumber]: Yup.string().required(
      'Please fill up this field',
    ),
    [fieldName_natureOfIndustry]: Yup.string().required(
      'Please fill up this field',
    ),
    // [fieldName_natureOfSubIndustry]: Yup.string().required(
    //   'Please fill up this field',
    // ),
    [fieldName_productDescription]: Yup.string().required(
      'Please fill up this field',
    ),
    [fieldName_companyWebsite]: Yup.string().nullable(),
    [fieldName_numberOfEmployee]: Yup.string().required(
      'Please fill up this field',
    ),
    [fieldName_annualTurnover]: Yup.string().required(
      'Please fill up this field',
    ),
  }),
  Yup.object().shape({
    [fieldName_addressUnit]: Yup.string().required('Please fill up this field'),
    [fieldName_addressLine1]: Yup.string().required(
      'Please fill up this field',
    ),
    [fieldName_addressLine2]: Yup.string().required(
      'Please fill up this field',
    ),
    [fieldName_addressLine3]: Yup.string().default(''),
    [fieldName_postcode]: Yup.string().required('Please fill up this field'),
    [fieldName_state_id]: Yup.string().required('Please fill up this field'),
    [fieldName_addressCountry]: Yup.string().required(
      'Please fill up this field',
    ),
  }),
  Yup.object().shape({
    [fieldName_businessFiles]: Yup.array().test({
      message: 'Please choose the required files',
      test: (arr) => arr.length > 0,
    }),
    [fieldName_checkbox2]: Yup.string().required('Please check'),
  }),
];

let validationSchemaObj_billing = {
  billing: Yup.object().shape(getValidationSchemaObj(address_fields)),
  [order_fields.remark.name]: Yup.string(),
  [order_fields.payment_method.name]: Yup.string().required(
    'Please select a payment method',
  ),
  // [fields.differentShipping.name]: Yup.boolean(),
  [order_fields.tnc.name]: Yup.boolean()
    .required()
    .oneOf([true], 'Please tick here to proceed'),
};

// let validationSchemaObj_shipping = {
//   ...validationSchemaObj_billing,
//   shipping: Yup.object().shape(getValidationSchemaObj(address_fields)),
// };

let forms = [
  {
    id: 1,
    stepTitle: 'Business Package',
    title: 'I want to open new Meyzer360 account',
    component: Form0,
    validationSchema: validationSchemas[0],
    fieldNames: fieldNames_0,
  },
  {
    id: 2,
    stepTitle: 'Business Representative',
    title: 'I want to open new Meyzer360 account',
    component: Form1,
    validationSchema: validationSchemas[1],
    fieldNames: fieldNames_1,
  },
  {
    id: 3,
    stepTitle: 'Business Structure',
    title: 'Fill in your business information',
    component: Form5,
    validationSchema: validationSchemas[2],
    fieldNames: fieldNames_2,
  },
  {
    id: 4,
    stepTitle: 'Business Details',
    title: 'Fill in your business address',
    component: Form7,
    validationSchema: validationSchemas[3],
    fieldNames: fieldNames_3,
  },
  {
    id: 5,
    stepTitle: 'Business Documents',
    title: 'Submit your business documents',
    component: Form8,
    validationSchema: validationSchemas[4],
    fieldNames: fieldNames_4,
  },
  {
    id: 6,
    stepTitle: 'Confirmation',
    title: 'Document Verification',
    component: Form13,
  },
];

const OnboardingCompany = (props) => {
  const { onSubmit } = props;

  const queryParams = useQueryParams();
  let isPaid = queryParams.get('paid');
  let selectedPackage = queryParams.get('package');

  const localStorageKey = 'onboarding-company';
  const setFormValueLocalStorage = (values) => {
    localStorage.setItem(localStorageKey, JSON.stringify(values));
  };
  const getFormValueLocalStorage = () => {
    return JSON.parse(localStorage.getItem(localStorageKey)) || {};
  };
  const removeFormValueLocalStorage = () => {
    return localStorage.removeItem(localStorageKey);
  };

  const defaultValues = getFormValueLocalStorage();
  const defaultOnboadingProfileId = null;
  const {
    formPage: defaultFormPage,
    order: orderData,
    ...restDefaultValues
  } = defaultValues;
  const { smBelow } = useBreakpoints();
  const routeHistory = useHistory();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  const {
    cartList,
    cart,
    removeCart,
    addCart,
    user,
    company,
    setConfirmationDialog,
  } = useContext(ConfigContext);
  const {
    ReadProduct,
    ListProducts,
    products: defaultProducts = [],
    product,
    productLoading,
    productError,
  } = useProvideProduct();

  const [initLoading, setInitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formPage, setFormPage] = useState(
    defaultFormPage ? defaultFormPage : 0,
  );
  const [defaultFormValues, setDefaultFormValues] = useState({});

  useEffect(() => {
    ListProducts();
  }, []);

  useEffect(() => {
    if (defaultOnboadingProfileId != undefined) {
      (async () => {
        setInitLoading(true);
        let res = await companySvc.getOnboardProfilePublicById(
          defaultOnboadingProfileId,
        );

        let newDefaultValues = res?.data?.onboard_profile || {};
        if (selectedPackage == 28 || selectedPackage == 39) {
          newDefaultValues = {
            ...newDefaultValues,
            [fieldName_productId]: selectedPackage,
          };
        }
        setDefaultFormValues(newDefaultValues);
        setInitLoading(false);
      })();
    } else {
      setDefaultFormValues({});
    }
    if (selectedPackage) {
      setFormValueLocalStorage({
        formPage: 1,
      });
      setFormPage(1);
    }
  }, [defaultOnboadingProfileId, selectedPackage]);

  useEffect(() => {
    if (isPaid) {
      setFormPage(forms?.length - 1);
    }
  }, [isPaid]);

  let activeStep = formPage;

  let steps = forms.map((anItem) => {
    return {
      id: anItem.id,
      label: anItem.stepTitle,
    };
  });

  const { options: countryOptions = [] } = useCustomCommonAPI(
    commonSvc.countries,
    {
      labelKey: 'country_name',
      valueKey: 'country_id',
      respondKey: 'countries',
    },
  );

  const {
    options: stateOptions = [],
    rawData: statesData,
  } = useCustomCommonAPI(commonSvc.states, {
    labelKey: 'state_name',
    valueKey: 'state_id',
    respondKey: 'states',
  });

  const { options: genderOptions = [] } = useCustomCommonAPI(
    commonSvc.genders,
    {
      labelKey: 'gender_name',
      valueKey: 'gender_code',
      respondKey: 'genders',
    },
  );
  const { options: applicationTypeOptions = [] } = useCustomCommonAPI(
    commonSvc.companyApplicationTypes,
    {
      labelKey: 'application_type_table_name',
      valueKey: 'company_application_type_id',
      respondKey: 'applicationtype',
    },
  );
  const { options: companyTypeOptions = [] } = useCustomCommonAPI(
    commonSvc.companyTypes,
    {
      labelKey: 'company_type_name',
      valueKey: 'company_type_id',
      respondKey: 'company_types',
    },
  );

  const entityCategoriesOptions = entityCategories.map((anItem) => {
    return {
      label: anItem.label,
      value: anItem.label,
    };
  });

  const industriesOptions = industries.map((anItem) => {
    return {
      label: anItem.label,
      value: anItem.label,
    };
  });

  const numberOfEmployeeOptions = numberOfEmployees.map((anItem) => {
    return {
      label: anItem.label,
      value: anItem.label,
    };
  });
  const annualRevenueOptions = annualRevenues.map((anItem) => {
    return {
      label: anItem.label,
      value: anItem.label,
    };
  });

  let formObj = forms?.[formPage] || {};
  const { validationSchema = {} } = formObj;
  const CurrentForm = formObj?.component;

  let yupDefaultValues = validationSchema?.cast ? validationSchema?.cast() : {};
  let mergedDefaultValues = {
    ...yupDefaultValues,
    ...defaultFormValues,
    [order_fields.payment_method.name]: 'payment2',
  };

  const getStepFormValues = (values = {}, fields = {}) => {
    let result = {};
    let fieldKeys = Object.keys(fields);
    fieldKeys.forEach((aKey) => {
      let fieldName = fields[aKey];
      result[fieldName] = values?.[fieldName] || null;
    });

    return result;
  };

  const handleSubmit = async (values, canSubmit = false) => {
    if (canSubmit) {
      const {
        [fieldName_businessFiles]: businessFiles,
        ...restValues
      } = values;
      let newPageIndex = formPage + 1;
      if (newPageIndex < forms.length - 1) {
        if (formPage == 2) {
          // create temp form value
        } else {
          // update temp form value
        }
        setFormValueLocalStorage({
          ...restValues,
          formPage: newPageIndex,
        });
        setFormPage((prev) => newPageIndex);
      } else {
        const {
          [fieldName_checkbox0]: checkValue0,
          [fieldName_checkbox1]: checkValue1,
          [fieldName_checkbox2]: checkValue2,
          [fieldName_businessFiles]: selectedFiles = [],
          [fieldName_directorName]: directorNameValue,
          [fieldName_directorPassportIC]: directorPassportICValue,
          [fieldName_directorEmail]: directorEmailValue,
          [fieldName_directorContactNumber]: directorContactNumberValue,
          ...restValues
        } = values;
        setLoading(true);
        if (selectedFiles && selectedFiles.length > 0 && user?.user_id) {
          // submit
          let createProfileResult;
          try {
            createProfileResult = await companySvc.onBoardProfile({
              ...restValues,
              [fieldName_onboard_status_id]: 1,
              [fieldName_created_by]: user?.user_id,
              remarks: `[${JSON.stringify({
                product:
                  staticProductOptions[values?.[fieldName_productId]]?.label,
                name: directorNameValue,
                passportIC: directorPassportICValue,
                email: directorEmailValue,
                contactNumber: directorContactNumberValue,
              })}]`,
            });
            // removeFormValueLocalStorage();
          } catch (error) {
            showErrorSnackbar('Failed to submit profile.');
            setLoading(false);
            return;
          }
          if (createProfileResult?.data?.is_success) {
            let profileId =
              createProfileResult?.data?.onboard_profile?.onboard_profile_id;
            try {
              for (let i = 0; i < selectedFiles.length; i++) {
                await S3UploadAndInsertDocuments(
                  selectedFiles[i],
                  135,
                  11,
                  profileId,
                );
              }
              // for (let i = 0; i < selectedFiles.length; i++) {
              // }
            } catch (error) {
              showErrorSnackbar('Failed to upload document(s)');
              setLoading(false);
              return;
            }
            let foundSelectedProduct = defaultProducts.find(
              (anItem) => anItem.id == values?.product_id,
            );
            if (foundSelectedProduct) {
              try {
                await addCart({
                  price: foundSelectedProduct.price,
                  product_id: foundSelectedProduct.id,
                  quantity: 1,
                  user_id: user.user_id,
                  // product_detail_id: res.body.applications.company_name_application_id,
                  company_id: company.company_id,
                  company_name: company.company_name,
                  company_registration_no: company.company_registration_no,
                  company_application_id: profileId,
                });
              } catch (error) {
                console.log('error', error);
                showErrorSnackbar('Failed to add to cart');
                setLoading(false);

                return;
              }
            } else {
              setLoading(false);

              return;
            }
            setFormPage((prev) => newPageIndex);
          } else {
            setLoading(false);

            showErrorSnackbar('Failed to submit profile.');
          }
        } else {
          showErrorSnackbar('Please fill in all required fields.');
        }
        setLoading(false);

        console.log('Formik values2: ', values);
      }
    }
  };

  let foundCompany = filterCompanies.find(
    (anItem) => anItem.id == company.company_id,
  );

  let disableForm = false;

  // temp disable form
  if (foundCompany) {
    disableForm = false;
  }
  if (initLoading) {
    return <CircularProgress />;
  }
  return (
    <Layout
      subHeader={
        smBelow ? (
          <Box display="flex" justifyContent="center">
            <StepsDisplay
              activeStep={activeStep}
              steps={steps}
              formPage={formPage}
              horizontal={true}
            />
          </Box>
        ) : null
      }
    >
      <Container maxWidth="lg" style={{ display: 'flex' }}>
        <Grid container spacing={2}>
          {smBelow ? null : (
            <Grid item xs={3} sm={3} md={3}>
              <Box height="100%">
                <StepsDisplay
                  activeStep={activeStep}
                  steps={steps}
                  formPage={formPage}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={6}>
            {productLoading ? (
              <Box
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <Formik
                initialValues={mergedDefaultValues}
                validationSchema={validationSchema}
                onSubmit={() => {}}
                validateOnChange={false}
              >
                {(props) => {
                  const { values, validateForm, setTouched, touched } = props;

                  const selectedEntityCategory =
                    values[fieldName_entityCategory];
                  let foundEntityCategory = entityCategories.find(
                    (aCategory) => aCategory.label == selectedEntityCategory,
                  );
                  let entityTypeOptions = [];
                  if (
                    foundEntityCategory &&
                    foundEntityCategory.children &&
                    foundEntityCategory.children.length > 0
                  ) {
                    entityTypeOptions = foundEntityCategory.children.map(
                      (anItem) => {
                        return {
                          label: anItem.label,
                          value: anItem.label,
                        };
                      },
                    );
                  }

                  const selectedIndustry = values[fieldName_natureOfIndustry];
                  let foundIndustry = industries.find(
                    (aCategory) => aCategory.label == selectedIndustry,
                  );

                  let subIndustriesOptions = [];
                  if (
                    foundIndustry &&
                    foundIndustry.children &&
                    foundIndustry.children.length > 0
                  ) {
                    subIndustriesOptions = foundIndustry.children.map(
                      (anItem) => {
                        return {
                          label: anItem.label,
                          value: anItem.label,
                        };
                      },
                    );
                  }

                  const handlePrevStep = () => {
                    let newPageIndex = formPage - 1;
                    if (newPageIndex >= 0) {
                      setFormPage((prev) => newPageIndex);
                      setFormValueLocalStorage({
                        ...defaultValues,
                        formPage: newPageIndex,
                      });
                    }
                  };
                  const handleNextStep = () => {
                    // console.log("touched", touched);
                    // setTouched(true, true);
                    if (formPage < forms.length - 1) {
                      validateForm()
                        .then((result) => {
                          console.log('validateForm result', result);
                          setTouched({
                            ...touched,
                            ...result,
                          });
                          let canSubmit = false;
                          if (result && Object.keys(result).length == 0) {
                            canSubmit = true;
                          }
                          if (canSubmit) {
                            handleSubmit(values, canSubmit);
                          }
                        })
                        .catch((errors) => {
                          console.log('validateForm error', errors);
                          setTouched({ ...touched, ...errors });
                        });
                    } else {
                      // redirect
                      // removeFormValueLocalStorage();

                      routeHistory.push(
                        ROUTES.CORPSEC_COMPANY_ACCOUNT_SETTINGS,
                      );
                    }
                  };

                  return (
                    <Form style={{ height: '100%' }}>
                      <Paper display="flex" style={{ height: '100%' }}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          height="100%"
                        >
                          <Box flexGrow={1}>
                            <Container maxWidth="xs" style={{ height: '100%' }}>
                              <StepFormContentWrapper
                                stepTitle={formObj?.stepTitle}
                                contentTitle={formObj?.title}
                              >
                                {CurrentForm && (
                                  <CurrentForm
                                    commonData={{
                                      countries: countryOptions,
                                      states: stateOptions,
                                      statesRawData: statesData,
                                      genders: genderOptions,
                                      applicationTypes: applicationTypeOptions,
                                      companyTypes: companyTypeOptions,
                                      entityCategories: entityCategoriesOptions,
                                      entityTypes: entityTypeOptions,
                                      industries: industriesOptions,
                                      subIndustries: subIndustriesOptions,
                                      numberOfEmployees: numberOfEmployeeOptions,
                                      annualRevenues: annualRevenueOptions,
                                      products: defaultProducts,
                                    }}
                                    showPrice={!Boolean(foundCompany)}
                                    isPublic={false}
                                  />
                                )}
                              </StepFormContentWrapper>
                            </Container>
                          </Box>
                          <Divider />
                          {/* Footer */}
                          <Box
                            py={3}
                            px={2}
                            display="flex"
                            justifyContent="space-between"
                          >
                            {formPage > 0 && formPage < forms.length - 1 && (
                              <Button
                                variant="contained"
                                disableElevation={true}
                                style={{ width: '150px' }}
                                onClick={handlePrevStep}
                              >
                                Back
                              </Button>
                            )}
                            {formPage < forms.length && (
                              <Button
                                variant="contained"
                                disableElevation={true}
                                color="primary"
                                style={{
                                  minWidth: '150px',
                                  marginLeft: 'auto',
                                }}
                                // type="submit"
                                type="button"
                                disabled={disableForm}
                                onClick={handleNextStep}
                              >
                                {formObj?.nextButtonLabel
                                  ? formObj?.nextButtonLabel
                                  : 'Next'}
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </Paper>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </Grid>
          <Grid item sm={3} md={3} />
        </Grid>
      </Container>
      <Backdrop open={loading} style={{ zIndex: 5 }}>
        <CircularProgress />
      </Backdrop>
    </Layout>
  );
};

export default OnboardingCompany;

const filterCompanies = [
  {
    id: 82,
    name: 'Meyzer Business Advisory Pte. Ltd.',
  },
  {
    id: 83,
    name: 'ETE Academy Pte. Ltd.',
  },
  {
    id: 84,
    name: 'MEYZER360  HOLDINGS PTE. LTD.',
  },
  {
    id: 101,
    name: 'EASTERN NOVA HOLDINGS PTE. LTD',
  },
  {
    id: 103,
    name: 'SOON AIK (PRIVATE) LTD',
  },
  {
    id: 104,
    name: 'CKT SYSTEM TECHNOLOGIES PTE. LTD.',
  },
  {
    id: 105,
    name: 'Fuente.N Global Pte. Ltd.',
  },
  {
    id: 106,
    name: 'SUPREME MARINE AGENCIES PTE. LTD.',
  },
  {
    id: 108,
    name: 'Medsphere Innovations Pte. Ltd.',
  },
  {
    id: 109,
    name: 'EVOLVE CAPITAL MANAGEMENT PRIVATE LIMITED',
  },
  {
    id: 110,
    name: 'CHAHAYA REALTY PTE LTD',
  },
  {
    id: 112,
    name: 'CHAHAYA SHIPPING & TRADING CO PTE LTD',
  },
  {
    id: 114,
    name: 'MUSICAL THEATRE LTD',
  },
  {
    id: 115,
    name: 'SEASPACE SHIPPING PTE LTD',
  },
  {
    id: 116,
    name: 'Alcare Management Services Pte Ltd',
  },
  {
    id: 117,
    name: 'Algas Engineering Pte. Ltd.',
  },
  {
    id: 120,
    name: 'HM Too S.E.Asia Pte. Limited',
  },
  {
    id: 121,
    name: 'HO BROTHERS INVESTMENT PTE LTD',
  },
  {
    id: 122,
    name: 'LONG BRIDGE PTE. LTD.',
  },
  {
    id: 123,
    name: 'LONG BRIDGE SECURITIES PTE. LTD.',
  },
  {
    id: 125,
    name: 'ELID TECHNOLOGY INTERNATIONAL PTE LTD',
  },
  {
    id: 128,
    name: 'CITRINE ASIA CAPITAL PTE. LTD.',
  },
  {
    id: 130,
    name: 'G CAPITAL PARTNERS PTE. LIMITED',
  },
  {
    id: 132,
    name: 'SNL LOGISTICS PTE LTD',
  },
  {
    id: 133,
    name: 'KECK SENG REALTY INVESTMENT PTE LTD',
  },
  {
    id: 134,
    name: 'HONG FONG ENGINEERING PTE LTD',
  },
  {
    id: 135,
    name: 'BILLFORD INVESTMENTS PTE LTD',
  },
];
