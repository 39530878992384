import { useContext, useState, createContext } from 'react';
import { APIRequest } from 'src/utils/api';
import { Config } from 'src/config';

const affiliateContext = createContext({
  error: '',
  loading: false,
  submitting: false,
  affiliate: {},
  affiliates: [],
  CreateAffiliate: () => Promise.resolve({}),
  ReadAffiliate: () => Promise.resolve({}),
  ReadAffiliateBySysInfoUserID: () => Promise.resolve({}),
  ListAffiliates: () => Promise.resolve({}),
});

export function useProvideAffiliate() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [affiliate, setAffiliate] = useState({});
  const [affiliates, setAffiliates] = useState([]);

  const CreateAffiliate = async (userID) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setLoading,
      path: `${Config.apiURL}/crm/affiliates`,
      method: 'post',
      body: {
        sysinfo_user_id: userID,
      },
    });
    if (res.status === 'success') {
      setAffiliate(res.body.affiliate);
    }
    return res;
  };

  const ReadAffiliate = async (affiliateID) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setLoading,
      path: `${Config.apiURL}/crm/affiliates/${affiliateID}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setAffiliate(res.body.affiliate);
    }
    return res;
  };

  const ReadAffiliateBySysInfoUserID = async (userID) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setLoading,
      path: `${Config.apiURL}/crm/affiliates/sysinfo/${userID}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setAffiliate(res.body.affiliate);
    }
    return res;
  };

  const ListAffiliates = async () => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setLoading,
      path: `${Config.apiURL}/crm/affiliates/`,
      method: 'get',
    });
    if (res.status === 'success') {
      setAffiliates(res.body.affiliates);
    }
    return res;
  };

  return {
    error,
    loading,
    submitting,
    affiliate,
    affiliates,
    CreateAffiliate,
    ReadAffiliate,
    ReadAffiliateBySysInfoUserID,
    ListAffiliates,
  };
}

export const useAffiliateContext = () => useContext(affiliateContext);

export function ProvideAffiliate({ children }) {
  const affiliate = useProvideAffiliate();
  return (
    <affiliateContext.Provider value={affiliate}>
      {children}
    </affiliateContext.Provider>
  );
}
