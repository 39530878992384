import React, { useEffect, useState, useContext } from 'react';
import {
  Grid,
  TextField,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Divider,
  Paper,
  ButtonBase,
  ListItemIcon,
  Badge,
  Modal,
  Fade,
  Avatar,
  Backdrop,
  LinearProgress,
} from '@material-ui/core';
import moment from 'moment';
import DetailList from 'src/components/DetailList';
import { useProvideRequest } from 'src/hooks/useRequests';
import { useProvideCompany } from 'src/hooks/useCompany';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined';
import ClearAllOutlinedIcon from '@material-ui/icons/ClearAllOutlined';
import AddIcon from '@material-ui/icons/Add';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import useBreakpoints from 'src/hooks/useBreakpoints';
import FilePreviewModal from 'src/components/Corpsec/Documents/mymodal.component';
import LoadingComponent from 'src/components/Corpsec/Documents/loading.component';
import EmptyComponent from 'src/components/Corpsec/Documents/empty.component';
import { useProvideData } from 'src/hooks/useData';
import { useProvideShareholder } from 'src/hooks/useShareholders';
import ButtonDropzone from 'src/components/ButtonDropzone';
import { useParams } from 'react-router-dom';
import { S3UploadAndInsertDocuments } from 'src/utils/aws';
import { ConfigContext } from 'src/contexts';
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader';

// import CustomMenu from "../../../components/CustomMenu";
// import commonApi from "../../../services/common.service";
// import shareholderApi from "../../../services/shareholder.service";
// import userApi from "../../../services/user.service";
// import documentApi from "../../../services/document.service";

/*
DeclareInterimDividendApplicationForm (dividend_declarations)

company_application_id: 462
dividend_amount: 12000
dividend_date: "2021-10-02T00:00:00.000Z"
dividend_declaration_application_id: 2
payment_date: "2021-10-02T00:00:00.000Z"
*/

/*

*/

/*

*/

/*

*/

/*

*/

//10
export const OpenBankAccountApplicationForm = ({ request, showTodoList }) => {
  const {
    openBankAccApplication,
    OpenBankAccApplication,
  } = useProvideRequest();

  useEffect(() => {
    OpenBankAccApplication(request.application_primary_id);
  }, []);

  const application = [
    {
      label: 'Bank Name *',
      description: openBankAccApplication.bank_name,
    },
    {
      label: 'Is SGD Account *',
      description: openBankAccApplication.is_sgd_account == 1 ? 'YES' : 'NO',
    },
    {
      label: 'Is USD Account *',
      description: openBankAccApplication.is_usd_account == 1 ? 'YES' : 'NO',
    },
  ];

  return (
    <>
      {ApplicationForm(
        request.company_application_type_id,
        application,
        showTodoList,
      )}
    </>
  );
};

//11
export const CloseBankAccountApplicationForm = ({ request, showTodoList }) => {
  const {
    closeBankAccApplication,
    CloseBankAccApplication,
  } = useProvideRequest();

  useEffect(() => {
    CloseBankAccApplication(request.application_primary_id);
  }, []);

  const application = [
    {
      label: 'Bank Name *',
      description: closeBankAccApplication.bank_name,
    },
    {
      label: 'Bank Account Number *',
      description: closeBankAccApplication.bank_account_number,
    },
  ];

  return (
    <>
      {ApplicationForm(
        request.company_application_type_id,
        application,
        showTodoList,
      )}
    </>
  );
};

//7
export const CessationApplicationForm = ({ request, showTodoList }) => {
  const { cessationApplication, CessationApplication } = useProvideRequest();

  useEffect(() => {
    CessationApplication(request.application_primary_id);
  }, []);

  const BAapplication = [
    {
      label: 'Cessation Date *',
      description: moment(cessationApplication.cessation_date).format(
        'YYYY-MM-DD',
      ),
    },
    {
      label: 'Cessation Reason *',
      description: cessationApplication.cessation_reason,
    },
    {
      label: 'Company Name *',
      description: cessationApplication.company_name,
    },
    {
      label: 'Role *',
      description: cessationApplication.role_name,
    },
    {
      label: 'First Name *',
      description: cessationApplication.first_name,
    },
    {
      label: 'Last Name *',
      description: cessationApplication.last_name,
    },
  ];

  return (
    <>
      {ApplicationForm(
        request.company_application_type_id,
        BAapplication,
        showTodoList,
      )}
    </>
  );
};

//18
export const AuditorCessationApplicationForm = ({ request, showTodoList }) => {
  const {
    auditorCessationApplicationList,
    AuditorCessationApplicationList,
    getDocumentById,
    GetDocumentById,
  } = useProvideRequest();

  const { company, GetCompanyByID, companyError } = useProvideCompany();

  useEffect(() => {
    AuditorCessationApplicationList(request.application_primary_id);
    GetDocumentById(request.company_application_id);
  }, []);

  useEffect(() => {
    if (auditorCessationApplicationList.auditor_company_id) {
      GetCompanyByID(auditorCessationApplicationList.auditor_company_id);
    }
  }, [auditorCessationApplicationList.auditor_company_id]);

  const application = [
    {
      label: 'Client Company Name',
      description: auditorCessationApplicationList.client_company_name,
    },
    {
      label: 'Appointment Date',
      description: moment(
        auditorCessationApplicationList.appointment_date,
      ).format('YYYY-MM-DD'),
    },
    {
      label: 'Auditor Company Name',
      description: auditorCessationApplicationList.auditor_company_name,
    },
    {
      label: 'Auditor Company Registration No',
      description: company.company_registration_no,
    },
    {
      label: 'Company Type Name',
      description: company.company_type_name,
    },
    {
      label: 'Country Of Incorporation',
      description: company.country_of_incorporation,
    },
    {
      label: 'Cessation Date',
      description: moment(
        auditorCessationApplicationList.cessation_date,
      ).format('YYYY-MM-DD'),
    },
    {
      label: 'Cessation Reason',
      description: auditorCessationApplicationList.cessation_reason,
    },
  ];

  return (
    <>
      {ApplicationForm(
        request.company_application_type_id,
        application,
        showTodoList,
        false,
        getDocumentById,
      )}
    </>
  );
};

// export const BusinessActivityApplication = ({ application = {} }) => {
//   /*
//   business_activity_application_id: 1
// change_effective_date: null
// company_application_id: 431
// delete_secondary_activity_flag: 0
// primary_activity_description: "desc 1"
// primary_activity_id: 1
// secondary_activity_description: "desc 2"
// secondary_activity_id: 2
// */
//   const [businessActivities, setBusinessActivities] = useState([]);

//   const businessActivitiesOptions = businessActivities.map((anItem) => {
//     return {
//       label: anItem.ssic_name,
//       value: anItem.ssic_id,
//     };
//   });
//   useEffect(() => {
//     commonApi
//       // .getSsicCodeByCode("123")
//       .getSsicCode()
//       .then((result) => {
//         // if (result?.data?.ssic_codes) {
//         //   setBusinessActivities(result?.data?.ssic_codes);
//         // }
//         if (result?.data?.document) {
//           setBusinessActivities(result?.data?.document);
//         }
//       })
//       .catch((error) => {
//         setBusinessActivities([]);
//       });
//   }, [JSON.stringify(application)]);

//   return (
//     <>
//       <Grid container spacing={3}>
//         <Grid item xs={12}>
//           <TextField
//             label="Change Effective Date"
//             value={application?.change_effective_date || "-"}
//             fullWidth
//             disabled={true}
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <CustomMenu
//             data={businessActivitiesOptions}
//             value={application?.primary_activity_id}
//             label="Primary Activity"
//             // onChange={handleModuleChange}
//             disabled={true}
//             variant="standard"
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <TextField
//             required
//             label="Primary Activity Desc"
//             value={application?.primary_activity_description || "-"}
//             fullWidth
//             disabled={true}
//           />
//         </Grid>
//         {/* <Grid item xs={12}>
//           <TextField
//             label="Delete Secondary Activity Flag"
//             value={
//               application.delete_secondary_activity_flag === 1 ? "YES" : "NO"
//             }
//             fullWidth
//             disabled={true}
//           />
//         </Grid> */}
//         <Grid item xs={12}>
//           <CustomMenu
//             data={businessActivitiesOptions}
//             value={application?.secondary_activity_id}
//             label="Secondary Activity"
//             // onChange={handleModuleChange}
//             disabled={true}
//             variant="standard"
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <TextField
//             required
//             label="Secondary Activity Desc"
//             value={application?.secondary_activity_description || "-"}
//             fullWidth
//             disabled={true}
//           />
//         </Grid>
//       </Grid>
//     </>
//   );
// };

//5
export const OfficerApplicationForm = ({ request, showTodoList }) => {
  const {
    officerApplications,
    getDocumentById,
    OfficerApplications,
    GetDocumentById,
  } = useProvideRequest();

  useEffect(() => {
    OfficerApplications(request.application_primary_id);
  }, []);

  useEffect(() => {
    if (officerApplications?.address_attachment_id) {
      GetDocumentById(officerApplications?.address_attachment_id);
    }
    if (officerApplications?.appoinment_letter_attachment_id) {
      GetDocumentById(officerApplications?.appoinment_letter_attachment_id);
    }
    if (officerApplications?.employment_pass_attachment_id) {
      GetDocumentById(officerApplications?.employment_pass_attachment_id);
    }
    if (officerApplications?.nric_attachment_id) {
      GetDocumentById(officerApplications?.nric_attachment_id);
    }
  }, [JSON.stringify(officerApplications)]);

  const Oapplication = [
    {
      label: 'Role *',
      description: officerApplications.role_name,
    },
    {
      label: 'Email *',
      description: officerApplications.email,
    },
    {
      label: 'First Name *',
      description: officerApplications.first_name,
    },
    {
      label: 'Last Name *',
      description: officerApplications.last_name,
    },
    {
      label: 'NRIC/Passport *',
      description: officerApplications.nric_passport_no,
    },
    {
      label: 'Mobile Number *',
      description: officerApplications.mobile_number,
    },
  ];

  return (
    <>
      {ApplicationForm(
        request.company_application_type_id,
        Oapplication,
        showTodoList,
        false,
        getDocumentById,
      )}
    </>
  );
};

//3
export const CompanyAddressApplicationForm = ({ request, showTodoList }) => {
  const {
    companyAddressApplication,
    CompanyAddressApplication,
  } = useProvideRequest();

  useEffect(() => {
    CompanyAddressApplication(request.application_primary_id);
  }, []);

  const CAapplication = [
    {
      label: 'Floor Unit No *',
      description: companyAddressApplication.floor_unit_no,
    },
    {
      label: 'Address Line 1 *',
      description: companyAddressApplication.address_line_1,
    },
    {
      label: 'Address Line 2 *',
      description: companyAddressApplication.address_line_2,
    },
    {
      label: 'City / State *',
      description: companyAddressApplication.address_line_3,
    },
    {
      label: 'Poster Code *',
      description: companyAddressApplication.postal_code,
    },
    {
      label: 'Country *',
      description: companyAddressApplication.country_name,
    },
  ];

  return (
    <>
      {ApplicationForm(
        request.company_application_type_id,
        CAapplication,
        showTodoList,
      )}
    </>
  );
};

//4
export const CompanyNameApplicationForm = ({ request, showTodoList }) => {
  const {
    companyNameApplications,
    CompanyNameApplications,
  } = useProvideRequest();

  useEffect(() => {
    CompanyNameApplications(request.application_primary_id);
  }, []);

  const CNCapplication = [
    {
      label: 'Proposed Name 1 *',
      description: companyNameApplications.proposed_company_name_1,
    },
    {
      label: 'Proposed Name 2 *',
      description: companyNameApplications.proposed_company_name_2,
    },
    {
      label: 'Proposed Name 3 *',
      description: companyNameApplications.proposed_company_name_3,
    },
  ];

  return (
    <>
      {ApplicationForm(
        request.company_application_type_id,
        CNCapplication,
        showTodoList,
      )}
    </>
  );
};

//13
export const DeclareInterimDividendApplicationForm = ({
  request,
  showTodoList,
}) => {
  const {
    declareInterimDividend,
    DeclareInterimDividend,
  } = useProvideRequest();

  useEffect(() => {
    DeclareInterimDividend(request.application_primary_id);
  }, []);

  const application = [
    {
      label: 'Amount *',
      description: declareInterimDividend.dividend_amount,
    },
    {
      label: 'Dividend Date *',
      description: moment(declareInterimDividend.dividend_date).format(
        'DD/MM/yyyy',
      ),
    },
    {
      label: 'Payment Date *',
      description: moment(declareInterimDividend.payment_date).format(
        'DD/MM/yyyy',
      ),
    },
  ];

  return (
    <>
      {ApplicationForm(
        request.company_application_type_id,
        application,
        showTodoList,
      )}
    </>
  );
};

//9
export const FinancialYearApplication = ({ request, showTodoList }) => {
  const { GetFinancialYearEnd, financialYearEnd } = useProvideCompany();

  useEffect(() => {
    GetFinancialYearEnd(request.company_id);
  }, []);

  const FYEapplication = [
    {
      label: 'Year End Date *',
      description: moment(financialYearEnd.financial_year_end_date).format(
        'DD/MM/yyyy hh:mm:ssa',
      ),
    },
    {
      label: 'NEW Year End Date *',
      description: moment(financialYearEnd.new_financial_year_end_date).format(
        'DD/MM/yyyy hh:mm:ssa',
      ),
    },
    {
      label: 'Next AGM Due Date *',
      description: moment(financialYearEnd.next_agm_due_date).format(
        'DD/MM/yyyy hh:mm:ssa',
      ),
    },
  ];

  return (
    <>
      {ApplicationForm(
        request.company_application_type_id,
        FYEapplication,
        showTodoList,
      )}
    </>
  );
};

//12
export const BusinessActivityApplicationForm = ({ request, showTodoList }) => {
  const {
    businessActivityApplication,
    BusinessActivityApplication,
  } = useProvideRequest();
  const { ListSSIC, ssic } = useProvideData();

  useEffect(() => {
    BusinessActivityApplication(request.application_primary_id);
    ListSSIC();
  }, []);

  let primary_activity_name = ssic.find(
    (item) => item.value === businessActivityApplication.primary_activity_id,
  );
  let secondary_activity_name = ssic.find(
    (item) => item.value === businessActivityApplication.secondary_activity_id,
  );

  const BAapplication = [
    {
      label: 'Change Effective Date',
      description: moment(
        businessActivityApplication.change_effective_date,
      ).format('DD/MM/yyyy hh:mm:ssa'),
    },
    {
      label: 'Primary Activity *',
      description: primary_activity_name?.label,
    },
    {
      label: 'Primary Activity Desc *',
      description: businessActivityApplication.primary_activity_description,
    },
    {
      label: 'Secondary Activity *',
      description: secondary_activity_name?.label,
    },
    {
      label: 'Secondary Activity Desc *',
      description: businessActivityApplication.secondary_activity_description,
    },
  ];

  return (
    <>
      {ApplicationForm(
        request.company_application_type_id,
        BAapplication,
        showTodoList,
      )}
    </>
  );
};

//14
export const OtherRequestApplicationForm = ({ request, showTodoList }) => {
  const {
    otherRequestApplication,
    OtherRequestApplication,
  } = useProvideRequest();

  useEffect(() => {
    OtherRequestApplication(request.application_primary_id);
  }, []);

  const requestType = [
    { label: 'Capital & Shares Related', value: 1 },
    { label: 'General Enquires', value: 2 },
    { label: 'Officers Related', value: 3 },
    { label: 'Product & Subscription Related', value: 4 },
    { label: 'Shareholders Related', value: 5 },
  ];

  const application = [
    {
      label: 'Request Type *',
      description:
        requestType[otherRequestApplication.other_request_type_id - 1]?.label ||
        '-',
    },
    {
      label: 'Request Description *',
      description: otherRequestApplication.request_description,
    },
  ];

  return (
    <>
      {ApplicationForm(
        request.company_application_type_id,
        application,
        showTodoList,
      )}
    </>
  );
};

const ShareAllotmentApplicationItem = ({ application = {}, request }) => {
  /*
  allotment_shareholder_id: 1
  company_application_id: 491
  currency: null
  is_new_shareholder: 0
  issued_paid_up_amount: 1000000
  new_capital: 0
  new_shares: 0
  no_shares: 20000
  proof_capital_attachment_id: null
  shares_allotment_application_id: 2
  */

  const [transferFrom, setTransferFrom] = useState({});
  const [transferTo, setTransferTo] = useState({});

  const {
    username: transferFrom_username = '',
    first_name: transferFrom_firstname = '',
    last_name: transferFrom_lastname = '',
  } = transferFrom;

  const {
    username: transferTo_username = '',
    first_name: transferTo_firstname = '',
    last_name: transferTo_lastname = '',
  } = transferTo;

  const [capitalAttachments, setCapitalAttachments] = useState([]);
  useEffect(() => {
    if (request?.company_id && application?.transfer_from_id) {
      shareholderApi
        .getShareholderById(request.company_id, application?.transfer_from_id)
        .then((result) => {
          if (result?.data?.shares) {
            setTransferFrom(result?.data?.shares);
          }
        })
        .catch((error) => {
          setTransferFrom({});
        });
    }
    if (request?.company_id && application?.transfer_to_id) {
      shareholderApi
        .getShareholderById(request.company_id, application?.transfer_to_id)
        .then((result) => {
          if (result?.data?.shares) {
            setTransferTo(result?.data?.shares);
          }
        })
        .catch((error) => {
          setTransferTo({});
        });
    }
    if (application?.proof_capital_attachment_id) {
      documentApi
        .getDocumentById(application?.proof_capital_attachment_id)
        .then((result) => {
          if (result?.data?.document) {
            setCapitalAttachments(result?.data?.document || []);
          }
          console.log('documentApi.getDocumentById', result);
        })
        .catch((error) => {
          setCapitalAttachments([]);

          console.log('documentApi.getDocumentById error', error);
        });
    }
  }, [JSON.stringify(application)]);
  console.log('application', application);
  return (
    <>
      <Grid container spacing={3}>
        {/* <Grid item xs={6}>
          <TextField
            required
            label="Status"
            variant="standard"
            value={application.status_name}
            fullWidth
          />
        </Grid> */}
        <Grid item xs={6}>
          <TextField
            required
            id="transfer_from_id"
            name="transfer_from_id"
            label="Transfer From"
            variant="standard"
            value={`${transferFrom_username || '-'} (${
              transferFrom_firstname || ''
            } ${transferFrom_lastname || ''})`}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            id="transfer_to_id"
            name="transfer_to_id"
            label="Transfer To"
            variant="standard"
            value={`${transferTo_username || '-'} (${
              transferTo_firstname || ''
            } ${transferTo_lastname || ''})`}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            id="currency"
            name="currency"
            label="Currency"
            variant="standard"
            value={application?.currency || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            id="issued_paid_up_amount"
            name="issued_paid_up_amount"
            label="Issued Paid Up Amount"
            value={application?.issued_paid_up_amount || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="shares"
            name="shares"
            label="No Of Shares"
            value={application?.no_shares || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <TextField
            required
            label="Is new capital"
            value={`${application?.new_capital === 1 ? "YES" : "NO"}`}
            fullWidth
            disabled={true}
          />
        </Grid> */}
        <Grid item xs={6}>
          <TextField
            required
            label="New capital"
            value={`${application?.new_capital || '-'}`}
            fullWidth
            disabled={true}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <TextField
            required
            label="Is new share"
            value={`${application?.new_shares === 1 ? "YES" : "NO"}`}
            fullWidth
            disabled={true}
          />
        </Grid> */}
        <Grid item xs={6}>
          <TextField
            required
            label="New share"
            value={`${application?.new_shares || '-'}`}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Is new shareholder"
            value={`${application?.is_new_shareholder === 1 ? 'YES' : 'NO'}`}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Box>Proof Capital Attachments</Box>
          {capitalAttachments?.length > 0
            ? capitalAttachments.map((anItem, index) => {
                return (
                  <Box key={index} mb={1}>
                    <Button
                      variant="contained"
                      target="_blank"
                      color={'primary'}
                      href={anItem?.document_url || ''}
                    >
                      View Attachment ({anItem?.document_type_name})
                    </Button>
                  </Box>
                );
              })
            : 'No Attachment Found'}
        </Grid>
      </Grid>
    </>
  );
};

//15
export const ShareAllotmentApplicationForm = ({ request, showTodoList }) => {
  const {
    shareAllotmentApplication,
    getDocumentById,
    shareTransferToApplications,
    GetDocumentById,
    ShareAllotmentApplication,
    ShareTransferApplicationsTo,
  } = useProvideRequest();
  const [toList, setToList] = useState([]);
  const [proofCapitalAttachment, setProofCapitalAttachment] = useState([]);
  const [proofIdentityAttachment, setProofIdentityAttachment] = useState([]);
  const attachments = [...proofCapitalAttachment, ...proofIdentityAttachment];

  useEffect(() => {
    ShareAllotmentApplication(request.application_primary_id);
  }, []);

  useEffect(() => {
    if (shareAllotmentApplication.length > 0) {
      shareAllotmentApplication.map(async (item, index) => {
        const proofCapitalDoc = await GetDocumentById(
          item.proof_capital_attachment_id,
        );
        const proofIdentityDoc = await GetDocumentById(
          item.proof_identity_attachment_id,
        );

        if (proofCapitalDoc.status === 'success') {
          setProofCapitalAttachment(proofCapitalDoc.body?.document);
        }
        if (proofIdentityDoc.status === 'success') {
          setProofIdentityAttachment(proofIdentityDoc.body?.document);
        }
      });
    }
  }, [JSON.stringify(shareAllotmentApplication)]);

  useEffect(() => {
    if (shareAllotmentApplication.length > 0) {
      shareAllotmentApplication.map((item, index) => {
        if (
          request.company_id &&
          shareAllotmentApplication[index]?.transfer_to_id
        ) {
          ShareTransferApplicationsTo(
            request.company_id,
            shareAllotmentApplication[index].transfer_to_id,
          );
        }
      });
    }
  }, [JSON.stringify(request), JSON.stringify(shareAllotmentApplication)]);

  useEffect(() => {
    if (shareTransferToApplications.shareholder_id) {
      setToList([...toList, shareTransferToApplications]);
    }
  }, [shareTransferToApplications]);

  let SAapplication = [];
  let existingValue = [];
  if (shareAllotmentApplication.length > 0) {
    shareAllotmentApplication.map((item, index) => {
      // new shareholder
      if (item.is_new_shareholder === 1) {
        if (item.is_company === 1) {
          // new company
          SAapplication.push([
            {
              label: 'Is new shareholder *',
              description: item.is_new_shareholder === 1 ? 'YES' : 'NO',
            },
            {
              label: 'Is Company *',
              description: item.is_company === 1 ? 'YES' : 'NO',
            },
            {
              label: 'Company Name *',
              description: item.company_name,
            },
            {
              label: 'Company Registration No *',
              description: item.company_registration_no,
            },
            {
              label: 'Country Incorporation Name *',
              description: item.country_incorporation_name,
            },
            {
              label: 'Floor Unit No *',
              description: item.floor_unit_no,
            },
            {
              label: 'Address Line 1 *',
              description: item.address_line_1,
            },
            {
              label: 'Address Line 2 *',
              description: item.address_line_2,
            },
            {
              label: 'Postal Code *',
              description: item.postal_code,
            },
            {
              label: 'Country Name *',
              description: item.country_name,
            },
            {
              label: 'New capital *',
              description: item.new_capital,
            },
            {
              label: 'New share *',
              description: item.new_shares,
            },
          ]);
        } else {
          // new individual
          SAapplication.push([
            {
              label: 'Is new shareholder *',
              description: item.is_new_shareholder === 1 ? 'YES' : 'NO',
            },
            {
              label: 'Is Company *',
              description: item.is_company === 1 ? 'YES' : 'NO',
            },
            {
              label: 'Transfer To *',
              description: item.shareholder_name
                ? item.shareholder_name
                : toList[index]?.username +
                  ' (' +
                  toList[index]?.first_name +
                  ' ' +
                  toList[index]?.last_name +
                  ')',
            },
            {
              label: 'Identity Id *',
              description: item.identity_id,
            },
            {
              label: 'Is Foreigner *',
              description: item.is_foreigner === 1 ? 'YES' : 'NO',
            },
            {
              label: 'Email *',
              description: item.email,
            },
            {
              label: 'Mobile Number *',
              description: item.mobile_number,
            },
            {
              label: 'New capital *',
              description: item.new_capital,
            },
            {
              label: 'New share *',
              description: item.new_shares,
            },
          ]);
        }
      }
      // existing shareholder
      else {
        if (item.is_company === 1) {
          // existing company
          SAapplication.push([
            {
              label: 'Is new shareholder *',
              description: item.is_new_shareholder === 1 ? 'YES' : 'NO',
            },
            {
              label: 'Is Company *',
              description: item.is_company === 1 ? 'YES' : 'NO',
            },
            {
              label: 'Company Name *',
              description: item.shareholder_name,
            },
            {
              label: 'Company Registration No *',
              description: toList[index]?.company_registration_no,
            },
            {
              label: 'New capital *',
              description: item.new_capital,
            },
            {
              label: 'New share *',
              description: item.new_shares,
            },
          ]);
        } else {
          // existing individual
          SAapplication.push([
            {
              label: 'Is new shareholder *',
              description: item.is_new_shareholder === 1 ? 'YES' : 'NO',
            },
            {
              label: 'Is Company *',
              description: item.is_company === 1 ? 'YES' : 'NO',
            },
            {
              label: 'Transfer To *',
              description: toList[index]?.username
                ? toList[index]?.username +
                  ' (' +
                  toList[index]?.first_name +
                  ' ' +
                  toList[index]?.last_name +
                  ')'
                : item.shareholder_name,
            },
            {
              label: 'New capital *',
              description: item.new_capital,
            },
            {
              label: 'New share *',
              description: item.new_shares,
            },
          ]);
        }
      }
    });

    existingValue.push(
      {
        label: 'Currency *',
        description: shareAllotmentApplication[0].currency,
      },
      {
        label: 'Issued Paid Up Amount *',
        description: shareAllotmentApplication[0].issued_paid_up_amount,
      },
      {
        label: 'No Of Shares *',
        description: shareAllotmentApplication[0].no_shares,
      },
    );
  }

  return (
    <>
      {ApplicationForm(
        request.company_application_type_id,
        SAapplication,
        showTodoList,
        true,
        attachments,
        existingValue,
      )}
    </>
  );
};

//16
export const CompanyAuditorApplicationForm = ({ request, showTodoList }) => {
  const {
    companyAuditorApplication,
    CompanyAuditorApplication,
  } = useProvideRequest();

  useEffect(() => {
    CompanyAuditorApplication(request.application_primary_id);
  }, []);

  const application = [
    {
      label: 'Company Name of Auditor *',
      description: companyAuditorApplication.company_name,
    },
    {
      label: 'Company Registration Number of Auditor *',
      description: companyAuditorApplication.company_registration_no,
    },
    {
      label: 'Floor/ Unit No *',
      description: companyAuditorApplication.floor_unit_no,
    },
    {
      label: 'Address Line 1 *',
      description: companyAuditorApplication.address_line_1,
    },
    {
      label: 'Address Line 2 *',
      description: companyAuditorApplication.address_line_2,
    },
    {
      label: 'Address Line 3 *',
      description: companyAuditorApplication.address_line_3,
    },
    {
      label: 'Postal Code *',
      description: companyAuditorApplication.postal_code,
    },
    {
      label: 'Country *',
      description: companyAuditorApplication.country_name,
    },
  ];

  return (
    <>
      {ApplicationForm(
        request.company_application_type_id,
        application,
        showTodoList,
      )}
    </>
  );
};

//1
export const ShareApplicationForm = ({ request, showTodoList }) => {
  const { shareApplication, ShareApplication } = useProvideRequest();

  useEffect(() => {
    ShareApplication(request.application_primary_id);
  }, []);

  const Sapplication = [
    {
      label: 'Number of shares *',
      description: shareApplication.current_number_of_shares,
    },
    {
      label: 'Current share capital *',
      description: shareApplication.current_share_capital,
    },
    {
      label: 'Propose number of shares *',
      description: shareApplication.proposed_number_of_shares,
    },
    {
      label: 'Proposed share capital *',
      description: shareApplication.proposed_share_capital,
    },
  ];

  return (
    <>
      {ApplicationForm(
        request.company_application_type_id,
        Sapplication,
        showTodoList,
      )}
    </>
  );
};

//6
export const ShareholderApplicationForm = ({ request, showTodoList }) => {
  const { newshareholder, NewShareholder } = useProvideShareholder();
  const { getDocumentById, GetDocumentById } = useProvideRequest();

  useEffect(() => {
    NewShareholder(request.application_primary_id);
  }, []);

  useEffect(() => {
    if (newshareholder?.passport_attachment_id) {
      GetDocumentById(newshareholder?.passport_attachment_id);
    }
    if (newshareholder?.proof_identity_attachment_id) {
      GetDocumentById(newshareholder?.proof_identity_attachment_id);
    }
    if (newshareholder?.proof_residency_attachment_id) {
      GetDocumentById(newshareholder?.proof_residency_attachment_id);
    }
  }, [JSON.stringify(newshareholder)]);

  const application = [
    {
      label: 'Shareholder ID *',
      description: newshareholder?.identity_id,
    },
    {
      label: 'Shareholder Name *',
      description: newshareholder?.shareholder_name,
    },
    {
      label: 'Shareholder Email *',
      description: newshareholder?.email,
    },
    {
      label: 'Shareholder Phone *',
      description: newshareholder?.mobile_number,
    },
    {
      label: 'Is Foreigner *',
      description: newshareholder?.is_foreigner === 1 ? 'YES' : 'NO',
    },
  ];

  return (
    <>
      {ApplicationForm(
        request.company_application_type_id,
        application,
        showTodoList,
        false,
        getDocumentById,
      )}
    </>
  );
};

//2
export const ShareTransferApplicationForm = ({ request, showTodoList }) => {
  const {
    shareTransferApplications,
    shareTransferFromApplications,
    shareTransferToApplications,
    getDocumentById,
    GetDocumentById,
    ShareTransferApplications,
    ShareTransferApplicationsFrom,
    ShareTransferApplicationsTo,
  } = useProvideRequest();

  const [toList, setToList] = useState([]);
  const [fromList, setFromList] = useState([]);
  const [proofCapitalAttachment, setProofCapitalAttachment] = useState([]);
  const [proofIdentityAttachment, setProofIdentityAttachment] = useState([]);
  const attachments = [...proofCapitalAttachment, ...proofIdentityAttachment];

  useEffect(() => {
    ShareTransferApplications(request.application_primary_id);
  }, []);

  useEffect(() => {
    if (shareTransferApplications.length > 0) {
      shareTransferApplications.map((item, index) => {
        if (
          request.company_id &&
          shareTransferApplications[index]?.transfer_from_id
        ) {
          ShareTransferApplicationsFrom(
            request.company_id,
            shareTransferApplications[index].transfer_from_id,
          );
        }
        if (
          request.company_id &&
          shareTransferApplications[index]?.transfer_to_id
        ) {
          ShareTransferApplicationsTo(
            request.company_id,
            shareTransferApplications[index].transfer_to_id,
          );
        }
      });
    }
  }, [JSON.stringify(request), JSON.stringify(shareTransferApplications)]);

  useEffect(() => {
    if (shareTransferToApplications.shareholder_id) {
      setToList([...toList, shareTransferToApplications]);
    }
  }, [shareTransferToApplications]);

  useEffect(() => {
    if (shareTransferFromApplications.shareholder_id) {
      setFromList([...fromList, shareTransferFromApplications]);
    }
  }, [shareTransferFromApplications]);

  useEffect(() => {
    if (shareTransferApplications.length > 0) {
      shareTransferApplications.map(async (item, index) => {
        const proofCapitalDoc = await GetDocumentById(
          item.proof_capital_attachment_id,
        );
        const proofIdentityDoc = await GetDocumentById(
          item.proof_identity_attachment_id,
        );

        if (proofCapitalDoc.status === 'success') {
          setProofCapitalAttachment(proofCapitalDoc.body?.document);
        }
        if (proofIdentityDoc.status === 'success') {
          setProofIdentityAttachment(proofIdentityDoc.body?.document);
        }
      });
    }
  }, [JSON.stringify(shareTransferApplications)]);

  let STapplication = [];
  let existingValue = [];
  if (shareTransferApplications.length > 0) {
    shareTransferApplications.map((item, index) => {
      // new shareholder
      if (item.is_new_shareholder === 1) {
        if (item.is_company === 1) {
          // new company
          STapplication.push([
            {
              label: 'Is new shareholder *',
              description: item.is_new_shareholder === 1 ? 'YES' : 'NO',
            },
            {
              label: 'Is Company *',
              description: item.is_company === 1 ? 'YES' : 'NO',
            },
            {
              label: 'Company Name *',
              description: item.company_name,
            },
            {
              label: 'Company Registration No *',
              description: item.company_registration_no,
            },
            {
              label: 'Country Incorporation Name *',
              description: item.country_incorporation_name,
            },
            {
              label: 'Floor Unit No *',
              description: item.floor_unit_no,
            },
            {
              label: 'Address Line 1 *',
              description: item.address_line_1,
            },
            {
              label: 'Address Line 2 *',
              description: item.address_line_2,
            },
            {
              label: 'Postal Code *',
              description: item.postal_code,
            },
            {
              label: 'Country Name *',
              description: item.country_name,
            },
            {
              label: 'New share *',
              description: item.number_share_transfer,
            },
          ]);
        } else {
          // new individual
          STapplication.push([
            {
              label: 'Is new shareholder *',
              description: item.is_new_shareholder === 1 ? 'YES' : 'NO',
            },
            {
              label: 'Is Company *',
              description: item.is_company === 1 ? 'YES' : 'NO',
            },
            {
              label: 'Transfer To *',
              description: item.shareholder_name
                ? item.shareholder_name
                : toList[index]?.username +
                  ' (' +
                  toList[index]?.first_name +
                  ' ' +
                  toList[index]?.last_name +
                  ')',
            },
            {
              label: 'Identity Id *',
              description: item.identity_id,
            },
            {
              label: 'Is Foreigner *',
              description: item.is_foreigner === 1 ? 'YES' : 'NO',
            },
            {
              label: 'Email *',
              description: item.email,
            },
            {
              label: 'Mobile Number *',
              description: item.mobile_number,
            },
            {
              label: 'Number of share transfer *',
              description: item.number_share_transfer,
            },
          ]);
        }
      }
      // existing shareholder
      else {
        if (item.is_company === 1) {
          // existing company
          STapplication.push([
            {
              label: 'Is new shareholder *',
              description: item.is_new_shareholder === 1 ? 'YES' : 'NO',
            },
            {
              label: 'Is Company *',
              description: item.is_company === 1 ? 'YES' : 'NO',
            },
            {
              label: 'Company Name *',
              description: item.shareholder_name,
            },
            {
              label: 'Company Registration No *',
              description: toList[0]?.company_registration_no,
            },
            {
              label: 'New share *',
              description: item.number_share_transfer,
            },
          ]);
        } else {
          // existing individual
          STapplication.push([
            {
              label: 'Is new shareholder *',
              description: item.is_new_shareholder === 1 ? 'YES' : 'NO',
            },
            {
              label: 'Is Company *',
              description: item.is_company === 1 ? 'YES' : 'NO',
            },
            {
              label: 'Transfer To *',
              description: toList[index]?.username
                ? toList[index]?.username +
                  ' (' +
                  toList[index]?.first_name +
                  ' ' +
                  toList[index]?.last_name +
                  ')'
                : item.shareholder_name,
            },
            {
              label: 'Number of share transfer *',
              description: item.number_share_transfer,
            },
          ]);
        }
      }
    });

    existingValue.push(
      {
        label: 'Share transfer from *',
        description: fromList[0]?.company_name
          ? fromList[0]?.company_name
          : fromList[0]?.username +
            ' (' +
            fromList[0]?.first_name +
            ' ' +
            fromList[0]?.last_name +
            ')',
      },
      {
        label: 'Total Share Transfer *',
        description: shareTransferApplications[0].total_share_transfer,
      },
      {
        label: 'Transfer Date *',
        description: moment(shareTransferApplications[0].transfer_date).format(
          'DD/MM/yyyy hh:mm:ssa',
        ),
      },
    );
  }

  return (
    <>
      {ApplicationForm(
        request.company_application_type_id,
        STapplication,
        showTodoList,
        true,
        attachments,
        existingValue,
      )}
    </>
  );
};

// function camelize(str) {
//   return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
//     if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
//     return index === 0 ? match.toLowerCase() : match.toUpperCase();
//   });
// }
function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

let ApplicationItem = (props) => {
  const { valueKey = '', value } = props;
  let lowerCaseKey = valueKey.toLocaleLowerCase();
  let keyTexts = lowerCaseKey.split('_');
  keyTexts = keyTexts.map((aText) => {
    if (aText?.length > 0) {
      return aText[0].toLocaleUpperCase() + aText.substring(1);
    }
    return '';
  });
  let label = keyTexts.join(' ');
  return (
    <Box>
      <Box>
        <small>{label}</small>
      </Box>
      <Box>{value || '-'}</Box>
    </Box>
  );
};

//8
export const IndividualParticularApplicationForm = ({
  request,
  showTodoList,
}) => {
  const {
    individualParticularsApplication,
    getDocumentById,
    IndividualParticularsApplication,
    GetDocumentById,
  } = useProvideRequest();

  useEffect(() => {
    IndividualParticularsApplication(request.application_primary_id);
  }, []);

  useEffect(() => {
    if (individualParticularsApplication?.identity_attachment_id) {
      GetDocumentById(individualParticularsApplication?.identity_attachment_id);
    }
    if (individualParticularsApplication?.residency_attachment_id) {
      GetDocumentById(
        individualParticularsApplication?.residency_attachment_id,
      );
    }
    if (individualParticularsApplication?.deed_poll_attachment_id) {
      GetDocumentById(
        individualParticularsApplication?.deed_poll_attachment_id,
      );
    }
  }, [JSON.stringify(individualParticularsApplication)]);

  const application = [
    {
      label: 'Individual Particular Application Id',
      description:
        individualParticularsApplication.individual_particular_application_id,
    },
    {
      label: 'Name Change Date',
      description: individualParticularsApplication.name_change_date,
    },
    {
      label: 'Individual Type Id',
      description: individualParticularsApplication.individual_type_id,
    },
    {
      label: 'Company Role Id',
      description: individualParticularsApplication.company_role_id,
    },
    {
      label: 'User Id',
      description: individualParticularsApplication.user_id,
    },
    {
      label: 'Email',
      description: individualParticularsApplication.email,
    },
    {
      label: 'First Name',
      description: individualParticularsApplication.first_name,
    },
    {
      label: 'Last Name',
      description: individualParticularsApplication.last_name,
    },
    {
      label: 'Mobile Number',
      description: individualParticularsApplication.mobile_number,
    },
    {
      label: 'Nationality Country Id',
      description: individualParticularsApplication.nationality_country_id,
    },
    {
      label: 'Identification Type Id',
      description: individualParticularsApplication.identification_type_id,
    },
    {
      label: 'Identification Value',
      description: individualParticularsApplication.identification_value,
    },

    {
      label: 'Floor Unit No',
      description: individualParticularsApplication.floor_unit_no,
    },
    {
      label: 'Address Line 1',
      description: individualParticularsApplication.address_line_1,
    },
    {
      label: 'Address Line 2',
      description: individualParticularsApplication.address_line_2,
    },
    {
      label: 'Address Line 3',
      description: individualParticularsApplication.address_line_3,
    },
    {
      label: 'Postal Code',
      description: individualParticularsApplication.postal_code,
    },
    {
      label: 'State Id',
      description: individualParticularsApplication.state_id,
    },
    {
      label: 'Country Id',
      description: individualParticularsApplication.country_id,
    },
    {
      label: 'Residence Type Id',
      description: individualParticularsApplication.residence_type_id,
    },
    {
      label: 'Deed Poll Date',
      description: individualParticularsApplication.deed_poll_date,
    },
  ];

  return (
    <>
      {ApplicationForm(
        request.company_application_type_id,
        application,
        showTodoList,
        false,
        getDocumentById,
      )}
    </>
  );
};

export const ApplicationForm = (
  typeId,
  applicationList,
  showTodoList = false,
  multiple = false,
  getDocumentById,
  existingValue,
) => {
  const { smBelow, mdBelow } = useBreakpoints();
  const [openTodoModal, setOpenTodoModal] = useState({
    isOpened: false,
    type: '',
    completed: false,
  });
  const [openTodoFiles, setOpenTodoFileType] = useState([]);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [loadingFilePreview, setLoadingFilePreview] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleGetFileType = async (url) => {
    setLoadingFilePreview(true);
    await fetch(url)
      .then((response) => {
        let filePreview = {
          filetype: response.headers
            .get('Content-Type')
            .slice(response.headers.get('Content-Type').lastIndexOf('/') + 1),
          document_url: url,
        };
        setShowFilePreview(filePreview);
        setLoadingFilePreview(false);
      })
      .catch((error) => [setLoadingFilePreview(false)]);
  };

  const handleDownloadFile = (url) => {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download =
          'download.' +
          response.headers
            .get('Content-Type')
            .slice(response.headers.get('Content-Type').lastIndexOf('/') + 1);
        a.click();
      });
    });
  };

  const GetS3UploadFileDetails = (typeId) => {
    let tableType, primaryId;
    switch (typeId) {
      case 1:
        tableType = 12;
        primaryId = 'share_application_id';
        break;
      case 2:
        tableType = 13;
        primaryId = 'share_transfer_application_id';
        break;
      case 3:
        tableType = 14;
        primaryId = 'company_address_application_id';
        break;
      case 4:
        tableType = 15;
        primaryId = 'company_name_application_id';
        break;
      case 5:
        tableType = 6;
        primaryId = 'officer application id';
        break;
      case 6:
        tableType = 9;
        primaryId = 'new_shareholder_application_id';
        break;
      case 7:
        tableType = 7;
        primaryId = 'cessation application id';
        break;
      case 8:
        tableType = 16;
        primaryId = 'individual_particular_application_id';
        break;
      case 9:
        tableType = 17;
        primaryId = 'financial_year_end_application_id';
        break;
      case 10:
        tableType = 18;
        primaryId = 'open_bank_account_application_id';
        break;
      case 11:
        tableType = 19;
        primaryId = 'close_bank_account_application_id';
        break;
      case 12:
        tableType = 20;
        primaryId = 'business_activity_application_id';
        break;
      case 13:
        tableType = 21;
        primaryId = 'declare_interim_dividend_application_id';
        break;
      case 14:
        tableType = 22;
        primaryId = 'other_request_id';
        break;
      case 15:
        tableType = 10;
        primaryId = 'shares_allotment_application_id';
        break;
      case 16:
        tableType = 23;
        primaryId = 'company_auditor_application_id';
        break;
    }

    return { table_type: tableType, primary_id: primaryId };
  };

  const TodoModal = ({ isModalOpen, onCloseModal, document }) => {
    const classes = useStyles();
    const { smBelow, mdBelow } = useBreakpoints();
    const [attachments, setAttachments] = useState([]);
    const { company, user } = useContext(ConfigContext);

    useEffect(() => {
      if (getDocumentById?.length > 0) {
        setAttachments([...getDocumentById, ...document]);
      } else {
        setAttachments(document);
      }
    }, [getDocumentById]);

    return (
      <Modal
        className={classes.modal}
        open={isModalOpen}
        onClose={onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <Paper className={classes.modalPaper}>
            <LoadingOverlay
              active={loading}
              spinner={<ClipLoader color="white" />}
              styles={{
                wrapper: {
                  overflow: loading ? 'hidden' : 'initial',
                },
              }}
            >
              <Box p={3} display="flex" style={{ color: '#92929D' }}>
                {/* <Paper
                  elevation={0}
                  variant={smBelow ? 'elevation' : 'outlined'}
                  style={{
                    borderRadius: 5,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10,
                    color: openTodoModal.completed ? '#43D249' : '#A21D23',
                    borderColor: openTodoModal.completed
                      ? '#43D249'
                      : '#A21D23',
                  }}
                >
                  <CheckOutlinedIcon />
                  <Box pr={1} />
                  <ButtonBase
                    onClick={() =>
                      setOpenTodoModal({
                        ...openTodoModal,
                        completed: true,
                      })
                    }
                  >
                    <Typography variant="h4">Mark Complete</Typography>
                  </ButtonBase>
                </Paper> */}
                <div style={{ flexGrow: 1 }} />
                <ButtonBase
                  onClick={() =>
                    setOpenTodoModal({
                      ...openTodoModal,
                      isOpened: false,
                      type: '',
                    })
                  }
                >
                  <CloseOutlinedIcon />
                </ButtonBase>
              </Box>
              <Divider flexItem style={{ height: '1px' }} />

              <Box p={3}>
                <Typography variant="h2">Submitted Files</Typography>
                <Box p={2} />
                <Box pb={1} display="flex" alignItems="center">
                  <ClearAllOutlinedIcon style={{ color: '#92929D' }} />
                  <Box p={1} />
                  <Typography variant="h4">Description</Typography>
                </Box>
                <Box pl={5}>
                  <Typography variant="body1">
                    Next Friday should be done. Next Monday we should deliver
                    the first iteration. Make sure, we have a good result to be
                    delivered by the day. Next Friday should be done. Next
                    Monday we should deliver the first iteration.
                  </Typography>
                </Box>
                <Box p={2} />
                <Divider flexItem style={{ height: '1px' }} />

                <Box pt={4} pb={1} display="flex" alignItems="center">
                  <AttachFileOutlinedIcon style={{ color: '#92929D' }} />
                  <Box p={1} />
                  <Typography variant="h4">Attachment</Typography>
                  <Box p={1} />

                  <ButtonDropzone
                    multiple={true}
                    files={[]}
                    setFiles={async (files = []) => {
                      if (files.length > 0) {
                        setLoading(true);
                        const applicationFile = GetS3UploadFileDetails(typeId);

                        const submittedFilesID = await S3UploadAndInsertDocuments(
                          files[0],
                          138,
                          applicationFile.table_type,
                          applicationFile.primary_id,
                        );
                        submittedFilesID.company_application_id = params.id;
                        submittedFilesID.company_id = company.company_id;
                        submittedFilesID.document_name = files[0].name;
                        submittedFilesID.document_container_name =
                          submittedFilesID.container_name;
                        submittedFilesID.document_table_type_name =
                          submittedFilesID.table_name;
                        submittedFilesID.uploaded_by = user.user_id;
                        submittedFilesID.type = openTodoModal.type;

                        if (submittedFilesID.document_id) {
                          await CreateCompanyApplicationDocument(
                            params.id,
                            submittedFilesID,
                          );
                        }
                        setLoading(false);
                      }
                    }}
                  />
                </Box>

                {loadingFilePreview ? (
                  <LoadingComponent />
                ) : (
                  showFilePreview && (
                    <FilePreviewModal
                      filetype={showFilePreview.filetype}
                      fileurl={showFilePreview.document_url}
                      IsModalOpened={Boolean(showFilePreview)}
                      onCloseModal={() => {
                        setShowFilePreview(false);
                      }}
                      onAfterOpen={() => {}}
                    />
                  )
                )}

                {attachments.length === 0 ? (
                  <Box pt={1} pl={5}>
                    <Typography variant="body1">No Attachment Found</Typography>
                  </Box>
                ) : (
                  attachments.map((item, index) => (
                    <Box
                      key={index}
                      pt={1}
                      pl={5}
                      display="flex"
                      alignItems="center"
                    >
                      <Avatar
                        variant="square"
                        style={{
                          backgroundColor: '#D7D7D7',
                          color: 'black',
                        }}
                      >
                        <Typography variant="h5">PDF</Typography>
                      </Avatar>
                      <Box pl={2} display="flex" flexDirection="column">
                        <Typography variant="h5">
                          {item.document_name
                            ? item.document_name
                            : item.document_type_name}
                        </Typography>
                        <Typography
                          variant="body2"
                          display="inline"
                          style={{ color: '#92929D' }}
                        >
                          Added{' '}
                          {moment(item.uploaded_date).format(
                            'DD/MM/yyyy hh:mma',
                          )}{' '}
                          --
                          <ButtonBase
                            onClick={() => {
                              if (item.document_url) {
                                handleDownloadFile(item.document_url);
                              }
                            }}
                          >
                            <Typography
                              variant="body2"
                              display="inline"
                              style={{
                                color: '#92929D',
                                textDecoration: 'underline',
                              }}
                            >
                              Download
                            </Typography>
                          </ButtonBase>{' '}
                          --{' '}
                          <ButtonBase
                            onClick={() => {
                              if (item.document_url) {
                                handleGetFileType(item.document_url);
                              }
                            }}
                          >
                            <Typography
                              variant="body2"
                              display="inline"
                              style={{
                                color: '#92929D',
                                textDecoration: 'underline',
                              }}
                            >
                              View
                            </Typography>
                          </ButtonBase>
                        </Typography>
                      </Box>
                    </Box>
                  ))
                )}
              </Box>
            </LoadingOverlay>
          </Paper>
        </Fade>
      </Modal>
    );
  };

  const ToDoRow = ({
    index,
    label,
    daysLeft,
    file,
    fileLength = 0,
    checklist,
    available = false,
  }) => {
    const classes = useStyles();
    return (
      <Box display="flex" alignItems="center" justifyContent="center" pb={4}>
        <Badge
          badgeContent={index}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          className={
            // fileLength || index === '1' || available
            //   ? classes.greenBadge
            //   :
            classes.greyBadge
          }
        />

        <Box pl={5} />
        <Typography variant="h4" style={{ color: '#808191' }}>
          {label}
        </Typography>
        <Box pl={2} />

        {!!daysLeft && (
          <Typography
            variant="caption"
            align="left"
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 5,
              paddingRight: 5,
              borderRadius: 5,
              color: '#FC5A5A',
              backgroundColor: '#FEF3F1',
              height: 25,
            }}
          >
            <AccessTimeIcon fontSize="small" />
            <Box pr={1} />
            {daysLeft} days left
          </Typography>
        )}

        <div style={{ flexGrow: 1 }} />

        <AttachFileOutlinedIcon style={{ color: '#92929D' }} />
        <Typography variant="body1" style={{ color: '#808191' }}>
          {fileLength}
        </Typography>
        {/* <Box pr={3} />
        <CheckBoxOutlinedIcon style={{ color: '#92929D' }} />
        <Typography variant="body1" style={{ color: '#808191' }}>
          {checklist}
        </Typography> */}
        <Box px={2} />
        <ButtonBase
          onClick={() => {
            if (fileLength || index === '1' || available) {
              setOpenTodoModal({
                ...openTodoModal,
                isOpened: true,
                type: label.toLowerCase(),
              });
            }
          }}
        >
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
        </ButtonBase>
      </Box>
    );
  };

  const ToDoList = () => {
    return (
      <Paper
        elevation={0}
        variant={smBelow ? 'elevation' : 'outlined'}
        style={{
          borderRadius: smBelow ? '0px' : '4px',
          flex: '1 1 0%',
        }}
      >
        <Box p={2}>
          <Typography variant="h1">To do list</Typography>
          <Box p={2} />
          {/* <Typography
            variant="body1"
            style={{
              color: '#696974',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            75% Completed
          </Typography>
          <BorderLinearProgress variant="determinate" value={50} />
          <Box p={4} /> */}
          <ToDoRow
            index="1"
            label="Submitted"
            file={submittedDoc}
            fileLength={
              getDocumentById?.length > 0
                ? submittedDoc?.length + getDocumentById?.length
                : submittedDoc?.length
            }
            checklist="8/8"
          />
          <ToDoRow
            index="2"
            label="Prepared"
            file={preparedDoc}
            fileLength={preparedDoc?.length}
            checklist="8/8"
          />
          <ToDoRow
            index="3"
            label="Signed"
            file={signedDoc}
            fileLength={signedDoc?.length}
            checklist="8/8"
            available={preparedDoc?.length > 0}
          />
          <ToDoRow
            index="4"
            label="Processed"
            file={processedDoc}
            fileLength={processedDoc?.length}
            checklist="4/8"
            // daysLeft="1"
          />
          <ToDoRow
            index="5"
            label="Approved"
            file={approvedDoc}
            fileLength={approvedDoc?.length}
            checklist="1/1"
          />
        </Box>
      </Paper>
    );
  };

  const params = useParams();

  const {
    listCompanyApplicationDocument,
    ListCompanyApplicationDocument,
    CreateCompanyApplicationDocument,
  } = useProvideRequest();

  let submittedDoc = [],
    preparedDoc = [],
    signedDoc = [],
    processedDoc = [],
    approvedDoc = [];
  if (listCompanyApplicationDocument.length > 0) {
    submittedDoc = listCompanyApplicationDocument.filter(
      (doc) => doc.type == 'submitted',
    );
    preparedDoc = listCompanyApplicationDocument.filter(
      (doc) => doc.type == 'prepared',
    );
    signedDoc = listCompanyApplicationDocument.filter(
      (doc) => doc.type == 'signed',
    );
    processedDoc = listCompanyApplicationDocument.filter(
      (doc) => doc.type == 'processed',
    );
    approvedDoc = listCompanyApplicationDocument.filter(
      (doc) => doc.type == 'approved',
    );
  }
  useEffect(() => {
    ListCompanyApplicationDocument(params.id);
  }, []);

  const renderApplication = (application) => {
    return (
      <>
        <TodoModal
          isModalOpen={openTodoModal.isOpened}
          onCloseModal={() =>
            setOpenTodoModal({
              ...openTodoModal,
              isOpened: false,
              type: '',
            })
          }
          document={
            listCompanyApplicationDocument.length > 0
              ? listCompanyApplicationDocument.filter(
                  (doc) => doc.type == openTodoModal.type,
                )
              : []
          }
        />
        <Paper
          elevation={0}
          variant={smBelow ? 'elevation' : 'outlined'}
          style={{
            borderRadius: smBelow ? '0px' : '4px',
            flex: '1 1 0%',
          }}
        >
          <List subheader={<li />} style={{ paddingBottom: 0 }}>
            <ListItem
              style={{
                backgroundColor: '#F5F5F5',
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    whiteSpace: 'normal',
                    fontWeight: 'bold',
                  },
                  variant: 'body2',
                }}
                primary={'Requester Details'}
              />
            </ListItem>
            <Divider />
            {application.map((item, index) => {
              return (
                <>
                  {!!item.description && ( // hide item when no value return
                    <>
                      <DetailList
                        key={index}
                        label={item.label}
                        description={item.description}
                      />
                      {application.length !== index + 1 && <Divider />}
                    </>
                  )}
                </>
              );
            })}
          </List>
        </Paper>
      </>
    );
  };

  return (
    <>
      {!showTodoList && (
        <>
          {existingValue && (
            <>
              <Box p={1} />
              {renderApplication(existingValue)}
            </>
          )}
          <Box p={2} />
          {multiple &&
            applicationList.map((application, index) => {
              return (
                <Box key={index}>
                  <Typography variant="h2">Shareholder {index + 1}</Typography>
                  {renderApplication(application)}
                  {applicationList.length !== index + 1 && <Box p={2} />}
                </Box>
              );
            })}
          {!multiple && <>{renderApplication(applicationList)}</>}
        </>
      )}

      <Box p={2} />
      {showTodoList && <ToDoList />}
      <Box p={2} />
    </>
  );
};

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#E2E2EA',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#3DD598',
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  dashboardBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: '10px',
    padding: 15,
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: '#FFFFFF',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
    marginBottom: '15px',
  },
  modalPaper: {
    boxShadow: '#191919',
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    borderRadius: 20,
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
  },
  greenBadge: {
    '& .MuiBadge-badge': {
      left: 10,
      backgroundColor: '#3DD598',
      color: 'white',
    },
  },
  greyBadge: {
    '& .MuiBadge-badge': {
      left: 10,
      backgroundColor: '#C5C5CE',
      color: 'white',
    },
  },
}));
