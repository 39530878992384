import { Box, Button, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { EnhancedSkeleton } from 'src/components/Enhanced';
import ProfileBox from 'src/components/ProfileBox';
import ROUTES from 'src/constants/routes';
import useBreakpoints from 'src/hooks/useBreakpoints';
import cartSvc from 'src/services/cart';
import companySvc from 'src/services/company';
import productSvc from 'src/services/product';
import useStyles from './Profile.styles';
import { useContext } from 'react';
import { ConfigContext } from 'src/contexts';

const Profile = () => {
  const classes = useStyles();
  const history = useHistory();
  const { smBelow } = useBreakpoints();
  const [tabValue, setTabValue] = useState(0);
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const { addCart, user } = useContext(ConfigContext);
  const handleTabChange = (event, newTabValue) => {
    if (newTabValue !== null) setTabValue(newTabValue);
  };
  useEffect(() => {
    setLoading(true);
    (async () => {
      const comps = await companySvc.companiesAndShares(user.user_id);
      if (comps && comps?.length > 0) setCompanies(comps);
      else {
        const prods = await productSvc.products();
        setProducts(prods);
      }
      setLoading(false);
    })();
  }, []);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        style={{ width: '100%' }}
        hidden={value !== index}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  };

  const renderAll = () => {
    return companies.map((c, i) => (
      <div key={i}>
        <ProfileBox
          companyId={c.company_id}
          companyName={c.company_name}
          UEN={c.company_registration_no}
          officer={c.role_name}
          shareholder={c.shares}
          DoA={moment(c.incorporation_date).format('DD/MM/YYYY')}
          DoC={moment(c.status_date).format('DD/MM/YYYY')}
          showBadge
        />
      </div>
    ));
  };

  const renderOfficer = () => {
    return companies.map((c, i) => (
      <div key={i}>
        <ProfileBox
          companyId={c.company_id}
          companyName={c.company_name}
          UEN={c.company_registration_no}
          officer={c.role_name}
          DoA={moment(c.incorporation_date).format('DD/MM/YYYY')}
          DoC={moment(c.status_date).format('DD/MM/YYYY')}
          showBadge
        />
      </div>
    ));
  };

  const renderShareholder = () => {
    return companies.map((c, i) => (
      <div key={i}>
        <ProfileBox
          companyId={c.company_id}
          companyName={c.company_name}
          UEN={c.company_registration_no}
          shareholder={c.shares}
        />
      </div>
    ));
  };

  if (loading)
    return (
      <div
        style={{
          width: '100%',
        }}
      >
        <EnhancedSkeleton
          layout={[
            {
              loaders: [{ variant: 'rect', height: 50, width: '100%' }],
              repeat: 5,
              mt: 4.25,
            },
          ]}
        />
      </div>
    );

  const RenderCompanies = () => (
    <>
      <Box className={classes.profileBox}>
        <Box p={smBelow ? 2 : 4} className={classes.boxColumn}>
          <Typography variant="subtitle2">Name</Typography>
          <Typography variant="h5" className={classes.fontWeight}>
            {`${user.first_name} ${user.last_name}`}
          </Typography>
        </Box>
        <Box p={smBelow ? 2 : 4} className={classes.boxColumn}>
          <Typography variant="subtitle2">NRIC</Typography>
          <Typography variant="h5" className={classes.fontWeight}>
            {user.nric}
          </Typography>
        </Box>
        <Box p={smBelow ? 2 : 4} className={classes.boxColumn}>
          <Typography variant="subtitle2">Nationality</Typography>
          <Typography variant="h5" className={classes.fontWeight}>
            {user.nationality}
          </Typography>
        </Box>
      </Box>

      <ToggleButtonGroup
        value={tabValue}
        exclusive
        onChange={handleTabChange}
        style={{ paddingTop: 50, paddingBottom: 30 }}
        classes={{ groupedHorizontal: classes.tabs }}
      >
        <ToggleButton value={0} className={classes.singleButton}>
          All
        </ToggleButton>
        <ToggleButton value={1} className={classes.singleButton}>
          Officer
        </ToggleButton>
        <ToggleButton value={2} className={classes.singleButton}>
          Shareholder
        </ToggleButton>
      </ToggleButtonGroup>

      <>
        <TabPanel value={tabValue} index={0}>
          {renderAll()}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {renderOfficer()}
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          {renderShareholder()}
        </TabPanel>
      </>
    </>
  );

  const handleSelectProd = (prod, i) => {
    setProduct(prod);
  };

  const handleAddToCart = async () => {
    // await cartSvc.addToCart(
    //   {
    //   product_id: product.id,
    //   quantity: 1,
    // }
    // );

    // history.push(ROUTES.PAYMENTCART);
    addCart({
      product_id: product.id,
      quantity: 1,
      user_id: user.user_id,
    }).then((rs) => {
      console.log(rs);
      history.push(ROUTES.PAYMENTCART);
    });
  };

  const RenderProducts = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <div className={classes.products}>
        {products
          .filter((p) => p.isNewCompany)
          .map((p, i) => (
            <div
              key={i}
              className={`${classes.product} ${
                p.id === product?.id ? classes.selectedProd : ''
              }`}
              onClick={() => handleSelectProd(p, i)}
            >
              <div>{p.name}</div>
            </div>
          ))}
      </div>
      {product ? (
        <>
          <Button
            color="primary"
            type="button"
            variant="contained"
            style={{
              backgroundColor: '#DEAD00',
              color: '#FFFFFF',
              borderRadius: 50,
              width: 180,
              textTransform: 'initial',
              fontWeight: 600,
              margin: 'auto',
            }}
            onClick={handleAddToCart}
          >
            Continue
          </Button>
        </>
      ) : null}
    </div>
  );
  return (
    <>
      {companies && companies?.length > 0 ? (
        <RenderCompanies />
      ) : (
        <RenderProducts />
      )}
    </>
  );
};

export default Profile;
