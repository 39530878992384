import React, { useContext, useEffect } from 'react';
import { Container, Paper } from '@material-ui/core';
import BusinessCenterRoundedIcon from '@material-ui/icons/BusinessCenterRounded';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import Avatar from '@material-ui/core/Avatar';
import { Box, Typography } from '@material-ui/core';
import useBreakpoints from 'src/hooks/useBreakpoints';
import useStyles from './InfoBox.styles';
import { ConfigContext } from 'src/contexts';
import { useProvideCapTable } from 'src/hooks/useCapTable';

function truncate(str, num) {
  if (str) return str.length > 10 ? str.substring(0, num) + '...' : str;
}
const InfoBox = ({ vertical, center, width }) => {
  const { mdBelow, mdAbove } = useBreakpoints();
  const classes = useStyles();
  const { company } = useContext(ConfigContext);
  let centerMobileView = center && mdBelow;
  let noCompany = Object.keys(company).length === 0;

  const {
    ListCapTableShareholders,
    capTableShareholders,
  } = useProvideCapTable();

  useEffect(() => {
    if (company?.company_id) {
      ListCapTableShareholders(company.company_id);
    }
  }, [company.company_id]);

  return (
    <Box
      display="flex"
      flexDirection={vertical ? 'column' : 'row'}
      width={width ? width : '100%'}
    >
      <Paper
        className={classes.paper}
        style={{
          height: centerMobileView ? 183 : 148,
          flexDirection: centerMobileView ? 'column' : 'row',
          justifyContent: centerMobileView ? 'center' : 'flex-start',
        }}
      >
        <Box pr={3} />
        <Avatar className={classes.avatar1}>
          <BusinessCenterRoundedIcon fontSize="large" />
        </Avatar>
        <Container
          style={{
            paddingLeft: mdBelow ? 'auto' : 'initial',
            marginLeft: mdBelow ? 'auto' : 'initial',
          }}
        >
          <Box
            pl={centerMobileView ? 0 : 2}
            display="flex"
            flexDirection="column"
          >
            <Box p={centerMobileView ? 1 : 0}>
              <Typography
                variant="h3"
                // numberOfLines={1}
                align={centerMobileView ? 'center' : 'left'}
                style={{ fontWeight: 600 }}
              >
                {!noCompany ? company.company_name : 'Main Account'}
              </Typography>
            </Box>
            <Typography
              variant="h5"
              align={centerMobileView ? 'center' : 'left'}
              style={{ color: '#8F96AD' }}
            >
              UEN: {!noCompany ? company.company_registration_no : 0}
            </Typography>
          </Box>
        </Container>
      </Paper>
      <Box p={1} />
      <Paper
        className={classes.paper}
        style={{
          height: centerMobileView ? 183 : 148,
          flexDirection: centerMobileView ? 'column' : 'row',
          justifyContent: centerMobileView ? 'center' : 'flex-start',
        }}
      >
        <Box pr={3} />
        <Avatar className={classes.avatar2}>
          <AttachMoneyRoundedIcon fontSize="large" />
        </Avatar>
        <Box
          pl={centerMobileView ? 0 : 2}
          display="flex"
          flexDirection="column"
        >
          <Box p={centerMobileView ? 1 : 0}>
            <Typography
              variant="h3"
              align={centerMobileView ? 'center' : 'left'}
              style={{ fontWeight: 600 }}
            >
              ${' '}
              {!!capTableShareholders
                ? capTableShareholders[0]?.issued_share_capital.toLocaleString()
                : 0}
            </Typography>
          </Box>
          <Typography
            variant="h5"
            align={centerMobileView ? 'center' : 'left'}
            style={{ color: '#8F96AD' }}
          >
            {'Share Capital'}
          </Typography>
        </Box>
      </Paper>
      <Box p={1} />
      <Paper
        className={classes.paper}
        style={{
          height: centerMobileView ? 183 : 148,
          flexDirection: centerMobileView ? 'column' : 'row',
          justifyContent: centerMobileView ? 'center' : 'flex-start',
        }}
      >
        <Box pr={3} />
        <Avatar className={classes.avatar3}>
          <CreditCardRoundedIcon fontSize="large" />
        </Avatar>
        <Box
          pl={centerMobileView ? 0 : 2}
          display="flex"
          flexDirection="column"
        >
          <Box p={centerMobileView ? 1 : 0}>
            <Typography
              variant="h3"
              align={centerMobileView ? 'center' : 'left'}
              style={{ fontWeight: 600 }}
            >
              ${' '}
              {!!capTableShareholders
                ? capTableShareholders[0]?.paid_up_capital.toLocaleString()
                : 0}
            </Typography>
          </Box>
          <Typography
            variant="h5"
            align={centerMobileView ? 'center' : 'left'}
            style={{ color: '#8F96AD' }}
          >
            {'Paid-up Capital'}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default InfoBox;
