import { useContext, useState, createContext } from 'react';
import { APIRequest } from 'src/utils/api';
import { Config } from 'src/config';

const sysInfoUserRolesContext = createContext({
  error: '',
  loading: false,
  submitting: false,
  ListSysInfoUserRoles: () => Promise.resolve({}),
  AddSysInfoUserRoles: () => Promise.resolve({}),
  UpdateSysInfoUserRoles: () => Promise.resolve({}),
  DeleteSysInfoUserRoles: () => Promise.resolve({}),
});

export function useProvideSysInfoUserRolesTable() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [sysInfoUserRoles, setSysInfoUserRoles] = useState([]);

  const ListSysInfoUserRoles = async (id) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setLoading,
      path: `${Config.userURL}/sysinfouserroles/company/${id}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setSysInfoUserRoles(res.body.users);
    }
    return res;
  };

  const AddSysInfoUserRoles = async (newUser = {}) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setLoading,
      path: `${Config.userURL}/sysinfouserroles`,
      method: 'post',
      body: newUser,
    });
    if (res.status === 'success') {
      setSysInfoUserRoles([...sysInfoUserRoles, res.body.users]);
    }
    return res;
  };

  const UpdateSysInfoUserRoles = async (username, companyId, newRole = {}) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setLoading,
      path: `${Config.userURL}/sysinfouserroles/username/${username}/company/${companyId}`,
      method: 'put',
      body: newRole,
    });
    if (res.status === 'success') {
      const userIndex = sysInfoUserRoles.findIndex(
        (u) => u.sysinfo_role_username === username,
      );
      if (userIndex !== undefined) {
        sysInfoUserRoles[userIndex] = res.body.users;
        setSysInfoUserRoles([...sysInfoUserRoles]); //reassign sysInfoUserRoles
      }
    }
    return res;
  };

  const DeleteSysInfoUserRoles = async (username, companyId, body = {}) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setLoading,
      path: `${Config.userURL}/sysinfouserroles/username/${username}/company/${companyId}`,
      method: 'delete',
      body: {
        data: body,
      },
    });
    if (res.status === 'success') {
      const userIndex = sysInfoUserRoles.findIndex(
        (u) => u.sysinfo_role_username === username,
      );
      if (userIndex !== -1) {
        const removedItem = sysInfoUserRoles.splice(userIndex, 1);
        const remainingUserRoles = sysInfoUserRoles.filter(
          (r) => r.sysinfo_role_username !== removedItem.sysinfo_role_username,
        );
        setSysInfoUserRoles(remainingUserRoles);
      }
    }
    return res;
  };

  return {
    error,
    loading,
    sysInfoUserRoles,
    ListSysInfoUserRoles,
    AddSysInfoUserRoles,
    UpdateSysInfoUserRoles,
    DeleteSysInfoUserRoles,
  };
}

export const useCapTableContext = () => useContext(sysInfoUserRolesContext);

export function ProvideSysInfoUserRolesTable({ children }) {
  const sysinfouserroles = useProvideSysInfoUserRolesTable();
  return (
    <sysInfoUserRolesContext.Provider value={sysinfouserroles}>
      {children}
    </sysInfoUserRolesContext.Provider>
  );
}
