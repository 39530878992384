import React from 'react';
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Typography,
} from '@material-ui/core';
import useBreakpoints from 'src/hooks/useBreakpoints';

const CustomList = (props) => {
  const {
    data = [],
    columns = [],
    cellProps = {},
    bordered = true,
    className = '',
    style = {},
  } = props;

  const { smBelow } = useBreakpoints();

  return (
    <Paper
      elevation={0}
      variant={smBelow ? 'elevation' : 'outlined'}
      style={{
        borderRadius: smBelow ? '0px' : '4px',
        ...style,
      }}
    >
      <List subheader={<li />} style={{ paddingBottom: 0 }}>
        <ListItem
          style={{
            backgroundColor: '#F5F5F5',
          }}
        >
          <ListItemText
            primaryTypographyProps={{
              style: {
                whiteSpace: 'normal',
              },
            }}
            primary={
              <Box display="flex" style={{ width: '100%' }}>
                {columns.map((anItem, index) => {
                  let flexGrow =
                    anItem?.flexGrow != undefined ? anItem.flexGrow : 1;
                  return (
                    <Typography
                      key={index}
                      style={{ flex: `${flexGrow} 1 0%` }}
                      variant="h5"
                    >
                      {anItem?.label || ''}
                    </Typography>
                  );
                })}
              </Box>
            }
          />
        </ListItem>
        <Divider />
        {data?.length > 0 &&
          data?.map((aData, index) => (
            <React.Fragment key={index}>
              {index != 0 && bordered ? <Divider /> : null}
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      whiteSpace: 'normal',
                    },
                  }}
                  primary={
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ width: '100%' }}
                      {...cellProps}
                    >
                      {columns.map((aColumn, index2) => {
                        let flexGrow =
                          aColumn?.flexGrow != undefined ? aColumn.flexGrow : 1;

                        let cellValue =
                          aData?.[aColumn?.dataIndex] || undefined;
                        let value = null;
                        if (
                          aColumn?.render &&
                          typeof aColumn.render == 'function'
                        ) {
                          value = aColumn.render(cellValue, aData, index);
                        } else {
                          value = cellValue;
                        }
                        return (
                          <Box
                            key={index2}
                            style={{ flex: `${flexGrow} 1 0%` }}
                            variant="h5"
                          >
                            {value}
                          </Box>
                        );
                      })}
                    </Box>
                  }
                />
              </ListItem>
            </React.Fragment>
          ))}
      </List>
    </Paper>
  );
};

export default CustomList;
