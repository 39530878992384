import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Link,
  Card,
  CardContent,
} from '@material-ui/core';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import ProgressLoader from '../../Progress';
import { ConfigContext } from 'src/contexts';
import useBreakpoints from 'src/hooks/useBreakpoints';
import CustomPinField from '../../CustomFormFields/CustomPinField';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  radioWrapper: {
    borderColor: theme.palette.grey['400'],
    borderWidth: '1px',
    borderStyle: 'solid',
    marginLeft: 0,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    height: '44px',
    color: theme.palette.grey['600'],
  },
  selectedRadioWrapper: {
    backgroundColor: 'rgba(255, 228, 142, 0.19)',
    borderColor: theme.palette.primary.main,
    borderWidth: '1px',
    borderStyle: 'solid',
    marginLeft: 0,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    height: '44px',
  },
}));

const fieldName_email = 'email';

function OTPVerificationForm(props) {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const { setUser } = useContext(ConfigContext);
  const { smBelow } = useBreakpoints();

  const [pinValue, setPinValue] = useState(['', '', '', '', '', '']);

  const validationSchema = Yup.object({
    [fieldName_email]: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
  });

  const formikOnSubmit = async (values, actions) => {
    console.log('values', values);
    // setMsg('');
    // setLoading(true);
    // await authSvc.signin(values).then(
    //   (rs) => {
    //     // if (rs.is_success === true) {
    //     setUser(rs.user);
    //     // }
    //     companySvc.companiesAndShares().then((response) => {
    //       console.log('response', response[0].company_name);
    //       companySvc.setCompany({
    //         companyName: response[0].company_name,
    //         companyId: response[0].company_id,
    //         uen: response[0].company_registration_no,
    //       });
    //       console.log('comps', companySvc.getCompany());
    //     });
    //     setLoading(false);

    //     history.push(ROUTES.DASHBOARD);
    //   },
    //   (err) => {
    //     setMsg(err.message);
    //     setLoading(false);
    //   },
    // );
  };

  return (
    <Card
      variant="outlined"
      style={{
        borderRadius: 16,
        border: smBelow ? 'none' : '',
        width: smBelow ? '100%' : '400px',
        maxWidth: '100%',
      }}
    >
      <CardContent>
        <Box fontWeight="700" fontSize="30px" textAlign="center">
          2-step verification
        </Box>
        <Box
          mb={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          // width={smBelow ? 'auto' : '275px'}
          width="100%"
        >
          <Box maxWidth="275px" width={'100%'}>
            <Typography color="textSecondary" variant="body2">
              {
                'Please enter the 6-digit verification code we sent to mobile number ********84.'
              }
            </Typography>
          </Box>
        </Box>
        <Formik
          initialValues={{
            [fieldName_email]: '',
          }}
          onSubmit={formikOnSubmit}
          validationSchema={validationSchema}
        >
          {(props) => {
            return (
              <Form style={{ width: '100%' }}>
                <Box mb={2}>
                  <CustomPinField value={pinValue} onChange={setPinValue} />
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  // disabled={!(props.isValid && props.dirty)}
                  type="submit"
                  // variant="contained"
                  style={{
                    color: '#FFFFFF',
                    textTransform: 'initial',
                    fontWeight: 700,
                  }}
                  size="large"
                  fullWidth={true}
                  disableElevation
                >
                  Verify
                </Button>
                {msg && <p>{msg}</p>}
                <Box
                  display="flex"
                  justifyContent="center"
                  mt={loading ? 2 : 0}
                >
                  <ProgressLoader loading={loading} />
                </Box>
              </Form>
            );
          }}
        </Formik>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={3}
        >
          <Box display="flex">
            <Typography color="textSecondary" variant="body2">
              {`Didn\’t receive a code? `}Resend
            </Typography>
            &nbsp;
            <Typography
              color="initial"
              variant="body2"
              style={{ fontWeight: 'bold' }}
            >
              (01:23s)
            </Typography>
          </Box>
          <Box>
            <Typography color="textSecondary" variant="body2">
              Need more help?{' '}
              <Link href="mailto:support@meyzer360.com" color="primary">
                Contact us
              </Link>
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

OTPVerificationForm.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default OTPVerificationForm;

// const calculateTimeLeft = () => {
//   let year = new Date().getFullYear();
//   let difference = +new Date(`10/01/${year}`) - +new Date();

//   let timeLeft = {};

//   if (difference > 0) {
//     timeLeft = {
//       days: Math.floor(difference / (1000 * 60 * 60 * 24)),
//       hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
//       minutes: Math.floor((difference / 1000 / 60) % 60),
//       seconds: Math.floor((difference / 1000) % 60),
//     };
//   }

//   return timeLeft;
// };

// const useTimer = () => {
//   const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setTimeLeft(calculateTimeLeft());
//     }, 1000);

//     return () => clearTimeout(timer);
//   });
// }
// const Timer = () => {
//   const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setTimeLeft(calculateTimeLeft());
//     }, 1000);

//     return () => clearTimeout(timer);
//   });

//   const timerComponents = [];

//   Object.keys(timeLeft).forEach((interval) => {
//     if (!timeLeft[interval]) {
//       return;
//     }

//     timerComponents.push(
//       <span>
//         {timeLeft[interval]} {interval}{' '}
//       </span>,
//     );
//   });

//   return (
//     <div>
//       {timerComponents.length ? timerComponents : <span>{"Time's up!"}</span>}
//     </div>
//   );
// };
