import React from 'react';
import useStyles from './DescriptionBox.styles';
import { Box, Container, Typography, Paper, Button } from '@material-ui/core';

const ContentBox = () => {
  const classes = useStyles();
  return (
    <Box className={classes.contentPaper}>
      <Typography style={{ fontWeight: 600, fontSize: 22 }}>
        Streamline Your Business
      </Typography>
      <Box p={1} />
      <Typography variant="body1">
        Coming Soon.
        {/*Find quick answers to complex problems from other users, or ask the
        community a question.*/}
      </Typography>
      <Box p={2} />
      <Button
        variant="contained"
        color="primary"
        style={{ width: '40%', margin: '5px' }}
      >
        Digital Banking
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{ width: '40%', margin: '5px' }}
      >
        Human Resources
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{ width: '40%', margin: '5px', padding: '18px' }}
      >
        Payroll
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{ width: '40%', margin: '5px' }}
      >
        Payment Collection
      </Button>
    </Box>
  );
};

const DescriptionBox = ({ img }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container>
        <div
          className={classes.background}
          style={{
            backgroundImage: `url(${img})`,
          }}
        />
        {ContentBox()}
      </Container>
    </Box>
  );
};

export default DescriptionBox;
