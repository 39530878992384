import React, { useState, useEffect, useContext } from 'react';
import { ConfigContext } from 'src/contexts';
import { Typography, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import ActionList from 'src/components/ActionList';
import { useProvideShareholder } from 'src/hooks/useShareholders';
import { useProvideProduct } from 'src/hooks/useProduct';

import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';

const ShareholderList = ({ toggleShowMenu }) => {
  // const classes = useStyles();
  const buttonClasses = useButtonStyle();

  const history = useHistory();
  const [step, setStep] = useState(1);
  const [filteredShareholders, setFilteredShareholders] = useState([]);
  const [multipleProduct, setMultipleProduct] = useState([]);

  const { ListShareholders, shareholders } = useProvideShareholder();
  const { company } = useContext(ConfigContext);
  const { ReadProduct, product, productError } = useProvideProduct();

  useEffect(() => {
    let multiProd = [];
    // get latest local individual/ foreign individual/ company shareholder particulars
    ReadProduct(9) //local individual
      .then((res) => {
        multiProd.push(res.body.product);

        ReadProduct(105) //foreign individual
          .then((res) => {
            multiProd.push(res.body.product);

            ReadProduct(106) //company
              .then((res) => {
                multiProd.push(res.body.product);
                setMultipleProduct(multiProd);
              })
              .catch((error) => {
                showErrorSnackbar(
                  'Failed load product, please try again later.',
                );
              });
          })
          .catch((error) => {
            showErrorSnackbar('Failed load product, please try again later.');
          });
      })
      .catch((error) => {
        showErrorSnackbar('Failed load product, please try again later.');
      });
  }, []);

  const getStarted = {
    one: {
      content: ['Change the particulars of one of your company’s shareholders'],
    },
    two: {
      content: [
        'The updated particulars of the shareholder',
        'For change of address, a copy of their proof of residency (in English)',
        'For change of residency status, a copy of their NRIC/passport',
        'For change of name, a copy of their deed poll',
      ],
    },
    three: {
      content: [
        'Create Request',
        'Meyzer360 Review',
        'Sign Resolution',
        'ACRA Filing',
      ],
    },
    four: {
      content: [
        'Local Individual: SGD' + multipleProduct[0]?.price,
        'Foreign Individual: SGD' + multipleProduct[1]?.price,
        'Company: SGD' + multipleProduct[2]?.price,
      ],
    },
    five: {
      content: [
        'Fill up form: < 5 minutes',
        'Average processing time: 3-5 days',
      ],
    },
  };

  useEffect(() => {
    if (company.company_id && shareholders.length === 0) {
      ListShareholders(company.company_id);
    }
  }, [company]);

  useEffect(() => {
    if (shareholders.length > 0) {
      setFilteredShareholders(
        shareholders,
        // shareholders.filter((sh) => {
        //   return sh.shareholder_type_id !== 2;
        // }),
      );
    }
  }, [shareholders]);

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={step}
        setStep={setStep}
        getStarted={getStarted}
        header={
          <>
            <Typography variant="h2">
              {step === 1 && 'Change in Shareholder Particulars'}
              {step === 2 && 'Select Shareholder'}
            </Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Requests', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Change Personal Particulars',
                  route: ROUTES.CORPSEC_REQ_CHANGE_PERSONAL_PARTICULARS,
                },
                {
                  path: 'Change Shareholder Particulars',
                  color: 'primary',
                },
              ]}
              // styles={{ paddingTop: 30, paddingBottom: 30 }}
            />
          </>
        }
      >
        {step === 2 && (
          <ActionList
            itemList={filteredShareholders}
            buttonLabel="Edit"
            // valueKey="shareholder_id"
            setName={(item) => {
              return item.Name;
            }}
            setIdentificationNum={(item) => {
              return (
                (item?.identifications &&
                  item?.identifications[0]?.identification_value) ||
                ''
              );
            }}
            onClick={(shareholderID, selectedShareholder) => {
              if (selectedShareholder.shareholder_type_id === 2) {
                return history.push(
                  ROUTES.CORPSEC_REQ_CHANGE_SHAREHOLDER_COMPANY_PARTICULARS(
                    selectedShareholder.company_id,
                  ),
                );
              }
              history.push(
                ROUTES.CORPSEC_REQ_CHANGE_SHAREHOLDER_PARTICULARS(
                  shareholderID,
                ),
              );
            }}
          />
        )}
      </GetStartedLayout>

      <Box py={5} />
    </>
  );
};

export default ShareholderList;
