import React, { useState, useEffect, useContext } from 'react';
import './message.css';
import {
  Typography,
  Box,
  Container,
  Button,
  ButtonBase,
  Divider,
  Paper,
  List,
  ListSubheader,
  ListItemSecondaryAction,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import MzButton from 'src/components/MzButton';
import ROUTES from '../constants/routes';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import useBreakpoints from '../hooks/useBreakpoints';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import { useHistory } from 'react-router-dom';
import { AllServices } from 'src/constants/list';
import { CorpsecNameBox } from 'src/components/Corpsec';
import AuthLayout from 'src/components/AuthLayout';
import { useProvideOrder } from 'src/hooks/useOrder';
import moment from 'moment';
import { EnhancedSkeleton } from 'src/components/Enhanced';
import useSnackbar from 'src/hooks/useSnackbar';
import { ConfigContext } from 'src/contexts';
import ContentLayout from 'src/components/ContentLayout';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CustomList from './cart/CustomList';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
  },
  gradientButton: {
    minWidth: '230px',
    minHeight: '50px',
    border: '2px solid transparent',
    borderRadius: '30px',
    backgroundImage:
      'linear-gradient(white, white), linear-gradient(to left, #760000 1.54%, #FFB800 101.02%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
  },
  fontWeight: {
    fontWeight: 600,
  },

  appbar: {
    backgroundImage: `url(${AllServices[0].img})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  toolbar: {
    minHeight: 200,
    alignSelf: 'baseline',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      // mobile view
      flexDirection: 'column',
      flexGrow: 0,
    },
  },
  paymentButton: {
    width: 130,
    textTransform: 'initial',
  },
  paymentActiveButton: {
    width: 130,
    textTransform: 'initial',
    backgroundColor: '#C5C5C5',
  },
}));

const OrderHistory = () => {
  const classes = useStyles();
  const history = useHistory();
  const { xsBelow, smBelow } = useBreakpoints();
  const { showSuccessSnackbar } = useSnackbar();
  const { clearCart, user } = useContext(ConfigContext);

  const { ListOrders, orders = [], orderLoading } = useProvideOrder();

  useEffect(() => {
    ListOrders(user.user_id);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.redirect && params.redirect === 'checkout') {
      // refetch cart
      clearCart();
      showSuccessSnackbar('Order submitted');
    }
  }, []);

  const TypographyStatus = ({ status }) => {
    let statusColor, textLabel;
    switch (status) {
      case 'completed':
        statusColor = '#24FF00';
        textLabel = 'Completed';
        break;
      case 'PENDING':
        statusColor = '#FF7F09';
        textLabel = 'Pending';
        break;
      case 'paid':
        statusColor = '#041DFF';
        textLabel = 'Paid';
        break;
      case 'processing':
        statusColor = '#FF7F09';
        textLabel = 'Processing';
        break;
      case 'failed':
        statusColor = '#FF4D00';
        textLabel = 'Failed';
        break;
    }
    return (
      <Typography
        variant="body1"
        align="right"
        style={{ fontWeight: 600, color: statusColor }}
      >
        {textLabel}
      </Typography>
    );
  };

  const renderItemNames = (items = []) => {
    const names = items.map((item) => item?.product.name) || [];
    return names.join(', ');
  };

  const renderOrderList = () => {
    return (
      <List subheader={<li />} style={{ paddingBottom: 0 }}>
        <ListItem
          style={{
            backgroundColor: '#F5F5F5',
          }}
        >
          <ListItemText
            primaryTypographyProps={{
              style: {
                whiteSpace: 'normal',
              },
            }}
            primary={
              <Box display="flex" style={{ width: '100%' }}>
                <Typography style={{ flex: '1 1 0%' }} variant="h5">
                  ORDER ID
                </Typography>
                <Typography style={{ flex: '2 1 0%' }} variant="h5">
                  DATE
                </Typography>
                <Typography style={{ flex: '3 1 0%' }} variant="h5">
                  QUANTITY (PRODUCT / SERVICE)
                </Typography>
                <Typography style={{ flex: '1 1 0%' }} variant="h5">
                  TOTAL
                </Typography>
                <Typography style={{ flex: '1 1 0%' }} variant="h5">
                  ACTION
                </Typography>
              </Box>
            }
          />
        </ListItem>
        <Divider />
        {orders.length !== 0 &&
          orders.map((item, index) => (
            <>
              <ListItem
                button
                key={`${item.id}${index}`}
                onClick={() => {
                  history.push(ROUTES.ORDERHISTORYDETAILS(item.id));
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      whiteSpace: 'normal',
                    },
                  }}
                  primary={
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ width: '100%' }}
                    >
                      <Typography
                        style={{ flex: '1 1 0%', fontWeight: 'bold' }}
                        variant="body1"
                      >
                        {item.id}
                      </Typography>
                      <Typography style={{ flex: '2 1 0%' }} variant="body1">
                        {moment(item.created_at).format(`DD/MM/yyyy HH:mm`)}
                      </Typography>
                      <Typography style={{ flex: '3 1 0%' }} variant="body1">
                        {item.items?.length} item(s)
                      </Typography>
                      {/* <Typography
                        style={{
                          flex: '4 1 0%',
                          fontWeight: 'bold',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                        variant="body1"
                      >
                        {renderItemNames(item.items)}
                      </Typography> */}

                      <Typography style={{ flex: '1 1 0%' }} variant="body1">
                        S$ {item.totalPrice}
                      </Typography>
                      <Typography
                        color="primary"
                        style={{ flex: '1 1 0%' }}
                        variant="body1"
                      >
                        View Details
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
              {index + 1 !== orders.length && <Divider />}
            </>
          ))}
      </List>
    );
  };

  return (
    <ContentLayout
      header={
        <>
          <Typography variant="h2">My Orders</Typography>
        </>
      }
    >
      <Paper
        elevation={0}
        variant="outlined"
        style={{
          borderRadius: smBelow ? '0px' : '12px',
        }}
        component={Box}
        p={3}
      >
        {orderLoading ? (
          <div
            style={{
              width: '100%',
            }}
          >
            <EnhancedSkeleton
              layout={[
                {
                  loaders: [{ variant: 'rect', height: 40, width: '100%' }],
                  repeat: 10,
                  mt: 4.25,
                },
              ]}
            />
          </div>
        ) : (
          <>
            <Box mb={2}>TOTAL {orders?.length || 0} ORDERS</Box>
            {/* <Paper
              elevation={0}
              variant="outlined"
              style={{
                borderRadius: smBelow ? '0px' : '12px',
              }}
              >
              {renderOrderList()}
          </Paper> */}

            <CustomList
              data={orders?.length > 0 ? orders : []}
              columns={[
                {
                  dataIndex: 'id',
                  label: 'ORDER ID',
                  flexGrow: 1,
                },
                {
                  dataIndex: 'created_at',
                  label: 'DATE',
                  flexGrow: 2,
                  render: (text) => {
                    return moment(text).format(`DD/MM/yyyy HH:mm`);
                  },
                },
                {
                  dataIndex: 'items',
                  label: 'QUANTITY (PRODUCT / SERVICE)',
                  flexGrow: 3,
                  render: (text, data) => {
                    return `${text?.length || 0} item(s)`;
                  },
                },
                {
                  dataIndex: 'totalPrice',
                  label: 'TOTAL',
                  flexGrow: 1,
                  render: (text, data) => {
                    return `S$ ${text || 0}`;
                  },
                },
                {
                  dataIndex: 'id',
                  label: 'ACTION',
                  flexGrow: 1,
                  render: (text) => {
                    return (
                      <Button
                        variant="text"
                        size="small"
                        color="primary"
                        onClick={() => {
                          history.push(ROUTES.ORDERHISTORYDETAILS(text));
                        }}
                        style={{ padding: 0 }}
                      >
                        View Details
                      </Button>
                    );
                  },
                },
              ]}
            />
          </>
        )}
      </Paper>
    </ContentLayout>
  );
};

export default OrderHistory;
