import { useContext, useState, createContext } from 'react';
import { APIRequest } from 'src/utils/api';
import { Config } from 'src/config';
import ENDPOINTS from 'src/constants/endpoints';

const boardroomContext = createContext({
  error: '',
  loading: false,
  submitting: false,
  ListBoardrooms: () => Promise.resolve({}),
});

export function useProvideBoardroom() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [boardrooms, setBoardrooms] = useState([]);

  const ListBoardrooms = async (companyId) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setLoading,
      // path: `${Config.apiURL}/company/companies/${companyId}/officers/?type=active`,
      path: `${ENDPOINTS.COMPANY}/companies/${companyId}/officers`,
      method: 'get',
    });
    if (res.status === 'success') {
      setBoardrooms(res.body.userCompanyRoles);
    }
    return res;
  };

  return {
    error,
    loading,
    submitting,
    boardrooms,
    ListBoardrooms,
  };
}

export const useBoardroomContext = () => useContext(boardroomContext);

export function ProvideBoardroom({ children }) {
  const boardroom = useProvideBoardroom();
  return (
    <boardroomContext.Provider value={boardroom}>
      {children}
    </boardroomContext.Provider>
  );
}
