import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const MembershipPlanCard = (props) => {
  const { data = {}, onClick, active = false } = props;
  const { image, name, description, price, note } = data;

  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      border={1}
      borderColor={theme.palette.grey[300]}
      p={3}
      textAlign="center"
      position="relative"
    >
      {active && (
        <Box
          position="absolute"
          style={{
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,0.4)',
          }}
        />
      )}
      <Box mb={2}>
        <img src={image} style={{ width: '100px', height: '100px' }} />
      </Box>
      <Box mb={1}>
        <Typography variant="h3">{name}</Typography>
      </Box>
      <Box mb={4} color={theme.palette.grey[600]}>
        <Typography
          variant="h4"
          style={{
            fontWeight: 'normal',
          }}
        >
          {description}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="h2">
          <b>{price} /yr</b>
        </Typography>
      </Box>
      <Box mb={2} color={theme.palette.grey[600]}>
        <Typography
          variant="h4"
          style={{
            fontWeight: 'normal',
          }}
        >
          {note}
        </Typography>
      </Box>
      {onClick && (
        <Box width="80%">
          <Button
            variant="contained"
            color="primary"
            disableElevation={true}
            fullWidth={true}
            size="large"
            style={{ textTransform: 'none' }}
            onClick={onClick}
            disabled={active}
          >
            {active ? 'Selected' : 'Select'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default MembershipPlanCard;
