import {
  useContext,
  useState,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { Config } from 'src/config';
import { APIRequest } from 'src/utils/api';
import ENDPOINTS from 'src/constants/endpoints';

const requestContext = createContext({
  requestError: '',
  requestLoading: false,
  requestSubmitting: false,
  requests: [],
  requestDetails: {},
  shareTransferApplications: {},
  shareTransferFromApplications: {},
  shareTransferToApplications: {},
  companyNameApplications: {},
  officerApplications: {},
  getDocumentById: {},
  cessationApplication: {},
  shareAllotmentApplication: {},
  shareApplication: {},
  companyAddressApplication: {},
  businessActivityApplication: {},
  openBankAccApplication: {},
  closeBankAccApplication: {},
  otherRequestApplication: {},
  companyAuditorApplication: {},
  declareInterimDividend: {},
  individualParticularsApplication: {},
  listCompanyApplicationDocument: {},
  auditorCessationApplicationList: {},
  ListRequests: () => Promise.resolve({}),
  ListRequestDetails: () => Promise.resolve({}),
  CreateAuditorCessationApplication: () => Promise.resolve({}),
  CreateBusinessActivityApplication: () => Promise.resolve({}),
  CreateCompanyAddressApplication: () => Promise.resolve({}),
  CreateCompanyNameApplication: () => Promise.resolve({}),
  CreateFinancialYearEndApplication: () => Promise.resolve({}),
  CreateOfficerApplication: () => Promise.resolve({}),
  CreateCessationApplication: () => Promise.resolve({}),
  CreateShareTransferApplication: () => Promise.resolve({}),
  CreateShareAllotmentApplication: () => Promise.resolve({}),
  CreateUpdateParticularsApplication: () => Promise.resolve({}),
  CreateNewShareholderApplication: () => Promise.resolve({}),
  CreateOtherRequestApplication: () => Promise.resolve({}),
  CreateDeclareInterimDividend: () => Promise.resolve({}),
  CreateOpenBankAccApplication: () => Promise.resolve({}),
  CreateCloseBankAccApplication: () => Promise.resolve({}),
  ShareTransferApplications: () => Promise.resolve({}),
  ShareTransferApplicationsFrom: () => Promise.resolve({}),
  ShareTransferApplicationsTo: () => Promise.resolve({}),
  CompanyNameApplications: () => Promise.resolve({}),
  OfficerApplications: () => Promise.resolve({}),
  GetDocumentById: () => Promise.resolve({}),
  CessationApplication: () => Promise.resolve({}),
  ShareAllotmentApplication: () => Promise.resolve({}),
  ShareApplication: () => Promise.resolve({}),
  CompanyAddressApplication: () => Promise.resolve({}),
  BusinessActivityApplication: () => Promise.resolve({}),
  OpenBankAccApplication: () => Promise.resolve({}),
  CloseBankAccApplication: () => Promise.resolve({}),
  OtherRequestApplication: () => Promise.resolve({}),
  CompanyAuditorApplication: () => Promise.resolve({}),
  DeclareInterimDividend: () => Promise.resolve({}),
  IndividualParticularsApplication: () => Promise.resolve({}),
  ListCompanyApplicationDocument: () => Promise.resolve({}),
  CreateCompanyApplicationDocument: () => Promise.resolve({}),
  AuditorCessationApplicationList: () => Promise.resolve({}),
});

export function useProvideRequest() {
  const [requestError, setRequestError] = useState();
  const [requestLoading, setRequestLoading] = useState(false);
  const [requestSubmitting, setRequestSubmitting] = useState(false);
  const [requests, setRequests] = useState([]);
  const [requestDetails, setRequestDetails] = useState({});
  const [shareTransferApplications, setShareTransferApplications] = useState(
    {},
  );
  const [shareApplication, setShareApplication] = useState({});
  const [
    businessActivityApplication,
    setBusinessActivityApplication,
  ] = useState({});

  const [
    shareTransferFromApplications,
    setShareTransferFromApplications,
  ] = useState({});
  const [
    shareTransferToApplications,
    setShareTransferToApplications,
  ] = useState({});
  const [companyAddressApplication, setCompanyAddressApplication] = useState(
    {},
  );
  const [openBankAccApplication, setOpenBankAccApplication] = useState({});
  const [closeBankAccApplication, setCloseBankAccApplication] = useState({});
  const [otherRequestApplication, setOtherRequestApplication] = useState({});
  const [companyAuditorApplication, setCompanyAuditorApplication] = useState(
    {},
  );
  const [declareInterimDividend, setDeclareInterimDividend] = useState({});
  const [
    auditorCessationApplicationList,
    setAuditorCessationApplicationList,
  ] = useState({});
  const [
    individualParticularsApplication,
    setIndividualParticularsApplication,
  ] = useState({});
  const [
    listCompanyApplicationDocument,
    setListCompanyApplicationDocument,
  ] = useState({});

  const [companyNameApplications, setCompanyNameApplications] = useState({});
  const [officerApplications, setOfficerApplications] = useState({});
  const [getDocumentById, setGetDocumentById] = useState([]);
  const [cessationApplication, setCessationApplication] = useState({});
  const [shareAllotmentApplication, setShareAllotmentApplication] = useState(
    {},
  );

  const ListRequests = async (companyID) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestLoading,
      path: `${ENDPOINTS.APPLICATION}/companyapplications/company/${companyID}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setRequests(res.body.applications);
    }
    return res;
  };

  const ListRequestDetails = async (companyApplicationId) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestLoading,
      path: `${ENDPOINTS.APPLICATION}/companyapplications/${companyApplicationId}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setRequestDetails(res.body.applications);
    }
    return res;
  };

  const CreateBusinessActivityApplication = async (businessActivity = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/bizactivity/businessactivityapplications`,
      method: 'post',
      body: businessActivity,
    });
    return res;
  };

  const CompanyAddressApplication = async (applicationPrimaryId) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/companyaddressapplications/${applicationPrimaryId}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setCompanyAddressApplication(res.body.applications);
    }
    return res;
  };

  const CreateCompanyAddressApplication = async (
    companyAddrApplication = {},
  ) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/companyaddressapplications`,
      method: 'post',
      body: companyAddrApplication,
    });
    return res;
  };

  const CreateCompanyNameApplication = async (companyNameApplication = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/companynameapplications`,
      method: 'post',
      body: companyNameApplication,
    });
    return res;
  };

  const CessationApplication = async (applicationPrimaryId = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/cessationapplications/${applicationPrimaryId}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setCessationApplication(res.body.applications);
    }
    return res;
  };

  const CreateAuditorCessationApplication = async (
    cessationApplication = {},
  ) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/auditorcessationapplications`,
      method: 'post',
      body: cessationApplication,
    });
    return res;
  };

  const AuditorCessationApplicationList = async (applicationPrimaryId) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/auditorcessationapplications/${applicationPrimaryId}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setAuditorCessationApplicationList(res.body.applications);
    }
    return res;
  };

  const CreateCessationApplication = async (cessationApplication = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/cessationapplications`,
      method: 'post',
      body: cessationApplication,
    });
    return res;
  };

  const CreateFinancialYearEndApplication = async (
    financialYearEndAppt = {},
  ) => {
    console.log('financialYearEndAppt', financialYearEndAppt);

    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/financialyearendapplications`,
      method: 'post',
      body: financialYearEndAppt,
    });
    return res;
  };

  const DeclareInterimDividend = async (applicationPrimaryId) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/dividenddeclarationapplications/${applicationPrimaryId}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setDeclareInterimDividend(res.body.dividend_declarations);
    }
    return res;
  };

  const CreateDeclareInterimDividend = async (dividend = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/dividenddeclarationapplications`,
      method: 'post',
      body: dividend,
    });
    return res;
  };

  const OpenBankAccApplication = async (applicationPrimaryId) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/openbankaccountapplications/${applicationPrimaryId}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setOpenBankAccApplication(res.body.applications);
    }
    return res;
  };

  const CreateOpenBankAccApplication = async (openApplication = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/openbankaccountapplications`,
      method: 'post',
      body: openApplication,
    });
    return res;
  };

  const CloseBankAccApplication = async (applicationPrimaryId) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/closebankaccountapplications/${applicationPrimaryId}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setCloseBankAccApplication(res.body.applications);
    }
    return res;
  };

  const CreateCloseBankAccApplication = async (closeApplication = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/closebankaccountapplications`,
      method: 'post',
      body: closeApplication,
    });
    return res;
  };

  const OtherRequestApplication = async (applicationPrimaryId) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/otherrequestapplications/${applicationPrimaryId}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setOtherRequestApplication(res.body.other_requests);
    }
    return res;
  };

  const CompanyAuditorApplication = async (applicationPrimaryId) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/companyauditorapplications/${applicationPrimaryId}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setCompanyAuditorApplication(res.body.applications);
    }
    return res;
  };

  const CreateAuditorApplication = async (auditorApplication = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/companyauditorapplications`,
      method: 'post',
      body: auditorApplication,
    });
    return res;
  };

  const CreateOtherRequestApplication = async (otherRequestAppt = {}) => {
    // console.log('other request', otherRequestAppt);

    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/otherrequestapplications`,
      method: 'post',
      body: otherRequestAppt,
    });
    return res;
  };

  const CreateOfficerApplication = async (officerApplication = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/newofficerapplications`,
      method: 'post',
      body: officerApplication,
    });
    return res;
  };

  const CompanyNameApplications = async (applicationPrimaryId) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/companynameapplications/${applicationPrimaryId}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setCompanyNameApplications(res.body.applications);
    }
    return res;
  };

  const OfficerApplications = async (applicationPrimaryId) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/newofficerapplications/${applicationPrimaryId}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setOfficerApplications(res.body.applications);
    }
    return res;
  };

  const GetDocumentById = async (documentId) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${Config.apiUrl}/documents/${documentId}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setGetDocumentById(res.body.document);
    }
    return res;
  };

  const BusinessActivityApplication = async (applicationPrimaryId) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.BIZACTIVITY}/businessactivityapplication/${applicationPrimaryId}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setBusinessActivityApplication(res.body.business_activities);
    }
    return res;
  };

  const ShareApplication = async (applicationPrimaryId) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/shareapplications/${applicationPrimaryId}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setShareApplication(res.body.applications);
    }
    return res;
  };

  const ShareTransferApplications = async (companyID) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/sharetransferapplicationsv2/${companyID}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setShareTransferApplications(res.body.applications);
    }
    return res;
  };

  const ShareTransferApplicationsFrom = async (companyID, userID) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${Config.shareholderURL}/companies/${companyID}/shareholders/${userID}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setShareTransferFromApplications(res.body.shares);
    }
    return res;
  };
  const ShareTransferApplicationsTo = async (companyID, userID) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${Config.shareholderURL}/companies/${companyID}/shareholders/${userID}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setShareTransferToApplications(res.body.shares);
    }
    return res;
  };

  const CreateShareTransferApplication = async (shareTransfer = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/sharetransferapplicationsv2`,
      method: 'post',
      body: shareTransfer,
    });
    return res;
  };

  const ShareAllotmentApplication = async (applicationPrimaryId = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/sharesallotmentapplicationsv2/${applicationPrimaryId}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setShareAllotmentApplication(res.body.applications);
    }
    return res;
  };

  const CreateShareAllotmentApplication = async (shareAllotment = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/sharesallotmentapplicationsv2`,
      method: 'post',
      body: shareAllotment,
    });
    return res;
  };

  const IndividualParticularsApplication = async (applicationPrimaryId) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/individualparticularapplications/${applicationPrimaryId}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setIndividualParticularsApplication(res.body.applications);
    }
    return res;
  };

  const CreateUpdateParticularsApplication = async (personParticulars = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/individualparticularapplications`,
      method: 'post',
      body: personParticulars,
    });
    return res;
  };

  const CreateNewShareholderApplication = async (newShareholder = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${ENDPOINTS.APPLICATION}/newshareholderapplications`,
      method: 'post',
      body: newShareholder,
    });
    return res;
  };

  const ListCompanyApplicationDocument = async (applicationPrimaryId) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${Config.coAppDocURL}/companyapplications/${applicationPrimaryId}/documents`,
      method: 'get',
    });
    if (res.status === 'success') {
      setListCompanyApplicationDocument(res.body.body.rows);
    }
    return res;
  };

  const CreateCompanyApplicationDocument = async (
    applicationPrimaryId,
    body = {},
  ) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${Config.coAppDocURL}/companyapplications/${applicationPrimaryId}/documents`,
      method: 'post',
      body: body,
    });
    if (res.status === 'success' && res.body.is_success === true) {
      setListCompanyApplicationDocument([
        ...listCompanyApplicationDocument,
        res.body.body,
      ]);
    }
    return res;
  };

  return {
    requestError,
    requestLoading,
    requestSubmitting,
    requests,
    requestDetails,
    shareTransferApplications,
    shareTransferFromApplications,
    shareTransferToApplications,
    companyNameApplications,
    officerApplications,
    getDocumentById,
    cessationApplication,
    shareAllotmentApplication,
    shareApplication,
    companyAddressApplication,
    businessActivityApplication,
    openBankAccApplication,
    closeBankAccApplication,
    otherRequestApplication,
    companyAuditorApplication,
    declareInterimDividend,
    individualParticularsApplication,
    listCompanyApplicationDocument,
    auditorCessationApplicationList,
    ListRequests,
    ListRequestDetails,
    CreateAuditorApplication,
    CreateAuditorCessationApplication,
    CreateBusinessActivityApplication,
    CreateCompanyAddressApplication,
    CreateCompanyNameApplication,
    CreateCessationApplication,
    CreateFinancialYearEndApplication,
    CreateOfficerApplication,
    CreateShareTransferApplication,
    CreateShareAllotmentApplication,
    CreateUpdateParticularsApplication,
    CreateNewShareholderApplication,
    CreateOtherRequestApplication,
    CreateDeclareInterimDividend,
    CreateOpenBankAccApplication,
    CreateCloseBankAccApplication,
    ShareTransferApplications,
    ShareTransferApplicationsFrom,
    ShareTransferApplicationsTo,
    CompanyNameApplications,
    OfficerApplications,
    GetDocumentById,
    CessationApplication,
    ShareAllotmentApplication,
    ShareApplication,
    CompanyAddressApplication,
    BusinessActivityApplication,
    OpenBankAccApplication,
    CloseBankAccApplication,
    OtherRequestApplication,
    CompanyAuditorApplication,
    DeclareInterimDividend,
    IndividualParticularsApplication,
    ListCompanyApplicationDocument,
    CreateCompanyApplicationDocument,
    AuditorCessationApplicationList,
  };
}

export const useRequestContext = () => useContext(requestContext);

export function ProvideRequest({ children }) {
  const request = useProvideRequest();
  return (
    <requestContext.Provider value={request}>
      {children}
    </requestContext.Provider>
  );
}
