import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Typography,
  Box,
  Paper,
  Grid,
  makeStyles,
  Divider,
  Tabs,
  Tab,
  InputAdornment,
  ButtonBase,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ListItem,
  ListItemText,
  Button,
  Checkbox,
  IconButton,
  List,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useHistory, Link } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import MzButton from 'src/components/MzButton';
import { Formik, Form, FieldArray } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { useProvideShareholder } from 'src/hooks/useShareholders';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import AlertBox from 'src/components/AlertBox';
import fourSteps from 'src/assets/requests/4steps.png';
import Stepper from 'src/components/Stepper';
import FormDropdown from 'src/components/FormDropdown/FormDropdown.js';
import { FormFileDropzone, FormDatePicker } from 'src/components/Form';
import DetailLabel from 'src/components/DetailLabel';
import FormDropdownTextField from 'src/components/FormDropdownTextField/FormDropdownTextField.js';
import { Icon } from '@iconify/react';
import companySvc from 'src/services/company';
import { ConfigContext } from 'src/contexts';
import { useProvideRequest } from 'src/hooks/useRequests';
import { useProvideProduct } from 'src/hooks/useProduct';
import useSnackbar from 'src/hooks/useSnackbar';
import { StringMasking } from 'src/utils/string';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';
import EnhancedTextField from 'src/components/EnhancedTextField/EnhancedTextField';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import TodayIcon from '@material-ui/icons/Today';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useTheme } from '@material-ui/core/styles';
import DottedLabel from 'src/components/DottedLabel';
import RowTable from 'src/components/RowTable';
import { S3UploadAndInsertDocuments } from 'src/utils/aws';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { useProvideData } from 'src/hooks/useData';

const tempSteps = [
  {
    id: 2,
    label: 'Transferor',
  },
  {
    id: 3,
    label: 'Select Shareholder',
  },
  {
    id: 4,
    label: 'Transferee',
  },
];

const currencyList = [
  { label: 'EUR' },
  { label: 'GBP' },
  { label: 'JPY' },
  { label: 'SGD' },
  { label: 'USD' },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: 'black',
    display: 'flex',
    alignItems: 'left',
    textAlign: 'left',
    backgroundColor: '#ECECEC',
  },
  fontWeight: {
    fontWeight: 600,
  },
}));

const TransferShares = ({ toggleShowMenu }) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const { smBelow, mdBelow } = useBreakpoints();
  const buttonClasses = useButtonStyle();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [shareToTransfer, setShareToTransfer] = useState();
  const [tabValue, setTabValue] = useState(0);
  const [transferShareholders, setTransferShareholders] = useState([]);
  const [shareholderOptions, setShareholderOptions] = useState([]);
  const [selectedSH, setSelectedSH] = useState([]);
  const [selectedFromSH, setSelectedFromSH] = useState({});
  const [multipleProduct, setMultipleProduct] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState({
    phone_number: '',
    country_code_number: '+65',
    country_code: 'SG',
  });
  const { countries, ListCountries } = useProvideData();

  const {
    addCart,
    user,
    cartSubmitting,
    setConfirmationDialog,
    company,
  } = useContext(ConfigContext);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { ReadProduct, product, productError } = useProvideProduct();
  const {
    GetShareholderByUserID,
    shareholderError,
    shareholder,
    shareholders,
    newshareholder,
    ListShareholders,
    ApplyNewShareholder,
  } = useProvideShareholder();
  const {
    CreateShareTransferApplication,
    requestSubmitting,
    requestError,
  } = useProvideRequest();
  const [touched, setTouched] = useState(false);
  const submitRef = useRef(null);

  useEffect(() => {
    let multiProd = [];
    // get latest individual & company product
    ReadProduct(21)
      .then((res) => {
        multiProd.push(res.body.product);

        ReadProduct(55)
          .then((res) => {
            multiProd.push(res.body.product);
            setMultipleProduct(multiProd);
          })
          .catch((error) => {
            showErrorSnackbar('Failed load product, please try again later.');
          });
      })
      .catch((error) => {
        showErrorSnackbar('Failed load product, please try again later.');
      });

    ListCountries();
    // GetShareholderByUserID(user.company_user_id);
  }, []);

  useEffect(() => {
    if (company.company_id) {
      ListShareholders(company.company_id);
    }
  }, [company.company_id]);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (shareholderError) {
      showErrorSnackbar(`Error loading shareholder info. ${shareholderError}`);
    }
  }, [shareholderError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const getShareholderByCompany = (shareholders = []) => {
    return shareholders.find((sh) => sh.company_id === company.company_id);
  };

  useEffect(() => {
    if (shareholders.length > 0) {
      setShareholderOptions(populateShareholderOptions(shareholders));
    }
  }, [shareholders]);

  const step2validationSchema = Yup.object({
    transfer_from_shareholder: Yup.string().required(
      'Transfer From Shareholder is required',
    ),
    number_of_share_transfer: Yup.number()
      .max(selectedFromSH.allotment, 'Cannot be more than your share')
      .min(0, 'Cannot be less than 0')
      .required('Shares to Transfer is required'),
  });

  const step3validationSchema = Yup.object().shape({
    new_shareholders: Yup.array().of(
      Yup.object().shape({
        number_share_transfer: Yup.string()
          .matches(/^[0-9]+$/, 'Must be only digits')
          .required('Share to Transfer is required')
          .test('checkBalance', '', function () {
            if (calcShareBalance() < 0) {
              return this.createError({
                message: 'Balance cannot be negative',
              });
            }
            return true;
          }),
      }),
    ),
    transfer_date: Yup.string().required('Appointment date is required'),
  });

  const step4validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    nric_passport_no: Yup.string().required('ID is required'),
    mobile_number: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Mobile Number is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    identityFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 0,
      then: Yup.array().min(1),
    }),
    residencyFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 1,
      then: Yup.array().min(1),
    }),
    passportFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 1,
      then: Yup.array().min(1),
    }),
  });

  const newCompanyShareholderValidationSchema = Yup.object({
    company_name: Yup.string().required('Company Name is required'),
    company_registration_no: Yup.string().required(
      'Registration No is required',
    ),
    country_incorporation_id: Yup.string().required(
      'Country of Incorporation is required',
    ),
    address_line_1: Yup.string().nullable().required('Address is required'),
    postal_code: Yup.string()
      .nullable()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Postal Code is required'),
    country_id: Yup.string().nullable().required('Country is required'),
  });

  const getStarted = {
    one: {
      content: [
        <Typography
          key={''}
          align="justify"
          variant="body2"
          color="textPrimary"
          display="inline"
        >
          {
            'Transfer of ordinary shares from an existing shareholder to new or existing'
          }
          {/* <Typography
            align="justify"
            variant="body2"
            color="textPrimary"
            display="inline"
            className={classes.fontWeight}
          > */}
          {' individual '}
          {/* </Typography> */}
          {'shareholders'}
        </Typography>,
        <span key={'2'}>
          {`To issue other types of shares and currencies, please go to `}
          <Link to={ROUTES.CORPSEC_REQUESTS_OTHER_REQUEST}>Other Requests</Link>
        </span>,
      ],
    },
    two: {
      content: [
        'Shareholder particulars and a copy of their NRIC/passport (new shareholders only)',
        'Transfer details (number of new shares and considerations for each shareholder)',
      ],
    },
    three: {
      content: [
        'Create Request',
        'Meyzer360 Review',
        'Sign Resolution',
        'ACRA Filing',
      ],
    },
    four: {
      content: [
        'Individual: SGD' + multipleProduct[0]?.price,
        'Company: SGD' + multipleProduct[1]?.price,
      ],
    },
    five: {
      content: [
        'Fill up form: < 5 minutes',
        'Average processing time: 10-15 days',
      ],
    },
  };

  const calcShareBalance = () => {
    return (
      shareToTransfer -
      transferShareholders.reduce((acc, shareholder) => {
        return acc + Number(shareholder.number_share_transfer);
      }, 0)
    );
  };

  const populateShareholderOptions = (shareholders = []) => {
    return shareholders.map((sh) => ({
      label: sh.Name,
      value: sh.shareholder_id,
      allotment: sh.user_allotment,
    }));
  };

  const handleTabChange = (event, newValue) => {
    setTouched(false);
    setTabValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        style={{ width: '100%' }}
        hidden={value !== index}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  };

  const formikOnSubmitStep2 = async (values, actions) => {
    setShareToTransfer(values.number_of_share_transfer);
    setStep(3);
  };

  const calculateTotalPrice = (products, shareholders) => {
    let totalPrice = 0;
    for (let i = 0; i < shareholders.length; i++) {
      if (shareholders[i].is_company === 0) {
        totalPrice = totalPrice + products[0].price;
      }
      if (shareholders[i].is_company === 1) {
        totalPrice = totalPrice + products[1].price;
      }
    }
    return totalPrice;
  };

  const checkProductType = (shareholders) => {
    const companyType = shareholders.find(
      (shareholder) => shareholder.is_company === 1,
    );
    const individualType = shareholders.find(
      (shareholder) => shareholder.is_company === 0,
    );
    if (companyType && individualType) {
      return 57;
    } else if (companyType) {
      return 55;
    } else {
      return 21;
    }
  };

  const formikOnSubmit = async (values, actions) => {
    if (calcShareBalance() < 0) {
      return showErrorSnackbar(`Share exceed available amount to transfer.`);
    }
    setLoading(true);
    values.company_id = company.company_id;
    values.company_application_status_id = 11;
    values.created_by = user.user_id;
    values.total_share_transfer = shareToTransfer;

    let res;
    try {
      res = await CreateShareTransferApplication(values);
    } catch (error) {
      showErrorSnackbar('Error submitting application');
    }
    if (res?.status === 'success') {
      try {
        res = await addCart({
          price: calculateTotalPrice(multipleProduct, res.body.applications),
          product_id: checkProductType(res.body.applications),
          quantity: 1,
          user_id: user.user_id,
          product_detail_id:
            res.body.applications[0].share_transfer_application_id,
          company_id: company.company_id,
          company_name: company.company_name,
          company_registration_no: company.company_registration_no,
          company_application_id:
            res.body.applications[0].company_application_id,
        });
        if (res.status === 'success') {
          actions.resetForm();
          setConfirmationDialog({
            isVisible: true,
            title: 'Item added to cart',
            type: 'success',
            cancelButtonLabel: 'Back to Request',
            confirmButtonLabel: 'View your cart',
            confirmButtonAction: () => {
              history.push(ROUTES.PAYMENTCART);
              setConfirmationDialog({
                isVisible: false,
              });
            },
            cancelButtonAction: () => {
              history.push(ROUTES.CORPSEC_REQ);
              setConfirmationDialog({
                isVisible: false,
              });
            },
          });
        } else {
          showErrorSnackbar('Error adding to cart');
        }
      } catch (error) {
        showErrorSnackbar('Error adding to cart');
      }
    } else {
      showErrorSnackbar('Error submitting application');
    }
    setLoading(false);
  };

  const formikOnSubmitNewCompanyShareholder = async (values, actions) => {
    setLoading(true);
    transferShareholders.push({
      ...values,
      transfer_from_id: selectedFromSH.value,
      transfer_to_id: 0,
      is_new_shareholder: 1,
      is_company: 1,
      number_share_transfer: '',
    });
    setTransferShareholders(transferShareholders);
    setLoading(false);
    actions.resetForm();
    setStep(4);
  };

  const formikOnSubmitNewShareholder = async (values, actions) => {
    setLoading(true);
    if (values.identityFiles.length > 0) {
      const identityFilesID = await S3UploadAndInsertDocuments(
        values.identityFiles[0],
        76,
        6,
        'officer_application_id',
      );
      values.proof_identity_attachment_id = identityFilesID.document_id;
    }

    if (values.residencyFiles.length > 0) {
      const residencyFilesID = await S3UploadAndInsertDocuments(
        values.residencyFiles[0],
        112,
        6,
        'officer_application_id',
      );
      values.proof_residency_attachment_id = residencyFilesID.document_id;
    }

    if (values.passportFiles.length > 0) {
      const passportFilesID = await S3UploadAndInsertDocuments(
        values.passportFiles[0],
        77,
        6,
        'officer_application_id',
      );
      values.passport_attachment_id = passportFilesID.document_id;
    }
    transferShareholders.push({
      company_id: company.company_id,
      company_application_status_id: 11,
      is_foreigner: tabValue == 0 ? 0 : 1,
      shareholder_name: values.name,
      identity_id: values.nric_passport_no,
      mobile_number: phoneNumber.country_code_number + phoneNumber.phone_number,
      email: values.email,
      proof_identity_attachment_id: values.proof_identity_attachment_id,
      passport_attachment_id: values.passport_attachment_id,
      proof_residency_attachment_id: values.proof_residency_attachment_id,
      created_by: user.user_id,
      number_share_transfer: '',
      is_new_shareholder: 1,
      is_company: 0,
      transfer_from_id: selectedFromSH.value,
      transfer_to_id: 0,
    });
    setTransferShareholders(transferShareholders);
    setLoading(false);
    actions.resetForm();
    setStep(4);
    // let res = await ApplyNewShareholder({
    //   company_id: company.company_id,
    //   company_application_status_id: 11,
    //   is_foreigner: tabValue == 0 ? 0 : 1,
    //   shareholder_name: values.name,
    //   identity_id: values.nric_passport_no,
    //   mobile_number: values.mobile_number,
    //   email: values.email,
    //   proof_identity_attachment_id: values.proof_identity_attachment_id,
    //   passport_attachment_id: values.passport_attachment_id,
    //   proof_residency_attachment_id: values.proof_residency_attachment_id,
    //   created_by: user.user_id,
    // });
    // setLoading(false);
    // if (res.status === 'success') {
    //   actions.resetForm();
    //   setStep(3);
    // }
  };

  const getDefaultFromShareholder = (shareholderLabel) => {
    return shareholderOptions.find((sh) => sh.label === shareholderLabel);
  };

  const stepTwo = () => {
    return (
      <Formik
        initialValues={{
          transfer_from_shareholder: selectedFromSH.label,
          number_of_share_transfer: shareToTransfer,
        }}
        onSubmit={formikOnSubmitStep2}
        validationSchema={step2validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <DottedLabel label="Transfer From:" />
            <Box
              display={'flex'}
              flexDirection={mdBelow ? 'column' : 'row'}
              width={'100%'}
              style={{ gap: 20 }}
            >
              <Paper
                variant={'outlined'}
                style={{
                  borderRadius: smBelow ? '0px' : '4px',
                  flex: '1 1 0%',
                  width: mdBelow ? '100%' : '50%',
                }}
              >
                <ListItem
                  style={{
                    backgroundColor: '#F5F5F5',
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        whiteSpace: 'normal',
                        fontWeight: 'bold',
                      },
                      variant: 'body2',
                    }}
                    primary={'Select Existing Shareholder'}
                  />
                </ListItem>
                <Divider />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  minHeight={'50px'}
                  px={7}
                  py={7}
                  flex={1}
                >
                  <FormDropdown
                    autoCompleteStyle={{ width: mdBelow ? '100%' : '85%' }}
                    name="transfer_from_shareholder"
                    onChange={(event, newValue) => {
                      props.setFieldValue(
                        'transfer_from_shareholder',
                        newValue?.value,
                      );
                      setSelectedFromSH(newValue ? newValue : {});
                    }}
                    optionList={shareholderOptions}
                    placeholder="Select"
                    fullWidth
                    shrink
                    variant="outlined"
                    defaultValue={getDefaultFromShareholder(
                      props.values.transfer_from_shareholder,
                    )}
                  />
                </Box>
              </Paper>

              <Paper
                style={{
                  width: mdBelow ? '100%' : '50%',
                  borderRadius: 5,
                  padding: '20px',
                }}
                variant={'outlined'}
              >
                <Box display="flex" flexDirection={'column'}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    width={props.fullWidth ? '100%' : '50%'}
                  >
                    <EnhancedTextField
                      disabled
                      labelText="EXISTING NO. OF SHARES"
                      value={selectedFromSH.allotment}
                      color="primary"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </Box>
                  <FormTextField
                    name="number_of_share_transfer"
                    labelText="SHARES TO TRANSFER"
                    variant="outlined"
                    type="number"
                    placeholder="0"
                    width={smBelow ? '100%' : '70%'}
                  />
                  {!smBelow && <Box width="30%" />}
                </Box>
              </Paper>
            </Box>
            <Box display="flex" pt={3}>
              <MzButton
                // disabled={!(props.isValid && props.dirty)}
                type="submit"
                onClick={() => {}}
                title={'Proceed'}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const stepThree = () => {
    return (
      <>
        <DottedLabel label="Transfer From:" value={selectedFromSH.label} />
        <Grid container>
          <RowTable
            label="Existing No. of Shares"
            latest={selectedFromSH.allotment || 0}
          />
          <RowTable
            label="Shares to Transfer"
            latest={calcShareBalance() || 0}
          />
          <RowTable
            label="Balance"
            latest={selectedFromSH.allotment - shareToTransfer || 0}
          />
        </Grid>
        <Box py={3} />
        <Box
          display={'flex'}
          flexDirection={'row'}
          width={'100%'}
          style={{ gap: 20 }}
        >
          <Paper
            variant={'outlined'}
            style={{
              borderRadius: smBelow ? '0px' : '4px',
              flex: '1 1 0%',
            }}
          >
            <ListItem
              style={{
                backgroundColor: '#F5F5F5',
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    whiteSpace: 'normal',
                    fontWeight: 'bold',
                  },
                  variant: 'body2',
                }}
                primary={'Select Existing Shareholders'}
              />
            </ListItem>
            <Divider />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              minHeight={'50px'}
              px={7}
              py={7}
              flex={1}
            >
              <Autocomplete
                multiple
                onClose={() => {
                  if (transferShareholders.length > 0) {
                    setStep(4);
                  }
                }}
                options={shareholderOptions}
                disableCloseOnSelect
                value={selectedSH}
                getOptionLabel={(option) => option.label}
                renderOption={(option, { selected }) => {
                  return (
                    <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                      />
                      {option.label}
                    </React.Fragment>
                  );
                }}
                style={{ width: 400 }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Select" />
                )}
                onChange={(event, values = [], reason) => {
                  event.preventDefault();
                  let selectedShareholders = [];
                  setSelectedSH(values);
                  if (reason === 'select-option') {
                    values.forEach((selectedShareholder) => {
                      const selectedSHData = shareholders.find(
                        (sh) => sh.shareholder_id === selectedShareholder.value,
                      );
                      const inTransferList = transferShareholders.find(
                        (tsh) => tsh.id === selectedSHData.shareholder_id,
                      );
                      if (inTransferList) return;

                      return selectedShareholders.push({
                        id: selectedSHData.shareholder_id,
                        company_id: company.company_id,
                        company_application_status_id: 11,
                        shareholder_name: selectedSHData.Name,
                        created_by: user.user_id,
                        number_share_transfer: '',
                        is_new_shareholder: 0,
                        transfer_from_id: selectedFromSH.value,
                        transfer_to_id: selectedSHData.shareholder_id,
                        is_company: selectedSHData.shareholder_type_id === 2,
                      });
                    });
                    selectedShareholders = [
                      ...selectedShareholders,
                      ...transferShareholders,
                    ];
                  }
                  if (reason === 'remove-option' || reason === 'clear') {
                    // remove from selection
                    selectedShareholders = transferShareholders.filter(
                      (tsh) => {
                        if (tsh.is_new_shareholder === 1) return true;
                        return values.find((sh) => sh.value === tsh.id);
                      },
                    );
                  }

                  setTransferShareholders([...selectedShareholders]);
                }}
              />
            </Box>
          </Paper>

          <Paper
            variant={'outlined'}
            style={{
              borderRadius: smBelow ? '0px' : '4px',
              flex: '1 1 0%',
            }}
          >
            <ListItem
              style={{
                backgroundColor: '#F5F5F5',
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    whiteSpace: 'normal',
                    fontWeight: 'bold',
                  },
                  variant: 'body2',
                }}
                primary={'Add New Shareholders'}
              />
            </ListItem>
            <Divider />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              minHeight={'50px'}
              flex={1}
              px={7}
              py={7}
            >
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                size="large"
                onClick={() => {
                  setStep(6);
                }}
              >
                Company
              </Button>
              <Box p={1} />
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                size="large"
                onClick={() => {
                  setStep(5);
                }}
              >
                Individual
              </Button>
            </Box>
          </Paper>
        </Box>
      </>
    );
  };

  const stepFour = () => {
    return (
      <>
        <DottedLabel label="Transfer From:" value={selectedFromSH.label} />
        <Grid container>
          <RowTable
            label="Existing No. of Shares"
            latest={selectedFromSH.allotment || 0}
          />
          <RowTable
            label="Shares to Transfer"
            latest={calcShareBalance() || 0}
          />
          <RowTable
            label="Balance"
            latest={selectedFromSH.allotment - shareToTransfer || 0}
          />
        </Grid>
        <Box py={3} />

        <Formik
          initialValues={{
            new_shareholders: [],
            transfer_date: new Date(),
          }}
          onSubmit={formikOnSubmit}
          validationSchema={step3validationSchema}
          enableReinitialize
        >
          {(props) => (
            <Form style={{ width: '100%' }}>
              <DottedLabel label="Transfer to:" />
              <Paper variant="outlined">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Shareholder</TableCell>
                      <TableCell>Shares</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray name="new_shareholders">
                      {() => (
                        <>
                          {transferShareholders.map((shareholder, index) => (
                            <TableRow key={shareholder.id}>
                              <TableCell>
                                <IconButton
                                  onClick={() => {
                                    transferShareholders.splice(index, 1);
                                    setTransferShareholders([
                                      ...transferShareholders,
                                    ]);
                                    if (shareholder.is_new_shareholder === 0) {
                                      // remove from selected option
                                      const indexToRemove = selectedSH.findIndex(
                                        (ssh) => {
                                          return ssh.value === shareholder.id;
                                        },
                                      );
                                      if (indexToRemove !== -1) {
                                        selectedSH.splice(indexToRemove, 1);
                                        setSelectedSH(selectedSH);
                                      }
                                    }
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell>
                                {shareholder.shareholder_name ||
                                  shareholder.company_name}
                              </TableCell>
                              <TableCell>
                                <FormTextField
                                  name={`new_shareholders.${index}.number_share_transfer`}
                                  variant="outlined"
                                  value={shareholder.number_share_transfer}
                                  onChange={(event) => {
                                    props.setFieldValue(
                                      `new_shareholders.${index}.number_share_transfer`,
                                      event.target.value,
                                    );
                                    transferShareholders[
                                      index
                                    ].number_share_transfer =
                                      event.target.value;
                                    setTransferShareholders([
                                      ...transferShareholders,
                                    ]);
                                  }}
                                  // width={smBelow ? '100%' : '33%'}
                                  fullWidth
                                  paddingBottom={false}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </TableBody>
                </Table>
              </Paper>
              <Box p={2} />
              <DottedLabel label="Date of Appointment" />

              <Paper variant="outlined">
                <Box p={3}>
                  <FormDatePicker
                    containerWidth={smBelow ? '100%' : '50%'}
                    name="transfer_date"
                    format="dd/MM/yyyy"
                    variant="outlined"
                    inputVariant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <TodayIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Paper>

              <Box p={2} />
              <Box
                display="flex"
                justifyContent="flex-end"
                style={{ gap: '10px' }}
              >
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor: '#9E9E9E',
                    color: 'white',
                    textTransform: 'initial',
                  }}
                  onClick={() => {
                    setStep(3);
                  }}
                >
                  Back to Select Shareholders
                </Button>
                <MzButton
                  disabled={calcShareBalance() !== 0}
                  type="submit"
                  onClick={async () => {
                    props.setFieldValue(
                      'new_shareholders',
                      [...transferShareholders],
                      true,
                    );
                    props.setFieldTouched('new_shareholders', true, true);
                  }}
                  title={'Submit'}
                  loading={loading}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </>
    );
  };

  const RowField = ({ leftComponent, rightComponent, gap }) => {
    return (
      <Box display="flex" flexDirection={mdBelow ? 'column' : 'row'}>
        {leftComponent}
        {!mdBelow && <Box p={gap ? gap : 5} />}
        {rightComponent}
      </Box>
    );
  };

  const addNewCompanyShareholder = () => {
    return (
      <Paper
        elevation={0}
        variant={smBelow ? 'elevation' : 'outlined'}
        style={{
          borderRadius: smBelow ? '0px' : '4px',
          flex: '1 1 0%',
        }}
      >
        <List subheader={<li />} style={{ paddingBottom: 0 }}>
          <ListItem
            style={{
              backgroundColor: '#F5F5F5',
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                style: {
                  whiteSpace: 'normal',
                  fontWeight: 'bold',
                },
                variant: 'h3',
              }}
              primary={'Company Shareholder'}
            />
          </ListItem>
          <Divider />
          <Box p={1} />

          <Formik
            initialValues={{
              company_name: '',
              company_registration_no: '',
              country_incorporation_id: '',
              floor_unit_no: '',
              address_line_1: '',
              address_line_2: '',
              postal_code: '',
              country_id: '',
            }}
            onSubmit={formikOnSubmitNewCompanyShareholder}
            validationSchema={newCompanyShareholderValidationSchema}
            innerRef={submitRef}
          >
            {(props) => (
              <Form>
                <Box p={3}>
                  <RowField
                    gap={2}
                    leftComponent={
                      <FormTextField
                        fullWidth={mdBelow ? true : false}
                        name="company_name"
                        labelText="Company Name"
                        placeholder="Company Name"
                        shrink
                        variant="outlined"
                      />
                    }
                    rightComponent={
                      <FormTextField
                        fullWidth={mdBelow ? true : false}
                        name="company_registration_no"
                        labelText="Registration No."
                        placeholder="Registration No."
                        shrink
                        variant="outlined"
                      />
                    }
                  />

                  <Box
                    display="flex"
                    flexDirection="column"
                    width={mdBelow ? '100%' : '50%'}
                  >
                    <FormDropdown
                      autoCompleteStyle={{ width: '100%' }}
                      name="country_incorporation_id"
                      onChange={(event, newValue) => {
                        props.setFieldValue(
                          'country_incorporation_id',
                          newValue?.value,
                        );
                      }}
                      optionList={countries}
                      labelText="Country of Incorporation"
                      placeholder="Select country"
                      fullWidth
                      shrink
                      variant="outlined"
                    />
                  </Box>
                </Box>

                <Divider />

                <Box p={3}>
                  <Box pb={4}>
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                          fontWeight: 'bold',
                        },
                        variant: 'h3',
                      }}
                      primary={'Contact Address'}
                    />
                  </Box>

                  <FormTextField
                    name="floor_unit_no"
                    labelText="Floor/ Unit No"
                    placeholder="Enter your floor/ unit no"
                    shrink
                    variant="outlined"
                    width={mdBelow ? '100%' : '50%'}
                  />
                  <RowField
                    gap={2}
                    leftComponent={
                      <FormTextField
                        name="address_line_1"
                        labelText="Address line 1"
                        placeholder="Enter your address"
                        shrink
                        variant="outlined"
                        width={mdBelow ? '100%' : '50%'}
                      />
                    }
                    rightComponent={
                      <FormTextField
                        name="address_line_2"
                        labelText="Address line 2 (Optional)"
                        placeholder="Apartment, suite, unit, floor, etc."
                        shrink
                        variant="outlined"
                        width={mdBelow ? '100%' : '50%'}
                      />
                    }
                  />

                  <RowField
                    gap={2}
                    leftComponent={
                      <FormTextField
                        name="postal_code"
                        labelText="Postal code"
                        placeholder="123456"
                        shrink
                        variant="outlined"
                        width={mdBelow ? '100%' : '50%'}
                      />
                    }
                    rightComponent={
                      <Box
                        display="flex"
                        flexDirection="column"
                        width={mdBelow ? '100%' : '50%'}
                      >
                        <FormDropdown
                          autoCompleteStyle={{ width: '100%' }}
                          name="country_id"
                          onChange={(event, newValue) => {
                            props.setFieldValue('country_id', newValue?.value);
                          }}
                          optionList={countries}
                          labelText="Country"
                          placeholder="Select country"
                          fullWidth
                          shrink
                          variant="outlined"
                        />
                      </Box>
                    }
                  />

                  <MzButton
                    fullWidth={smBelow ? true : false}
                    type="submit"
                    title={'Submit'}
                    onClick={() => {}}
                    loading={loading}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        </List>
      </Paper>
    );
  };

  const addNewShareholder = () => {
    return (
      <Paper
        elevation={0}
        variant={smBelow ? 'elevation' : 'outlined'}
        style={{
          borderRadius: smBelow ? '0px' : '4px',
          flex: '1 1 0%',
        }}
      >
        <List subheader={<li />} style={{ paddingBottom: 0 }}>
          <ListItem
            style={{
              backgroundColor: '#F5F5F5',
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                style: {
                  whiteSpace: 'normal',
                  fontWeight: 'bold',
                },
                variant: 'h3',
              }}
              primary={'Individual Shareholder'}
            />
          </ListItem>
          <Divider />
          <Box p={1} />
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            style={{
              borderBottom: '0.01rem solid #E4E4E4',
              paddingLeft: theme.spacing(smBelow ? 3 : 12),
            }}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label="Local"
              style={{
                textTransform: 'initial',
                fontSize: 16,
              }}
            />
            <Tab
              label="Foreigner"
              style={{
                textTransform: 'initial',
                fontSize: 16,
              }}
            />
          </Tabs>
          <Box p={1} />
          {renderForm()}
        </List>
      </Paper>
    );
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          company_role_id: 5,
          name: '',
          nric_passport_no: '',
          email: '',
          mobile_number: '',
          identityFiles: [],
          residencyFiles: [],
          passportFiles: [],
        }}
        onSubmit={formikOnSubmitNewShareholder}
        validationSchema={step4validationSchema}
        innerRef={submitRef}
      >
        {(props) => (
          <Box p={3} pl={smBelow ? 3 : 12}>
            <Form>
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="name"
                labelText={
                  tabValue === 0
                    ? 'NAME (as in NRIC/FIN) *'
                    : 'NAME (as in PASSPORT) *'
                }
                placeholder="Name"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="nric_passport_no"
                labelText="ID *"
                placeholder="ID"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreditCardIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormDropdownTextField
                name="mobile_number"
                labelText="Mobile Number *"
                placeholder="91234567"
                containerWidth={smBelow ? '100%' : '50%'}
                fullWidth
                shrink
                variant="outlined"
                showFlag={false}
                onValueChange={(event, newValue) => {
                  setPhoneNumber({
                    ...phoneNumber,
                    country_code_number: newValue.phone,
                    country_code: newValue.code,
                  });
                }}
                countryCode={phoneNumber.country_code}
                onChange={(event) => {
                  setPhoneNumber({
                    ...phoneNumber,
                    phone_number: event.target.value,
                  });
                  props.setFieldValue('mobile_number', event.target.value);
                }}
                inputProps={{
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="email"
                labelText="Email Address *"
                placeholder="Email Address"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {tabValue === 0 && (
                <FormFileDropzone
                  containerWidth={smBelow ? '100%' : '50%'}
                  name="identityFiles"
                  labelText="Proof of Identity *"
                />
              )}

              {tabValue === 1 && (
                <Box
                  display="flex"
                  flexDirection={smBelow ? 'column' : 'row'}
                  style={{
                    gap: '20px',
                  }}
                >
                  <FormFileDropzone
                    containerWidth={smBelow ? '100%' : '50%'}
                    name="passportFiles"
                    labelText="Copy of Passport *"
                    manualTouch={touched}
                  />
                  <FormFileDropzone
                    containerWidth={smBelow ? '100%' : '50%'}
                    name="residencyFiles"
                    labelText="Proof of Residency (Must be in English) *"
                    manualTouch={touched}
                  />
                </Box>
              )}

              <Box display="flex" pt={3} justifyContent="flex-start">
                <MzButton
                  // disabled={!(props.isValid && props.dirty)}
                  fullWidth={smBelow ? true : false}
                  type="submit"
                  title={'SAVE'}
                  onClick={async () => {
                    if (
                      tabValue == 1 &&
                      (props.values.passportFiles.length == 0 ||
                        props.values.residencyFiles.length == 0)
                    ) {
                      await setTouched(true);
                      submitRef.current.handleSubmit();
                    }
                  }}
                  loading={loading}
                />
              </Box>
            </Form>
          </Box>
        )}
      </Formik>
    );
  };

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={step}
        setStep={(newStep) => {
          if (step === 5 || step === 6) {
            return setStep(3);
          }
          return setStep(newStep);
        }}
        getStarted={getStarted}
        header={
          <>
            <Typography variant="h2">
              {(step === 1 || step === 2 || step === 3 || step === 4) &&
                'Transfer of Shares'}
              {(step === 5 || step === 6) &&
                'Fill in information for New Shareholder'}
            </Typography>
            <BreadcrumbsNav
              pathList={
                step === 5
                  ? [
                      { path: 'Requests', route: ROUTES.CORPSEC_REQ },
                      {
                        path: 'Change Share Information',
                        route: ROUTES.CORPSEC_REQ_CHANGE_SHARE_INFORMATION,
                      },
                      {
                        path: 'Transfer of Shares',
                        onClick: () => {
                          setStep(3);
                        },
                      },
                      {
                        path: 'Add New Shareholder',
                        color: 'primary',
                      },
                    ]
                  : [
                      { path: 'Requests', route: ROUTES.CORPSEC_REQ },
                      {
                        path: 'Change Share Information',
                        route: ROUTES.CORPSEC_REQ_CHANGE_SHARE_INFORMATION,
                      },
                      {
                        path: 'Transfer of Shares',
                        color: 'primary',
                      },
                    ]
              }
            />
          </>
        }
      >
        {step !== 1 && (
          <Box px={4} py={4} style={{ borderTop: '0.01rem solid #E4E4E4' }}>
            {(step === 2 || step === 3 || step === 4) && (
              <Stepper
                steps={tempSteps}
                providedStep={step - 2}
                setProvidedStep={(step) => {
                  setStep(step + 2);
                }}
              />
            )}
            {step === 2 && stepTwo()}
            {step === 3 && stepThree()}
            {step === 4 && stepFour()}
            {step === 5 && addNewShareholder()}
            {step === 6 && addNewCompanyShareholder()}
          </Box>
        )}
        <Box py={5} />
      </GetStartedLayout>
    </>
  );
};

export default TransferShares;
