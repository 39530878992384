import React, { useState, useEffect } from 'react';
import './message.css';
import {
  Typography,
  Box,
  Container,
  Button,
  Divider,
  Paper,
  List,
  ListSubheader,
  ListItemSecondaryAction,
  ListItem,
  ListItemIcon,
  ListItemText,
  ButtonBase,
  Grid,
} from '@material-ui/core';
import MzButton from 'src/components/MzButton';
import ROUTES from '../constants/routes';
import useBreakpoints from '../hooks/useBreakpoints';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { AllServices } from 'src/constants/list';
import { useProvideOrder } from 'src/hooks/useOrder';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import ContentLayout from 'src/components/ContentLayout';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { EnhancedSkeleton } from 'src/components/Enhanced';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { sgdFormat } from 'src/utils/currency';
import CustomList from './cart/CustomList';
import ImageContainer from 'src/components/ImageContainer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export const validateValue = (
  value,
  additionalChecker = [],
  defaultChecker = [undefined, null, ''],
) => {
  let result = true;
  let checker = [...defaultChecker, ...additionalChecker];
  for (let i = 0; i < checker.length; i++) {
    if (value === checker[i]) {
      result = false;
      break;
    }
  }
  return result;
};
const getAddressString = (data = {}, direction = 'vertical') => {
  // const {
  //   contactPerson = '',
  //   contactNumber = '',
  //   address1 = '',
  //   address2 = '',
  //   address3 = '',
  //   postcode = '',
  //   stateId = '',
  //   state = '',
  //   countryId = '',
  //   country = '',
  // } = data;

  let addressKeys = [
    'address1',
    'address2',
    'address3',
    ['postcode', 'state'],
    'country',
  ];
  let result = [];
  addressKeys.forEach((aKey) => {
    if (Array.isArray(aKey)) {
      let subKeysValue = '';
      aKey.forEach((aSubKey) => {
        if (validateValue(data[aSubKey])) {
          if (subKeysValue == '') {
            subKeysValue += data[aSubKey];
          } else {
            subKeysValue += ` ${data[aSubKey]}`;
          }
        }
      });
      if (validateValue(subKeysValue)) {
        result.push(subKeysValue);
      }
    } else {
      if (validateValue(data[aKey])) {
        result.push(data[aKey]);
      }
    }
  });

  if (direction == 'horizontal') {
    let resultString = '';
    result.map((aData, index) => {
      resultString += `${aData}${index != result.length - 1 ? ', ' : ''}`;
    });
    return <div>{resultString}</div>;
  }
  return (
    <div>
      {result.map((aData, index) => {
        return (
          <div key={index}>{`${aData}${
            index != result.length - 1 ? ',' : ''
          }`}</div>
        );
      })}
    </div>
  );
};

const OrderHistoryDetails = () => {
  // const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { smBelow } = useBreakpoints();
  const {
    ReadOrder,
    GetOrderAddressById,
    order,
    orderLoading,
  } = useProvideOrder();
  const params = useParams();

  useEffect(() => {
    ReadOrder(params.id);
  }, []);

  const orderSummaryData = [
    {
      label: 'Receiver',
      dataIndex: 'first_name',
      render: (text, data) => {
        return (
          <>
            {data?.first_name} {data?.last_name}
          </>
        );
      },
    },
    {
      label: 'Company Name',
      dataIndex: 'company_name',
      render: (text) => {
        return text || '-';
      },
    },
    {
      label: 'Billing Address',
      dataIndex: 'billing_address_id',
      render: (text, order) => {
        return order?.billing_address_id ? (
          <Box>
            <Box>
              {!order.first_name && !order.last_name
                ? '-'
                : `${order.first_name} ${order.last_name}`}
            </Box>
            <Box>{order?.email}</Box>
            <Box>{order?.phone}</Box>
            <Box>
              {getAddressString({
                address1: order?.billing_address?.address_line_1,
                address2: order?.billing_address?.address_line_2,
                address3: order?.billing_address?.address_line_3,
                postcode: order?.billing_address?.postal_code,
                state: order?.billing_address?.state,
                country: order?.billing_address?.country,
              })}
            </Box>
          </Box>
        ) : (
          <Box>-</Box>
        );
      },
    },
    {
      label: 'Shipping Address',
      dataIndex: 'shipping_address_id',
      render: (text, order) => {
        return order?.shipping_address_id ? (
          <Box>
            <Box>
              {!order.shipping_first_name && !order.shipping_last_name
                ? '-'
                : `${order.shipping_first_name} ${order.shipping_last_name}`}
            </Box>
            <Box>{order?.shipping_email}</Box>
            <Box>{order?.shipping_phone}</Box>
            <Box>
              {getAddressString({
                address1: order?.shipping_address?.address_line_1,
                address2: order?.shipping_address?.address_line_2,
                address3: order?.shipping_address?.address_line_3,
                postcode: order?.shipping_address?.postal_code,
                state: order?.shipping_address?.state,
                country: order?.shipping_address?.country,
              })}
            </Box>
          </Box>
        ) : (
          <Box>-</Box>
        );
      },
    },
    {
      label: 'Phone',
      dataIndex: 'phone',
      render: (text) => {
        return text || '-';
      },
    },
    {
      label: 'Email',
      dataIndex: 'email',
      render: (text) => {
        return text || '-';
      },
    },
    {
      label: 'Date',
      dataIndex: 'created_at',
      render: (text) => {
        return <>{text ? moment(text).format(`DD-MM-yyyy hh:mma`) : '-'}</>;
      },
    },
    {
      label: 'Payment Method',
      dataIndex: 'payment_method',
      render: (text) => {
        return text || '-';
      },
    },
  ];

  const orderTotalSummaryData = [
    {
      label: (
        <Box fontWeight="normal" color={theme.palette.grey[500]}>
          Subtotal
        </Box>
      ),
      dataIndex: 'items',
      render: (text, data) => {
        let subtotal = 0;
        if (text?.length > 0) {
          text.forEach((anItem) => {
            subtotal += anItem?.price || 0;
          });
        }
        return <b>{sgdFormat(subtotal)}</b>;
      },
    },
    {
      label: (
        <Box fontWeight="normal" color={theme.palette.grey[500]}>
          Shipping Fee
        </Box>
      ),
      dataIndex: 'shipping_fee',
      render: (text) => {
        return <b>{`${sgdFormat(text || 0)}`}</b>;
      },
    },
    {
      label: (
        <Box fontWeight="normal" color={theme.palette.grey[500]}>
          Tax 6%
        </Box>
      ),
      dataIndex: 'tax',
      render: (text) => {
        return <b>{`${sgdFormat(text || 0)}`}</b>;
      },
    },
  ];

  const orderItemsColumns = [
    {
      dataIndex: 'product',
      label: 'PRODUCT / SERVICE',
      flexGrow: 4,
      render: (text, data) => {
        const { quantity, price, product = {} } = data;
        const { name, item_category } = text;

        let imageURL = product?.product_documents?.[0]?.document?.document_url;
        return (
          <Box display="flex">
            <Box minWidth="75px" mr={2}>
              <ImageContainer src={imageURL} ratio="120%" />
            </Box>
            <Box>
              <Box color={theme.palette.grey[400]} fontWeight="normal">
                {item_category || ''}
              </Box>
              <Box mb={1}>
                <Typography variant="h4">
                  <b>{name}</b>
                </Typography>
              </Box>
              <Box mb={1}>
                <span
                  style={{
                    fontWeight: 'normal',
                  }}
                >
                  Type:
                </span>{' '}
                <b>{data?.type || '-'}</b>
              </Box>
              <Box>Item ID: #{data?.product?.id}</Box>
            </Box>
          </Box>
        );
      },
    },
    {
      dataIndex: 'quantity',
      label: 'QUANTITY',
      flexGrow: 2,
      render: (text, data) => {
        return text || '-';
      },
    },
    {
      dataIndex: 'status',
      label: 'STATUS',
      flexGrow: 1,
      render: (text, data) => {
        return text || '-';
      },
    },
    {
      dataIndex: 'price',
      label: 'PRICE',
      flexGrow: 1,
      render: (text, data) => {
        return sgdFormat(text || 0);
      },
    },
    {
      dataIndex: 'id',
      label: '',
      flexGrow: 1,
      render: (text, data) => {
        let viewDetailPath = null;
        if (
          (data?.product_id == 28 || data?.product_id == 39) &&
          data.company_application_id
        ) {
          viewDetailPath = `/corpsec/company-account/${data.company_application_id}`;
        } else {
          let productCategoryId = data?.product?.product_category_id;

          if (productCategoryId) {
            if (productCategoryId == 1) {
              if (data.company_application_id) {
                viewDetailPath = ROUTES.CORPSEC_DETAILS(
                  data.company_application_id,
                );
              }
            } else if (productCategoryId == 2) {
              if (data?.product?.id) {
                viewDetailPath = `/office-supplies/${data?.product?.id}`;
              }
            }
          }
        }
        if (viewDetailPath) {
          return (
            <Button
              variant="text"
              size="small"
              color="primary"
              onClick={() => {
                history.push(viewDetailPath);
              }}
              style={{ padding: 0 }}
            >
              View Details
            </Button>
          );
        }
        return null;
      },
    },
  ];

  return (
    <ContentLayout
      header={
        <>
          <Typography variant="h2">Order Details</Typography>
        </>
      }
    >
      <Paper
        elevation={0}
        variant="outlined"
        style={{
          borderRadius: smBelow ? '0px' : '12px',
        }}
      >
        <Box p={3}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              history.push(ROUTES.ORDERHISTORY);
            }}
          >
            Back
          </Button>
          <Box p={1} />
          {orderLoading ? (
            <OrderDetailsSkeleton />
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <CustomSummaryList
                    title={`Order ID: #${order?.id || '-'}`}
                    data={order || {}}
                    columns={orderSummaryData}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomSummaryList
                    title={`Total`}
                    data={order || {}}
                    columns={orderTotalSummaryData}
                    bordered={false}
                  >
                    <Box px={2}>
                      <Divider />
                    </Box>
                    <ListItem>
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            whiteSpace: 'normal',
                          },
                          variant: 'body2',
                        }}
                        primary={
                          <Typography variant="h3">Grand Total</Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Typography variant="h3">{`${sgdFormat(
                          order?.totalPrice || 0,
                        )}`}</Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </CustomSummaryList>
                </Grid>
                <Grid item xs={12}>
                  <CustomList
                    data={order?.items || []}
                    columns={orderItemsColumns}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Paper>
    </ContentLayout>
  );
};

export default OrderHistoryDetails;

const OrderDetailsSkeleton = () => {
  return (
    <div
      style={{
        width: '50%',
      }}
    >
      <EnhancedSkeleton
        layout={[
          {
            loaders: [{ variant: 'rect', height: 40, width: '100%' }],
            repeat: 1,
          },
        ]}
      />
      <List subheader={<li />}>
        {new Array(4).fill().map((x, i) => (
          <ListItem
            key={i}
            style={{
              backgroundColor: '#F5F5F5',
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                style: {
                  whiteSpace: 'normal',
                },
                variant: 'body2',
              }}
              primary={
                <EnhancedSkeleton
                  layout={[
                    {
                      loaders: [{ variant: 'rect', height: 20, width: '30%' }],
                      repeat: 1,
                    },
                  ]}
                />
              }
            />
          </ListItem>
        ))}
      </List>
      <Box p={3} />

      <EnhancedSkeleton
        layout={[
          {
            loaders: [{ variant: 'rect', height: 40, width: '100%' }],
            repeat: 1,
          },
        ]}
      />
      <List subheader={<li />}>
        {new Array(5).fill().map((x, i) => (
          <ListItem
            key={i}
            style={{
              backgroundColor: '#F5F5F5',
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                style: {
                  whiteSpace: 'normal',
                },
                variant: 'body2',
              }}
              primary={
                <EnhancedSkeleton
                  layout={[
                    {
                      loaders: [{ variant: 'rect', height: 20, width: '30%' }],
                      repeat: 1,
                    },
                  ]}
                />
              }
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

const CustomSummaryList = (props) => {
  const {
    title = '',
    data = {},
    columns = [],
    bordered = true,
    children,
  } = props;
  const { smBelow } = useBreakpoints();

  return (
    <Paper
      elevation={0}
      variant={smBelow ? 'elevation' : 'outlined'}
      style={{
        borderRadius: smBelow ? '0px' : '4px',
      }}
    >
      <List subheader={<li />} style={{ paddingBottom: 0 }}>
        {title && (
          <>
            <ListItem
              style={{
                backgroundColor: '#F5F5F5',
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    whiteSpace: 'normal',
                    fontWeight: 'bold',
                  },
                  variant: 'body2',
                }}
                primary={title}
              />
            </ListItem>
            <Divider />
          </>
        )}

        {columns.map((anItem, index) => {
          let value = data?.[anItem?.dataIndex] || undefined;
          if (anItem?.render) {
            value = anItem?.render(value, data || {}, index);
          }

          return (
            <React.Fragment key={index}>
              {index != 0 && bordered ? <Divider /> : null}
              <ListItem style={{ alignItems: 'flex-start' }}>
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      whiteSpace: 'normal',
                      fontWeight: 'normal',
                    },
                    variant: 'body2',
                  }}
                  primary={anItem?.label || ''}
                />
                {/* <ListItemSecondaryAction>{value}</ListItemSecondaryAction> */}
                <Box textAlign={'right'}>{value}</Box>
              </ListItem>
            </React.Fragment>
          );
        })}
        {children}
      </List>
    </Paper>
  );
};
