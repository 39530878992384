import React, { useState, useContext, useEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Link,
  Grid,
  TextField,
  SvgIcon,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  InputAdornment,
  IconButton,
  Card,
  CardContent,
} from '@material-ui/core';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Form, Formik, Field, useField } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';

import ROUTES from '../../../constants/routes';
import authSvc from '../../../services/authentication';
import ProgressLoader from '../../Progress';
import { ConfigContext } from 'src/contexts';
import companySvc from 'src/services/company';
import commonSvc from 'src/services/common';
import useBreakpoints from 'src/hooks/useBreakpoints';

import CustomTextField from '../../CustomFormFields/CustomTextField';
import CustomSelectField from '../../CustomFormFields/CustomSelectField';
import CustomPasswordField from '../../CustomFormFields/CustomPasswordField';
import CustomRadioGroupField from '../../CustomFormFields/CustomRadioGroupField';
import CustomPhoneNumberField from '../../CustomFormFields/CustomPhoneNumberField';

import CountdownTimer from 'src/components/CountdownTimer';
import { useProvideOtp } from 'src/hooks/useOtp';
import OtpInput from 'react-otp-input';
import useSnackbar from 'src/hooks/useSnackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  radioWrapper: {
    borderColor: theme.palette.grey['400'],
    borderWidth: '1px',
    borderStyle: 'solid',
    marginLeft: 0,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    height: '44px',
    color: theme.palette.grey['600'],
  },
  selectedRadioWrapper: {
    backgroundColor: 'rgba(255, 228, 142, 0.19)',
    borderColor: theme.palette.primary.main,
    borderWidth: '1px',
    borderStyle: 'solid',
    marginLeft: 0,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    height: '44px',
  },
}));

const fieldName_username = 'username';
const fieldName_roleId = 'role_id';
const fieldName_name = 'name';
const fieldName_firstName = 'first_name';
const fieldName_lastName = 'last_name';
const fieldName_email = 'email';
const fieldName_password = 'password';
const fieldName_mobileNumber = 'contact_number';
const fieldName_mobileNumberCountry = 'contact_phone_code';
const fieldName_gender = 'gender_id';
const fieldName_dateOfBirth = 'dob';
const fieldName_countryOfBirth = 'birth_country_id';
const fieldName_nationality = 'nationality_id';
const fieldName_created_by = 'created_by';

const roles = [
  {
    label: 'Personal',
    value: 1,
  },
  {
    label: 'Business',
    value: 2,
  },
];

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="initial"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const validationSchema = Yup.object({
  // [fieldName_name]: Yup.string().required('Full name is required'),
  [fieldName_firstName]: Yup.string().required('Full name is required'),
  [fieldName_email]: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  [fieldName_password]: Yup.string().required('Password is required'),
  // [fieldName_roleId]: Yup.string(),
  [fieldName_mobileNumber]: Yup.string().required('Mobile number is required'),
  // [fieldName_mobileNumber]: Yup.object().test({
  //   message: 'Mobile number is required',
  //   test: (value) => {
  //     console.log('valuevalue', value);
  //     return value && Object.keys(value).length > 0;
  //   },
  // }),
  // [fieldName_mobileNumberCountry]: Yup.string().required(
  //   'Mobile phone code is required',
  // ),
  [fieldName_gender]: Yup.number().required('Gender is required'),
  [fieldName_dateOfBirth]: Yup.string().required('Date of birth is required'),
  [fieldName_countryOfBirth]: Yup.number().required(
    'Country of birth is required',
  ),
  [fieldName_nationality]: Yup.string().required('Nationality is required'),
});

const validationSchemaStep2 = Yup.object({});

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function SignUpForm(props) {
  const routeHistory = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const { setUser } = useContext(ConfigContext);
  const { smBelow } = useBreakpoints();
  const [step, setStep] = useState(0);
  const [otp, setOtp] = useState('');
  const [signupValues, setSignupValues] = useState({});

  const { GetOtp, ValidateOtp } = useProvideOtp();

  useEffect(() => {
    if (step === 1) {
      requestOtp();
    }
  }, [step]);

  const queryParams = useQuery();
  let invitation_username = queryParams.get('username');
  let invitation_company_id = queryParams.get('company_id');
  let invitation_role_id = queryParams.get('role_id');
  let invitation_ref_id = queryParams.get('ref_id');

  let withInvitation =
    invitation_username &&
    invitation_company_id &&
    invitation_role_id &&
    invitation_ref_id
      ? true
      : false;

  const initialValues = {
    [fieldName_roleId]: 1,
    // [fieldName_name]: '',
    [fieldName_username]: withInvitation ? invitation_username : '',
    [fieldName_firstName]: '',
    [fieldName_lastName]: '',
    [fieldName_email]: withInvitation ? invitation_username : '',
    [fieldName_password]: '',
    [fieldName_mobileNumber]: '',
    [fieldName_mobileNumberCountry]: '60',
    [fieldName_gender]: '1',
    [fieldName_dateOfBirth]: '',
    [fieldName_countryOfBirth]: '',
    [fieldName_nationality]: '',
    [fieldName_created_by]: 1,
  };

  const initialValues2 = {
    birth_country_id: 20,
    contact_number: '12345566778',
    contact_phone_code: '60',
    dob: '2022-01-13',
    email: 'email@email.com',
    username: 'email@email.com',
    first_name: 'john',
    last_name: 'cena',
    gender_id: '1',
    nationality_id: 20,
    password: 'Password123',
    role_id: 1,
    created_by: 1,
  };

  const [countries, setCountries] = useState([]);
  const countryOptions = countries.map((anItem) => {
    return {
      label: anItem.country_name,
      value: anItem.country_id,
      data: anItem,
    };
  });
  const [genders, setGenders] = useState([]);
  const genderOptions = genders.map((anItem) => {
    return {
      label: anItem.gender_name,
      value: `${anItem.gender_id}`,
      data: anItem,
    };
  });

  const [phoneCodes, setPhoneCodes] = useState([]);
  const phoneCodeOptions = phoneCodes.map((anItem) => {
    return {
      // label: (
      //   <Box textAlign="right" width="100%">{`+${anItem.phone_code}`}</Box>
      // ),
      label: `${anItem.country_name} (+${anItem.phone_code})`,
      value: anItem.country_phone_code_id,
      data: anItem,
    };
  });

  useEffect(() => {
    (async () => {
      const countriesResult = await commonSvc.countries();
      if (countriesResult && countriesResult.countries) {
        setCountries(countriesResult.countries);
      } else {
        setCountries([]);
      }

      const gendersResult = await commonSvc.genders();
      if (gendersResult && gendersResult.genders) {
        setGenders(gendersResult.genders);
      } else {
        setGenders([]);
      }

      const phoneCodesResult = await commonSvc.phoneCodes();
      if (phoneCodesResult && phoneCodesResult.phonecodes) {
        setPhoneCodes(phoneCodesResult.phonecodes);
      } else {
        setPhoneCodes([]);
      }
    })();
  }, []);

  const formikOnSubmit = async (values, actions) => {
    setSignupValues(values);
    setStep(1);
  };

  const formikOnSubmitStep2 = async (values, actions) => {
    let res = await ValidateOtp(otp, {
      user_id: signupValues.created_by,
    });
    if (res.status === 'success') {
      if (res.body.otp.is_verified === 1) {
        submitSignUpForm();
      }
    } else {
      showErrorSnackbar(`Invalid OTP`);
    }
  };

  const submitSignUpForm = async () => {
    const values = signupValues;
    const {
      [fieldName_roleId]: roleIdValue,
      [fieldName_gender]: genderValue,
      ...restValues
    } = values;

    let body = {
      ...restValues,
      [fieldName_username]: values[fieldName_email],
      [fieldName_gender]: parseInt(genderValue),
      [fieldName_roleId]: 1,
      [fieldName_created_by]: 1,
    };

    setLoading(true);
    setMsg('');
    await authSvc
      .signup2(body)
      .then((result) => {
        console.log('result', result);
        setLoading(false);
        if (withInvitation) {
          authSvc
            .acceptInvitation(
              {
                username: invitation_username,
                company_id: invitation_company_id,
                role_id: invitation_role_id,
              },
              {
                sysinfo_user_id: result?.users?.user_id,
                ref_id: invitation_ref_id,
              },
            )
            .then((result) => {})
            .catch((error) => {})
            .finally(() => {
              routeHistory.push('/account-activated');
            });
        } else {
          routeHistory.push('/account-activated');
        }
      })
      .catch((error) => {
        if (error?.error?.message) {
          setMsg(error?.error?.message);
        }
        setLoading(false);
      });

    // INSERT INTO SysInfoUsers  (username,  email,  password,  first_name,  last_name,  role_id,  contact_number,  floor_unit_no,  address_line_1,  address_line_2,  address_line_3,  postal_code,  state_id,  country_id,  gender_id,  nationality_id,  birth_country_id,  dob,  created_by)
    // VALUES                    (NULL, 'testing2@testing.com', '0b14d501a594442a01c6859541bcb3e8164d183d32937b851835442f69d5c94e', 'erty', NULL, 'personal', '12345566778', NULL, NULL, NULL, NULL, NULL, NULL, NULL, 1, 'ERFV', 20, '2022-01-13', NULL);
    // setMsg('');
    // setLoading(true);
    // await authSvc.signin(values).then(
    //   (rs) => {
    //     // if (rs.is_success === true) {
    //     setUser(rs.user);
    //     // }
    //     companySvc.companiesAndShares().then((response) => {
    //       console.log('response', response[0].company_name);
    //       companySvc.setCompany({
    //         companyName: response[0].company_name,
    //         companyId: response[0].company_id,
    //         uen: response[0].company_registration_no,
    //       });
    //       console.log('comps', companySvc.getCompany());
    //     });
    //     setLoading(false);

    //     history.push(ROUTES.DASHBOARD);
    //   },
    //   (err) => {
    //     setMsg(err.message);
    //     setLoading(false);
    //   },
    // );
  };

  const requestOtp = async () => {
    let res = await GetOtp({
      mobile_number: signupValues.contact_number,
      created_by: signupValues.created_by,
    });
    console.log('OTP:', res.body.otp.otp);
    if (res.body.is_success === true) {
      setOtp(null);
    }
  };

  const stepOne = () => {
    return (
      <>
        <Box fontWeight="700" fontSize="30px" textAlign="center">
          Register account
        </Box>
        <Box
          mb={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <Typography color="textSecondary" variant="body2">
            Already have an account?&nbsp;
            {/* <<<<<<< HEAD */}
            <NavLink color="primary" to={'/signin'}>
              <Typography color="primary" variant="inherit">
                Sign in
              </Typography>
            </NavLink>
            {/* =======
            <Link href="/signin" color="primary">
              Sign in
            </Link> 
            >>>>>>> d447e6a01b38a354cd98fcf61f3c3a1313f5c97a*/}
          </Typography>
        </Box>
        <Formik
          initialValues={initialValues}
          onSubmit={formikOnSubmit}
          validationSchema={validationSchema}
        >
          {(props) => {
            const { values } = props;
            let selectedUserType = values[fieldName_roleId];
            return (
              <Form style={{ width: '100%' }}>
                {/* <Box mb={2}>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    style={{
                      display: 'flex',
                      marginBottom: '4px',
                    }}
                  >
                    <span>You are registering as:</span>
                  </Typography>
                  <Grid container spacing={3}>
                    {roles.map((aRole, index) => {
                      return (
                        <Grid item xs={6} key={index}>
                          <Field
                            type="radio"
                            name={fieldName_roleId}
                            as={FormControlLabel}
                            value={`${aRole.value}`}
                            control={<StyledRadio />}
                            label={aRole.label}
                            className={
                              selectedUserType == aRole.value
                                ? classes.selectedRadioWrapper
                                : classes.radioWrapper
                            }
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
                <Box mb={2}>
                  <CustomTextField
                    label="Full name"
                    name={fieldName_name}
                    placeholder="What's your full name"
                  />
                  </Box> */}
                <Box mb={2}>
                  <CustomTextField
                    label="First name"
                    name={fieldName_firstName}
                    placeholder="What's your first name"
                  />
                </Box>
                <Box mb={2}>
                  <CustomTextField
                    label="Last name"
                    name={fieldName_lastName}
                    placeholder="What's your last name"
                  />
                </Box>
                <Box mb={2}>
                  <CustomTextField
                    label="Email address"
                    name={fieldName_email}
                    placeholder="you@example.com"
                    type="email"
                    disabled={withInvitation}
                  />
                </Box>
                <Box mb={2}>
                  <CustomPhoneNumberField
                    label="Mobile number"
                    name={fieldName_mobileNumber}
                    phoneCodeName={fieldName_mobileNumberCountry}
                  />
                </Box>
                {/* <Box mb={2}>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    style={{
                      display: 'flex',
                      marginBottom: '4px',
                    }}
                  >
                    <span>Mobile number</span>
                  </Typography>
                  <Box display="flex">
                    <Box mr={1} width="90px">
                      <CustomSelectField
                        name={fieldName_mobileNumberCountry}
                        options={phoneCodeOptions}
                        renderValue={(value) => {
                          let result = (
                            <span
                              style={{ color: theme.palette.grey['500'] }}
                            />
                          );
                          let foundValue = phoneCodeOptions.find((anOption) => {
                            return anOption.value == value;
                          });
                          if (foundValue) {
                            result = `+${foundValue.data.phone_code}`;
                          }
                          return result;
                        }}
                      />
                    </Box>
                    <Box flexGrow={1}>
                      <CustomTextField
                        name={fieldName_mobileNumber}
                        placeholder="What's your mobile number"
                      />
                    </Box>
                  </Box>
                </Box> */}
                <Box mb={2}>
                  <CustomRadioGroupField
                    label={'Gender'}
                    name={fieldName_gender}
                    options={genderOptions}
                  />
                </Box>
                <Box width={smBelow ? '100%' : '70%'}>
                  <Box mb={2}>
                    <CustomTextField
                      label="Date of birth"
                      name={fieldName_dateOfBirth}
                      type="date"
                    />
                  </Box>
                  <Box mb={2}>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      style={{
                        display: 'flex',
                        marginBottom: '4px',
                      }}
                    >
                      <span>Country of birth</span>
                    </Typography>
                    <CustomSelectField
                      name={fieldName_countryOfBirth}
                      options={countryOptions}
                      placeholder="What's your country of birth"
                    />
                  </Box>
                  <Box mb={2}>
                    <CustomSelectField
                      label="Nationality"
                      name={fieldName_nationality}
                      options={countryOptions}
                      placeholder="What's your nationality"
                    />
                  </Box>
                </Box>
                <Box mb={2}>
                  <CustomPasswordField
                    label="Password"
                    name={fieldName_password}
                    showStrength={true}
                  />
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  // disabled={!(props.isValid && props.dirty)}
                  type="submit"
                  // variant="contained"
                  style={{
                    color: '#FFFFFF',
                    textTransform: 'initial',
                    fontWeight: 700,
                  }}
                  size="large"
                  fullWidth={true}
                  disableElevation
                >
                  Register
                </Button>
                {msg && (
                  <Box my={1}>
                    <Typography color="error">{msg}</Typography>
                  </Box>
                )}
                <Box
                  display="flex"
                  justifyContent="center"
                  mt={loading ? 2 : 0}
                >
                  <ProgressLoader loading={loading} />
                </Box>
              </Form>
            );
          }}
        </Formik>
        <Box display="flex" alignItems="center" justifyContent="center" p={3}>
          <Typography color="textSecondary" variant="body2">
            Or, continue with{' '}
            <Link href="#" color="initial">
              Facebook
            </Link>
            ,{' '}
            <Link href="#" color="initial">
              Google
            </Link>{' '}
            or{' '}
            <Link href="#" color="initial">
              Apple
            </Link>
          </Typography>
        </Box>
      </>
    );
  };

  const stepTwo = () => {
    return (
      <>
        <Box fontWeight="700" fontSize="30px" textAlign="center">
          2-step verification
        </Box>
        <Box
          mb={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          // width={smBelow ? 'auto' : '275px'}
          width="100%"
        >
          <Box maxWidth="275px" width={'100%'}>
            <Typography color="textSecondary" variant="body2">
              {'Please enter the 6-digit verification code we sent to mobile number ' +
                signupValues?.contact_number +
                '.'}
            </Typography>
          </Box>
        </Box>
        <Formik
          initialValues={{}}
          onSubmit={formikOnSubmitStep2}
          validationSchema={validationSchemaStep2}
        >
          {(props) => {
            return (
              <Form style={{ width: '100%' }}>
                <Box mb={2}>
                  <OtpInput
                    value={otp}
                    onChange={(newValue) => {
                      setOtp(newValue);
                    }}
                    numInputs={6}
                    isInputNum
                    shouldAutoFocus={true}
                    inputStyle={{
                      width: '3rem',
                      height: '3rem',
                      margin: '0 0.5rem',
                      borderRadius: 4,
                      border: '1px solid #E4E7F1',
                      fontWeight: 600,
                      fontSize: 22,
                    }}
                    focusStyle={{
                      border: '1px solid #A21D23',
                      outline: 'none',
                    }}
                  />
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  // disabled={!(props.isValid && props.dirty)}
                  type="submit"
                  // variant="contained"
                  style={{
                    color: '#FFFFFF',
                    textTransform: 'initial',
                    fontWeight: 700,
                  }}
                  size="large"
                  fullWidth={true}
                  disableElevation
                >
                  Verify
                </Button>
                {msg && <p>{msg}</p>}
                {/* <Box
                  display="flex"
                  justifyContent="center"
                  mt={loading ? 2 : 0}
                >
                  <ProgressLoader loading={loading} />
                </Box> */}
              </Form>
            );
          }}
        </Formik>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={3}
        >
          <CountdownTimer onReset={() => requestOtp()} />
          <Box>
            <Typography color="textSecondary" variant="body2">
              Need more help?{' '}
              <Link href="mailto:support@meyzer360.com" color="primary">
                Contact us
              </Link>
            </Typography>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Card
      variant="outlined"
      style={{
        borderRadius: 16,
        border: smBelow ? 'none' : '',
        width: smBelow ? '100%' : '400px',
        maxWidth: '100%',
      }}
    >
      <CardContent>
        {step === 0 && stepOne()}
        {step === 1 && stepTwo()}
      </CardContent>
    </Card>
  );
}

SignUpForm.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SignUpForm;
