import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import React, { Fragment, useEffect, useState, useContext } from 'react';
import MzButton from 'src/components/MzButton';
import documentSvc from 'src/services/document';
import useStyles from './Documents.styles';
import { EnhancedSkeleton } from 'src/components/Enhanced';
import Modal from 'react-modal';
import FileViewer from 'react-file-viewer';
Modal.setAppElement('#root');
import CircularProgress from '@material-ui/core/CircularProgress';
import MyModalComponent from './mymodal.component';
import { ConfigContext } from 'src/contexts';

// const documentURLKey = 'document_url'
const documentURLKey = 'signed_document_url';

let folderList = [];
let fileList = [];
const Documents = () => {
  const { addCart, user, setConfirmationDialog, company } = useContext(
    ConfigContext,
  );

  const [keyword, setKeyword] = useState(null);
  const [isChild, setIsChild] = useState(false);
  const [folderLoaded, setFolderLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [crumbs, setCrumbs] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (keyword == null) return;

    if (keyword == '') {
      setItems(folderList);
      return;
    }
    return () => {};
  }, [keyword]);

  useEffect(() => {
    (async () => {
      if (!folderLoaded && !loading) {
        setLoading(true);
        await documentSvc.getFolders().then((rs) => {
          folderList = rs;
          setItems(rs);
          setCrumbs([{ title: 'Documents', data: rs }]);
          setFolderLoaded(true);
          setLoading(false);
        });
      }
    })();
  }, []);

  const handleCrumbClick = (c) => {
    if (c.data) {
      setItems(c.data);
      const index = crumbs.findIndex((cx) => cx.title === c.title);
      if (crumbs.length - 1 !== index)
        setCrumbs([
          ...crumbs.filter((cx, i) => (i == 0 ? true : index + 1 > i)),
        ]);
    }
  };

  const handleFolderClick = async (f) => {
    console.log(f);
    setItems(f.child || []);
    setCrumbs([...crumbs, { title: f.name, data: f.child }]);
    if (!f.hasSubFolders) {
      await documentSvc
        .getDocumentsSTS(
          f.document_type_id,
          2,
          company?.company_id,
          user?.username,
          company?.company_id,
        )
        .then((rs) => {
          console.log('getDocumentsSTS', rs);
          let documents =
            rs?.data?.document?.length > 0 ? rs?.data?.document : [];
          fileList = documents;
          setItems(documents);
        });
      // await documentSvc.getFiles(f.document_type_name).then((rs) => {
      //   fileList = rs;
      //   setItems(rs);
      // });
    }
  };

  const handleSearch = () => {
    if (keyword == null || keyword == '') {
      setItems(folderList);
      return;
    }

    documentSvc.searchFiles(keyword).then((rs) => {
      fileList = rs;
      setItems(rs);
    });
  };

  return (
    <>
      <Box display="flex" alignItems="flex-start" flexDirection="row">
        {crumbs?.map((c, i) => (
          <RenderCrumb
            key={i}
            title={`${c.title} ${
              crumbs.length !== 1 && i + 1 !== crumbs.length ? '>' : ''
            }`}
            onClick={() => handleCrumbClick(c)}
          />
        ))}
      </Box>

      <Box py={3} display="flex" flexDirection="row" width="100%">
        <OutlinedInput
          style={{
            borderRadius: '50px',
          }}
          placeholder="Search files"
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          value={keyword}
          startAdornment={
            <InputAdornment>
              <SearchIcon />
            </InputAdornment>
          }
          fullWidth
          labelWidth={0}
          color="secondary"
        />
        <Box p={2} />
        <MzButton title={'Search'} onClick={handleSearch} />
      </Box>
      {isChild && (
        <Button
          startIcon={<ChevronLeftIcon />}
          style={{
            color: '#DEAD00',
            textTransform: 'initial',
          }}
          onClick={() => {
            setRenderType('folder');
            setFolderList(tempFolderList);
            setIsChild(false);
          }}
        >
          Back
        </Button>
      )}

      {!loading && items?.length == 0 ? <h1> Files Not found </h1> : null}

      {!loading ? (
        <List
          style={{
            width: '100%',
          }}
        >
          {items?.map((itm, index) => (
            <Fragment key={index}>
              {itm.type == 'folder' ? (
                <RenderFolder
                  folder={itm}
                  index={index}
                  onClick={() => {
                    handleFolderClick(itm);
                  }}
                />
              ) : (
                <RenderFile file={itm} index={index} />
              )}
            </Fragment>
          ))}
        </List>
      ) : (
        <div
          style={{
            width: '100%',
          }}
        >
          <EnhancedSkeleton
            layout={[
              {
                loaders: [{ variant: 'rect', height: 50, width: '100%' }],
                repeat: 5,
                mt: 4.25,
              },
            ]}
          />
        </div>
      )}
    </>
  );
};

export default Documents;

const RenderCrumb = ({ title, onClick }) => {
  return (
    <Typography
      variant="h6"
      style={{
        color: '#DEAD00',
      }}
      button
      onClick={onClick}
    >
      <a href onClick={onClick}>
        {title}
      </a>
    </Typography>
  );
};

const RenderFolder = ({ folder, index, onClick }) => {
  return (
    <ListItem
      key={`${folder.name}${index}`}
      style={{
        border: '1px solid #ccc',
        borderRadius: '4px',
        minHeight: '60px',
        marginBottom: '15px',
      }}
      button
      onClick={onClick}
    >
      <ListItemIcon>
        <Icon icon="ant-design:folder-filled" width="30" height="30" />
      </ListItemIcon>
      <ListItemText primary={folder.name} />
      <ListItemSecondaryAction>
        <IconButton>
          <ChevronRightIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const RenderFile = ({ file, index }) => {
  const modalData = {
    title: 'My Title From Parent',
    body: ['Apple', 'Ipple', 'Opple', 'Upple', 'Epple'],
  };
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openFromParent() {
    setIsOpen(true);
  }

  function handleCloseModal(event, data) {
    console.log(event, data);
    setIsOpen(false);
  }

  function handleAfterOpen(event, data) {
    console.log(event, data);
  }

  console.log(file);
  const handleDownloadFile = (url) => {
    fetch(url).then((response) => {
      console.log(
        'response.headers',
        response.headers
          .get('Content-Type')
          .slice(response.headers.get('Content-Type').lastIndexOf('/') + 1),
      );
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download =
          'download.' +
          response.headers
            .get('Content-Type')
            .slice(response.headers.get('Content-Type').lastIndexOf('/') + 1);
        a.click();
      });
      //window.location.href = response.url;
    });
  };
  const [isloadingmodal, setLoadingmodal] = useState(false);
  const classes = useStyles();
  // const [isOpen, setIsOpen] = useState(false);
  const [filetypes, setFiletypes] = useState(null);
  var filetype = null;
  // function toggleModal(c) {
  //   setIsOpen(!isOpen);
  //   console.log('c', c);
  // }
  const onError = (e) => {
    console.log(e, 'error in file-viewer');
  };
  useEffect(() => {
    handleGetFileType(file?.[documentURLKey]);
  }, []);
  const handleGetFileType = async (url) => {
    setLoadingmodal(true);
    await fetch(url).then((response) => {
      filetype = response.headers
        .get('Content-Type')
        .slice(response.headers.get('Content-Type').lastIndexOf('/') + 1);
      setFiletypes(
        response.headers
          .get('Content-Type')
          .slice(response.headers.get('Content-Type').lastIndexOf('/') + 1),
      );
      setLoadingmodal(false);
    });
    console.log('filetype', filetype);
    // let filetypeformat = await fetch(url);
    // console.log(
    //   filetypeformat.headers
    //     .get('Content-Type')
    //     .slice(filetypeformat.headers.get('Content-Type').lastIndexOf('/') + 1),
    // );
    // await setFiletype(
    //   filetypeformat.headers
    //     .get('Content-Type')
    //     .slice(filetypeformat.headers.get('Content-Type').lastIndexOf('/') + 1),
    // );

    // toggleModal(filetype);
    // setTimeout(() => setLoadingmodal(flas), 1000);
  };

  return (
    <>
      <ListItem
        style={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          minHeight: '60px',
          marginBottom: '15px',
        }}
        button
      >
        <ListItemIcon>
          <Icon icon="ic:baseline-picture-as-pdf" width="30" height="30" />
        </ListItemIcon>
        <ListItemText
          primary={`${file?.document_type_name}- ${moment(
            file?.uploaded_date,
          ).format('DD/MM/YYYY')}`}
        />
        <ListItemSecondaryAction>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={() => {
              // handleGetFileType(file?.[documentURLKey]);
              openFromParent();
              // window.open(file?.[documentURLKey], '_blank');
            }}
          >
            <Icon
              icon="icon-park-outline:preview-open"
              width="25"
              height="25"
            />
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={(e) => {
              window.location = `mailto:?subject=${file?.document_type_name}-${
                file?.uploaded_date
              }&body=${encodeURI(
                file?.[documentURLKey],
              )}>Click link to download attachement ${encodeURI(
                file?.[documentURLKey],
              )}`;
              e.preventDefault();
            }}
          >
            <Icon
              icon="akar-icons:arrow-forward-thick-fill"
              width="20"
              height="20"
            />
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={() => {
              handleDownloadFile(file?.[documentURLKey]);
              console.log(file?.[documentURLKey]);
              // window.open(file?.[documentURLKey], '_blank', 'noreferrer');
            }}
          >
            <Icon icon="ic:baseline-file-download" width="25" height="25" />
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
      {isloadingmodal ? (
        <CircularProgress />
      ) : (
        <MyModalComponent
          // dynData={modalData}
          filetype={filetypes}
          fileurl={file?.[documentURLKey]}
          IsModalOpened={modalIsOpen}
          onCloseModal={handleCloseModal}
          onAfterOpen={handleAfterOpen}
        />
        // <Modal
        //   style={{
        //     overlay: {
        //       zIndex: 10000,
        //       position: 'fixed',
        //       top: 0,
        //       left: 0,
        //       right: 0,
        //       bottom: 0,
        //       backgroundColor: 'rgba(255, 255, 255, 0.75)',
        //     },
        //     content: {
        //       position: 'absolute',
        //       top: '40px',
        //       left: '40px',
        //       right: '40px',
        //       bottom: '40px',
        //       border: '1px solid #ccc',
        //       background: '#fff',
        //       overflow: 'auto',
        //       WebkitOverflowScrolling: 'touch',
        //       borderRadius: '4px',
        //       outline: 'none',
        //       padding: '20px',
        //     },
        //   }}
        //   isOpen={isOpen}
        //   onRequestClose={toggleModal}
        //   contentLabel="Doc dialog"
        // >
        //   <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        //     <button style={{ borderRadius: 200 }} onClick={toggleModal}>
        //       CLOSE
        //     </button>
        //   </div>
        //   {/* {isLoading ? <CircularProgress /> : <Table data={companyList} />} */}
        //   <FileViewer
        //     fileType={filetype}
        //     filePath={file?.[documentURLKey]}
        //     onError={onError}
        //   />
        // </Modal>
      )}
    </>
  );
};
