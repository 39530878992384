import React from 'react';
import { Box, Container } from '@material-ui/core';

import AuthLayout from 'src/components/AuthLayout';
import { CompanyAccount } from 'src/components/Corpsec';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  return {
    containerRoot: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  };
});

const CompanyAccountSettings = () => {
  const classes = useStyles();
  const { mdBelow } = useBreakpoints();

  return (
    // <AuthLayout>
    <Box display="flex" height="calc(100% - 64px)" flexDirection="column">
      <Box
        display={mdBelow ? 'initial' : 'flex'}
        flexDirection="row"
        flexGrow={1}
      >
        <Container
          maxWidth="xl"
          classes={{
            root: classes.containerRoot,
          }}
        >
          <CompanyAccount />
        </Container>
      </Box>
    </Box>
    // </AuthLayout>
  );
};

export default CompanyAccountSettings;
