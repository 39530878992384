import React, { useState, useEffect, useRef } from 'react';
import { Typography } from '@material-ui/core';

import GuestLayout from 'src/components/GuestLayout';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import ContactSupportForm from 'src/components/ContactSupport';

const ContactSupport = () => {
  return (
    <GuestLayout
    // header={
    //   <>
    //     <Typography variant="h2">Contact Support</Typography>
    //     <BreadcrumbsNav pathList={[]} />
    //   </>
    // }
    >
      <ContactSupportForm />
    </GuestLayout>
  );
};

export default ContactSupport;
