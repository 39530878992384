import React, { useState, useContext, useEffect, useRef } from 'react';
import { Tabs, Tab, Typography, Box, InputAdornment } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import { FormFileDropzone, FormDatePicker } from 'src/components/Form';
import FormDropdownTextField from 'src/components/FormDropdownTextField/FormDropdownTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import MzButton from 'src/components/MzButton';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import { useProvideRequest } from 'src/hooks/useRequests';
import useSnackbar from 'src/hooks/useSnackbar';
import { S3UploadAndInsertDocuments } from 'src/utils/aws';
import { ConfigContext } from 'src/contexts';
import { useProvideProduct } from 'src/hooks/useProduct';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import TodayIcon from '@material-ui/icons/Today';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const ApptDirector = ({ toggleShowMenu }) => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [touched, setTouched] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState({
    phone_number: '',
    country_code_number: '+65',
    country_code: 'SG',
  });
  const [mobileNumber, setMobileNumber] = useState('');
  const { smBelow } = useBreakpoints();
  const theme = useTheme();
  const submitRef = useRef(null);

  const {
    CreateOfficerApplication,
    requestSubmitting,
    requestError,
  } = useProvideRequest();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { addCart, user, setConfirmationDialog, company } = useContext(
    ConfigContext,
  );
  const { ReadProduct, product, productError } = useProvideProduct();

  const getStarted = {
    one: {
      content: ['Appoint a New Local or Foreign Director to your business'],
    },
    two: {
      content: [
        'Personal particulars of the new director',
        'For local directors, a copy of their NRIC or FIN (JPG/PNG/PDF format)',
        'For foreign directors, a copy of their passport and proof of residency',
      ],
    },
    three: {
      content: [
        'Create Request',
        'Meyzer360 Review',
        'Sign Resolution',
        'ACRA Filing',
      ],
    },
    four: {
      content: ['SGD' + product.price],
    },
    five: {
      content: [
        'Fill up form: < 5 minutes',
        'Average processing time: 3-5 days',
      ],
    },
  };

  useEffect(() => {
    // get latest product
    ReadProduct(3);
  }, []);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const validationSchema = Yup.object({
    // name: Yup.string().required('Name is required'),
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    nric_passport_no: Yup.string().required('ID is required'),
    appointment_date: Yup.string().required('Appointment date is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    mobile_number: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Mobile Number is required'),
    identityFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 0,
      then: Yup.array().min(1),
    }),
    residencyFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 1,
      then: Yup.array().min(1),
    }),
    passportFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 1,
      then: Yup.array().min(1),
    }),
  });

  const handleTabChange = (event, newValue) => {
    setTouched(false);
    setTabValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        style={{ width: '100%' }}
        hidden={value !== index}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  };

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.company_id;
    values.company_application_status_id = 11; // in cart status
    values.user_id = 0;
    values.created_by = user.user_id;
    values.mobile_number =
      phoneNumber.country_code_number + phoneNumber.phone_number;

    setLoading(true);
    try {
      if (values.identityFiles.length > 0) {
        const identityFilesID = await S3UploadAndInsertDocuments(
          values.identityFiles[0],
          76,
          6,
          'officer_application_id',
        );
        values.nric_attachment_id = identityFilesID.document_id;
      }

      if (values.residencyFiles.length > 0) {
        const residencyFilesID = await S3UploadAndInsertDocuments(
          values.residencyFiles[0],
          112,
          6,
          'officer_application_id',
        );
        values.residency_attachment_id = residencyFilesID.document_id;
      }

      if (values.passportFiles.length > 0) {
        const passportFilesID = await S3UploadAndInsertDocuments(
          values.passportFiles[0],
          77,
          6,
          'officer_application_id',
        );
        values.passport_attachment_id = passportFilesID.document_id;
      }
      // setLoading(false);
    } catch (err) {
      setLoading(false);
      showErrorSnackbar('Error uploading documents');
      return;
    }

    let res;
    try {
      res = await CreateOfficerApplication(values);
    } catch (error) {
      showErrorSnackbar('Error submitting application');
      setLoading(false);
      return;
    }

    if (res?.status === 'success') {
      try {
        let addCartRes = await addCart({
          price: product.price,
          product_id: product.id,
          quantity: 1,
          user_id: user.user_id,
          product_detail_id: res.body.applications.officer_application_id,
          company_id: company.company_id,
          company_name: company.company_name,
          company_registration_no: company.company_registration_no,
          company_application_id: res.body.applications.company_application_id,
        });
        if (addCartRes.status === 'success') {
          actions.resetForm();
          setConfirmationDialog({
            isVisible: true,
            title: 'Item added to cart',
            type: 'success',
            cancelButtonLabel: 'Back to Request',
            confirmButtonLabel: 'View your cart',
            confirmButtonAction: () => {
              history.push(ROUTES.PAYMENTCART);
              setConfirmationDialog({
                isVisible: false,
              });
            },
            cancelButtonAction: () => {
              history.push(ROUTES.CORPSEC_REQ);
              setConfirmationDialog({
                isVisible: false,
              });
            },
          });
        } else {
          showErrorSnackbar('Error adding to cart');
        }
      } catch (error) {
        showErrorSnackbar('Error adding to cart');
      }
    } else {
      showErrorSnackbar('Error submitting application');
    }
    setLoading(false);
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          company_role_id: 5,
          first_name: '',
          last_name: '',
          nric_passport_no: '',
          email: '',
          mobile_number: '',
          appointment_date: new Date(),
          identityFiles: [],
          residencyFiles: [],
          passportFiles: [],
        }}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
        innerRef={submitRef}
      >
        {(props) => (
          <Box p={3} pl={smBelow ? 3 : 12}>
            <Form>
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="first_name"
                labelText={
                  tabValue === 0
                    ? 'FIRST NAME (as in NRIC/FIN) *'
                    : 'FIRST NAME (as in PASSPORT) *'
                }
                placeholder="First Name"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="last_name"
                labelText={
                  tabValue === 0
                    ? 'LAST NAME (as in NRIC/FIN) *'
                    : 'LAST NAME (as in PASSPORT) *'
                }
                placeholder="Last Name"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="nric_passport_no"
                labelText="ID*"
                placeholder="ID"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreditCardIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormDropdownTextField
                name="mobile_number"
                labelText="Mobile Number*"
                placeholder="91234567"
                containerWidth={smBelow ? '100%' : '50%'}
                fullWidth
                shrink
                variant="outlined"
                showFlag={false}
                onValueChange={(event, newValue) => {
                  setPhoneNumber({
                    ...phoneNumber,
                    country_code_number: newValue.phone,
                    country_code: newValue.code,
                  });
                }}
                countryCode={phoneNumber.country_code}
                onChange={(event) => {
                  setPhoneNumber({
                    ...phoneNumber,
                    phone_number: event.target.value,
                  });
                  props.setFieldValue('mobile_number', event.target.value);
                }}
                inputProps={{
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="email"
                labelText="Email Address*"
                placeholder="Email Address"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormDatePicker
                containerWidth={smBelow ? '100%' : '50%'}
                name="appointment_date"
                labelText="Date of Appointment*"
                format="dd/MM/yyyy"
                variant="outlined"
                inputVariant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TodayIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {tabValue === 0 && (
                <FormFileDropzone
                  containerWidth={smBelow ? '100%' : '50%'}
                  name="identityFiles"
                  labelText="Proof of Identity*"
                />
              )}

              {tabValue === 1 && (
                <Box
                  display="flex"
                  flexDirection={smBelow ? 'column' : 'row'}
                  style={{
                    gap: '20px',
                  }}
                >
                  <FormFileDropzone
                    containerWidth={smBelow ? '100%' : '50%'}
                    name="passportFiles"
                    labelText="Copy of Passport*"
                    manualTouch={touched}
                  />
                  <FormFileDropzone
                    containerWidth={smBelow ? '100%' : '50%'}
                    name="residencyFiles"
                    labelText="Proof of Residency (Must be in English)*"
                    manualTouch={touched}
                  />
                </Box>
              )}

              <Box display="flex" pt={3} justifyContent="flex-start">
                <MzButton
                  fullWidth={smBelow ? true : false}
                  loading={requestSubmitting || loading}
                  type="submit"
                  title={'SUBMIT'}
                  onClick={async () => {
                    if (
                      tabValue == 1 &&
                      (props.values.passportFiles.length == 0 ||
                        props.values.residencyFiles.length == 0)
                    ) {
                      await setTouched(true);
                      submitRef.current.handleSubmit();
                    }

                    setStep(2);
                  }}
                />
              </Box>
            </Form>
          </Box>
        )}
      </Formik>
    );
  };

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={step}
        setStep={setStep}
        getStarted={getStarted}
        header={
          <>
            <Typography variant="h2">
              {step === 1 && 'Appointment of Director'}
              {step === 2 && 'Fill in Information for New Director'}
            </Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Request', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Change of Officers',
                  route: ROUTES.CORPSEC_REQ_CHANGE_OFFICER,
                },
                {
                  path: 'Appointment of Director',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        {step === 2 && (
          <>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              style={{
                borderBottom: '0.01rem solid #E4E4E4',
                paddingLeft: theme.spacing(smBelow ? 3 : 12),
              }}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                label="Local"
                style={{
                  textTransform: 'initial',
                  fontSize: 16,
                }}
              />
              <Tab
                label="Foreigner"
                style={{
                  textTransform: 'initial',
                  fontSize: 16,
                }}
              />
            </Tabs>
            <Box p={1} />
            {renderForm()}
          </>
        )}
        <Box py={5} />
      </GetStartedLayout>
    </>
  );
};

export default ApptDirector;
