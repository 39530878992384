import React, { useState } from 'react';
import {
  Box,
  Grid,
  Button,
  Typography,
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import FormFieldContainer from 'src/components/CustomFormFields/FormFieldContainer';
import { sgdFormat } from 'src/utils/currency';

const fields = {
  remark: {
    name: 'remark',
    label: 'Write a Note',
  },
  payment_method: {
    name: 'payment_method',
    label: 'Payment Method',
  },
  tnc: {
    name: 'tnc',
    label: 'Terms and Conditions',
  },
};

let validationSchemaObj = {
  [fields.remark.name]: Yup.string(),
  [fields.payment_method.name]: Yup.string()
    .required('Please select a payment method')
    .default('payment2'),
  // [fields.differentShipping.name]: Yup.boolean(),
  [fields.tnc.name]: Yup.boolean()
    .required()
    .oneOf([true], 'Please tick here to proceed'),
};

const MembershipForm = (props) => {
  const { plan, onSubmit, submitLabel } = props;
  const theme = useTheme();

  let validationSchema = Yup.object().shape(validationSchemaObj);
  let initialValues = validationSchema.cast();

  const handleOnSubmitCheckout = (values) => {
    if (onSubmit) {
      onSubmit(values);
    } else {
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmitCheckout}
      validateOnChange={false}
    >
      {(props) => {
        const { handleSubmit } = props;
        return (
          <Form onSubmit={handleSubmit}>
            {plan ? (
              <>
                <Box>
                  <Box mb={1}>Plan Name: {plan?.name}</Box>
                  <Box mb={1}>Plan Price: {sgdFormat(plan?.price)}</Box>
                  <Box mb={1}>Tax: 6%</Box>
                  <Box>Total Amount: {sgdFormat(plan?.price)}</Box>
                </Box>
                <Box py={3}>
                  <Divider />
                </Box>
              </>
            ) : null}
            <Box mt={1}>
              <Field name={fields.payment_method.name}>
                {({ field, meta }) => {
                  let error = meta.touched && !!meta.error;

                  return (
                    <FormFieldContainer
                      error={error}
                      helperText={meta.touched && meta.error ? meta.error : ''}
                    >
                      <RadioGroup
                        aria-label="payment-method"
                        name="payment_method"
                        {...field}
                      >
                        {/* <FormControlLabel
                          value="payment1"
                          control={<Radio color="primary" />}
                          label="PayNow"
                        /> */}
                        <FormControlLabel
                          value="payment2"
                          control={
                            <Radio color="primary" style={{ paddingTop: 0 }} />
                          }
                          label={
                            <Box>
                              <Box>Pay via Checkout.com</Box>
                              <Box color={theme.palette.grey[500]}>
                                <small>
                                  Make a payment using your credit/debit card
                                </small>
                              </Box>
                            </Box>
                          }
                          style={{ alignItems: 'flex-start' }}
                        />
                      </RadioGroup>
                    </FormFieldContainer>
                  );
                }}
              </Field>
            </Box>
            <Box py={3}>
              <Divider />
            </Box>
            <Box color={theme.palette.grey[500]} mb={1}>
              <Typography variant="h6" style={{ fontWeight: 'normal' }}>
                Your personal data will be used to process your order, support
                your experience throughout this website, and for other purposes
                described in our{' '}
                <span style={{ color: 'black' }}>privacy policy</span>.
              </Typography>
            </Box>
            <Box py={3}>
              <Divider />
            </Box>
            <Box mb={2}>
              <Field name={fields.tnc.name}>
                {({ field, meta }) => {
                  let error = meta.touched && !!meta.error;

                  return (
                    <FormFieldContainer
                      error={error}
                      helperText={meta.touched && meta.error ? meta.error : ''}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={isDifferentAddress}
                            // onChange={(e) => {
                            //   setIsDifferentAddress(e.target.checked);
                            // }}
                            {...field}
                            // required={true}
                            color="primary"
                          />
                        }
                        label={
                          <Box color={theme.palette.grey[500]}>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: 'normal' }}
                            >
                              I have read and agree to the website{' '}
                              <a style={{ color: 'black' }}>
                                terms & conditions
                              </a>
                              .
                            </Typography>
                          </Box>
                        }
                      />
                    </FormFieldContainer>
                  );
                }}
              </Field>
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disableElevation={true}
              size="large"
              style={{ textTransform: 'none' }}
              onClick={() => {}}
            >
              {submitLabel || 'Checkout'}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MembershipForm;
