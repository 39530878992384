import React from 'react';
import { Paper, Box, Button, Typography } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import ImageContainer, { RatioContainer } from 'src/components/ImageContainer';

const useStyles = makeStyles(() => {
  return {
    imageContainer: {
      '& .card-overlay': {
        transition: '0.4s',
        opacity: 0,
      },
      '& .card-overlay:hover': {
        opacity: 1,
      },
    },
  };
});

const ProductCard = (props) => {
  const { data = {}, onClick } = props;
  const {
    name,
    price,
    // imageSrc = '/sample-office-chair.png',
    imageSrc,
    href,
    quantity,
  } = data;

  const theme = useTheme();
  const classes = useStyles();
  const routeHistory = useHistory();

  const handleOnAddToCart = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <Paper component={Box} elevation={0} overflow="hidden" square>
      <Box borderColor={theme.palette.grey[200]} border={1}>
        <Box position="relative" className={classes.imageContainer}>
          {imageSrc ? (
            <ImageContainer src={imageSrc} ratio="100%" />
          ) : (
            <RatioContainer>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                Image Not Available
              </Box>
            </RatioContainer>
          )}
          <Box
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{
              backgroundColor: 'rgba(0,0,0,0.3)',
            }}
            className="card-overlay"
          >
            <Button
              variant="contained"
              color="default"
              size="small"
              style={{
                backgroundColor: 'white',
                borderRadius: '1rem',
              }}
              onClick={() => {
                if (href) {
                  routeHistory.push(href);
                }
              }}
            >
              <Typography variant="body2">View detail</Typography>
            </Button>
          </Box>
        </Box>
        <Box p={1}>
          <Box mb={1}>{name}</Box>
          <Box color={'#F4A51C'}>${price.toFixed(2)}</Box>
        </Box>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disableElevation={true}
          style={{ borderRadius: 0 }}
          disabled={!(quantity && quantity >= 0)}
          onClick={handleOnAddToCart}
        >
          Add to Cart
        </Button>
      </Box>
    </Paper>
  );
};
export default ProductCard;
