import React, { Suspense, useEffect, useState, useContext } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { Cookies, useCookies } from 'react-cookie';
import jwt from 'jsonwebtoken';
import axios from 'axios';

import ROUTES from '../constants/routes';
import { ConfigContext } from 'src/contexts';
import { useProvideCapTable } from 'src/hooks/useCapTable';
import { useSelector } from 'src/Provider';
import AuthLayout from 'src/components/AuthLayout';

// pages
import Dashboard from '../pages/dashboard';
import Messagepage from '../pages/messagepage';
import Otppage from '../pages/otppage';
import Signuppage from '../pages/signuppage';
import Signinpage from '../pages/signinpage';
import Homepage from '../pages/homepage';
import PaymentCart from '../pages/paymentcart';
import OrderHistory from '../pages/orderhistory';
import OrderHistoryDetails from '../pages/orderhistorydetails';
import ResetPasswordPage from 'src/pages/reset-password';
import SendVerificationEmail from 'src/pages/sendVerificationEmail';
import Onboarding from 'src/pages/onboarding';
import OnboardingCompany from 'src/pages/onboardingCompany';
import OnboardingCompanyPublic from 'src/pages/onboardingCompanyPublic';
import Settings from 'src/pages/settings';
import ContactSupport from 'src/pages/contactSupport';
import ContactSupportPublic from 'src/pages/contactSupportPublic';
import AccountActivated from 'src/pages/accountactivated';
import UpcomingServiceForm from 'src/pages/upcomingServiceForm';
import OfficeSupplies from 'src/pages/officeSupplies';
import OfficeSuppliesDetail from 'src/pages/officeSuppliesDetail';
import WebDev from '../pages/webdev';

// corpsec
import Corpsec from 'src/pages/legal/corpsec';
import CorpsecDashboard from 'src/pages/legal/dashboard';
import CorpsecRequest from 'src/pages/legal/request';
import CorpsecDocument from 'src/pages/legal/document';
import CorpsecBoardRoom from 'src/pages/legal/boardroom';
import CorpsecCapTable from 'src/pages/legal/captable';
import CorpsecManageUser from 'src/pages/legal/manageuser';
import CorpsecCompanyAccountSettings from 'src/pages/legal/companyAccountSettings';
import CorpsecCompanyAccount from 'src/pages/legal/companyAccount';

// paymentpage
import SuccessfulPaymentPage from '../pages/paymentpage/successfulpayment';
import UnsuccessfulPaymentPage from '../pages/paymentpage/unsuccessfulpayment';

// webservice
import WebService from '../pages/webservice';
import WebServiceCategory from '../pages/webservice/category';
import WebServiceTheme from '../pages/webservice/theme';
import WebServiceThemeInfo from '../pages/webservice/themeinfo';
import WebServicePlan from '../pages/webservice/plan';

// createwebsite
import CreateWebsite from '../pages/createwebsite';
import CreateWebsiteTitle from '../pages/createwebsite/content';
import CreateWebsiteCorpidentity from '../pages/createwebsite/corpidentity';
import CreateWebsiteBackground from '../pages/createwebsite/background';

// errorpage
import PageNotFound from 'src/pages/errorpage/pagenotfound';
import KycDone from 'src/pages/errorpage/kycdone';
import ScheduledMaintenance from 'src/pages/errorpage/scheduledmaintenance';
import ComingSoonPage from 'src/pages/errorpage/comingsoon';
import LoginFailedPage from 'src/pages/errorpage/loginfailed';

const GuardedRoute = ({ component: Component, roleAccess, ...rest }) => {
  const { company, companyLoading, userLoading } = useContext(ConfigContext);

  let loading = companyLoading || userLoading;
  const access = roleAccess?.find((role) => role === company.role_id);
  const noCompany = !company.role_id;
  const globalState = useSelector((s) => s.global);

  useEffect(() => {
    if (globalState?.auth?.timespan !== 0) {
      let auth = globalState?.auth?.isAuthenticated;
    }
  }, [globalState]);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {!loading && company.role_id && (
            <>
              {/* check for role access when change company */}

              {!!roleAccess && !access ? (
                <Redirect to={ROUTES.CORPSEC} />
              ) : (
                <Component {...props} />
              )}
            </>
          )}
          {noCompany && <Component {...props} />}
        </>
      )}
    />
  );
};

const Routes = () => {
  const user = new Cookies().get('idToken');
  const [cookieIdToken, setCookieIdToken] = useCookies(['idToken']);
  const [accessToken, setAccessToken] = useCookies(['accessToken']);
  const { setUserLoading, userLoading, companyLoading, company } = useContext(
    ConfigContext,
  );
  const {
    ListCapTableShareholders,
    capTableShareholders,
    loading: loadingCapTableShareholders,
  } = useProvideCapTable();

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let noCapTableShareholders = capTableShareholders?.length === 0;

  useEffect(() => {
    setUserLoading(true);
    let fetching = true;
    if (user) {
      setUserLoading(false);
    }

    if (!params.get('code') && !user) {
      if (
        location?.pathname?.indexOf(ROUTES.ONBOARDING_COMPANY_PUBLIC) < 0 &&
        location?.pathname?.indexOf(ROUTES.CONTACT_SUPPORT_PUBLIC) < 0 &&
        location?.pathname?.indexOf('/reset-password') < 0
        // location?.pathname != ROUTES.CONTACT_SUPPORT_PUBLIC &&
        // location?.pathname != '/reset-password'
      ) {
        window.location.href = `${process.env.REACT_APP_ACCESS}?redirect_uri=${window.origin}`;
      }
    }
    if (params.get('code') && !user) {
      axios
        .post(process.env.REACT_APP_BACKEND, {
          code: params.get('code'),
        })
        .then(function (response) {
          setUserLoading(false);
          var idTokenData = jwt.decode(response.data.id_token);

          if (idTokenData && fetching) {
            setCookieIdToken('idToken', response.data.id_token, {
              expires: idTokenData?.exp
                ? new Date(idTokenData?.exp * 1000)
                : null,
            });
            if (response.data.access_token) {
              setAccessToken('accessToken', response.data.access_token, {
                expires: idTokenData?.exp
                  ? new Date(idTokenData?.exp * 1000)
                  : null,
              });
            }
            history.replace(ROUTES.DASHBOARD);
          }
        })
        .catch(function (error) {
          setUserLoading(false);
          console.log(error);
        });
    }
    return () => {
      setUserLoading(false);
      fetching = false;
    };
  }, []);

  useEffect(() => {
    if (company?.company_id) {
      ListCapTableShareholders(company.company_id);
    }
  }, [company?.company_id]);

  let loading = userLoading || companyLoading || loadingCapTableShareholders;

  let guardedRoutesWithAuthLayoutData = [
    {
      exact: true,
      path: ROUTES.DASHBOARD,
      component: Dashboard,
    },
    { exact: true, path: ROUTES.CORPSEC, component: CorpsecDashboard },
    { exact: true, path: ROUTES.CORPSEC_PRO, component: Corpsec },
    { exact: true, path: ROUTES.CORPSEC_TASK, component: Corpsec },

    {
      exact: true,
      path: ROUTES.CORPSEC_COMPANY_ACCOUNT_SETTINGS,
      component: CorpsecCompanyAccountSettings,
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_COMPANY_ACCOUNT,
      component: CorpsecCompanyAccount,
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_DOC,
      component: CorpsecDocument,
      roleAccess: [1, 2, 4],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_DASHBOARD,
      component: CorpsecRequest,
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_DETAILS(':id'),
      component: CorpsecRequest,
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_CHANGE_OFFICER,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_APPT_ADMIN,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_APPT_AUDITOR,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_APPT_SECRETARY,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_APPT_DIRECTOR,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_RESGN_ADMIN,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_RESGN_AUDITOR,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_RESGN_DIRECTOR,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_RESGN_SECRETARY,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      path: ROUTES.CORPSEC_REQ_RESGN_DIRECTOR_INFO(':id'),
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_RESGN_ADMIN_INFO(':id'),
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_RESGN_AUDITOR_INFO(':id'),
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_RESGN_SECRETARY_INFO(':id'),
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_ALLOTMENT_SHARES,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_TRANSFER_SHARES,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_ALLOTMENT_SHARES_NEW,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_TRANSFER_SHARES_NEW,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_FILLING_OTHER_RESOLUTIONS,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQUESTS_DECLARE_INTERIM_DIVIDEND,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQUESTS_OPEN_BANK_ACCOUNT,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQUESTS_CLOSE_BANK_ACCOUNT,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQUESTS_OTHER_REQUEST,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },

    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_CHANGE_PERSONAL_PARTICULARS,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_DIRECTOR_LIST,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_SHAREHOLDER_LIST,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      path: ROUTES.CORPSEC_REQ_CHANGE_DIRECTOR_PARTICULARS(':id'),
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      path: ROUTES.CORPSEC_REQ_CHANGE_SHAREHOLDER_PARTICULARS(':id'),
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      path: ROUTES.CORPSEC_REQ_CHANGE_SHAREHOLDER_COMPANY_PARTICULARS(':id'),
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },

    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_CHANGE_COMPANY_PARTICULARS,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_CHANGE_COMPANY_NAME,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_CHANGE_COMPANY_FINANCIAL_YEAR_END,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_CHANGE_BUSINESS_ACTIVITIES,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_CHANGE_COMPANY_ADDRESS,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_REQ_CHANGE_SHARE_INFORMATION,
      component: CorpsecRequest,
      roleAccess: [1, 2, 3],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_BOARD,
      component: CorpsecBoardRoom,
      roleAccess: [1, 2, 4],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_CAP,
      component: CorpsecCapTable,
      roleAccess: noCapTableShareholders ? [] : [1, 2, 4],
    },
    {
      exact: true,
      path: ROUTES.CORPSEC_MANAGEUSER,
      component: CorpsecManageUser,
      roleAccess: [1],
    },
    { exact: true, path: ROUTES.PAYMENTCART, component: PaymentCart },
    { exact: true, path: ROUTES.ORDERHISTORY, component: OrderHistory },
    {
      exact: true,
      path: ROUTES.ORDERHISTORYDETAILS(':id'),
      component: OrderHistoryDetails,
    },
    { exact: true, path: ROUTES.SETTINGS, component: Settings },
    { exact: true, path: ROUTES.CONTACT_SUPPORT, component: ContactSupport },
    {
      exact: true,
      path: ROUTES.UPCOMING_SERVICE,
      component: UpcomingServiceForm,
    },
    { exact: true, path: ROUTES.OFFICE_SUPPLIES, component: OfficeSupplies },
    {
      exact: true,
      path: `${ROUTES.OFFICE_SUPPLIES}/:id`,
      component: OfficeSuppliesDetail,
    },
    {
      exact: true,
      path: ROUTES.WEBDEV,
      component: WebDev,
    },
    {
      exact: true,
      path: ROUTES.COMINGSOON,
      component: ComingSoonPage,
    },
    {
      exact: true,
      path: ROUTES.LOGINFAILED,
      component: LoginFailedPage,
    },
    {
      exact: true,
      path: ROUTES.WEBSERVICE,
      component: WebService,
    },
    {
      path: ROUTES.WEBSERVICE_CATEGORY(':packageid'),
      component: WebServiceCategory,
    },
    {
      path: ROUTES.WEBSERVICE_THEME(':packageid', ':categoryid'),
      component: WebServiceTheme,
    },
    {
      path: ROUTES.WEBSERVICE_THEME_INFO(
        ':packageid',
        ':categoryid',
        ':themeid',
      ),
      component: WebServiceThemeInfo,
    },
    {
      path: ROUTES.WEBSERVICE_PLAN(':packageid', ':categoryid', ':themeid'),
      component: WebServicePlan,
    },
    {
      path: ROUTES.CREATEWEBSITE(':webdevid'),
      component: CreateWebsite,
    },
    {
      path: ROUTES.CREATEWEBSITE_CONTENT(':webdevid'),
      component: CreateWebsiteTitle,
    },
    {
      path: ROUTES.CREATEWEBSITE_CORPIDENTITY(':webdevid'),
      component: CreateWebsiteCorpidentity,
    },
    {
      path: ROUTES.CREATEWEBSITE_IMAGES(':webdevid'),
      component: CreateWebsiteBackground,
    },
  ];

  return (
    <Suspense fallback={<h1>loading</h1>}>
      <Switch>
        {/* <Route
          exact
          path="/"
          render={() => {
            return auth.isAuth ? (
              <Redirect to={ROUTES.DASHBOARD} />
            ) : (
              <Signinpage />
            );
          }}
        /> */}
        <Route exact path={ROUTES.OTPPAGE}>
          <Otppage />
        </Route>
        <Route exact path={ROUTES.MESSAGEPAGE}>
          <Messagepage />
        </Route>
        <Route
          exact
          path={ROUTES.SIGNUPPAGE}
          render={() => {
            return (window.location.href = `${process.env.REACT_APP_ACCESS}/signup`);
          }}
        />
        <Route
          exact
          path={ROUTES.SIGNINPAGE}
          render={() => {
            if (!user) {
              return (window.location.href = `${process.env.REACT_APP_ACCESS}?redirect_uri=${window.origin}`);
            }
            return <Redirect push to={ROUTES.DASHBOARD} />;
          }}
        />

        <Route exact path={'/onboarding'}>
          <Onboarding />
        </Route>
        <Route exact path={'/reset-password'}>
          <ResetPasswordPage />
        </Route>
        {/* <GuardedRoute
          exact
          path={ROUTES.CHANGE_PASSWORD}
          component={ResetPasswordPage}
        /> */}
        <Route exact path={'/send-verification-email'}>
          <SendVerificationEmail />
        </Route>
        <Route exact path={'/account-activated'}>
          <AccountActivated />
        </Route>
        <Route exact path={ROUTES.CONTACT_SUPPORT_PUBLIC}>
          <ContactSupportPublic />
        </Route>
        <Route exact path={ROUTES.SUCCESSFUL_PAYMENT}>
          <SuccessfulPaymentPage />
        </Route>
        <Route exact path={ROUTES.UNSUCCESSFUL_PAYMENT}>
          <UnsuccessfulPaymentPage />
        </Route>

        <Route
          exact
          path={guardedRoutesWithAuthLayoutData?.map((anItem) => anItem.path)}
        >
          <AuthLayout>
            <Switch>
              {guardedRoutesWithAuthLayoutData?.map((anItem) => {
                return <GuardedRoute key={anItem.path} {...anItem} />;
              })}
            </Switch>
          </AuthLayout>
        </Route>
        <Route
          exact
          path={ROUTES.HOMEPAGE}
          render={() => {
            return <Redirect push to={ROUTES.DASHBOARD} />;
          }}
        />

        <GuardedRoute
          exact
          path={ROUTES.ONBOARDING_COMPANY}
          component={OnboardingCompany}
        />
        <Route
          exact
          path={ROUTES.ONBOARDING_COMPANY_PUBLIC}
          render={() => {
            if (!user) {
              return <OnboardingCompanyPublic />;
            }
            return <Redirect to={ROUTES.ONBOARDING_COMPANY} />;
          }}
        />
        <GuardedRoute
          exact
          path={ROUTES.PAGENOTFOUND}
          component={PageNotFound}
        />
        <GuardedRoute exact path={ROUTES.KYCDONE} component={KycDone} />
        <GuardedRoute
          exact
          path={ROUTES.SCHEDULEDMAINTENANCE}
          component={ScheduledMaintenance}
        />

        {/* <Route exact path={ROUTES.WEBSERVICE}>
          <WebService />
        </Route>
        <Route path={ROUTES.WEBSERVICE_CATEGORY(':packageid')}>
          <WebServiceCategory />
        </Route>

        <Route path={ROUTES.WEBSERVICE_THEME(':packageid', ':categoryid')}>
          <WebServiceTheme />
        </Route>
        <Route
          path={ROUTES.WEBSERVICE_THEME_INFO(
            ':packageid',
            ':categoryid',
            ':themeid',
          )}
        >
          <WebServiceThemeInfo />
        </Route>
        <Route
          path={ROUTES.WEBSERVICE_PLAN(':packageid', ':categoryid', ':themeid')}
        >
          <WebServicePlan />
        </Route> */}

        {/* <GuardedRoute exact path={ROUTES.WEBSERVICE} component={WebService} />
        <GuardedRoute
          path={ROUTES.WEBSERVICE_CATEGORY(':packageid')}
          component={WebServiceCategory}
        />
        <GuardedRoute
          path={ROUTES.WEBSERVICE_THEME(':packageid', ':categoryid')}
          component={WebServiceTheme}
        />
        <GuardedRoute
          path={ROUTES.WEBSERVICE_THEME_INFO(
            ':packageid',
            ':categoryid',
            ':themeid',
          )}
          component={WebServiceThemeInfo}
        />
        <GuardedRoute
          path={ROUTES.WEBSERVICE_PLAN(':packageid', ':categoryid', ':themeid')}
          component={WebServicePlan}
        />

        <GuardedRoute path={ROUTES.CREATEWEBSITE(':webdevid')}>
          <CreateWebsite />
        </GuardedRoute>
        <GuardedRoute path={ROUTES.CREATEWEBSITE_CONTENT(':webdevid')}>
          <CreateWebsiteTitle />
        </GuardedRoute>
        <GuardedRoute path={ROUTES.CREATEWEBSITE_CORPIDENTITY(':webdevid')}>
          <CreateWebsiteCorpidentity />
        </GuardedRoute>
        <GuardedRoute path={ROUTES.CREATEWEBSITE_IMAGES(':webdevid')}>
          <CreateWebsiteBackground />
        </GuardedRoute> */}
      </Switch>
      <Backdrop
        open={loading}
        style={{ zIndex: 5 }}
        transitionDuration={{ exit: 2000 }}
      >
        <CircularProgress />
      </Backdrop>
    </Suspense>
  );
};

export default Routes;
