import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  Divider,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';

import NavItem from 'src/components/NavItem';
import ROUTES from 'src/constants/routes';
import useBreakpoints from 'src/hooks/useBreakpoints';
import documentSvc from 'src/services/document';
import LoadingComponent from '../loading.component';
import EmptyComponent from '../empty.component';
import { ConfigContext } from 'src/contexts';

const Menu = ({ toggleShowMenu, onClickMenuItem, mainFolder }) => {
  const { smBelow } = useBreakpoints();

  const [loading, setLoading] = useState(false);
  const [folderLoaded, setFolderLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const { company, companyLoading, user } = useContext(ConfigContext);

  useEffect(() => {
    setFolderLoaded(false);
    setLoading(false);
    // console.log('company', company);
    // if (company && company.company_id != undefined) {
    // (async () => {
    setLoading(true);
    documentSvc
      .getFolders()
      .then((rs) => {
        // folderList = rs;
        // setCrumbs([{ title: 'Documents', data: rs }]);
        setItems(rs);
        if (onClickMenuItem) {
          onClickMenuItem(rs?.[0]);
          // if (toggleShowMenu && smBelow) toggleShowMenu();
        }
        // console.log('dddcc');
      })
      .catch((error) => {
        console.log('getFolders error', error);

        setItems([]);
      })
      .finally(() => {
        setFolderLoaded(true);
        setLoading(false);
      });
    // }
    // })();
    // } else {
    //   setItems([]);
    //   setFolderLoaded(true);
    //   setLoading(false);
    // }
  }, [company?.company_id]);

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        // minHeight={smBelow ? 'auto' : '115px'}
        height={'115px'}
      >
        <Box
          // px={smBelow ? 2 : 3}
          // py={smBelow ? 2 : 3}
          pt={4}
          pl={4}
          pb={4}
          flex={1}
        >
          <Typography variant="h2" style={{ fontSize: '1.5rem' }}>
            All Files
          </Typography>
          <Typography variant="h6" color="textSecondary">
            Update your documents here
          </Typography>
        </Box>
        {smBelow && (
          <IconButton
            // size="large"
            onClick={() => {
              if (toggleShowMenu) toggleShowMenu();
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        )}
      </Box>
      <Divider />
      <Box flex={1}>
        {!folderLoaded || loading ? (
          <LoadingComponent />
        ) : items && items.length > 0 ? (
          <List
            style={{
              display: 'flex',
              flexDirection: 'column',
              // alignItems: 'flex-start',
              width: '100%',
              height: '100%',
            }}
            subheader={<li />}
          >
            {items.map((anItem, index) => {
              const { document_type_id, name = '-' } = anItem;
              let isActive =
                mainFolder && mainFolder.document_type_id
                  ? mainFolder.document_type_id == document_type_id
                  : false;
              return (
                <ListItem
                  key={index}
                  button
                  selected={isActive}
                  onClick={() => {
                    if (onClickMenuItem) {
                      onClickMenuItem(anItem);
                      if (toggleShowMenu && smBelow) toggleShowMenu();
                    }
                  }}
                >
                  <ListItemIcon>
                    <FolderOutlinedIcon
                      color={isActive ? 'primary' : 'action'}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      color: isActive ? 'primary' : 'initial',
                    }}
                  >
                    {name}
                  </ListItemText>
                  <ListItemIcon
                    style={{
                      justifyContent: 'flex-end',
                    }}
                  >
                    <ChevronRightOutlinedIcon
                      color={isActive ? 'primary' : 'action'}
                    />
                  </ListItemIcon>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <EmptyComponent>No Folder Found</EmptyComponent>
        )}
      </Box>
    </Box>
  );

  return (
    <>
      <Box py={smBelow ? 1 : 4} flex={3}>
        <Box display="flex" flexDirection="row" alignItems="baseline">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            flex={3}
            pb={4}
            pl={4}
          >
            <Typography variant="h2" style={{ fontSize: '1.5rem' }}>
              All Files
            </Typography>
            <Typography variant="h6" color="textSecondary">
              Here you can update your documents
            </Typography>
          </Box>
          {smBelow && (
            <IconButton
              size="medium"
              onClick={() => {
                if (toggleShowMenu) toggleShowMenu();
              }}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          )}
        </Box>
        <Box>
          {!folderLoaded || loading ? (
            <div>Loading</div>
          ) : items && items.length > 0 ? (
            <List
              style={{
                display: 'flex',
                flexDirection: 'column',
                // alignItems: 'flex-start',
                width: '100%',
                height: '100%',
              }}
              subheader={<li />}
            >
              {items.map((anItem, index) => {
                const { document_type_id, hasSubFolders, name = '-' } = anItem;
                let isActive = mainFolder
                  ? mainFolder.document_type_id == document_type_id
                  : false;
                return (
                  <ListItem
                    key={index}
                    button
                    selected={isActive}
                    onClick={() => {
                      if (onClickMenuItem) {
                        onClickMenuItem(anItem);
                        if (toggleShowMenu && smBelow) toggleShowMenu();
                      }
                    }}
                  >
                    <ListItemIcon>
                      <FolderOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText>{name}</ListItemText>
                    <ListItemIcon>
                      <ChevronRightOutlinedIcon />
                    </ListItemIcon>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <div>No Folder Found</div>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Menu;
