import React, { useEffect, useState, useContext } from 'react';
import { Paper, Box, Divider, Grid } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';
import useBreakpoints from 'src/hooks/useBreakpoints';
import ReqLayout from 'src/components/Corpsec/Documents/Layout';
import ReqMenu from 'src/components/Corpsec/Documents/Menu';
import { CorpsecDocumentFiles } from 'src/components/Corpsec';
import { ConfigContext } from 'src/contexts';

import ROUTES from 'src/constants/routes';

const Document = () => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(true);
  const params = useParams();
  const { smBelow } = useBreakpoints();
  const [selectedFolder, setSelectedFolder] = useState(null);
  const { company, companyLoading } = useContext(ConfigContext);
  console.log('selectedFolder', selectedFolder);
  useEffect(() => {
    setSelectedFolder(null);
  }, [company]);

  const toggleShowMenu = () => {
    setShowMenu((prevState) => {
      return !prevState;
    });
  };

  useEffect(() => {
    if (location.pathname === ROUTES.CORPSEC_DOC) {
      setShowMenu(true);
    }
  }, [location]);

  return (
    <ReqLayout>
      <Paper
        elevation={1}
        style={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'row',
        }}
      >
        {showMenu && (
          <Box flex={2}>
            <ReqMenu
              toggleShowMenu={toggleShowMenu}
              onClickMenuItem={(value) => setSelectedFolder({ ...value })}
              mainFolder={selectedFolder}
            />
          </Box>
        )}
        {!smBelow && <Divider orientation="vertical" flexItem />}
        <Box
          flex={6}
          maxWidth="100%"
          style={(smBelow && !showMenu) || !smBelow ? {} : { display: 'none' }}
        >
          <CorpsecDocumentFiles
            toggleShowMenu={toggleShowMenu}
            mainFolder={selectedFolder}
          />
        </Box>
      </Paper>
    </ReqLayout>
  );
};

export default Document;
