import {
  useContext,
  useState,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { APIRequest } from 'src/utils/api';
import { Config } from 'src/config';
import ENDPOINTS from 'src/constants/endpoints';
import documentSvc from 'src/services/document';

const orderContext = createContext({
  orders: [],
  order: {},
  orderError: '',
  orderLoading: false,
  orderSubmitting: false,
  CreateOrder: (order = {}) => Promise.resolve({}),
  ListOrders: () => Promise.resolve({}),
  ReadOrder: (orderID) => Promise.resolve({}),
});

export function useProvideOrder() {
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState({});
  const [orderError, setOrderError] = useState();
  const [orderLoading, setOrderLoading] = useState(false);
  const [orderSubmitting, setOrderSubmitting] = useState(false);

  const CreateOrder = async (order = {}) => {
    const res = await APIRequest({
      setError: setOrderError,
      setLoading: setOrderSubmitting,
      // path: `https://9qta428pn8.execute-api.ap-southeast-1.amazonaws.com/Prod/order`,
      path: `${ENDPOINTS.ORDER}/order`,
      method: 'post',
      body: order,
    });
    if (res.status === 'success') {
      // console.log('orders list', res.body.orders);
      setOrder(res.body.order || {});
    }
    return res;
  };

  const ListOrders = async (userID) => {
    const res = await APIRequest({
      setError: setOrderError,
      setLoading: setOrderLoading,
      // path: `https://9qta428pn8.execute-api.ap-southeast-1.amazonaws.com/Prod/order/user/${userID}`,
      path: `${ENDPOINTS.ORDER}/order/user/${userID}`,
      method: 'get',
    });
    if (res.status === 'success') {
      // console.log('orders list', res.body.orders);
      setOrders(res.body.orders || []);
    }
    return res;
  };

  const mergeProductAndImage = (orderItems = [], documents = []) => {
    let result = orderItems.map((anOrderItem) => {
      if (anOrderItem?.product?.product_documents?.length > 0) {
        let newProductDocuments = anOrderItem?.product?.product_documents.map(
          (aProductDocument) => {
            let foundDoc = documents.find((aDoc) => {
              return aDoc.document_id == aProductDocument.document_id;
            });
            if (foundDoc) {
              return {
                ...aProductDocument,
                document: foundDoc,
              };
            }
            return aProductDocument;
          },
        );
        return {
          ...anOrderItem,
          product: {
            ...anOrderItem.product,
            product_documents: newProductDocuments,
          },
        };
      }
      return anOrderItem;
    });
    return result;
  };

  const ListOrderItemDocuments = async (orderItems = []) => {
    let ids = [];
    orderItems.forEach((anOrderItem) => {
      if (anOrderItem?.product?.product_documents?.length > 0) {
        anOrderItem?.product?.product_documents.forEach((anItem) => {
          ids.push(anItem?.document_id);
        });
      }
    });
    let res;
    // setProductLoading(true);
    if (ids?.length > 0) {
      try {
        res = await documentSvc.getProductImageDocumentsByIds(ids);
        if (res?.data?.documents?.length > 0) {
          let documents =
            res?.data?.documents?.length > 0 ? res?.data?.documents : [];
          let mergedOrderItems = mergeProductAndImage(orderItems, documents);

          return mergedOrderItems;
        }
      } catch (error) {
        // setProductError(error);
      }
      // setProductLoading(false);
    }

    return orderItems;
  };

  const ReadOrder = async (orderID) => {
    let res;
    try {
      res = await APIRequest({
        setError: setOrderError,
        setLoading: setOrderLoading,
        path: `${ENDPOINTS.ORDER}/order/${orderID}`,
        method: 'get',
      });
      if (res.status === 'success') {
        // console.log('order', res);
        let newOrderItems = await ListOrderItemDocuments(
          res?.body?.order?.items || [],
        );
        setOrder({ ...res.body.order, items: newOrderItems });
      }
    } catch (error) {}
    return res;
  };

  const UpdateOrder = async (order) => {
    //   {
    //     "id": 644,
    //     "billing_address_id": 5,
    //     "shipping_address_id": 11,
    //     "is_invoice":1,
    //     "updated_by": 1
    // }

    const res = await APIRequest({
      setError: setOrderError,
      setLoading: setOrderLoading,
      path: `${ENDPOINTS.ORDER}/order/update`,
      method: 'put',
      body: order,
    });

    if (res.status === 'success') {
      // concat last item from res
      setOrder(res?.body?.order);
    }
    return res;
  };

  const CreateOrderAddress = async (address = {}) => {
    const res = await APIRequest({
      setError: setOrderError,
      setLoading: setOrderSubmitting,
      // path: `https://9qta428pn8.execute-api.ap-southeast-1.amazonaws.com/Prod/order`,
      path: `${ENDPOINTS.ORDER}/order-address`,
      method: 'post',
      body: address,
    });
    if (res.status === 'success') {
      // console.log('orders list', res.body.orders);
      setOrder(res.body.order || {});
    }
    return res;
  };

  const GetOrderAddressById = async (id) => {
    const res = await APIRequest({
      setError: setOrderError,
      setLoading: setOrderSubmitting,
      // path: `https://9qta428pn8.execute-api.ap-southeast-1.amazonaws.com/Prod/order`,
      path: `${ENDPOINTS.ORDER}/order-address/${id}`,
      method: 'post',
      body: address,
    });
    if (res.status === 'success') {
      // console.log('orders list', res.body);
      // setOrder(res.body.order || {});
    }
    return res;
  };

  const UpdateOrderAddressByOrderId = async (id, order = {}) => {
    //   {
    //     "id": 644,
    //     "billing_address_id": 5,
    //     "shipping_address_id": 11,
    //     "is_invoice":1,
    //     "updated_by": 1
    // }

    const res = await APIRequest({
      setError: setOrderError,
      setLoading: setOrderLoading,
      path: `${ENDPOINTS.ORDER}/order/update-addresses/${id}`,
      method: 'put',
      body: {
        // billing_address_id: null,
        // shipping_address_id: null,
        // user_id: '',
        ...order,
      },
    });

    if (res.status === 'success') {
      // concat last item from res
      // console.log('orders UpdateOrderAddressByOrderId', res.body);
      // setOrder(res?.body?.order);
    }
    return res;
  };

  const GetOrderAddressPublicByOrderId = async (id) => {
    const res = await APIRequest({
      setError: setOrderError,
      setLoading: setOrderLoading,
      path: `${process.env.REACT_APP_ORDER_URL}/order-address/${id}`,
      method: 'get',
    });

    if (res.status === 'success') {
      // console.log('orders GetOrderAddressPublicByOrderId', res);
    }
    return res;
  };

  const CreateOrderAddressPublic = async (body = {}) => {
    const res = await APIRequest({
      setError: setOrderError,
      setLoading: setOrderLoading,
      path: `${process.env.REACT_APP_ORDER_URL}/order-address`,
      method: 'post',
      body: body,
    });

    if (res.status === 'success') {
      console.log('orders CreateOrderAddressPublic', res);
    }
    return res;
  };

  const UpdateOrderAddressPublic = async (id, body = {}) => {
    const res = await APIRequest({
      setError: setOrderError,
      setLoading: setOrderLoading,
      path: `${process.env.REACT_APP_ORDER_URL}/order-address/${id}`,
      method: 'put',
      body: body,
    });

    if (res.status === 'success') {
      // console.log('orders UpdateOrderAddressPublic', res);
    }
    return res;
  };

  const GetPublicOrderById = async (id) => {
    const res = await APIRequest({
      setError: setOrderError,
      setLoading: setOrderSubmitting,
      // path: `${process.env.REACT_APP_ORDER_URL}/public-order/${id}`,
      path: `${process.env.REACT_APP_ORDER_URL}/order/${id}`,
      method: 'get',
      body: order,
    });
    if (res.status === 'success') {
      // console.log('orders GetPublicOrderById', res.body);
      // setOrder(res.body.order || {});
    }
    return res;
  };

  const CreatePublicOrder = async (order = {}) => {
    const res = await APIRequest({
      setError: setOrderError,
      setLoading: setOrderSubmitting,
      path: `${process.env.REACT_APP_ORDER_URL}/public-order`,
      method: 'post',
      body: order,
    });
    if (res.status === 'success') {
      // console.log('orders CreatePublicOrder', res.body);
      // setOrder(res.body.order || {});
    }
    return res;
  };

  const UpdatePublicOrder = async (id, order = {}) => {
    const res = await APIRequest({
      setError: setOrderError,
      setLoading: setOrderSubmitting,
      // path: `${process.env.REACT_APP_ORDER_URL}/public-order/${id}`,
      path: `${process.env.REACT_APP_ORDER_URL}/order/update`,
      method: 'put',
      body: { ...order, id: id, user_id: 0 },
    });
    if (res.status === 'success') {
      // console.log('orders UpdatePublicOrder', res.body);
      // setOrder(res.body.order || {});
    }
    return res;
  };

  const UpdatePublicOrderByAddress = async (id, order = {}) => {
    const res = await APIRequest({
      setError: setOrderError,
      setLoading: setOrderSubmitting,
      // path: `${process.env.REACT_APP_ORDER_URL}/public-order/${id}`,
      path: `${process.env.REACT_APP_ORDER_URL}/order/update-addresses/${id}`,
      method: 'put',
      body: { ...order, user_id: 0 },
    });
    if (res.status === 'success') {
      // console.log('orders UpdatePublicOrder', res.body);
      // setOrder(res.body.order || {});
    }
    return res;
  };

  return {
    orders,
    order,
    orderError,
    orderLoading,
    orderSubmitting,
    CreateOrder,
    ListOrders,
    ReadOrder,
    UpdateOrder,
    CreateOrderAddress,
    GetOrderAddressById,
    UpdateOrderAddressByOrderId,
    GetOrderAddressPublicByOrderId,
    CreateOrderAddressPublic,
    UpdateOrderAddressPublic,
    GetPublicOrderById,
    CreatePublicOrder,
    UpdatePublicOrder,
    UpdatePublicOrderByAddress,
  };
}

export const useOrderContext = () => useContext(orderContext);

export function ProvideOrder({ children }) {
  const order = useProvideOrder();
  return (
    <orderContext.Provider value={order}>{children}</orderContext.Provider>
  );
}
