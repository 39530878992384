import { Paper, Box, Typography, Button, Grid } from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ReqLayout from 'src/components/Corpsec/Requests/Layout';
import useBreakpoints from 'src/hooks/useBreakpoints';
import InfoBox from 'src/components/InfoBox';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable from 'material-table';
import { ConfigContext } from 'src/contexts';
import ROUTES from 'src/constants/routes';
import { useProvideRequest } from 'src/hooks/useRequests';
import { EnhancedSkeleton } from 'src/components/Enhanced';
import requestDetailsImg from 'src/assets/requests/RequestDetails.png';
import skybg from 'src/assets/requests/skybg.png';
import moment from 'moment';

const tempRecentRequestList = [
  {
    request: 'Appointment of Director',
    status: 1,
    startdate: '23/02/2022',
  },
  {
    request: 'Change Particulars',
    status: 2,
    startdate: '23/02/2022',
  },
  {
    request: 'Filling of Other Resolutions',
    status: 1,
    startdate: '23/02/2022',
  },
  {
    request: 'Transfer of Shares',
    status: 2,
    startdate: '23/02/2022',
  },
];

const Dashboard = () => {
  const history = useHistory();
  const { smBelow, mdBelow } = useBreakpoints();
  const { company } = useContext(ConfigContext);
  const { requestLoading, requests, ListRequests } = useProvideRequest();
  const filteredRequests = requests.filter(
    (request) =>
      request.status_name !== 'In Cart' &&
      request.status_name !== 'Cart Removed',
  );

  useEffect(() => {
    if (company.company_id) {
      ListRequests(company.company_id);
    }
  }, [company.company_id]);

  const mapUserTable = () => {
    return filteredRequests.map((user, index) => ({
      applicationId: user.company_application_id,
      request: user.application_type_table_name,
      status: user.status_name,
      startdate: moment(user.created_date)
        .utc('+0800')
        .format('YYYY-MM-DD HH:mm')
        ? moment(user.created_date).utc('+0800').format('YYYY-MM-DD HH:mm')
        : '-',
    }));
  };

  const RequestStatus = ({ label, unit }) => {
    return (
      <>
        <Typography variant="h3" style={{ color: '#635D5D', fontWeight: 800 }}>
          {unit}
        </Typography>
        <Typography variant="h3" style={{ color: '#8F96AD' }}>
          {label}
        </Typography>
      </>
    );
  };

  return (
    <ReqLayout label="Requests" hideBreadcrumbs>
      <InfoBox vertical={mdBelow ? true : false} />
      <Box p={2} />

      <Box display="flex" flexDirection={mdBelow ? 'column' : 'row'} pb={4}>
        {!requestLoading ? (
          <Paper
            style={{
              width: mdBelow ? '100% ' : '67%',
              display: 'flex',
              flexDirection: 'row',
              borderRadius: 10,
              padding: 20,
            }}
          >
            <Grid
              container
              spacing={2}
              style={{
                padding: smBelow ? 0 : 20,
              }}
            >
              <Grid
                item
                xs={6}
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <RequestStatus
                  label="All Requests"
                  unit={filteredRequests.length}
                />
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <RequestStatus
                  label="Completed"
                  unit={
                    filteredRequests.filter(
                      (r) => r.status_name === 'Completed',
                    ).length
                  }
                />
              </Grid>

              <Grid
                item
                xs={6}
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <RequestStatus
                  label="In Progress"
                  unit={
                    filteredRequests.filter(
                      (r) =>
                        r.status_name !== 'Completed' &&
                        r.status_name !== 'Rejected',
                    ).length
                  }
                />
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <RequestStatus
                  label="Rejected"
                  unit={
                    filteredRequests.filter((r) => r.status_name === 'Rejected')
                      .length
                  }
                />
              </Grid>
            </Grid>
            <img src={requestDetailsImg} width="50%" />
          </Paper>
        ) : (
          <div
            style={{
              width: '100%',
            }}
          >
            <EnhancedSkeleton
              layout={[
                {
                  loaders: [{ variant: 'rect', height: 50, width: '100%' }],
                  repeat: 5,
                  mt: 4.25,
                },
              ]}
            />
          </div>
        )}

        <Box p={1} />
        <Paper
          style={{
            width: mdBelow ? '100% ' : '33%',
            borderRadius: 10,
            padding: smBelow ? 20 : 40,
            backgroundImage: `url(${skybg})`,
            backgroundSize: 'contain',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h1"
            align="center"
            style={{ color: '#A21D23', fontWeight: 800 }}
          >
            Make a New Request
          </Typography>
          <Typography variant="h3" align="center">
            Submit secretarial requests, organise documents and keep track of
            your company’s compliance timeline
          </Typography>

          <Box p={2} />
          <Button
            variant="contained"
            size="large"
            color="primary"
            style={{
              textTransform: 'initial',
            }}
            onClick={() => {
              history.push(ROUTES.CORPSEC_REQ_APPT_DIRECTOR);
            }}
          >
            <AddIcon fontSize="small" /> New Request
          </Button>
        </Paper>
      </Box>

      <Paper
        style={{
          width: '100%',
          borderRadius: 10,
          padding: smBelow ? 20 : 40,
        }}
      >
        <Typography variant="h4">Recent Request</Typography>
        <Box p={1} />
        {!requestLoading ? (
          <MaterialTable
            options={{
              filtering: false,
              paging: true,
              toolbar: false,
              draggable: false,
              sorting: false,
              headerStyle: {
                backgroundColor: '#FAFAFB',
                paddingRight: 100,
              },
              rowStyle: { backgroundColor: '#FEFEFE' },
            }}
            style={{ width: '100%', zIndex: 0 }}
            columns={[
              {
                title: 'REQUEST',
                field: 'request',
              },
              {
                title: 'STATUS',
                field: 'status',
                render: (rowData) => (
                  <Box display="flex" alignItems="center">
                    {rowData.status !== 'Completed' &&
                      rowData.status !== 'Rejected' && (
                        <Box
                          style={{
                            backgroundColor: '#BCE2FF',
                            borderRadius: 8,
                            padding: 8,
                            fontSize: 12,
                          }}
                        >
                          {rowData.status}
                        </Box>
                      )}
                    {rowData.status === 'Completed' && (
                      <Box
                        style={{
                          backgroundColor: '#C4F9C6',
                          borderRadius: 8,
                          padding: 8,
                          fontSize: 12,
                        }}
                      >
                        Completed
                      </Box>
                    )}
                    {rowData.status === 'Rejected' && (
                      <Box
                        style={{
                          backgroundColor: '#FEF3F1',
                          borderRadius: 8,
                          padding: 8,
                          fontSize: 12,
                        }}
                      >
                        Rejected
                      </Box>
                    )}
                  </Box>
                ),
              },
              {
                title: 'START DATE',
                field: 'startdate',
              },
              {
                title: 'ACTION',
                field: 'action',
                render: (rowData) => (
                  <Button
                    variant="contained"
                    style={{
                      textTransform: 'initial',
                      backgroundColor: '#F0F3F6',
                    }}
                    onClick={() => {
                      history.push(
                        ROUTES.CORPSEC_DETAILS(rowData.applicationId),
                      );
                    }}
                  >
                    View Details
                  </Button>
                ),
              },
            ]}
            data={mapUserTable()}
          />
        ) : (
          <div
            style={{
              width: '100%',
            }}
          >
            <EnhancedSkeleton
              layout={[
                {
                  loaders: [{ variant: 'rect', height: 50, width: '100%' }],
                  repeat: 5,
                  mt: 4.25,
                },
              ]}
            />
          </div>
        )}
        <Box p={1} />
        <Typography variant="h6" style={{ fontWeight: 600 }}>
          Total {filteredRequests.length} Request
        </Typography>
        <Box p={smBelow ? 1 : 5} />
      </Paper>
    </ReqLayout>
  );
};

export default Dashboard;
