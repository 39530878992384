import React, { useState, useContext, useEffect } from 'react';
import { Typography, Box, Button, Paper } from '@material-ui/core';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import FormDropdown from 'src/components/FormDropdown/FormDropdown.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import MzButton from 'src/components/MzButton';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import { useProvideRequest } from 'src/hooks/useRequests';
import useSnackbar from 'src/hooks/useSnackbar';
import { ConfigContext } from 'src/contexts';
import { useProvideProduct } from 'src/hooks/useProduct';
import { useProvideData } from 'src/hooks/useData';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';

const ApptAuditor = ({ toggleShowMenu }) => {
  // const classes = useStyles();
  const buttonClasses = useButtonStyle();

  const history = useHistory();
  const { countries, ListCountries } = useProvideData();
  const [step, setStep] = useState(1);
  const [tabValue, setTabValue] = useState(0);
  const { smBelow } = useBreakpoints();
  const [showAlert, setShowAlert] = useState(false);

  const {
    CreateAuditorApplication,
    requestError,
    requestSubmitting,
  } = useProvideRequest();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { addCart, user, setConfirmationDialog, company } = useContext(
    ConfigContext,
  );
  const { ReadProduct, product, productError } = useProvideProduct();

  const getStarted = {
    one: {
      content: ['Appoint a New Auditor Company for your business'],
    },
    two: {
      content: ['Personal particulars of the new auditor'],
    },
    three: {
      content: [
        'Create Request',
        'Meyzer360 Review',
        'Sign Resolution',
        'ACRA Filing',
      ],
    },
    four: {
      content: ['SGD' + product.price],
    },
    five: {
      content: [
        'Time to fill form: < 5 minutes',
        'Average processing time: 3-5 days',
      ],
    },
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    company_name: Yup.string().required('Company Name is required'),
    company_registration_no: Yup.string().required(
      'Company Registration No is required',
    ),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
  });

  useEffect(() => {
    // get latest product
    ReadProduct(26);
    ListCountries();
  }, []);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.company_id;
    values.company_application_status_id = 11; // in cart status
    values.created_by = user.user_id;
    values.user_id = 0;
    values.country_incorporation_id = values.country_id;

    let res = await CreateAuditorApplication(values);

    if (res.status === 'success') {
      res = await addCart({
        type: 'Appointment of Auditor',
        price: product.price,
        product_id: product.id,
        quantity: 1,
        user_id: user.user_id,
        product_detail_id: res.body.applications.officer_application_id,
        company_id: company.company_id,
        company_name: company.company_name,
        company_registration_no: company.company_registration_no,
        company_application_status_id: values.company_application_status_id,
        company_application_id: res.body.applications.company_application_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setConfirmationDialog({
          isVisible: true,
          title: 'Item added to cart',
          type: 'success',
          cancelButtonLabel: 'Back to Request',
          confirmButtonLabel: 'View your cart',
          confirmButtonAction: () => {
            history.push(ROUTES.PAYMENTCART);
            setConfirmationDialog({
              isVisible: false,
            });
          },
          cancelButtonAction: () => {
            history.push(ROUTES.CORPSEC_REQ);
            setConfirmationDialog({
              isVisible: false,
            });
          },
        });
      }
    }
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          company_name: '',
          company_registration_no: '',
          name: '',
          email: '',
          company_role_id: 6,
        }}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <FormTextField
              name="company_name"
              labelText="COMPANY NAME"
              placeholder="ABC COMPANY"
              shrink
              variant="outlined"
              fullWidth={smBelow ? true : false}
            />
            <FormTextField
              name="company_registration_no"
              labelText="COMPANY REGISTRATION NO"
              placeholder="GXZ123456798"
              shrink
              variant="outlined"
              fullWidth={smBelow ? true : false}
            />

            <FormTextField
              name="address_line_1"
              labelText="Address Line 1"
              placeholder="Address Line 1"
              containerWidth="50%"
              shrink
              variant="outlined"
            />
            <FormTextField
              name="address_line_2"
              labelText="Address Line 2"
              placeholder="Address Line 2"
              containerWidth="50%"
              shrink
              variant="outlined"
            />
            <FormTextField
              name="address_line_3"
              labelText="Address Line 3"
              placeholder="Address Line 3"
              containerWidth="50%"
              shrink
              variant="outlined"
            />
            <FormTextField
              id="postal_code"
              name="postal_code"
              labelText="Postal Code"
              placeholder="Postal Code"
              containerWidth="50%"
              shrink
              variant="outlined"
            />
            <FormTextField
              name="floor_unit_no"
              labelText="Floor/ Unit No"
              placeholder="Unit No"
              containerWidth="50%"
              shrink
              variant="outlined"
            />
            <FormDropdown
              autoCompleteStyle={{ width: '50%' }}
              name="country_id"
              onChange={(event, newValue) => {
                props.setFieldValue('country_id', newValue?.value);
              }}
              optionList={countries}
              labelText="Country"
              placeholder="Select One"
              fullWidth
              shrink
              variant="outlined"
            />
            <FormTextField
              name="name"
              labelText="NAME (as in NRIC/FIN) *"
              placeholder="Name"
              shrink
              variant="outlined"
              fullWidth={smBelow ? true : false}
            />
            <FormTextField
              name="email"
              labelText="Email Address"
              placeholder="Email Address"
              shrink
              variant="outlined"
              fullWidth={smBelow ? true : false}
            />

            <Box display="flex" pt={3} justifyContent="flex-start">
              <MzButton
                fullWidth={smBelow ? true : false}
                type="submit"
                title={'SUBMIT'}
                onClick={() => {
                  setStep(2);
                }}
                loading={requestSubmitting}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={step}
        setStep={setStep}
        getStarted={getStarted}
        header={
          <>
            <Typography variant="h2">
              {step === 1 && 'Appointment of Auditor'}
              {step === 2 && 'Fill in Information for New Auditor'}
            </Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Request', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Change of Officers',
                  route: ROUTES.CORPSEC_REQ_CHANGE_OFFICER,
                },
                {
                  path: 'Appointment of Auditor',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        {step === 2 && (
          <>
            <Box px={4} py={4} style={{ borderTop: '0.01rem solid #E4E4E4' }}>
              {renderForm()}
            </Box>
          </>
        )}
        <Box py={5} />
      </GetStartedLayout>
    </>
  );
};

export default ApptAuditor;
