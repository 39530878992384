import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: '40vh',
      [theme.breakpoints.down('md')]: {
        height: '60vh',
      },
    },
    background: {
      borderRadius: 10,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      zIndex: -2,
      backgroundPosition: 'center',
    },
    contentPaper: {
      paddingLeft: 30,
      paddingTop: 50,
      paddingBottom: 50,
      width: '30%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginBottom: '40%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: '50%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: '70%',
      },
    },
    contentButton: {
      backgroundColor: '#C35339',
      color: '#FFFFFF',
      borderRadius: 20,
      textTransform: 'initial',
      padding: 15,
    },
  }),
);
