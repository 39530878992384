const baseURL = process.env.REACT_APP_API_V2_URL;
const ENDPOINTS = {
  BASE: baseURL,
  ADDRESS: `${baseURL}/address`,
  APPLICATION: `${baseURL}/application`,
  BIZACTIVITY: `${baseURL}/bizactivity`,
  COMPANY: `${baseURL}/company`,
  DOCUMENT: `${baseURL}/document`,
  ORDER: `${baseURL}/order`,
};

export default ENDPOINTS;
