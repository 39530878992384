import React, { useState, useEffect, useContext } from 'react';
import {
  Typography,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  Divider,
  RadioGroup,
  Radio,
  FormControl,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Formik, Form, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import commonSvc from '../../services/common';
import { ConfigContext } from 'src/contexts';
import CartSummary from './CartSummary';
import CustomTextField from 'src/components/CustomFormFields/CustomTextField';
import CustomSelectField from 'src/components/CustomFormFields/CustomSelectField';
import CustomPhoneNumberField from 'src/components/CustomFormFields/CustomPhoneNumberField';
import FormFieldContainer from 'src/components/CustomFormFields/FormFieldContainer';
import useSnackbar from 'src/hooks/useSnackbar';
import { useProvideOrder } from 'src/hooks/useOrder';
import { useProvideCheckout } from 'src/hooks/useCheckoutIntegration';
import { fieldNames_4 } from 'src/components/OnboardingCompany/StepForms';

const useCustomCommonAPI = (api, props) => {
  const { respondKey, labelKey, valueKey } = props;

  const [value, setValue] = useState({
    options: [],
    rawData: [],
  });

  useEffect(() => {
    (async () => {
      const apiRes = await api();
      let options = [];
      if (apiRes && apiRes?.[respondKey]) {
        apiRes[respondKey].map((aData) => {
          options.push({
            label: aData[labelKey],
            value: aData[valueKey],
          });
        });
        setValue((prev) => {
          return {
            ...prev,
            options: options,
            rawData: apiRes[respondKey],
          };
        });
      }
    })();
  }, []);

  return {
    ...value,
  };
};

const address_fields = {
  first_name: {
    name: 'first_name',
    label: 'First Name',
  },
  last_name: {
    name: 'last_name',
    label: 'Last Name',
  },
  email: {
    name: 'email',
    label: 'Your Email',
  },
  phoneNumber: {
    name: 'phone',
    label: 'Phone Number',
    custom: Yup.string()
      .required('Please fill up this field')
      .min(3, 'Please fill up this field'),
  },
  phoneNumberCountry: {
    name: 'phoneNumberCountry',
    label: 'Phone Number Country',
    custom: Yup.string(),
  },
  country_id: {
    name: 'country_id',
    label: 'Country',
  },
  state_id: {
    name: 'state_id',
    label: 'State',
  },
  // city: {
  //   name: 'state',
  //   label: 'City',
  // },
  postal_code: {
    name: 'postal_code',
    label: 'ZIP Code',
  },
  floor_unit_no: {
    name: 'floor_unit_no',
    label: 'Floor/Unit No',
  },
  address_line_1: {
    name: 'address_line_1',
    label: 'Address Line 1',
  },
  address_line_2: {
    name: 'address_line_2',
    label: 'Address Line 2',
  },
  address_line_3: {
    name: 'address_line_3',
    label: 'Address Line 3 (Optional)',
    required: false,
  },
};

const fields = {
  remark: {
    name: 'remark',
    label: 'Write a Note',
  },
  payment_method: {
    name: 'payment_method',
    label: 'Payment Method',
  },
  tnc: {
    name: 'tnc',
    label: 'Terms and Conditions',
  },
};

const getValidationSchemaObj = (
  fieldsData = {},
  prefix = '',
  required = true,
) => {
  let result = {};
  let fieldsDataKeys = Object.keys(fieldsData);
  fieldsDataKeys.forEach((aKey) => {
    let fieldName = prefix + fieldsData[aKey]['name'];
    result[fieldName] = Yup.string();

    if (fieldsData[aKey].required != undefined) {
      let isRequired = fieldsData[aKey].required;
      if (isRequired) {
        result[fieldName] = Yup.string().required('Please fill up this field');
      }
    } else {
      if (required) {
        result[fieldName] = Yup.string().required('Please fill up this field');
      }
    }

    if (fieldsData[aKey]?.custom) {
      result[fieldName] = fieldsData[aKey]?.custom;
    }
  });
  return result;
};

let validationSchemaObj = {
  billing: Yup.object().shape(getValidationSchemaObj(address_fields)),
  [fields.remark.name]: Yup.string(),
  [fields.payment_method.name]: Yup.string()
    .required('Please select a payment method')
    .default('payment2'),
  // [fields.differentShipping.name]: Yup.boolean(),
  [fields.tnc.name]: Yup.boolean()
    .required()
    .oneOf([true], 'Please tick here to proceed'),
};

let validationSchemaObj_shipping = {
  ...validationSchemaObj,
  shipping: Yup.object().shape(getValidationSchemaObj(address_fields)),
};

const AddressFields = (props) => {
  const {
    nestedKey,
    prefix = '',
    countryOptions = [],
    stateOptions: defaultStateOptions = [],
    statesData,
  } = props;

  const { values } = useFormikContext();
  const {
    first_name,
    last_name,
    email,
    phoneNumber,
    phoneNumberCountry,
    country_id,
    state_id,
    // city,
    postal_code,
    floor_unit_no,
    address_line_1,
    address_line_2,
    address_line_3,
  } = address_fields;

  let stateOptions = [];

  let countryFieldName = prefix + country_id.name;
  let countryValue = values?.[countryFieldName];
  if (nestedKey) {
    countryValue = values?.[nestedKey]?.[country_id.name];
  }

  if (countryValue) {
    statesData.forEach((anItem) => {
      if (anItem?.country_id == countryValue) {
        stateOptions.push({
          label: anItem?.state_name,
          value: anItem?.state_id,
        });
      }
    });
  }
  return (
    <>
      <Grid item xs={12} md={6}>
        <FormFieldContainer label={first_name.label} mb={2}>
          <CustomTextField name={prefix + first_name.name} />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormFieldContainer label={last_name.label} mb={2}>
          <CustomTextField name={prefix + last_name.name} />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormFieldContainer label={email.label} mb={2}>
          <CustomTextField name={prefix + email.name} type="email" />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormFieldContainer label={phoneNumber.label} mb={2}>
          <CustomPhoneNumberField
            name={prefix + phoneNumber.name}
            phoneCodeName={prefix + phoneNumberCountry.name}
          />
        </FormFieldContainer>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormFieldContainer label={floor_unit_no.label} mb={2}>
          <CustomTextField name={prefix + floor_unit_no.name} />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormFieldContainer label={address_line_1.label} mb={2}>
          <CustomTextField name={prefix + address_line_1.name} />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormFieldContainer label={address_line_2.label} mb={2}>
          <CustomTextField name={prefix + address_line_2.name} />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormFieldContainer label={address_line_3.label} mb={2}>
          <CustomTextField name={prefix + address_line_3.name} />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormFieldContainer label={postal_code.label} mb={2}>
          <CustomTextField name={prefix + postal_code.name} />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormFieldContainer label={country_id.label} mb={2}>
          <CustomSelectField
            name={prefix + country_id.name}
            options={countryOptions}
            placeholder="Select a country"
          />
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormFieldContainer label={state_id.label} mb={2}>
          <CustomSelectField
            name={prefix + state_id.name}
            options={stateOptions}
            placeholder="Select a state"
          />
        </FormFieldContainer>
      </Grid>
      {/* <Grid item xs={12} md={4}>
      <FormFieldContainer label={city.label} mb={2}>
        <CustomTextField name={prefix + city.name} />
      </FormFieldContainer>
    </Grid> */}
    </>
  );
};

const Checkout = (props) => {
  const { onSubmit } = props;
  const theme = useTheme();
  const {
    cartList,
    cart,
    updateCart,
    removeCart,
    webInfo,
    user,
    fetchCart,
  } = useContext(ConfigContext);
  const {
    CreateOrder,
    CreateOrderAddress,
    UpdateOrder,
    orderError,
    orderSubmitting,
  } = useProvideOrder();
  const {
    PaymentByLink,
    checkoutError,
    checkoutSubmitting,
  } = useProvideCheckout();
  const routeHistory = useHistory();

  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [isDifferentAddress, setIsDifferentAddress] = useState(false);
  const { options: countryOptions = [] } = useCustomCommonAPI(
    commonSvc.countries,
    {
      labelKey: 'country_name',
      valueKey: 'country_id',
      respondKey: 'countries',
    },
  );

  const {
    options: stateOptions = [],
    rawData: statesData,
  } = useCustomCommonAPI(commonSvc.states, {
    labelKey: 'state_name',
    valueKey: 'state_id',
    respondKey: 'states',
  });

  let validationSchema = Yup.object().shape(validationSchemaObj);

  if (isDifferentAddress) {
    validationSchema = Yup.object().shape(validationSchemaObj_shipping);
  }
  let initialValues = validationSchema.cast();

  // let foundUserAddressCountry = countryOptions.find((anItem) => {
  //   return anItem.value == user?.country_id;
  // });

  let userAddress = {
    [address_fields.first_name.name]:
      user?.[address_fields.first_name.name] || '',
    [address_fields.last_name.name]:
      user?.[address_fields.last_name.name] || '',
    [address_fields.email.name]: user?.[address_fields.email.name] || '',
    [address_fields.phoneNumber.name]: user?.['contact_number'] || '',
    [address_fields.country_id.name]: user?.country_id,
    [address_fields.state_id.name]: user?.state_id,
    [address_fields.postal_code.name]:
      user?.[address_fields.postal_code.name] || '',
    [address_fields.address_line_1.name]:
      user?.[address_fields.address_line_1.name] || '',
  };

  initialValues = {
    ...initialValues,
    billing: {
      ...initialValues.billing,
      ...userAddress,
    },
    [fields.payment_method.name]: 'payment2',
  };

  const handleOnSubmit = async (values) => {
    setLoading(true);

    await (async () => {
      let totalPrice = 0;
      for (let i = 0; i < cartList.length; i++) {
        totalPrice += Number(cartList[i].price);
      }

      const products = cartList.map((item) => {
        return {
          name: item.product.name,
          price: item.price * 100,
          quantity: item.quantity,
        };
      });
      if (!cart || !cart.id) {
        showErrorSnackbar(
          `Problem checking out your cart. Please refresh and try again.`,
        );
        return;
      }

      let billingAddressRes;
      try {
        // let foundCountry = countryOptions.find((anItem) => {
        //   return (
        //     anItem.value == values?.billing?.[address_fields.country_id.name]
        //   );
        // });
        // let foundState = stateOptions.find((anItem) => {
        //   return (
        //     anItem.value == values?.billing?.[address_fields.state_id.name]
        //   );
        // });
        billingAddressRes = await CreateOrderAddress({
          ...values.billing,
          cart_id: cart.id,
          created_by: user.user_id,
          // country: foundCountry?.label || '',
          // state: foundState?.label || '',
          // country_id: null,
        });
      } catch (error) {
        showErrorSnackbar(
          `Problem checking out your cart. Please refresh and try again.`,
        );
        return;
      }

      let shippingAddressRes;
      if (isDifferentAddress) {
        try {
          // let foundCountry = countryOptions.find((anItem) => {
          //   return (
          //     anItem.value == values?.shipping?.[address_fields.country_id.name]
          //   );
          // });
          // let foundState = stateOptions.find((anItem) => {
          //   return (
          //     anItem.value == values?.shipping?.[address_fields.state_id.name]
          //   );
          // });
          shippingAddressRes = await CreateOrderAddress({
            ...values.shipping,
            cart_id: cart.id,
            created_by: user.user_id,
            // country: foundCountry?.label || '',
            // state: foundState?.label || '',
          });
        } catch (error) {
          showErrorSnackbar(
            `Problem checking out your cart. Please refresh and try again.`,
          );
          return;
        }
      }

      let orderRes;
      try {
        orderRes = await CreateOrder({
          user_id: user.user_id,
          remark: values?.remark || '',
        });

        if (orderRes.status === 'success') {
          fetchCart(user);
          showSuccessSnackbar('Order submitted');
          // history.push(ROUTES.ORDERHISTORY);
        } else {
          showErrorSnackbar(
            `Problem checking out your cart. Please refresh and try again.`,
          );
          return;
        }
        //   return;
      } catch (error) {
        console.log('CreateOrder error', error);
        return;
      }

      if (orderRes.status === 'success') {
        let billing_address_id = billingAddressRes?.body?.order_address?.id;
        let shipping_address_id = shippingAddressRes?.body?.order_address?.id;
        let orderId = orderRes?.body?.order?.id;
        try {
          // let newCartBody = {
          //   id: cart.id,
          //   updated_by: user.user_id,
          //   billing_address_id: billing_address_id,
          // };
          // if (shipping_address_id) {
          //   newCartBody['shipping_address_id'] = shipping_address_id;
          // }
          // let updateCartRes = await updateCart(newCartBody);
          let newCartBody = {
            id: orderId,
            user_id: user.user_id,
            billing_address_id: billing_address_id,
            last_name: values?.billing?.[address_fields.first_name.name],
            first_name: values?.billing?.[address_fields.last_name.name],
            email: values?.billing?.[address_fields.email.name],
            phone: values?.billing?.[address_fields.phoneNumber.name],
            shipping_last_name:
              values?.shipping?.[address_fields.first_name.name],
            shipping_first_name:
              values?.shipping?.[address_fields.last_name.name],
            shipping_email: values?.shipping?.[address_fields.email.name],
            shipping_phone: values?.shipping?.[address_fields.phoneNumber.name],
          };
          if (shipping_address_id) {
            newCartBody['shipping_address_id'] = shipping_address_id;
          }
          let updateOrderRes = await UpdateOrder(newCartBody);
        } catch (error) {
          console.log('aaaaaaaa', error);
          showErrorSnackbar(
            `Problem checking out your cart. Please refresh and try again.`,
          );
          return;
        }
        if (totalPrice == 0) {
          let redirect_url = `${window.location.origin}/payment?redirect=${orderId}`;
          // routeHistory.replace(redirect_url);
          window.location.replace(redirect_url);
        } else {
          // request payment link
          const res = await PaymentByLink({
            billing: {
              address: {
                country: 'SG',
              },
            },
            products,
            currency: 'SGD',
            amount: totalPrice * 100,
            '3ds': {
              enabled: false,
              attempt_n3d: false,
            },
            reference: String(cart.id),
            // return_url: `${window.location.origin}/payment?redirect=checkout`,
            return_url: `${window.location.origin}/payment?redirect=${orderId}`,
          });
          console.log('PaymentByLink', res);
          if (res.status === 'success') {
            showSuccessSnackbar('Redirecting to payment...');
            window.location.replace(res.body._links.redirect.href);
          }
        }
      }
    })();
    setLoading(false);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        validateOnChange={false}
      >
        {(props) => {
          const {
            handleSubmit,
            values,
            validateForm,
            setTouched,
            touched,
          } = props;
          // console.log('values', values);
          // let isDifferentAddress = values[fields.differentShipping.name];
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={7} md={8}>
                  <Box p={2} border={1} borderColor={theme.palette.grey[300]}>
                    <Box mb={2}>
                      <Typography variant="h3">Billing Information</Typography>
                    </Box>
                    <Grid container spacing={2}>
                      <AddressFields
                        nestedKey="billing"
                        prefix="billing."
                        countryOptions={countryOptions}
                        stateOptions={stateOptions}
                        statesData={statesData}
                      />
                      <Grid item xs={12}>
                        {/* <Field name={fields.differentShipping.name}>
                        {({ field, meta }) => {
                          let error = meta.touched && !!meta.error;

                          return (
                            <FormFieldContainer
                              error={error}
                              helperText={
                                meta.touched && meta.error ? meta.error : ''
                              }
                            > */}
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isDifferentAddress}
                              onChange={(e) => {
                                setIsDifferentAddress(e.target.checked);
                              }}
                              // {...field}
                              color="primary"
                            />
                          }
                          label={<b>Ship to Different Address</b>}
                        />
                        {/* </FormFieldContainer>
                          );
                        }}
                      </Field> */}
                      </Grid>
                      {isDifferentAddress ? (
                        <AddressFields
                          nestedKey="shipping"
                          prefix="shipping."
                          countryOptions={countryOptions}
                          stateOptions={stateOptions}
                          statesData={statesData}
                        />
                      ) : null}
                      <Grid item xs={12}>
                        <FormFieldContainer
                          label={<b>{fields.remark.label}</b>}
                          mb={2}
                        >
                          <CustomTextField
                            name={fields.remark.name}
                            multiline
                            rows={4}
                          />
                        </FormFieldContainer>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={5} md={4}>
                  <CartSummary
                    //   onSubmit={onSubmit}
                    isCheckout={true}
                    buttonProps={{
                      // type: 'submit',
                      type: 'button',
                      onClick: () => {
                        validateForm()
                          .then((result) => {
                            console.log('result', result);
                            setTouched({
                              ...touched,
                              ...result,
                            });
                            let canSubmit = false;
                            if (result && Object.keys(result).length == 0) {
                              canSubmit = true;
                            }
                            if (canSubmit) {
                              handleSubmit(values, canSubmit);
                            }
                          })
                          .catch((errors) => {
                            console.log('validateForm error', errors);
                            setTouched({ ...touched, ...errors });
                          });
                      },
                    }}
                    label="Place Order"
                    footer={
                      <Box mb={2}>
                        <Box mb={1}>
                          <Typography variant="h3">
                            Select Payment Method
                          </Typography>
                        </Box>
                        <Box>
                          <Field name={fields.payment_method.name}>
                            {({ field, meta }) => {
                              let error = meta.touched && !!meta.error;

                              return (
                                <FormFieldContainer
                                  error={error}
                                  helperText={
                                    meta.touched && meta.error ? meta.error : ''
                                  }
                                >
                                  <RadioGroup
                                    aria-label="payment-method"
                                    name="payment_method"
                                    {...field}
                                  >
                                    {/* <FormControlLabel
                                      value="payment1"
                                      control={<Radio color="primary" />}
                                      label="PayNow"
                                    /> */}
                                    <FormControlLabel
                                      value="payment2"
                                      control={
                                        <Radio
                                          color="primary"
                                          style={{ paddingTop: 0 }}
                                        />
                                      }
                                      label={
                                        <Box>
                                          <Box>Pay via Checkout.com</Box>
                                          <Box color={theme.palette.grey[500]}>
                                            <small>
                                              Make a payment using your
                                              credit/debit card
                                            </small>
                                          </Box>
                                        </Box>
                                      }
                                      style={{ alignItems: 'flex-start' }}
                                    />
                                  </RadioGroup>
                                </FormFieldContainer>
                              );
                            }}
                          </Field>
                        </Box>
                        <Box py={2}>
                          <Divider />
                        </Box>
                        <Box color={theme.palette.grey[500]} mb={1}>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: 'normal' }}
                          >
                            Your personal data will be used to process your
                            order, support your experience throughout this
                            website, and for other purposes described in our{' '}
                            <span style={{ color: 'black' }}>
                              privacy policy
                            </span>
                            .
                          </Typography>
                        </Box>
                        <Box>
                          <Field name={fields.tnc.name}>
                            {({ field, meta }) => {
                              let error = meta.touched && !!meta.error;

                              return (
                                <FormFieldContainer
                                  error={error}
                                  helperText={
                                    meta.touched && meta.error ? meta.error : ''
                                  }
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        // checked={isDifferentAddress}
                                        // onChange={(e) => {
                                        //   setIsDifferentAddress(e.target.checked);
                                        // }}
                                        {...field}
                                        // required={true}
                                        color="primary"
                                      />
                                    }
                                    label={
                                      <Box color={theme.palette.grey[500]}>
                                        <Typography
                                          variant="h6"
                                          style={{ fontWeight: 'normal' }}
                                        >
                                          I have read and agree to the website{' '}
                                          <a style={{ color: 'black' }}>
                                            terms & conditions
                                          </a>
                                          .
                                        </Typography>
                                      </Box>
                                    }
                                  />
                                </FormFieldContainer>
                              );
                            }}
                          </Field>
                        </Box>
                      </Box>
                    }
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Backdrop open={loading} style={{ zIndex: 5 }}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default Checkout;
