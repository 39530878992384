import axios from './customAxios';
import storageSvc from './storage';
import { Config } from 'src/config';
import ENDPOINTS from 'src/constants/endpoints';
const API_URL = Config.apiURL;
import { Cookies } from 'react-cookie';

const accessToken = new Cookies().get('accessToken');
let companyURL_original = process.env.REACT_APP_COMPANY_URL;
// const companies = async () => {
//   const auth = authSvc.getAuthData();
//   const { data } = await axios.get(
//     `${API_URL}/company/companies/user/${auth?.user?.user_id}`,
//   );

//   if (data && !data.is_success) throw Error(data?.message);

//   return data?.company;
// };

const companiesAndShares = async (userID) => {
  let shares = [];
  let companies = [];
  try {
    const sharesRs = await axios.get(
      `${API_URL}/shareholder/users/${userID}/shareholders`,
    );

    if (sharesRs?.data?.is_success) {
      shares = sharesRs.data?.shares;
    }
  } catch (err) {}

  try {
    const { data } = await axios.get(
      `${ENDPOINTS.COMPANY}/companies/user/${userID}`,
      {
        headers: { Authorization: `${accessToken}` },
      },
    );
    if (data && !data.is_success) throw Error(data?.message);

    if (data.company?.length == 0) return [];

    if (shares?.length == 0) return data.company;

    const comps = [
      ...new Map(
        shares.concat(data.company).map((item) => [item['company_id'], item]),
      ).values(),
    ];
    companies = comps.map((c) => {
      let totalShares = 0;

      for (const s of shares.filter((s) => s.company_id == c.company_id)) {
        totalShares += s.user_allotment;
      }

      return {
        ...c,
        shares: totalShares === 0 ? '0' : totalShares,
      };
    });
  } catch (err) {}

  return companies;
};

const getCompany = () => storageSvc.getItem('@selected_company');

const setCompany = (company) =>
  storageSvc.setItem('@selected_company', company);

const removeSelectedCompany = () => {
  storageSvc.deleteItem('@selected_company');
};

const onBoardProfile = async (body) => {
  return await axios.post(`${ENDPOINTS.COMPANY}/onboardprofiles`, body, {
    headers: { Authorization: `${accessToken}` },
  });
};
const getOnBoardProfileById = async (id) => {
  return await axios.get(`${ENDPOINTS.COMPANY}/onboardprofiles/${id}`, {
    headers: { Authorization: `${accessToken}` },
  });
};

const getOnBoardProfilesByUserId = async (userID) => {
  return new Promise((resolve, reject) => {
    if (userID) {
      axios
        .get(`${ENDPOINTS.COMPANY}/onboardprofiles/user/${userID}`, {
          headers: { Authorization: `${accessToken}` },
        })
        .then((result) => {
          if (result?.data?.is_success) {
            resolve(result.data.onboard_profiles);
          } else {
            reject(result);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      reject('User not found');
    }
  });
};

const getOnboardProfilePublicById = async (id) => {
  return await axios.get(
    `${companyURL_original}/public-onboardprofiles/${id}`,
    {
      // headers: { Authorization: `${accessToken}` },
    },
  );
};
const createOnboardProfilePublic = async (body = {}) => {
  return await axios.post(
    `${companyURL_original}/public-onboardprofiles`,
    body,
    {
      // headers: { Authorization: `${accessToken}` },
    },
  );
};
const updateOnboardProfilePublicById = async (id, body = {}) => {
  return await axios.put(
    `${companyURL_original}/public-onboardprofiles/${id}`,
    body,
    {
      // headers: { Authorization: `${accessToken}` },
    },
  );
};

const companySvc = {
  // companies,
  companiesAndShares,
  getCompany,
  setCompany,
  removeSelectedCompany,
  onBoardProfile,
  getOnBoardProfilesByUserId,
  getOnBoardProfileById,
  getOnboardProfilePublicById,
  createOnboardProfilePublic,
  updateOnboardProfilePublicById,
};

export default companySvc;
