import React, { useState, useEffect, useContext } from 'react';
import {
  Typography,
  Box,
  Grid,
  Container,
  Button,
  Divider,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';

import { ConfigContext } from 'src/contexts';
import { sgdFormat } from 'src/utils/currency';
import ImageContainer from 'src/components/ImageContainer';
import CartSummary from './CartSummary';
import ROUTES from 'src/constants/routes';
import { SelectField } from 'src/components/CustomFormFields/CustomSelectField';

const Cart = (props) => {
  const { onSubmit } = props;
  const theme = useTheme();
  const {
    cartList: defaultCartList = [],
    cart,
    updateCartItem,
    removeCart,
    webInfo,
    user,
    fetchCart,
  } = useContext(ConfigContext);
  const history = useHistory();

  let cartList = [...defaultCartList];
  cartList.sort((a, b) => {
    return b.id - a.id;
  });

  const handleUpdateQuantity = async (cartItem, quantity) => {
    try {
      let newCartBody = {
        quantity: quantity,
        user_id: user.user_id,
        product_id: cartItem?.product_id,
      };
      let updateCartRes = await updateCartItem(cartItem, newCartBody);
    } catch (error) {
      // showErrorSnackbar(
      //   `Problem checking out your cart. Please refresh and try again.`,
      // );
      return;
    }
  };

  const handleRemoveCartItem = async (data, index) => {
    if (window.confirm('Remove this item?')) {
      await removeCart(cartList, index);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Box p={2} border={1} borderColor={theme.palette.grey[300]}>
          <Grid container>
            <Grid item xs={1}>
              {' '}
            </Grid>
            <Grid item xs={5}>
              PRODUCT / SERVICE
            </Grid>
            <Grid item xs={2} component={Box} textAlign="center">
              QUANTITY
            </Grid>
            <Grid item xs={3} component={Box} textAlign="center">
              PRICE
            </Grid>
            <Grid item xs={1}>
              {' '}
            </Grid>
            <Grid item xs={12}>
              <Box py={2}>
                <Divider />
              </Box>
            </Grid>
            {cartList?.length > 0 ? (
              cartList.map((anItem, index) => {
                return (
                  <React.Fragment key={index}>
                    {index != 0 ? (
                      <Grid item xs={12}>
                        <Box py={2}>
                          <Divider />
                        </Box>
                      </Grid>
                    ) : null}
                    <CartItem
                      data={anItem}
                      onUpdate={(data, quantity) => {
                        handleUpdateQuantity(data, quantity);
                      }}
                      onRemove={(data) => {
                        handleRemoveCartItem(data, index);
                      }}
                    />
                  </React.Fragment>
                );
              })
            ) : (
              <Grid
                item
                xs={12}
                component={Box}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box mb={2}>No Item(s)</Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    sie="small"
                    onClick={() => {
                      history.push(ROUTES.OFFICE_SUPPLIES);
                    }}
                  >
                    Continue Shopping
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <CartSummary onSubmit={onSubmit} />
      </Grid>
    </Grid>
  );
};

export default Cart;

const QuantitySelectField = (props) => {
  let quantityOptions = [];
  for (let i = 0; i < 99; i++) {
    quantityOptions.push({
      label: i + 1,
      value: i + 1,
    });
  }
  return <SelectField options={quantityOptions} {...props} />;
};
const CartItem = (props) => {
  const { data = {}, onUpdate, onRemove } = props;
  const theme = useTheme();
  const history = useHistory();

  const { quantity, price, product = {} } = data;
  const { name, item_category } = product;

  const handleChangeQuantity = async (newQuantity) => {
    if (onUpdate) {
      onUpdate(data, newQuantity);
    }
  };

  const handleRemoveItem = () => {
    if (onRemove) {
      onRemove(data);
    }
  };

  let viewDetailPath = null;
  let isOfficeSupplies = false;
  if (
    (data?.product_id == 28 || data?.product_id == 39) &&
    data.company_application_id
  ) {
    viewDetailPath = `/corpsec/company-account/${data.company_application_id}`;
  } else {
    let productCategoryId = data?.product?.product_category_id;

    if (productCategoryId) {
      if (productCategoryId == 1) {
        if (data.company_application_id) {
          viewDetailPath = ROUTES.CORPSEC_DETAILS(data.company_application_id);
        }
      } else if (productCategoryId == 2) {
        if (data?.product?.id) {
          viewDetailPath = `/office-supplies/${data?.product?.id}`;
          isOfficeSupplies = true;
        }
      }
    }
  }

  let imageURL = data?.product?.product_documents?.[0]?.document?.document_url;

  return (
    <Grid
      container
      item
      xs={12}
      component={Box}
      // pb={2}
    >
      <Grid xs={1} item>
        {' '}
      </Grid>
      <Grid item xs={5}>
        <Box display="flex">
          <Box minWidth="75px" mr={2}>
            <ImageContainer src={imageURL} ratio="120%" />
          </Box>
          <Box>
            <Box color={theme.palette.grey[400]} fontWeight="normal">
              {item_category || ''}
            </Box>
            <Box mb={1}>
              <Typography variant="h4">
                <b>{name}</b>
              </Typography>
            </Box>
            <Box mb={1}>
              <span
                style={{
                  fontWeight: 'normal',
                }}
              >
                Type:
              </span>{' '}
              <b>{data?.type || '-'}</b>
            </Box>
            <Box>
              <small>
                {viewDetailPath ? (
                  <a
                    color="primary"
                    style={{
                      color: theme.palette.primary.main,
                      fontWeight: 'normal',
                    }}
                    onClick={() => {
                      history.push(viewDetailPath);
                    }}
                  >
                    View Details
                  </a>
                ) : null}
              </small>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid
        xs={2}
        item
        component={Box}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isOfficeSupplies ? (
          <QuantitySelectField
            value={quantity}
            onChange={(e) => {
              handleChangeQuantity(e.target.value);
            }}
          />
        ) : null}
      </Grid>
      <Grid
        xs={3}
        item
        component={Box}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {sgdFormat(price)}
      </Grid>
      <Grid
        xs={1}
        item
        component={Box}
        color={theme.palette.grey[400]}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button onClick={handleRemoveItem}>
          <CancelIcon
            style={{
              color: theme.palette.grey[400],
            }}
          />
        </Button>
      </Grid>
    </Grid>
  );
};
