import React from 'react';
import {
  Typography,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
} from '@material-ui/core';

const DetailList = ({ label, description, bold }) => {
  return (
    <ListItem>
      <ListItemText
        primaryTypographyProps={{
          style: {
            whiteSpace: 'normal',
            fontWeight: bold ? 'bold' : null,
          },
          variant: 'body1',
        }}
        primary={label}
      />

      <Box p={1} />
      <Typography
        style={{ fontWeight: bold ? 'bold' : null }}
        variant="body1"
        align="right"
      >
        {!!description && description !== 'Invalid date' ? description : '-'}
      </Typography>
    </ListItem>
  );
};

export default DetailList;
