import React, { useState, useContext, useEffect } from 'react';
import { Typography, Box, Button, Paper } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MzButton from 'src/components/MzButton';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import { useProvideRequest } from 'src/hooks/useRequests';
import useSnackbar from 'src/hooks/useSnackbar';
import { ConfigContext } from 'src/contexts';
import { useProvideProduct } from 'src/hooks/useProduct';
import { useProvideCompany } from 'src/hooks/useCompany';
import { FormDatePicker } from 'src/components/Form';
import moment from 'moment';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';
import useBreakpoints from 'src/hooks/useBreakpoints';

const ChangeCompanyFinancialYearEnd = ({ toggleShowMenu }) => {
  // const classes = useStyles();
  const buttonClasses = useButtonStyle();
  const { smBelow } = useBreakpoints();

  const history = useHistory();
  const [step, setStep] = useState(1);
  const [showAlert, setShowAlert] = useState(false);

  const {
    CreateFinancialYearEndApplication,
    requestError,
    requestSubmitting,
  } = useProvideRequest();
  const {
    GetFinancialYearEnd,
    GetAGM,
    agm,
    financialYearEnd,
    companyError,
  } = useProvideCompany();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { addCart, user, company, setConfirmationDialog } = useContext(
    ConfigContext,
  );
  const { ReadProduct, product, productError } = useProvideProduct();

  const validationSchema = Yup.object({
    new_financial_year_end_date: Yup.string().required(
      'Financial Year End is required',
    ),
  });

  const getStarted = {
    one: {
      content: ['Change your company’s financial year end'],
    },
    two: {
      content: ['Your company’s new financial year end'],
    },
    three: {
      content: [
        'Create Request',
        'Meyzer360 Review',
        'Sign Resolution',
        'ACRA Filing',
      ],
    },
    four: {
      content: ['SGD' + product.price],
    },
    five: {
      content: [
        'Fill up form: < 2 minutes',
        'Average processing time: 3-5 days',
      ],
    },
  };

  useEffect(() => {
    // get latest product
    ReadProduct(13);
  }, []);

  useEffect(() => {
    if (company.company_id) {
      GetFinancialYearEnd(company.company_id);
      GetAGM(company.company_id);
    }
  }, [company.company_id]);

  useEffect(() => {
    if (companyError) {
      showErrorSnackbar(
        `Error loading Company's financial year end. ${companyError}`,
      );
    }
  }, [companyError]);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const formikOnSubmit = async (values, actions) => {
    console.log('financialYearEnd', financialYearEnd?.event_datetime);
    values.company_id = company.company_id;
    values.company_application_status_id = 11;
    values.created_by = user.user_id;
    values.financial_year_end_date = moment(financialYearEnd?.event_datetime);
    values.next_agm_due_date = agm?.event_datetime;
    console.log('FYE', values);
    let res = await CreateFinancialYearEndApplication(values);

    if (res.status === 'success') {
      res = await addCart({
        price: product.price,
        product_id: product.id,
        quantity: 1,
        user_id: user.user_id,
        product_detail_id:
          res.body.applications[0].financial_year_end_application_id,
        company_id: company.company_id,
        company_name: company.company_name,
        company_registration_no: company.company_registration_no,
        company_application_id: res.body.applications[0].company_application_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setConfirmationDialog({
          isVisible: true,
          title: 'Item added to cart',
          type: 'success',
          cancelButtonLabel: 'Back to Request',
          confirmButtonLabel: 'View your cart',
          confirmButtonAction: () => {
            history.push(ROUTES.PAYMENTCART);
            setConfirmationDialog({
              isVisible: false,
            });
          },
          cancelButtonAction: () => {
            history.push(ROUTES.CORPSEC_REQ);
            setConfirmationDialog({
              isVisible: false,
            });
          },
        });
      }
    }
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          new_financial_year_end_date: '',
        }}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Box
              p={3}
              display="flex"
              alignItems="flex-start"
              flexDirection="column"
            >
              <Typography variant="caption">Financial Year End Date</Typography>
              <Typography variant="h6">{`${moment(agm?.fye_date).format(
                'DD/MM/YYYY',
              )}`}</Typography>
              <Box py={1} />
              <Typography variant="caption">Next AGM Due Date</Typography>
              <Typography variant="h6">{`${moment(agm?.fye_date)
                .add(6, 'M')
                .format('DD/MM/YYYY')}`}</Typography>
              <Box py={1} />
              <FormDatePicker
                color="secondary"
                name="new_financial_year_end_date"
                labelText="New Financial Year End *"
                format="dd/MM/yyyy"
                variant="outlined"
                inputVariant="outlined"
              />
              <Box display="flex" pt={3} justifyContent="flex-start">
                <MzButton
                  fullWidth={smBelow ? true : false}
                  type="submit"
                  title={'SUBMIT'}
                  onClick={() => {}}
                  loading={requestSubmitting}
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={step}
        setStep={setStep}
        getStarted={getStarted}
        header={
          <>
            <Typography variant="h2">
              {step === 1 && 'Change of Financial Year End'}
              {step === 2 &&
                'Fill in Information for Change Company Financial Year End'}
            </Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Requests', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Change Company Particulars',
                  route: ROUTES.CORPSEC_REQ_CHANGE_COMPANY_PARTICULARS,
                },
                {
                  path: 'Change Company Financial Year End',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        {step === 2 && (
          <>
            <Box px={4} py={4} style={{ borderTop: '0.01rem solid #E4E4E4' }}>
              {renderForm()}
            </Box>
          </>
        )}
        <Box py={5} />
      </GetStartedLayout>
    </>
  );
};

export default ChangeCompanyFinancialYearEnd;
