import React, { useState, useContext, useEffect } from 'react';
import { Typography, Box, Button, Paper } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory, useParams } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import MzButton from 'src/components/MzButton';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import { FormDatePicker } from 'src/components/Form';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import { useProvideRequest } from 'src/hooks/useRequests';
import { ConfigContext } from 'src/contexts';
import useSnackbar from 'src/hooks/useSnackbar';
import { useProvideProduct } from 'src/hooks/useProduct';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';

const DeclareInterimDividend = ({ toggleShowMenu }) => {
  const history = useHistory();
  const {
    CreateDeclareInterimDividend,
    requestError,
    requestSubmitting,
  } = useProvideRequest();
  const {
    addCart,
    user,
    cartSubmitting,
    company,
    setConfirmationDialog,
  } = useContext(ConfigContext);
  const { ReadProduct, product, productError } = useProvideProduct();
  const { showErrorSnackbar } = useSnackbar();

  const buttonClasses = useButtonStyle();
  const [step, setStep] = useState(1);
  const { smBelow } = useBreakpoints();

  const validationSchema = Yup.object({
    dividend_amount: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Total Dividend Amount is required'),
    dividend_date: Yup.string().required('Dividend Date is required'),
    payment_date: Yup.string().required('Payment Date is required'),
  });

  const getStarted = {
    one: {
      content: ['Declare your company’s interim dividend'],
    },
    two: {
      content: [
        'Amount of dividend to be paid',
        'Dividend date',
        'Payment date',
      ],
    },
    three: {
      content: [
        'Create Request',
        'Meyzer360 Review',
        'Sign Resolution',
        'ACRA Filing',
      ],
    },
    four: {
      content: ['SGD' + product.price],
    },
    five: {
      content: [
        'Fill up form: < 2 minutes',
        'Average processing time: 3-5 days',
      ],
    },
  };

  useEffect(() => {
    // get latest product
    ReadProduct(16);
  }, []);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const stepTwo = () => {
    return (
      <>
        <Formik
          initialValues={{
            dividend_amount: '',
            dividend_date: new Date(),
            payment_date: new Date(),
          }}
          onSubmit={formikOnSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form style={{ width: '100%' }}>
              <FormTextField
                name="dividend_amount"
                labelText="Total Dividend Amount *"
                fullWidth={smBelow ? true : false}
                shrink
                variant="outlined"
              />
              <FormDatePicker
                name="dividend_date"
                labelText="Dividend Date *"
                format="dd/MM/yyyy"
                variant="outlined"
                inputVariant="outlined"
                containerWidth={smBelow ? '100%' : '50%'}
              />
              <FormDatePicker
                name="payment_date"
                labelText="Payment Date *"
                format="dd/MM/yyyy"
                variant="outlined"
                inputVariant="outlined"
                containerWidth={smBelow ? '100%' : '50%'}
              />
              {/* <Box
                display="flex"
                alignItems="flex-start"
                flexDirection="row"
                style={{
                  gap: '20px',
                }}
              >
                <FormDatePicker
                  name="dividend_date"
                  labelText="Dividend Date *"
                  format="dd/MM/yyyy"
                  variant="outlined"
                  inputVariant="outlined"
                  containerWidth={smBelow ? '100%' : '50%'}
                />
                <FormDatePicker
                  name="payment_date"
                  labelText="Payment Date *"
                  format="dd/MM/yyyy"
                  variant="outlined"
                  inputVariant="outlined"
                  containerWidth={smBelow ? '100%' : '50%'}
                />
              </Box> */}
              <Box display="flex" pt={3} justifyContent="flex-start">
                <MzButton
                  fullWidth={smBelow ? true : false}
                  type="submit"
                  title={'SUBMIT'}
                  onClick={() => {
                    setStep(2);
                  }}
                  loading={cartSubmitting || requestSubmitting}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </>
    );
  };

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.company_id;
    values.company_application_status_id = 11; // in cart
    values.created_by = user.user_id;
    let res = await CreateDeclareInterimDividend(values);
    if (res.status === 'success') {
      res = await addCart({
        price: product.price,
        product_id: product.id,
        quantity: 1,
        user_id: user.user_id,
        product_detail_id:
          res.body.applications.dividend_declaration_application_id,
        company_id: company.company_id,
        company_name: company.company_name,
        company_registration_no: company.company_registration_no,
        company_application_id: res.body.applications.company_application_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setConfirmationDialog({
          isVisible: true,
          title: 'Item added to cart',
          type: 'success',
          cancelButtonLabel: 'Back to Request',
          confirmButtonLabel: 'View your cart',
          confirmButtonAction: () => {
            history.push(ROUTES.PAYMENTCART);
            setConfirmationDialog({
              isVisible: false,
            });
          },
          cancelButtonAction: () => {
            history.push(ROUTES.CORPSEC_REQ);
            setConfirmationDialog({
              isVisible: false,
            });
          },
        });
      }
    }
  };

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={step}
        setStep={setStep}
        getStarted={getStarted}
        header={
          <>
            <Typography variant="h2">
              {step === 1 && 'Declaration of Interim Dividend'}
              {step === 2 && 'Fill in Information for Declare Interim Dividend'}
            </Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Requests', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Filing of Other Resolutions',
                  route: ROUTES.CORPSEC_REQ_FILLING_OTHER_RESOLUTIONS,
                },
                {
                  path: 'Declare Interim Dividend',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        {step === 2 && (
          <>
            <Box px={4} py={4} style={{ borderTop: '0.01rem solid #E4E4E4' }}>
              {stepTwo()}
            </Box>
          </>
        )}
        <Box py={5} />
      </GetStartedLayout>

      {/* <Formik
        initialValues={{
          dividend_amount: '',
          dividend_date: new Date(),
          payment_date: new Date(),
        }}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Box
              flexDirection="column"
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: '100%' }}
            >
              {step === 0 && stepOne()}
              {step === 1 && stepTwo()}
              <Box p={2} />
              {step === 1 && (
                <MzButton
                  disabled={!(props.isValid && props.dirty)}
                  type="submit"
                  onClick={() => {}}
                  title={'Submit'}
                  loading={cartSubmitting || requestSubmitting}
                />
              )}
            </Box>
          </Form>
        )}
      </Formik> */}
      {/* <Box p={4} /> */}
    </>
  );
};

export default DeclareInterimDividend;
