import React from 'react';
import {
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Radio,
  RadioGroup,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  checkedIcon: {
    '&.Mui-checked': { color: theme.palette.primary.main },
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  // checkedIcon: {
  //   backgroundColor: theme.palette.primary.main,
  //   backgroundImage:
  //     'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  //   '&:before': {
  //     display: 'block',
  //     width: 16,
  //     height: 16,
  //     backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
  //     content: '""',
  //   },
  //   'input:hover ~ &': {
  //     backgroundColor: theme.palette.primary.main,
  //   },
  // },
  radioLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: '12px',
    },
  },
  radioWrapper: {
    borderColor: theme.palette.grey['400'],
    borderWidth: '1px',
    borderStyle: 'solid',
    marginLeft: 0,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    height: '100%',
    color: theme.palette.grey['600'],
    paddingLeft: theme.spacing(2),
  },
  selectedRadioWrapper: {
    backgroundColor: 'rgba(255, 228, 142, 0.19)',
    borderColor: theme.palette.primary.main,
    borderWidth: '1px',
    borderStyle: 'solid',
    marginLeft: 0,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    height: '100%',
    paddingLeft: theme.spacing(2),
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="initial"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const RadioGroupField2 = (props) => {
  const {
    label,
    name,
    value = '',
    onChange,
    options = [],
    error,
    disabled = false,
    required = false,
    children,
    ...restProps
  } = props;

  const classes = useStyles();
  let radioGroupProps = {
    name,
    value,
    onChange,
    row: true,
    disabled,
    required,
    ...restProps,
  };

  let radioClassName = clsx(classes.radioLabel, classes.radioWrapper);
  let selectedRadioClassName = clsx(
    classes.radioLabel,
    classes.selectedRadioWrapper,
  );
  return (
    <>
      <RadioGroup {...radioGroupProps}>
        <Grid container spacing={2}>
          {options?.map((anOption, index) => {
            let optionLabel = anOption?.label;
            let optionValue = anOption?.value;
            // }
            return (
              <Grid item key={index} xs={4}>
                <FormControlLabel
                  // label={
                  //   <Typography variant="caption">{optionLabel}</Typography>
                  // }
                  label={optionLabel}
                  value={optionValue}
                  // control={<StyledRadio />}
                  control={<Radio className={classes.checkedIcon} />}
                  labelPlacement="start"
                  className={
                    value == optionValue
                      ? selectedRadioClassName
                      : radioClassName
                  }
                  disabled={disabled}
                />
              </Grid>
            );
          })}
          {children}
        </Grid>
      </RadioGroup>
    </>
  );
};

export default RadioGroupField2;
