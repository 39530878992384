import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import { alpha, withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  InputBase,
  Divider,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import NearMeOutlinedIcon from '@material-ui/icons/NearMeOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';

import documentSvc from 'src/services/document';
import useBreakpoints from 'src/hooks/useBreakpoints';
import useSnackbar from 'src/hooks/useSnackbar';
import { ConfigContext } from 'src/contexts';
import GetStartedLayout from 'src/components/Corpsec/Documents/GetStartedLayout';
import FilePreviewModal from '../mymodal.component';
import LoadingComponent from '../loading.component';
import EmptyComponent from '../empty.component';
import FileViewerButton from 'src/components/FileViewerButton';
import FileViewerButton2 from 'src/components/FileViewerButton2';
const useStyles = makeStyles((theme) => {
  return {
    dateColumn: {
      // maxWidth: 'auto',
      width: '160px',
    },
    actionColumn: {
      // maxWidth: 'auto',
      width: '90px',
    },
    textDanger: {
      color: theme.palette.error.main,
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      // marginRight: theme.spacing(2),
      // marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        // marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      // [theme.breakpoints.up('md')]: {
      //   width: '20ch',
      // },
      border: '1px solid #000',
      borderColor: theme.palette.grey['500'],
      borderRadius: theme.shape.borderRadius,
    },
  };
});

function dateComparison(a, b) {
  const key = 'uploaded_date';
  const order = 'desc';
  if (a[key] && b[key]) {
    const date1 = new Date(a[key]);
    const date2 = new Date(b[key]);

    if (order == 'desc') {
      return date2 - date1;
    } else {
      return date1 - date2;
    }
  }
  return 0;
}

// const documentURLKey = 'document_url'
const documentURLKey = 'signed_document_url';
const DocumentFiles = ({
  toggleShowMenu,
  mainFolder: defaultMainFolder = null,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [folderLoaded, setFolderLoaded] = useState(false);
  const [mainFolder, setMainFolder] = useState(defaultMainFolder);
  const [items, setItems] = useState([]);
  const [filteredItems_initial, setFilteredItems] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const { addCart, user, setConfirmationDialog, company } = useContext(
    ConfigContext,
  );

  const [searchKeyword, setSearchKeyword] = useState('');
  const [showSearchResult, setShowSearchResult] = useState(false);

  const [showFilePreview, setShowFilePreview] = useState(false);
  const [loadingFilePreview, setLoadingFilePreview] = useState(false);
  const [reloadedSrc, setReloadedSrc] = useState();
  const [reloading, setReloading] = useState(false);
  const [reloaded, setReloaded] = useState(false);

  const { smBelow, smAbove, mdAbove, mdBelow } = useBreakpoints();

  let filteredItems = [
    ...(filteredItems_initial?.length > 0 ? filteredItems_initial : []),
  ].sort(dateComparison);

  const handleGetFileType = async (url) => {
    setLoadingFilePreview(true);
    await fetch(url)
      .then((response) => {
        let filePreview = {
          filetype: response.headers
            .get('Content-Type')
            .slice(response.headers.get('Content-Type').lastIndexOf('/') + 1),
          [documentURLKey]: url,
        };
        setShowFilePreview(filePreview);
        setLoadingFilePreview(false);
      })
      .catch((error) => [setLoadingFilePreview(false)]);
  };

  const handleDownloadFile = (url) => {
    fetch(url).then((response) => {
      // console.log(
      //   'response.headers',
      //   response.headers
      //     .get('Content-Type')
      //     .slice(response.headers.get('Content-Type').lastIndexOf('/') + 1),
      // );
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download =
          'download.' +
          response.headers
            .get('Content-Type')
            .slice(response.headers.get('Content-Type').lastIndexOf('/') + 1);
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  const handleFilePreviewClose = (event, data) => {
    setShowFilePreview(false);
  };

  const handleOnReload = async (documentId) => {
    setReloading(true);
    try {
      let res = await documentSvc.getDocumentsByIdSTS(
        documentId,
        user?.username,
        company?.company_id,
      );
      setReloaded(true);
      setReloadedSrc(res?.data?.document?.[0]?.signed_document_url);
      // setSrc('');
    } catch (error) {
      setReloadedSrc();

      // setSrc('');
    }
    setReloading(false);
  };

  const filterItemsByKeyword = (items, searchKeyword) => {
    let result = items;
    let isFolder = mainFolder && mainFolder['hasSubFolders'];
    if (searchKeyword) {
      setLoading(true);

      documentSvc
        .getDocumentsByFileName(2, company?.company_id, searchKeyword)
        .then((rs) => {
          console.log('searchFiles: ', rs);
          setFilteredItems(rs);
          setShowSearchResult(true);
          setLoading(false);
        })
        .catch((error) => {
          console.log('searchFiles error: ', error);
          setFilteredItems([]);
          setLoading(false);
        });
      // documentSvc
      //   .searchFiles(searchKeyword, company?.company_id)
      //   .then((rs) => {
      //     console.log('searchFiles: ', rs);
      //     setFilteredItems(rs);
      //     setShowSearchResult(true);
      //     setLoading(false);
      //   })
      //   .catch((error) => {
      //     console.log('searchFiles error: ', error);
      //     setFilteredItems([]);
      //     setLoading(false);
      //   });

      // result = result.filter((anItem) => {
      //   let title = isFolder ? anItem['name'] : anItem['document_type_name'];
      //   if (title) {
      //     if (title.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1) {
      //       return true;
      //     }
      //   }
      //   return false;
      // });
    } else {
      setFilteredItems(result);
    }
    return result;
  };

  useEffect(() => {
    setFilteredItems(items);
    setReloadedSrc();
    setReloaded(false);
    setReloading(false);
  }, [items]);
  // useEffect(() => {
  //   if (searchKeyword) {
  //     filterItemsByKeyword(items, searchKeyword);
  //   } else {
  //     setFilteredItems(items);
  //   }
  // }, [searchKeyword, items]);

  useEffect(() => {
    setSelectedFolders([]);
    if (defaultMainFolder) {
      setMainFolder(defaultMainFolder);
    } else {
      setMainFolder(null);
    }
  }, [defaultMainFolder]);

  useEffect(() => {
    setSearchKeyword('');
    setShowSearchResult(false);
    if (selectedFolders && selectedFolders.length > 0) {
      let lastFolder = selectedFolders[selectedFolders.length - 1];
      setMainFolder(lastFolder);
    } else {
      setMainFolder(defaultMainFolder);
    }
  }, [selectedFolders]);

  const fetchDocuments = async () => {
    if (mainFolder) {
      if (mainFolder.hasSubFolders) {
        if (mainFolder.child && mainFolder.child.length > 0) {
          setItems([...mainFolder.child]);
        } else {
          setItems([]);
        }
      } else {
        if (mainFolder.document_type_name) {
          setLoading(true);
          let newFiles = [];
          await documentSvc
            .getDocumentsSTS(
              mainFolder.document_type_id,
              2,
              company?.company_id,
              user?.username,
              company?.company_id,
            )
            .then((rs) => {
              // folderList = rs;
              // setCrumbs([{ title: 'Documents', data: rs }]);
              newFiles =
                rs?.data?.document?.length > 0 ? rs?.data?.document : [];
            })
            .catch((error) => {});

          if (mainFolder?.otherIds?.length > 0) {
            for (let i = 0; i < mainFolder?.otherIds.length; i++) {
              await documentSvc
                .getDocumentsSTS(
                  mainFolder?.otherIds[i],
                  2,
                  company?.company_id,
                  user?.username,
                  company?.company_id,
                )
                .then((rs) => {
                  // folderList = rs;
                  // setCrumbs([{ title: 'Documents', data: rs }]);
                  let documents =
                    rs?.data?.document?.length > 0 ? rs?.data?.document : [];
                  newFiles = [...newFiles, ...documents];
                })
                .catch((error) => {});
            }
          }
          setItems(newFiles);
          setFolderLoaded(true);
          setLoading(false);
          // await documentSvc
          //   .getFiles(mainFolder.document_type_name, company?.company_id)
          //   .then((rs) => {
          //     // folderList = rs;
          //     // setCrumbs([{ title: 'Documents', data: rs }]);
          //     setItems(rs);
          //   })
          //   .catch((error) => {
          //     setItems([]);
          //   })
          //   .finally(() => {
          //     setFolderLoaded(true);
          //     setLoading(false);
          //   });
        } else {
          setItems([]);
          setFolderLoaded(true);
        }
      }
    } else {
      setFolderLoaded(true);
      setItems([]);
    }
  };
  useEffect(() => {
    (async () => {
      await fetchDocuments();
    })();
  }, [mainFolder]);

  const tableFolderColumns = [
    {
      title: mainFolder
        ? mainFolder.document_type_name || mainFolder.name
        : 'Undefined',
      dataIndex: 'name',
      render: (text) => {
        return (
          <Box display="flex" alignItems="center">
            <Box display="flex" mr={1} alignItems="center">
              <FolderOutlinedIcon />
            </Box>
            <Box flex={1}>{text}</Box>
            <Box display="flex" alignItems="center">
              <ChevronRightOutlinedIcon />
            </Box>
          </Box>
        );
      },
      cellProps: {
        component: 'th',
        scope: 'row',
      },
    },
  ];

  const getFileNameFromURL = (value) => {
    let result = value;

    const urlKey = `amazonaws.com/`;
    if (value.indexOf(urlKey) >= 0) {
      let urlArray = value.split(urlKey);
      if (urlArray?.[1]?.length > 36) {
        result = urlArray[1].substring(36);
        try {
          result = decodeURI(result);
        } catch (error) {}
      }
    }

    return result;
  };

  const tableFileColumns = [
    {
      title: mainFolder
        ? mainFolder.document_type_name || mainFolder.name
        : 'Undefined',
      dataIndex: 'document_type_name',
      cellProps: {
        component: 'th',
        scope: 'row',
      },
      render: (text, data) => {
        let filename = data?.['file_name'] || '-';
        if (data?.['file_name']) {
          filename = getFileNameFromURL(data?.['file_name']);
        }
        // if (data[documentURLKey]) {
        //   let urlArray = data[documentURLKey].split(`amazonaws.com/`);
        //   if (urlArray?.[1]?.length > 36) {
        //     filename = urlArray[1].substring(36);
        //     filename = decodeURI(filename);
        //   }
        // }
        return (
          <Box display="flex" alignItems="center">
            <Box display="flex" mr={1} alignItems="center">
              <DescriptionOutlinedIcon />
            </Box>
            <Box flex={1}>{filename}</Box>
          </Box>
        );
      },
    },
    {
      title: 'Last Opened',
      dataIndex: 'uploaded_date',
      cellProps: {
        className: (mdBelow && smAbove) || mdAbove ? classes.dateColumn : '',
      },
      render: (text) => {
        let result = '-';
        if (text) {
          result = moment(text).format('DD/MM/YYYY');
        }
        return <Typography>{result}</Typography>;
      },
    },
    {
      title: 'Action',
      cellProps: {
        className: classes.actionColumn,
      },
      render: (_, data, index) => {
        // return null;
        let disablePreview = false;
        let fileType = null;

        if (data?.file_type) {
          try {
            fileType = data?.file_type?.toLocaleLowerCase();
          } catch (error) {}
        }
        if (
          !(
            fileType == null ||
            fileType?.indexOf('pdf') >= 0 ||
            fileType?.indexOf('png') >= 0 ||
            fileType?.indexOf('jpeg') >= 0
          )
        ) {
          disablePreview = true;
        }

        return (
          <CustomMenu>
            <FileViewerButton2
              srcType="url"
              src={data?.[documentURLKey]}
              size="medium"
              documentId={data?.document_id}
              filePreviewProps={{
                onReload: () => handleOnReload(data?.document_id),
              }}
              loading={reloading}
            >
              {(fileViewerButtonProps) => {
                return (
                  <>
                    <MenuItem
                      onClick={(e) => {
                        if (fileViewerButtonProps?.handlePreviewOpen) {
                          fileViewerButtonProps.handlePreviewOpen();
                        }
                      }}
                      disabled={disablePreview}
                    >
                      <ListItemIcon>
                        <VisibilityOutlinedIcon />
                      </ListItemIcon>
                      Preview
                    </MenuItem>
                  </>
                );
              }}
            </FileViewerButton2>
            <MenuItem
              onClick={(e) => {
                if (window && window.location && data[documentURLKey]) {
                  let mailToLink = `mailto:?body=${data[documentURLKey]}`;
                  window.location.href = mailToLink;
                }
              }}
            >
              <ListItemIcon>
                <NearMeOutlinedIcon />
              </ListItemIcon>
              Email
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                if (data[documentURLKey]) {
                  handleDownloadFile(data[documentURLKey]);
                }
              }}
            >
              <ListItemIcon>
                <CloudDownloadOutlinedIcon />
              </ListItemIcon>
              Download
            </MenuItem>
            {/* <MenuItem
                onClick={(e) => {

                }}
                className={classes.textDanger}
              >
                <ListItemIcon className={classes.textDanger}>
                  <DeleteOutlinedIcon />
                </ListItemIcon>
                Remove
              </MenuItem> */}
          </CustomMenu>
        );
      },
    },
  ];

  let breadcrumbItems = [
    {
      ...defaultMainFolder,
      onClick: () => {
        setSelectedFolders([]);
      },
    },
  ];
  if (selectedFolders && selectedFolders.length > 0) {
    selectedFolders.forEach((aFolder, index) => {
      breadcrumbItems.push({
        ...aFolder,
        onClick: () => {
          setSelectedFolders(selectedFolders.slice(0, index + 1));
        },
      });
    });
  }

  console.log('reloadedSrc', reloadedSrc);
  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        breadcrumbItems={breadcrumbItems}
        onBack={() => {
          setSelectedFolders(
            selectedFolders.slice(0, selectedFolders.length - 1),
          );
        }}
        header={
          mainFolder ? (
            <Box
              display="flex"
              // justifyContent="flex-end"
              // flexDirection={smBelow ? 'column' : 'row'}
              flexDirection={'row'}
              flexWrap="no-wrap"
              width="100%"
            >
              <Box
                // width={smBelow ? '100%' : 'auto'}
                width={'100%'}
              >
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search for anything..."
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={(e) => {
                      setSearchKeyword(e.target.value);
                    }}
                    value={searchKeyword}
                  />
                </div>
              </Box>
              <Box
                // ml={smBelow ? 0 : 1}
                // mt={smBelow ? 2 : 0}
                ml={1}
                alignSelf={smBelow ? 'flex-end' : 'flex-start'}
              >
                <Button
                  disableElevation
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    filterItemsByKeyword(items, searchKeyword);
                  }}
                >
                  Search
                </Button>
              </Box>
              <Box
                // ml={smBelow ? 0 : 1}
                // mt={smBelow ? 2 : 0}
                ml={1}
                alignSelf={smBelow ? 'flex-end' : 'flex-start'}
              >
                <Button
                  disableElevation
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    (async () => {
                      await fetchDocuments();
                    })();
                  }}
                >
                  <RefreshIcon />
                </Button>
              </Box>{' '}
            </Box>
          ) : null
        }
      />
      <Divider />

      {mainFolder ? (
        <Box flex={1}>
          {loading ? (
            <LoadingComponent />
          ) : showSearchResult ? (
            <FilesTable
              columns={tableFileColumns}
              data={filteredItems}
              customCell={StyledTableCell}
            />
          ) : mainFolder.hasSubFolders ? (
            <FilesTable
              columns={tableFolderColumns}
              data={filteredItems}
              customCell={StyledTableCell}
              onClickRow={(rowData) => {
                setSelectedFolders([...selectedFolders, rowData]);
              }}
            />
          ) : (
            <FilesTable
              columns={tableFileColumns}
              data={filteredItems}
              customCell={StyledTableCell}
            />
          )}
        </Box>
      ) : (
        <EmptyComponent>Please select a Folder</EmptyComponent>
      )}
      {loadingFilePreview ? (
        <LoadingComponent />
      ) : (
        showFilePreview && (
          <FilePreviewModal
            filetype={showFilePreview.filetype}
            fileurl={showFilePreview?.[documentURLKey]}
            IsModalOpened={Boolean(showFilePreview)}
            onCloseModal={handleFilePreviewClose}
            onAfterOpen={() => {}}
          />
        )
      )}
    </>
  );
};

export default DocumentFiles;

const useTableStyles = makeStyles((theme) => ({
  pagination: {
    width: '100%',
  },
  table: {
    width: '100%',
    tableLayout: 'fixed',
  },
  clickableStyle: {
    cursor: 'pointer',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.action.hover,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FAFAFB',
    textTransform: 'uppercase',
  },
}))(TableCell);

const FilesTable = (props) => {
  const { data = [], columns = [], onClickRow, customCell = TableCell } = props;
  const classes = useTableStyles();

  const FilesTableCell = customCell;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setRowsPerPage(10);
    setPage(0);
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let dataByPage = data.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="document files table" className={classes.table}>
          {columns && columns.length > 0 ? (
            <>
              <TableHead>
                <TableRow>
                  {columns.map((aColumn, index) => {
                    const {
                      title = '',
                      dataIndex,
                      render,
                      renderHeader,
                      cellProps = {},
                      ...restColumnProps
                    } = aColumn;
                    return (
                      <FilesTableCell key={index} {...cellProps}>
                        {renderHeader ? renderHeader(title, index) : title}
                      </FilesTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {dataByPage && dataByPage.length > 0 ? (
                  dataByPage.map((aData, index) => {
                    const handleOnClickRow = () => {
                      if (onClickRow) {
                        onClickRow(aData, index);
                      }
                    };
                    if (aData.type == 'folder') {
                      return (
                        <FolderRow
                          key={index}
                          index={index}
                          columns={columns}
                          data={aData}
                          onClick={onClickRow}
                          className={onClickRow ? classes.clickableStyle : ''}
                          // Cell={StyledTableCell}
                        />
                      );
                    } else {
                      return (
                        <FileRow
                          key={index}
                          index={index}
                          columns={columns}
                          data={aData}
                          // Cell={StyledTableCell}
                        />
                      );
                    }
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <EmptyComponent>Files not found</EmptyComponent>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </>
          ) : null}
        </Table>
      </TableContainer>
      {dataByPage && dataByPage.length > 0 && (
        <>
          <Box
            p={2}
          >{`Showing ${dataByPage.length} items out of ${data.length} results found`}</Box>
          <TablePagination
            className={classes.pagination}
            // rowsPerPageOptions={[10, 25, 100]}
            rowsPerPageOptions={[]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => {
              return '';
            }}
          />
        </>
      )}
    </>
  );
};

const FolderRow = (props) => {
  const {
    index,
    columns,
    data,
    className = '',
    onClick,
    Cell = TableCell,
  } = props;

  const handleOnClickRow = () => {
    if (onClick) {
      onClick(data, index);
    }
  };
  return (
    <TableRow onClick={handleOnClickRow} className={`${className}`}>
      {columns.map((aColumn, columnIndex) => {
        const {
          dataIndex,
          cellProps = {},
          render,
          renderHeader,
          ...restColumnProps
        } = aColumn;
        let columnData = data[dataIndex] || '-';

        return (
          <Cell
            key={columnIndex}
            // component="th"
            // scope="row"
            // onClick={handleOnClickRow}
            {...cellProps}
          >
            {render ? render(columnData, data, index) : columnData}
          </Cell>
        );
      })}
    </TableRow>
  );
};

const FileRow = (props) => {
  const {
    index,
    columns,
    data,
    className = '',
    onClick,
    Cell = TableCell,
  } = props;

  return (
    <TableRow className={`${className}`}>
      {columns.map((aColumn, columnIndex) => {
        const {
          dataIndex,
          cellProps = {},
          render,
          renderHeader,
          ...restColumnProps
        } = aColumn;
        let columnData = data[dataIndex] || '-';

        return (
          <Cell
            key={columnIndex}
            // component="th"
            // scope="row"
            // onClick={handleOnClickRow}
            {...cellProps}
          >
            {render ? render(columnData, data, index) : columnData}
          </Cell>
        );
      })}
    </TableRow>
  );
};

const CustomMenu = (props) => {
  const { children, ...restProps } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenActionMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseActionMenu = (e) => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton size="small" onClick={handleOpenActionMenu}>
        <MoreVertOutlinedIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseActionMenu}
      >
        {children}
      </Menu>
    </>
  );
};
