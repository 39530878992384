import ENDPOINTS from 'src/constants/endpoints';
import axios from 'axios';
import { Cookies } from 'react-cookie';

const accessToken = new Cookies().get('accessToken');

let documentURL = `${process.env.REACT_APP_API_V2_URL2}/document`;
let documentURL_original = process.env.REACT_APP_DOCUMENT_URL;

const getFiles = async (docType, companyId) => {
  const res = await axios.get(
    `${documentURL}/documentsbytypename?table_type=2&primary_id=${companyId}&document_type_name=${docType}`,
    {
      headers: { Authorization: `${new Cookies().get('accessToken')}` },
    },
  );
  return res.data?.document;
};

const searchFiles = async (keyword, companyId) => {
  const res = await axios.get(
    `${documentURL}/documentsbytypename?table_type=2&primary_id=${companyId}&document_type_name=${keyword}`,
    {
      headers: { Authorization: `${new Cookies().get('accessToken')}` },
    },
  );
  return res.data?.document;
};

const getFolders = async () => {
  return Promise.resolve(documentFolders);
};

const getDocumentsByFileName = async (
  table_type,
  primary_id,
  filename = '',
) => {
  let res = [];
  res = await axios.get(
    `${documentURL}/documents?table_type=${table_type}&primary_id=${primary_id}`,
    {
      headers: { Authorization: `${new Cookies().get('accessToken')}` },
    },
  );
  let filteredDocuments = res?.data?.document || [];
  if (filteredDocuments?.length > 0) {
    filteredDocuments = filteredDocuments.filter((anItem) => {
      if (anItem?.file_name) {
        let itemFilename = anItem?.file_name.toLowerCase();
        if (itemFilename.indexOf(filename.toLowerCase()) >= 0) {
          return true;
        }
      }
      return false;
    });
  }

  return filteredDocuments;
};

// public doc with access token (eg. product images)
const getDocuments = async (doc_type, table_type, primary_id) => {
  let res = [];
  res = axios.get(
    `${documentURL}/documents?doc_type=${doc_type}&table_type=${table_type}&primary_id=${primary_id}`,
    {
      headers: { Authorization: `${new Cookies().get('accessToken')}` },
    },
  );
  return res;
};

const getDocumentsByIds = async (ids = []) => {
  let idsStringArray = [];
  ids.forEach((anId) => {
    idsStringArray.push(`doc_ids=${anId}`);
  });
  let idsString = idsStringArray.join('&');

  let res = axios.get(`${documentURL}/multi-documents?${idsString}`, {
    headers: { Authorization: `${new Cookies().get('accessToken')}` },
  });
  return res;
};

const getDocumentsById = async (id) => {
  let res = axios.get(`${documentURL}/documents/${id}`, {
    headers: { Authorization: `${new Cookies().get('accessToken')}` },
  });
  return res;
};

const getProductImageDocumentsByIds = async (ids = []) => {
  let idsStringArray = [];
  ids.forEach((anId) => {
    idsStringArray.push(`doc_ids=${anId}`);
  });
  let idsString = idsStringArray.join('&');

  let res = axios.get(`${documentURL_original}/multi-documents?${idsString}`, {
    headers: { Authorization: `${new Cookies().get('accessToken')}` },
  });
  return res;
};

// private doc
const getDocumentsSTS = async (
  doc_type,
  table_type,
  primary_id,
  username,
  company_id,
) => {
  let res = [];
  res = axios.get(
    `${documentURL}/sts-documents?doc_type=${doc_type}&table_type=${table_type}&primary_id=${primary_id}&sysinfo_username=${username}&company_id=${company_id}`,
    {
      headers: { Authorization: `${new Cookies().get('accessToken')}` },
    },
  );
  return res;
};

const getDocumentsByIdsSTS = async (ids = [], sysinfo_username, company_id) => {
  let idsStringArray = [];
  ids.forEach((anId) => {
    idsStringArray.push(`doc_ids=${anId}`);
  });
  if (sysinfo_username) {
    idsStringArray.push(`sysinfo_username=${sysinfo_username}`);
  }
  if (company_id) {
    idsStringArray.push(`company_id=${company_id}`);
  }
  let idsString = idsStringArray.join('&');

  let res = axios.get(`${documentURL}/sts-multi-documents?${idsString}`, {
    headers: { Authorization: `${new Cookies().get('accessToken')}` },
  });
  return res;
};

const getDocumentsByIdSTS = async (id, username, company_id) => {
  // let res = axios.get(
  //   `${documentURL}/sts-documents/${id}?sysinfo_username=${username}&company_id=${company_id}`,
  //   {
  //     headers: { Authorization: `${new Cookies().get('accessToken')}` },
  //   },
  // );

  return new Promise((resolve, reject) => {
    axios
      .get(
        `${documentURL}/sts-documents/${id}?sysinfo_username=${username}&company_id=${company_id}`,
        {
          headers: { Authorization: `${new Cookies().get('accessToken')}` },
        },
      )
      .then((result) => {
        let newBody = result?.data?.body;
        if (typeof newBody == 'string') {
          try {
            newBody = JSON.parse(result?.data?.body);
          } catch (error) {}
        }
        resolve({
          ...result,
          data: {
            ...result.data,
            ...newBody,
          },
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// public doc without access token
const getDocumentsPublic = async (doc_type, table_type, primary_id) => {
  let res = [];
  res = await axios.get(
    `${documentURL_original}/documents?doc_type=${doc_type}&table_type=${table_type}&primary_id=${primary_id}`,
  );
  return res;
};

const documentSvc = {
  getFolders,
  searchFiles,
  getFiles,
  getDocumentsByFileName,
  getDocuments,
  getDocumentsByIds,
  getDocumentsById,
  getDocumentsSTS,
  getDocumentsByIdsSTS,
  getDocumentsByIdSTS,
  getDocumentsPublic,
  getProductImageDocumentsByIds,
};

export default documentSvc;

const documentFolders = [
  {
    name: 'A - Bizfile Business Profile / Certificate of Incorporation',
    type: 'folder',
    hasSubFolders: false,
    document_type_id: 15,
    otherIds: [18],
    document_type_name: 'Biz File',
  },
  {
    name: 'B - Constitution',
    type: 'folder',
    document_type_id: 28,
    otherIds: [58],
    document_type_name: 'Constitutional Documents',
    hasSubFolders: false,
  },
  {
    name: 'C - ACRA Filings',
    type: 'folder',
    hasSubFolders: true,
    document_type_id: 115,
    document_type_name: 'ACRA Filings',
    child: [
      {
        name: '1 - ACRA Filings',
        type: 'folder',
        hasSubFolders: false,
        document_type_id: 115,
        document_type_name: 'ACRA Filings',
      },
      {
        name: '2 - Financial Statements',
        type: 'folder',
        hasSubFolders: false,
        document_type_id: 45,
        otherIds: [5, 12, 57],
        document_type_name: 'Financial Statements',
        // document_type_name: 'RoC Annual - Form AOC-4 - Financial Statements',
      },
    ],
  },
  {
    name: 'D - Share Certificates',
    type: 'folder',
    hasSubFolders: false,
    document_type_id: 103,
    document_type_name: 'Share Certificates',
  },
  {
    name: 'E - Registers',
    type: 'folder',
    hasSubFolders: true,
    document_type_id: 116,
    document_type_name: 'Registers',
    child: [
      {
        name: '1 - Register of Directors and CEO',
        type: 'folder',
        document_type_id: 136,
        document_type_name: 'Register of Directors and CEO',
        hasSubFolders: false,
      },
      {
        name:
          '2 - Register of Directors and CEO Shareholdings and Other Interest',
        type: 'folder',
        document_type_id: 121,
        document_type_name:
          'Register of Directors and CEO Shareholdings and Other Interest',
        hasSubFolders: false,
      },
      {
        name: '3 - Register of Managers, Secretaries and Auditors',
        type: 'folder',
        document_type_id: 122,
        otherIds: [144, 145],
        document_type_name: 'Register of Managers, Secretaries and Auditors',
        hasSubFolders: false,
      },
      {
        name: '4 - Register of Mortgages and Charges',
        type: 'folder',
        document_type_id: 123,
        document_type_name: 'Register of Mortgages and Charges',
        hasSubFolders: false,
      },
      {
        name: '5 - Register of Registrable Controllers',
        type: 'folder',
        document_type_id: 143,
        document_type_name: 'Register of Registrable Controllers',
        hasSubFolders: false,
      },
      {
        name: '6 - Register of Nominee Directors',
        type: 'folder',
        document_type_id: 125,
        document_type_name: 'Register of Nominee Directors',
        hasSubFolders: false,
      },
      {
        name: '7 - Register of Members',
        type: 'folder',
        hasSubFolders: false,
        document_type_id: 146,
        document_type_name: 'E-Register of Members',
      },
      {
        name: '8 - Register of Debenture',
        type: 'folder',
        document_type_id: 126,
        document_type_name: 'Register of Debenture',
        hasSubFolders: false,
      },
    ],
  },
  {
    name: 'F - Minutes and Resolutions',
    type: 'folder',
    document_type_id: 117,
    document_type_name: 'Minutes and Resolutions',
    hasSubFolders: true,
    child: [
      {
        name: '1 - Directors Meetings and Resolutions',
        type: 'folder',
        document_type_id: 127,
        document_type_name: 'Directors Meetings and Resolutions',
        hasSubFolders: false,
      },
      {
        name: '2 - Shareholders Meetings and Resolutions',
        type: 'folder',
        document_type_id: 128,
        document_type_name: 'Shareholders Meetings and Resolutions',
        hasSubFolders: false,
      },
    ],
  },

  {
    name: 'G - Miscellaneous',
    type: 'folder',
    document_type_id: 119,
    document_type_name: 'Miscellaneous',
    hasSubFolders: false,
  },
  {
    name: 'H - Scanned Mail',
    type: 'folder',
    document_type_id: 120,
    document_type_name: 'Scanned Mail',
    hasSubFolders: false,
  },
];
