import axios from './customAxios';
import { Config } from 'src/config';
import helper from 'src/helper';
import ENDPOINTS from 'src/constants/endpoints';
import { Cookies } from 'react-cookie';

const timeline = async (companyId) => {
  const accessToken = new Cookies().get('accessToken');
  const { data } = await axios.get(
    `${ENDPOINTS.COMPANY}/timeline/company/${companyId}`,
    {
      headers: { Authorization: `${accessToken}` },
    },
  );

  if (data && !data.is_success) throw Error(data?.message);

  return helper.isEmpty(data?.timeline) ? [] : data?.timeline;
};

const timelineSvc = { timeline };

export default timelineSvc;
