// backgroundColor: '#F7F7F7'
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';

import CustomTableHead from './TableHead';

const CustomTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    // marginBottom: theme.spacing(2),
  },
  table: {
    // minWidth: 750,
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function CustomTable(props) {
  const {
    data = [],
    columns = [],
    rowSelection = {},
    rowKey = '',
    size = 'medium',
    paperProps = {},
    striped = false,
  } = props;
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');

  // selections
  const { selectedRowKeys: selected, onChange: setSelected } = rowSelection;
  // const [selected, setSelected] = useState([]);
  let withSelection = selected && setSelected;

  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (withSelection) {
      if (event.target.checked) {
        const newSelecteds = data.map((n, index) => {
          if (rowKey && n?.[rowKey]) {
            return n[rowKey];
          }
          return index;
        });
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    }
  };

  const handleClick = (event, name, rowData, index) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  let CustomTableRowComponent = striped ? CustomTableRow : TableRow;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} {...paperProps}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={size}
            aria-label="enhanced table"
          >
            <CustomTableHead
              columns={columns}
              classes={classes}
              numSelected={selected && selected.length}
              order={order}
              orderBy={orderBy}
              withSelection={withSelection}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {/* {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
              {data.map((row, index) => {
                let rowId = rowKey ? row?.[rowKey] : index;
                const isItemSelected = withSelection
                  ? isSelected(rowId)
                  : false;
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <CustomTableRowComponent
                    hover
                    onClick={(event) => {
                      if (withSelection) {
                        handleClick(event, rowId, row, index);
                      }
                    }}
                    // role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    color="primary"
                  >
                    {withSelection && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          color="primary"
                        />
                      </TableCell>
                    )}
                    {columns.map((aColumn, index2) => {
                      let cellData = row[aColumn.id];
                      return (
                        <TableCell
                          key={index2}
                          id={labelId}
                          align={aColumn.numeric ? 'right' : 'left'}
                        >
                          {aColumn.render
                            ? aColumn.render(cellData, row, index)
                            : cellData}
                        </TableCell>
                      );
                    })}
                  </CustomTableRowComponent>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{ height: (size == 'small' ? 33 : 53) * emptyRows }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </div>
  );
}

CustomTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  rowSelection: PropTypes.object,
  rowKey: PropTypes.string,
  size: PropTypes.string,
};

export default CustomTable;
