import React, { useState, useEffect } from 'react';
import { Button, Box, IconButton } from '@material-ui/core';
// import FileViewer from 'react-file-viewer';
import { useSnackbar } from 'notistack';
import GetAppIcon from '@material-ui/icons/GetApp';

import CustomModal from '../CustomModal';
import FilePreview from './Preview';
import documentSvc from 'src/services/document';

const FileViewer = React.forwardRef((props, ref) => {
  const {
    src,
    srcType = 'file', // "file" or "url"

    disabled = false,
    size = 'medium',
    modalProps = {},
    filePreviewProps: defaultFilePreviewProps = {},
    onChange,
    className = '',
    style = {},
    children,
    ...restProps
  } = props;

  const [showPreview, setShowPreview] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handlePreviewOpen = () => {
    setShowPreview(true);
  };
  const handlePreviewClose = () => {
    setShowPreview(false);
  };

  const handlePreviewOnError = (e) => {
    console.log('handlePreviewOnError', e);
  };

  let filePreviewProps = { ...defaultFilePreviewProps };
  if (srcType == 'file') {
    filePreviewProps['file'] = src;
  } else if (srcType == 'url') {
    filePreviewProps['url'] = src;
  }

  return (
    <>
      {children &&
        (typeof children == 'function' ? (
          <>
            {children({
              handlePreviewOpen: handlePreviewOpen,
            })}
          </>
        ) : (
          <Box display="flex">
            <Box mr={2}>
              <Button
                variant="contained"
                size={size}
                onClick={handlePreviewOpen}
                className={`${className}`}
                style={style}
                disabled={disabled}
                {...restProps}
              >
                {children}
              </Button>
            </Box>
          </Box>
        ))}
      <CustomModal
        show={showPreview}
        onHide={handlePreviewClose}
        // scrollable={false}
        title={
          <Box>
            Preview{' '}
            {/* {srcType == 'url' ? (
              <IconButton
                aria-label="downloadfile"
                color="primary"
                as="a"
                href={src}
                size="small"
              >
                <GetAppIcon />
              </IconButton>
            ) : null} */}
          </Box>
        }
        //   style={{ maxHeight: '100%' }}
      >
        {src ? (
          <FilePreview
            type={srcType}
            onError={handlePreviewOnError}
            {...filePreviewProps}
          />
        ) : (
          <div>Source Not Found</div>
        )}
      </CustomModal>
    </>
  );
  return (
    <Box display="flex">
      <Box mr={2}>
        <Button
          variant="contained"
          size={size}
          onClick={handlePreviewOpen}
          className={`${className}`}
          style={style}
          disabled={disabled}
          {...restProps}
        >
          {children}
        </Button>
        <CustomModal
          show={showPreview}
          onHide={handlePreviewClose}
          // scrollable={false}
          title={
            <Box>
              Preview{' '}
              {srcType == 'url' ? (
                <IconButton
                  aria-label="downloadfile"
                  color="primary"
                  as="a"
                  href={src}
                  size="small"
                >
                  <GetAppIcon />
                </IconButton>
              ) : null}
            </Box>
          }
          //   style={{ maxHeight: '100%' }}
        >
          <FilePreview
            type={srcType}
            onError={handlePreviewOnError}
            {...filePreviewProps}
          />
        </CustomModal>
      </Box>
    </Box>
  );
});

export default FileViewer;
